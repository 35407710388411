import React, { FC, useEffect, useState } from "react";
import { IconButton, Tooltip } from '@mui/material';
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from "@mui/icons-material/Person";
import { Button, Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { ReactComponent as PasswIcon } from "src/assets/icons/passw2.svg";
import { theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { useUsers } from "src/hooks/users";
import { useConfirmation } from "src/hooks/confirmation";
import { UserProps, IUsersItemCard } from "src/types";
import { isUser } from "src/types/hooks/users";

const UsersItemCard: FC<IUsersItemCard> = (params: IUsersItemCard) => {
  if (!params.user && !params.userId)
    throw new Error("Is required to pass user or userId in params");

  const authHooks = useAuth();
  const userHook = useUsers();
  const [user, setUser] = useState<UserProps | undefined>(params.user ?? undefined);
  const { showConfirmation, hideConfirmation } = useConfirmation();
  useEffect(() => {
    if (!user) fetchData();
  }, [user]);

  const canManageUsers: boolean = (authHooks.hasRights && authHooks.hasRights('manage-users')) ?? false;

  const fetchData = async () => {
    if (!params.userId) return;
    const auxUser: UserProps = await userHook.fetchEntity(params.userId);
    setUser(auxUser);
  };

  const deleteUser = async () => {
    showConfirmation("Deseja realmente excluir esse usúario?", undefined, undefined, undefined, async () => {
      hideConfirmation();
      if (params.userId) {
        await userHook.deleteEntity(params.userId);
      }
      params.onRefresh();
    })

  }
  return (
    <Container fluid flex veryPadded color={theme.palette.backgroundElements.main} sx={{ border: `solid 1px ${theme.palette.backgroundElements.dark}` }} >
      <Grid container rowSpacing={2} columnSpacing={2} display="flex">
        <Grid item xs={3} md={2} lg={1} display="flex" justifyContent="center">
          {user ? (
            <PersonIcon style={{ color: theme.palette.secondary.main, width: "73px", height: "73px" }} />
          ) : (
            <Skeleton variant="rectangular" width={73} height={73} />
          )}
        </Grid>
        <Grid item xs={9} md={5} lg={8}>
          <Container fluid>
            {user ? (
              <Text subtitle medium color={theme.palette.primary.main}>
                {user?.firstName} {user?.lastName}
              </Text>
            ) : (
              <Skeleton variant="rectangular" width={150} height={20} />
            )}
            <Spacing top={PADDING} />
            <Text info color={theme.palette.secondary.main}>
              {params.clientName}
            </Text>
            <Spacing top={PADDING} />
            {user ? (
              <Text
                secondaryInfo
                size={11}
                color={theme.palette.textInfo.main}
              >
                {user?.email}
              </Text>
            ) : (
              <Skeleton variant="rectangular" width={150} height={10} />
            )}
            <Container inline>
              <PasswIcon />
              <Spacing left={PADDING} />
              <Container fluid>
                <Spacing top={2} />
                <Text
                  secondaryInfo
                  size={11}
                  color={theme.palette.textInfo.main}
                >
                  Senha Cadastrada: ********
                </Text>
              </Container>
            </Container>
          </Container>
        </Grid>
        {canManageUsers &&
          <Grid item xs={12} md={5} lg={3} display="flex" alignItems="flex-end" justifyContent="flex-end">
            <Container fluid centered>
              <Container inline fluid>
                <Button
                  disabled={!user}
                  size="small"
                  text="Editar Informações"
                  color={theme.palette.primary.main}
                  onClick={() => user && params.onEdit(user)}
                  sx={{ minWidth: 140 }}
                />
                <Spacing right={VERY_PADDING} />
                <Button
                  disabled={!user}
                  size="small"
                  text="Excluir Usuário"
                  color="#A1A1A1"
                  onClick={() => deleteUser()}
                  sx={{ minWidth: 140 }}
                />
              </Container>
            </Container>
          </Grid>
        }
      </Grid>
    </Container>
  );
};

export default UsersItemCard;

export function convertTableRowPropsToIUser(props: any) {
  const data: any = props.row;
  let user: UserProps | undefined = undefined;
  if (isUser(data)) {
    user = props.row;
  }
  return user;
}
