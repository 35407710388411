import React, { FC, createContext, useContext, useState } from "react";

import { ProductServices } from "../services/products";
import { IProduct, IProductContext, IHookProvider } from "../types";

const ProductsContext = createContext<IProductContext>({} as IProductContext);

export const ProductsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const productServices = new ProductServices();
    const [entities, setProducts] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchEntity, setSearchEntity] = useState<string>("");
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    const [paginate, setPaginate] = useState<number>(0);

    const createNewEntity = async (_product: IProduct) => {
        try {
            const product = await productServices.createEntity(_product);
            fetchEntities();
            return product;
        } catch (_err) {
            throw _err;
        }
    };

    const editEntity = async (_id: string, _product: IProduct) => {
        try {
            const product = await productServices.updateEntity(_id, _product);
            fetchEntities();
            return product;
        } catch (_err) {
            throw _err;
        }
    };

    const deleteEntity = async (_id: string) => {
        try {
            const product = await productServices.destroyEntity(_id);
            fetchEntities();
            return product;
        } catch (_err) {
            throw _err;
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        try {
            const subscriptionsPlans: IProduct[] = await productServices.getEntities();
            setProducts([...subscriptionsPlans]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchEntity = async (_id: string) => {
        const product: IProduct = await productServices.getEntity(_id);
        return product;
    };

    return (
        <ProductsContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,
            }}
        >
            {_params.children}
        </ProductsContext.Provider>
    );
};

export function useProducts() {
    const context = useContext(ProductsContext);

    if (!context) {
        throw new Error(
            "useProducts must be used within an ProductsProvider"
        );
    }

    return context;
}
