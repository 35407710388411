import { FC } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Theme, Tooltip } from "@mui/material";
import { Info as InfoIcon } from "@mui/icons-material";
import { CSSProperties } from "styled-components";
import moment from "moment";
import { Container, Text } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { SplitButton, theme } from "src/components";
import { useResponsive } from "src/hooks/responsive";
import { useDocuments } from "src/hooks/documents";
import { IDocument, IDocumentsDatalogItemCard } from "src/types";
import { parseDateToString, DATE_TIME_FORMAT } from "src/utils/dates";

const DocumentsDatalogItemCard: FC<IDocumentsDatalogItemCard> = (props: IDocumentsDatalogItemCard) => {
  const { documentsTypes } = useDocuments();
  const navigate = useNavigate();
  const responsiveHooks = useResponsive();

  const isHome = window.location.pathname == "/";
  const textFontSize: number = responsiveHooks.lgUp ? 15 : 12;

  const getResponseTime = (_document: IDocument) => {
    const initialDatetime: Date | undefined = _document.createdAt;
    const finalDatetime: Date | undefined = _document.finishedAt ?? _document.errorAt;
    if (initialDatetime && finalDatetime) {
      const initialMoment = moment(initialDatetime);
      const finalMoment = moment(finalDatetime);
      const duration = moment.duration(finalMoment.diff(initialMoment));
      return `${duration.asSeconds()} segundos`;
    }
    return "";
  }

  return (
    <Container fluid verticalPadded>
      <Container relative fluid centered minHeight="60px" sx={containerStyle(theme)}>
        <Box position={"absolute"} left={0} top={0} width={7} height={"101%"} sx={{ backgroundColor: theme.palette.secondary.main, borderTopLeftRadius: "2px", borderBottomLeftRadius: "2px" }} />

        <Grid container rowSpacing={responsiveHooks.mdDown ? 2 : 1} columnSpacing={0} flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} md={1} alignItems="center" justifyContent="center">
            <Text size={textFontSize} color={theme.palette.primary.dark}>#{props.document.index}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={2} alignItems="center" justifyContent="center">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Modelo: </Text>
            }
            {
              props.document.model ?
                <Text size={textFontSize} color={theme.palette.primary.dark}>{props.document.model.description}</Text>
                :
                props.document.documentCustomModel ?
                  <Text size={textFontSize} color={theme.palette.primary.dark}>{props.document.documentCustomModel.name}</Text>
                  :
                  <Text size={textFontSize} color={theme.palette.primary.dark}>{documentsTypes.find((_document) => _document.value == props.document?.type)?.description}</Text>
            }
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Data e hora: </Text>
            }
            <Text size={textFontSize} color={theme.palette.primary.dark}>
              {props.document.createdAt ? parseDateToString(props.document.createdAt, DATE_TIME_FORMAT) : ""}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={1} alignItems="center" justifyContent="center">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Origem:</Text>
            }
            {props.document.source == 0 ?
              <Text size={textFontSize} color={theme.palette.primary.dark}>API</Text> :
              props.document.source == 1 ?
                <Text size={textFontSize} color={theme.palette.primary.dark}>Plataforma</Text> :
                null
            }
          </Grid>
          <Grid item xs={12} sm={6} md={2} alignItems="center" justifyContent="center">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Usuário:</Text>
            }
            {props.document.userName &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>{props.document.userName}</Text>
            }
          </Grid>
          <Grid item xs={12} sm={6} md={1.5}>
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Tempo de resposta: </Text>
            }
            <Text size={textFontSize} color={theme.palette.primary.dark}>
              {getResponseTime(props.document)}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={3} display="flex" alignItems="center" justifyContent="center">
            {(props.document.status == 0 || props.document.status > 2) &&
              <Text center bold color={theme.palette.primary.dark}>
                {props.document.statusDesc}
              </Text>
            }
            {props.document.status === 2 &&
              <Container inline>
                <Text center bold color={theme.palette.error.main}>
                  {props.document.statusVision ? props.document.statusVision.statusMessage : props.document.statusDesc}
                </Text>
                {(props.document.data != undefined && props.document.data.error != undefined) &&
                  <Tooltip placement="top" title={props.document.data?.error ?? ""}>
                    <InfoIcon color="error" sx={{ fontSize: 20, marginBottom: 2 }} />
                  </Tooltip>
                }
              </Container>
            }
            {props.document.status == 1 &&
              <SplitButton
                text="Resposta do Sistema" textFontSize={textFontSize - 1}
                onClick={() => navigate(`/documents/${props.document.id}`)}
                options={[]}
                color={theme.palette.primary.dark} />
            }
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default DocumentsDatalogItemCard;

const containerStyle = (theme: Theme): CSSProperties => {
  return {
    background: "rgba(255, 255, 255, 0.80)",
    border: `solid 1px ${theme.palette.secondary.main}`,
    borderRadius: "2px",
    paddingLeft: VERY_PADDING * 1.5,
    paddingRight: VERY_PADDING * 1.5,
    boxShadow: "3px 4px 5px 0px rgba(0, 0, 0, 0.25)"
  }
}