import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import { Container, Text } from "@pix-force/react-atoms";

import { theme } from "src/components";
import { useResponsive } from "src/hooks/responsive";
import { IInvoicesItemsListItem } from "src/types";

const InvoicesItemsListItem: FC<IInvoicesItemsListItem> = (params: IInvoicesItemsListItem) => {
  const { mdDown } = useResponsive();

  if (params.item == undefined) return null;

  return (
    <Container fluid flex veryPadded color={theme.palette.backgroundStrokeActive.main} sx={{ border: `solid 1px ${theme.palette.backgroundElements.main}` }} >
      <Grid container rowSpacing={2} columnSpacing={2} display="flex">
        <Grid item xs={12} md={6}>
          <Text subtitle medium>Descrição: </Text>
          <Text subtitle medium>
            {params.item?.description}
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Text subtitle medium>Quantidade: </Text>
          <Text subtitle medium>
            {params.item.quantity}
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Text subtitle medium>Valor: </Text>
          <Text subtitle medium>
            {params.item.amount.toFixed(2).replace('.', ',')}
          </Text>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoicesItemsListItem;
