import React, { FC } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Container } from "@pix-force/react-atoms";

import InvoicesList from "src/components/molecules/invoices/list";
import DocumentsDatalogList from "src/components/molecules/documents/datalog/list";
import SubscriptionsList from "src/components/molecules/subscriptions/list";
import { useAuth } from "src/hooks/auth";
import { BILLINGS_TAB_INDEX, CLIENT_DESC_TAB_INDEX, DATALOG_TAB_INDEX, SUBSCRIPTION_TAB_INDEX, } from "src/pages/clients/detailed/consts";
import { IClientsDetailedBottomTab } from "src/types";
import ClientsDetailedInfoCard from "../infoCard";
import PaymentFormsList from "src/components/molecules/paymentForms/list";

const ClientsDetailedBottomTab: FC<IClientsDetailedBottomTab> = (params: IClientsDetailedBottomTab) => {
    const { hasRights } = useAuth();

    return (
        <Container fluid flex horizontalCentered relative sx={{ marginBottom: 50, paddingTop: 20 }}>
            {params.currentTab == CLIENT_DESC_TAB_INDEX && (
                <ClientsDetailedInfoCard
                    client={params.client}
                    subscription={params.subscription}
                    onEditInformationsCallback={params.onEditInformations}
                    onChangeTabToDatalog={() => params.onChangeTabToDatalog(DATALOG_TAB_INDEX)}
                />
            )}
            {hasRights?.("view-clients__datalog") && params.currentTab == DATALOG_TAB_INDEX &&
                params.client && params.client.id && (
                    <Container fluid flex padded width="95%">
                        <DocumentsDatalogList clientId={params.client.id} />
                    </Container>
                )}
            {hasRights?.("view-clients__subscriptions") && params.currentTab == SUBSCRIPTION_TAB_INDEX && params.client && params.client.id && (
                <Container fluid flex padded width="95%">
                    <SubscriptionsList
                        loading={params.subscriptionLoading}
                        clientId={params.client.id}
                        subscription={params.subscription}
                        onRefresh={() => params.onRefreshSubscription()} />
                </Container>
            )}
            {hasRights?.("view-clients__payment") && params.subscription && params.currentTab == SUBSCRIPTION_TAB_INDEX && params.client && params.client.id && (
                <Container fluid flex padded width="95%">
                    <PaymentFormsList
                        loading={params.clientCardsLoading}
                        subscription={params.subscription}
                        upcomingInvoice={(params.invoices && params.invoices.length > 0 && params.invoices[0]) || undefined}
                        clientCards={params.clientCards}
                        onTicketClick={() => alert("boleto")}
                        onCardClick={() => alert("Cartao")}
                        onManageCardClick={() => params.onPaymentFormAddCards()}
                        onChangePaymentForm={params.onChangePaymentForm} />
                </Container>
            )}
            {hasRights?.("view-clients__invoices") && params.currentTab == BILLINGS_TAB_INDEX && params.client && params.client.id &&
                <Container fluid flex padded width="95%">
                    <InvoicesList clientId={params.client.id} invoices={params.invoices} onRefresh={() => params.onRefreshInvoices()} />
                </Container>
            }
            <Container fluid sx={{ position: "fixed", left: 0, bottom: 0, right: 0 }}>
                <BottomNavigation
                    showLabels
                    value={params.currentTab}
                    onChange={(event, newValue) => {
                        params.onHandleChangeCurrentTab(event, newValue)
                    }}>
                    <BottomNavigationAction label="Cliente" />
                    {hasRights?.("view-clients__datalog") && <BottomNavigationAction label="Datalog" />}
                    {hasRights?.("view-clients__subscriptions") && <BottomNavigationAction label="Assinatura" />}
                    {hasRights?.("view-clients__invoices") && <BottomNavigationAction label="Faturas" />}
                </BottomNavigation>
            </Container>
        </Container>
    );
}

export default ClientsDetailedBottomTab;
