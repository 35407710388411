import { FC } from "react";
import { Box, Grid, IconButton, Theme, Tooltip } from "@mui/material";
import { CSSProperties } from "styled-components";
import { Container, Text } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { theme } from "src/components";
import { useResponsive } from "src/hooks/responsive";
import { IDocument, IDocumentCustomModelItemCard } from "src/types";
import { parseDateToString, DATE_TIME_FORMAT } from "src/utils/dates";
import { Delete, Edit } from "@mui/icons-material";

const DocumentsDatalogItemCard: FC<IDocumentCustomModelItemCard> = (props: IDocumentCustomModelItemCard) => {
  const responsiveHooks = useResponsive();

  const textFontSize: number = responsiveHooks.lgUp ? 15 : 12;

  return (
    <Container fluid verticalPadded>
      <Container relative fluid centered minHeight="60px" sx={containerStyle(theme)}>
        <Box position={"absolute"} left={0} top={0} width={7} height={"101%"} sx={{ backgroundColor: theme.palette.secondary.main, borderTopLeftRadius: "2px", borderBottomLeftRadius: "2px" }} />

        <Grid container rowSpacing={responsiveHooks.mdDown ? 2 : 1} columnSpacing={0} flexDirection="row" alignItems="center">
          <Grid item xs={12} sm={6} md={2} alignItems="center" justifyContent="center">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Data de criação: </Text>
            }
            <Text size={textFontSize} color={theme.palette.primary.dark}>
              {props.documentCustomModel.createdAt ? parseDateToString(props.documentCustomModel.createdAt, DATE_TIME_FORMAT) : ""}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={4} alignItems="center" justifyContent="center">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>
                Nome:
              </Text>
            }
            <Text size={textFontSize} color={theme.palette.primary.dark}>{props.documentCustomModel.name}</Text>
          </Grid>
          <Grid item xs={12} sm={6} md={5} alignItems="center" justifyContent="center">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>
                Descrição:
              </Text>
            }
            <Text size={textFontSize} color={theme.palette.primary.dark}>
              {props.documentCustomModel.description}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} md={1} alignItems="center" justifyContent="flex-end">
            {responsiveHooks.mdDown &&
              <Text size={textFontSize} color={theme.palette.primary.dark}>Ações: </Text>
            }
            <Container inline flexEnd>
              <Tooltip title="Excluir modelo">
                <IconButton onClick={() => props.onActionTriggered(props.documentCustomModel, "DELETE")}>
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default DocumentsDatalogItemCard;

const containerStyle = (theme: Theme): CSSProperties => {
  return {
    background: "rgba(255, 255, 255, 0.80)",
    border: `solid 1px ${theme.palette.secondary.main}`,
    borderRadius: "2px",
    paddingLeft: VERY_PADDING * 1.5,
    paddingRight: VERY_PADDING * 1.5,
    boxShadow: "3px 4px 5px 0px rgba(0, 0, 0, 0.25)"
  }
}