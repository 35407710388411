import { IDocumentCustomModelField } from "src/types/hooks/documentsCustomsModels";
import api, { API_DOCUMENTS_URL } from ".";
import { EntityServices } from "./entities";
import { IDocumentCustomModel, IEntityPaginated } from "src/types";
import { parseStringToDate } from "src/utils/dates";

export class DocumentCustomModelServices extends EntityServices<IDocumentCustomModel> {
  constructor() {
    super("Modelos customizados de documentos", `${API_DOCUMENTS_URL}/document_custom_models`);
  }

  async getEntitiesByClientId(_clientId: string, _query: string): Promise<IEntityPaginated<IDocumentCustomModel>> {
    const response = await api.get(`${this.baseUrl}/by_client_id/${_clientId}${_query}`);
    if (response.data && response?.request?.status === 200) {
      const documentsCustomsModels: IDocumentCustomModel[] = [];

      const data = response.data;
      for (let i = 0; i < data.items.length; i++) {
        const item = data.items[i];
        const documentCustomModel: IDocumentCustomModel = this.convertApiResponseToIEntity(item);
        documentsCustomsModels.push(documentCustomModel);
      }

      return {
        items: documentsCustomsModels,
        page: data.page,
        pages: data.pages,
        total: data.total,
      };
    }
    throw new Error("Não foi possível buscar os modelos de análise");
  }

  convertApiResponseToIDocumentCustomModelField(_data: any): IDocumentCustomModelField {
    return {
      id: _data.id,
      documentCustomModelId: _data.documentCustomModelId,
      name: _data.name,
      key: _data.key,
      type: _data.type,
      typeDesc: _data.typeDesc,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }

  override convertApiResponseToIEntity(_data: any): IDocumentCustomModel {
    const fields: IDocumentCustomModelField[] = [];
    if (_data?.fields) {
      for (let i = 0; i < _data.fields.length; i++) {
        const documentCustomModel = _data.fields[i];
        fields.push(this.convertApiResponseToIDocumentCustomModelField(documentCustomModel));
      }
    }

    return {
      id: _data.id,
      clientId: _data.clientId,
      userId: _data.userId,
      documentType: _data.documentType,
      name: _data.name,
      description: _data.description,
      fields,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
