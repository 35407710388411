import { FC, useState } from "react";
import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import {
    Add as AddIcon,
    DeleteOutlineOutlined as DeleteIcon,
} from '@mui/icons-material';

import RegisterTemplate from "src/components/templates/registerTemplate";
import theme from "src/components/theme";
import { useAuth } from "src/hooks/auth";
import { useDocumentsCustomsModels } from "src/hooks/documentsCustomsModels";
import { useFeedback } from "src/hooks/feedback";
import { IDocumentCustomModel, IDocumentCustomModelField } from "src/types";
import { IDocumentsCustomsModelsRegister } from "src/types/components/molecules/v2/documentsCustomsModels";

const DocumentsCustomsModelsRegister: FC<IDocumentsCustomsModelsRegister> = (props: IDocumentsCustomsModelsRegister) => {
    // Auth hooks to get client information
    const auth = useAuth();
    // Documents customs models hooks to create or update data
    const documentsCustomsModels = useDocumentsCustomsModels();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // State for loading control
    const [loading, setLoading] = useState<boolean>(false);
    // State for name of model
    const [name, setName] = useState<string>("");
    // State for name error status
    const [nameError, setNameError] = useState<string>("");
    // State for description of model
    const [description, setDescription] = useState<string | undefined>("");
    // State for additional fields of model
    const [fields, setFields] = useState<string[]>([""]);
    // State for fields error status
    const [fieldError, setFieldError] = useState<string>("");

    /**
     * Handle the change text of setText passed on args
     * @function
     */
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => {
        setText(event.target.value);
    }

    /**
     * Handle the add routine of additional fields
     * @function
     */
    const addField = () => {
        let auxFields: string[] = fields;
        auxFields.push("")
        setFields([...auxFields]);
    }

    /**
     * Handle the removal routine of additional fields
     * @function
     */
    const removeField = (_index: number) => {
        let auxFields: string[] = fields;
        auxFields.splice(_index, 1);
        setFields([...auxFields]);
    }

    /**
     * Validation routine to check fields
     * @function
     */
    const validation = () => {
        let isValid: boolean = true;
        setNameError("");
        setFieldError("");

        if (name == "") {
            isValid = false;
            setNameError("Preenchimento obrigatório")
        }

        let hasEmpty = false;
        for (let i = 0; i < fields.length; i++)
            if (fields[i] === "") hasEmpty = true;
        if (hasEmpty) {
            isValid = false;
            setFieldError("Favor preencher todos os campos");
        }

        return isValid;
    };

    /**
     * Sends request to save model in back-end
     * @function
     */
    const save = async () => {
        if (!auth.client || !auth.client.id) return;
        if (!auth.user || !auth.user.id) return;
        if (!validation()) return;

        try {
            setLoading(true);

            const documentsCustomsModelsFields: IDocumentCustomModelField[] = [];
            for (let i = 0; i < fields.length; i++)
                documentsCustomsModelsFields.push({
                    documentCustomModelId: "",
                    name: fields[i],
                    key: fields[i],
                    type: 0
                })

            let payload: IDocumentCustomModel = {
                clientId: auth.client.id,
                userId: auth.user.id,
                name,
                description,
                documentType: 0,
                fields: documentsCustomsModelsFields
            };

            await documentsCustomsModels.createNewEntity(payload, false);
            cleanData();
            props.onCancel();
            setLoading(false);
            feedback.open(true, "success", "Sucesso", undefined, "Modelo cadastrado com sucesso.");
        } catch (err: any) {
            console.log(err);
            feedback.open(true, "error", "Erro", undefined, err.message as string);
            setLoading(false);
        }
    }

    /**
     * Cleans all data fields
     * @function
     */
    const cleanData = () => {
        setName("");
        setDescription("");
    };

    return (
        <RegisterTemplate
            title="Novo modelo de documento"
            subtitle="Crie um modelo se os existentes não servirem para você. Quando o modelo estiver pronto, nossa inteligência artificial analisará seu documento em busca dos campos que você indicou. Portanto, é crucial que os nomes dos campos estejam claramente relacionados aos dados desejados."
            cancelClick={() => props.onCancel()}
            okClick={() => save()}>
            <Grid container display="flex" rowSpacing="24px" sx={{
                maxHeight: "60vh",
                overflowY: "auto"
            }}>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="Nome do modelo"
                        placeholder="Nome mostrado na lista de modelos"
                        value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName)}
                        error={nameError !== ""} helperText={nameError} />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="Descrição do modelo"
                        placeholder="Breve descrição do seu modelo"
                        value={description} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setDescription)}
                        helperText="Opcional" />
                </Grid>
                <Grid item xs={12}>
                    <Typography fontSize="16px" fontStyle="normal" fontWeight="500"
                        lineHeight="24px" letterSpacing="0.15px"
                        color={theme.palette.text.primary}>
                        Adicione os campos do seu documento
                    </Typography>
                    <Box display="flex" flexDirection="column" flex={1}>
                        <Grid container rowSpacing="12px" paddingTop="24px">
                            {fields.map((item: string, index: number) => {
                                return (
                                    <Grid item xs={12}>
                                        <TextField variant="outlined" fullWidth size="small"
                                            label={`Campo ${index + 1}`}
                                            placeholder="Informe o nome do campo"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                let auxFields: string[] = fields;
                                                auxFields[index] = event.target.value;
                                                setFields([...auxFields]);
                                            }}
                                            InputProps={{
                                                endAdornment: index == 0 ? null :
                                                    <IconButton color="error"
                                                        onClick={() => removeField(index)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                            }} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center"
                        paddingTop="8px">
                        <Button variant="text" color="primary" startIcon={<AddIcon />}
                            onClick={() => addField()}>
                            <Typography
                                font-size="14px" font-style="normal" font-weight="500"
                                line-height="20px" letter-spacing="0.1px" textTransform="initial">
                                Adicionar campo
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </RegisterTemplate>
    )
}

export default DocumentsCustomsModelsRegister;