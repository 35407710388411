import React, { FC, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import moment from "moment";
import 'moment/locale/pt-br'

import { theme } from "src/components";
import { useResponsive } from "src/hooks/responsive";
import { IInvoice, IInvoicesItemCard } from "src/types";
import { isInvoice } from "src/types/hooks/invoices";
import { Collapse } from "@mui/material";
import InvoicesDocumentsCountList from "../documentsCountList";
import { sumArray } from "src/utils/numbers";
import InvoicesItemsList from "../invoiceItemList";
import { parseDateToString } from "src/utils/dates";
import { LoadingButton } from "@mui/lab";

const InvoicesItemCard: FC<IInvoicesItemCard> = (params: IInvoicesItemCard) => {
  const { mdDown } = useResponsive();
  const [openDocumentsCount, setOpenDocumentsCount] = useState<boolean>(false);

  if (params.invoice == undefined) return null;

  return (
    <Container relative fluid flex color={theme.palette.backgroundElements.main}
      sx={{ border: `solid 1px ${theme.palette.backgroundElements.main}` }}>
      <Container absolute fluid flex width="8px" height="100%" color={params.invoice.paid ? theme.palette.success.main : theme.palette.info.main} sx={{ left: 0, top: 0 }} />

      <Container fluid flex veryPadded>
        <Container fluid>
          <Grid container rowSpacing={2} columnSpacing={2} display="flex">
            <Grid item xs={12} md={3} display="flex" alignItems="center" justifyContent="center" onClick={() => setOpenDocumentsCount(!openDocumentsCount)} sx={{ cursor: "pointer" }}>
              {mdDown && <Text light size={13} color={theme.palette.textInfo.main}>Plano: </Text>}
              <Text light size={13} color={theme.palette.textInfo.main}>
                {params.invoice.subscription && params.invoice.subscription.subscriptionPlan?.name}
              </Text>
            </Grid>
            <Grid item xs={12} md={2} display="flex" alignItems="center" justifyContent="center" onClick={() => setOpenDocumentsCount(!openDocumentsCount)} sx={{ cursor: "pointer" }}>
              {mdDown && <Text light size={13} color={theme.palette.textInfo.main}>Total de páginas: </Text>}
              <Text light size={13} color={theme.palette.textInfo.main}>
                {params.invoice.items && sumArray(params.invoice.items.map(item => item.quantity)) || 0}
              </Text>
            </Grid>
            <Grid item xs={12} md={1} display="flex" alignItems="center" justifyContent="center" onClick={() => setOpenDocumentsCount(!openDocumentsCount)} sx={{ cursor: "pointer" }}>
              {mdDown && <Text light size={13} color={theme.palette.textInfo.main}>Vencimento: </Text>}
              {params.invoice.periodEnd && <Text light size={13} color={theme.palette.textInfo.main}>
                {parseDateToString(params.invoice.periodEnd, "DD/MM/YYYY")}
              </Text>}
            </Grid>
            <Grid item xs={12} md={1} display="flex" alignItems="center" justifyContent="center" onClick={() => setOpenDocumentsCount(!openDocumentsCount)} sx={{ cursor: "pointer" }}>
              {mdDown && <Text light size={13} color={theme.palette.textInfo.main}>Valor: </Text>}
              <Text light size={13} color={theme.palette.textInfo.main}>
                R$ {params.invoice.total.toFixed(2).replaceAll(".", ",")}
              </Text>
            </Grid>
            <Grid item xs={12} md={2} display="flex" alignItems="center" justifyContent="center" onClick={() => setOpenDocumentsCount(!openDocumentsCount)} sx={{ cursor: "pointer" }}>
              {mdDown && <Text light size={13} color={theme.palette.textInfo.main}>Situação: </Text>}
              <Text light size={13} color={theme.palette.textInfo.main}>
                {params.invoice.status == 0 ? "Em aberto" : params.invoice.status == 1 ? "Pago" : "Em andamento"}
              </Text>
            </Grid>
            <Grid item xs={12} md={3} display="flex" alignItems="center" justifyContent="center">
              {mdDown && <Text light size={13} color={theme.palette.textInfo.main}>Ações: </Text>}
              {params.invoice.url_pdf &&
                <a href={params.invoice.url_pdf}>
                  <LoadingButton sx={{ width: "80px", height: "40px", backgroudColor: theme.palette.primary.main, borderRadius: "4px" }}>
                    <Text medium color={theme.palette.background.default}>
                      Download
                    </Text>
                  </LoadingButton>
                </a>
              }
            </Grid>
          </Grid>
        </Container>

        {params.invoice && params.invoice.id &&
          <Collapse in={openDocumentsCount} timeout="auto" unmountOnExit>
            <>
              {params.invoice.items && <InvoicesItemsList items={params.invoice.items} />}
              <InvoicesDocumentsCountList clientId={params.invoice.subscription && params.invoice.subscription.clientId || ""} invoiceId={params.invoice.id} status={params.invoice.status} />
            </>
          </Collapse>
        }
      </Container>
    </Container>
  );
};

export default InvoicesItemCard;

export function convertTableRowPropsToIInvoice(props: any) {
  const data: any = props.row;
  let invoice: IInvoice | undefined = undefined;
  if (isInvoice(data)) {
    invoice = props.row;
  }
  return invoice;
}
