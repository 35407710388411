import { AxiosResponse } from "axios";

import api, { API_FINANCES_URL } from "..";
import { ClientServices } from "../clients";
import SubscriptionService from "./subscriptions";
import { ClientProps, SubscriptionProps } from "../../types";
import { parseStringToDate } from "../../utils/dates";
import { AccountAttributesProps, AccountCredentialsProps, AccountProps } from "src/types/hooks/account";

export class AccountService {
  baseUrl: string;

  constructor() {
    this.baseUrl = `${API_FINANCES_URL}/clients/users/account`;
  }

  async updateClientAccount(userId: string, payload: AccountProps): Promise<AccountProps> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${userId}`, payload);
    if (response.status == 200) return this.convertApiResponseToAccountProps(response.data);
    throw new Error("Não foi possível atualizar os dados do cliente.");
  }

  async resetPassword(userId: string, payload: AccountCredentialsProps): Promise<AccountProps> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${userId}/reset-password`, payload);
    if (response.status == 200) return this.convertApiResponseToAccountProps(response.data);
    throw new Error("Não foi possível atualizar a senha.");
  }

  async uploadAvatar(userId: string, payload: FormData): Promise<boolean> {
    const response: AxiosResponse = await api.post(`${this.baseUrl}/${userId}/avatar`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status == 204) return true;
    throw new Error("Não foi possível atualizar a foto de perfil.");
  }

  convertApiResponseToAccountAttributesProps(data: any): AccountAttributesProps {
    const attributes: AccountAttributesProps = {
      phoneNumber: data.phoneNumber || data.phone_number,
      receiveNotifications: data.receiveNotifications || data.receive_notifications,
      avatar: data.avatar,
    };
    return attributes;
  }

  convertApiResponseToAccountProps(data: any): AccountProps {
    let attributes: AccountAttributesProps | undefined = undefined;
    if (data.attributes) attributes = this.convertApiResponseToAccountAttributesProps(data.attributes);
    let client: ClientProps | undefined = undefined;
    if (data.client) client = new ClientServices().convertApiResponseToIEntity(data.client);
    let subscription: SubscriptionProps | undefined = undefined;
    if (data.subscription) subscription = new SubscriptionService().convertApiResponseToIEntity(data.subscription);

    const account: AccountProps = {
      id: data.id,
      username: data.username,
      email: data.email,
      enabled: data.enabled,
      emailVerified: data.emailVerified,
      firstName: data.firstName,
      lastName: data.lastName,
      groups: data.groups,
      credentials: data.credentials,
      attributes: attributes,
      createdTimestamp: parseStringToDate(data.createdTimestamp),
      phoneNumber: data.phoneNumber,
      isSuperuser: data.isSuperuser,
      isActive: data.isActive,
      clientRoles: data.clientRoles,
      client: data.client,
      subscription: data.subscription,
    };
    return account;
  }
}
