import { FC } from "react";
import { Grid } from "@mui/material";
import { Container, Text } from "@pix-force/react-atoms";

import { theme } from "src/components";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

const PaymentFormsListHeader: FC = () => {
    return (
        <Container fluid flex>
            <Container fluid centered minHeight="25px" color={theme.palette.backgroundStrokeActive.main}
                sx={{
                    border: `solid 1px ${theme.palette.backgroundStrokeActive.dark}`,
                    borderRadius: "4px",
                    paddingTop: VERY_PADDING,
                    paddingBottom: VERY_PADDING
                }}>
                <Grid container rowSpacing={2} columnSpacing={2} display="flex" paddingLeft={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={3} lg={3} display="flex">
                        <Text>Cartão Principal</Text>
                    </Grid>
                    <Grid item xs={12} md={2} lg={1} display="flex">
                        <Text>Valor</Text>
                    </Grid>
                    <Grid item xs={12} md={3} lg={2} display="flex">
                        <Text>Vencimento</Text>
                    </Grid>
                    <Grid item xs={12} md={4} xl={2} display="flex" justifyContent="center">
                        <Text>Forma de Pagamento</Text>
                    </Grid>
                    <Grid item xs={12} md={6} xl={3} display="flex" justifyContent="center">
                        <Text>Ações</Text>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default PaymentFormsListHeader;
