import api, { API_DOCUMENTS_URL } from ".";
import { EntityServices } from "./entities";
import { IDocumentModelField } from "src/types/hooks/documentModelFields";
import { parseStringToDate } from "src/utils/dates";

export class DocumentsModelsFieldsServices extends EntityServices<IDocumentModelField> {
  constructor() {
    super("Campos dos modelos de documentos", `${API_DOCUMENTS_URL}/document_model_fields`);
  }

  async getDocumentModelsbyDocumentType(_documentType: string): Promise<IDocumentModelField[]> {
    const response = await api.get(`${this.baseUrl}/all_by_document_type/${_documentType}`);

    if (response.data && response?.request?.status === 200) {
      const documentModelFields: IDocumentModelField[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const documentModelField: IDocumentModelField = this.convertApiResponseToIEntity(data);

        documentModelFields.push(documentModelField);
      }
      return documentModelFields;
    }
    throw new Error("Não foi possível buscar os modelos de comparação");
  }

  override convertApiResponseToIEntity(_data: any): IDocumentModelField {
    return {
      id: _data.id,
      name: _data.name,
      description: _data.description,
      type: _data.type,
      documentType: _data.documentType,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
