import { FC } from "react";

import { ClientsProvider } from "./clients";
import { ClientCardsProvider } from "./clientsCards";
import { ConfirmationProvider } from "./confirmation";
import { ContactUsFormsProvider } from "./contactUsForms";
import { DocumentsProvider } from "./documents";
import { DocumentModelsProvider } from "./documentModels";
import { DocumentsCustomsModelsProvider } from "./documentsCustomsModels";
import { DocumentModelFieldsProvider } from "./documentModelFields";
import { DocumentModelFavoritesProvider } from "./documentModelFavorites";
import { DrawerProvider } from "./drawer";
import { FeedbackProvider } from "./feedback";
import { InformationProvider } from "./information";
import { InvoicesProvider } from "./invoices";
import { IHookProvider } from "src/types";
import { ModelsProvider } from "./models";
import { PrivilegesProvider } from "./privileges";
import { ProductsProvider } from "./products";
import { ResponsiveProvider } from "./responsive";
import { RoutesProvider } from "./routes";
import { SnackbarProvider } from "./snackbar";
import { SubscriptionsProvider } from "./subscriptions";
import { SubscriptionsPlansProvider } from "./subscriptionsPlans";
import { SubscriptionsPlansPricesProvider } from "./subscriptionsPlansPrices";
import { UsersProvider } from "./users";
import { useAuth } from "./auth";
import { AccountProvider } from "./account";

export const FeedbacksProvider: FC<IHookProvider> = (params: IHookProvider) => {
  return (
    <ContactUsFormsProvider>
      <FeedbackProvider>
        <InformationProvider>
          <ConfirmationProvider>
            <ResponsiveProvider>
              <SnackbarProvider>{params.children}</SnackbarProvider>
            </ResponsiveProvider>
          </ConfirmationProvider>
        </InformationProvider>
      </FeedbackProvider>
    </ContactUsFormsProvider>
  );
};

const FinancesProvider: FC<IHookProvider> = (params: IHookProvider) => {
  return (
    <InvoicesProvider>
      <ClientsProvider>
        <ClientCardsProvider>
          <ProductsProvider>
            <SubscriptionsProvider>
              <SubscriptionsPlansProvider>
                <SubscriptionsPlansPricesProvider>{params.children}</SubscriptionsPlansPricesProvider>
              </SubscriptionsPlansProvider>
            </SubscriptionsProvider>
          </ProductsProvider>
        </ClientCardsProvider>
      </ClientsProvider>
    </InvoicesProvider>
  );
};

const DocumentsManagementProvider: FC<IHookProvider> = (params: IHookProvider) => {
  return (
    <ModelsProvider>
      <DocumentsProvider>
        <DocumentModelsProvider>
          <DocumentModelFavoritesProvider>
            <DocumentsCustomsModelsProvider>
              <DocumentModelFieldsProvider>{params.children}</DocumentModelFieldsProvider>
            </DocumentsCustomsModelsProvider>
          </DocumentModelFavoritesProvider>
        </DocumentModelsProvider>
      </DocumentsProvider>
    </ModelsProvider>
  );
};

const UsersManagementProvider: FC<IHookProvider> = (params: IHookProvider) => {
  return (
    <AccountProvider>
      <PrivilegesProvider>
        <RoutesProvider>
          <UsersProvider>{params.children}</UsersProvider>
        </RoutesProvider>
      </PrivilegesProvider>
    </AccountProvider>
  );
};

export const AppProvider: FC<IHookProvider> = (params: IHookProvider) => {
  const authHooks = useAuth();

  if (authHooks.keycloak && authHooks.keycloak.authenticated) {
    return (
      <DrawerProvider>
        <DocumentsManagementProvider>
          <FinancesProvider>
            <UsersManagementProvider>{params.children}</UsersManagementProvider>
          </FinancesProvider>
        </DocumentsManagementProvider>
      </DrawerProvider>
    );
  }

  return <DrawerProvider>{params.children}</DrawerProvider>;
};
