import { FC, createContext, useState, useContext } from 'react'
import { AlertColor } from '@mui/material';

import { ISnackbarContext } from '../types'

const SnackbarContext = createContext<ISnackbarContext>({} as ISnackbarContext);


export const SnackbarProvider: FC<any> = ({ children }) => {
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor | undefined>(undefined);
    const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(undefined);
    const [snackbarActionLabel, setSnackbarActionLabel] = useState<string | undefined>(undefined);
    const [snackbarOnActionClick, setSnackbarOnActionClick] = useState<((() => void) | undefined)>(undefined);

    const showSnackbar = (_message: string, _ActionLabel?: string, _onActionClick?: () => void, _severity: AlertColor = "success") => {
        setSnackbarSeverity(_severity);
        setSnackbarMessage(_message);
        if (_ActionLabel) setSnackbarActionLabel(_ActionLabel);
        if (_onActionClick) setSnackbarOnActionClick(() => () => _onActionClick());

        setSnackbarOpen(true);
    }

    const hideSnackbar = () => {
        setSnackbarMessage(undefined);
        setSnackbarSeverity(undefined);
        setSnackbarActionLabel(undefined);
        setSnackbarOnActionClick(undefined);

        setSnackbarOpen(false);
    }


    return (
        <SnackbarContext.Provider value={{
            snackbarOpen, snackbarSeverity, snackbarMessage, snackbarActionLabel,
            showSnackbar, hideSnackbar,
            snackbarOnActionClick
        }}>{children}</SnackbarContext.Provider>
    );
}

export function useSnackbar() {
    const context = useContext(SnackbarContext);

    if (!context) {
        throw new Error('useSnackbar must be used within an SnackbarProvider')
    }

    return context;
}