import { FC, useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';

import RegisterTemplate from "src/components/templates/registerTemplate";
import { useAuth } from "src/hooks/auth";
import { useApiKeys } from "src/hooks/apiKeys";
import { useFeedback } from "src/hooks/feedback";
import { IApiKeysRegister } from "src/types/components/molecules/v2/apiKeys";
import { ApiKeyProps } from "src/types";

const ApiKeysRegister: FC<IApiKeysRegister> = (props: IApiKeysRegister) => {
    // Const about edit mode
    const edit: boolean = props.payload !== undefined;

    // Auth hooks to get client information
    const auth = useAuth();
    // Api keys hooks to create or update data
    const apiKeys = useApiKeys();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // State for loading control
    const [loading, setLoading] = useState<boolean>(false);
    // State for name of api key
    const [name, setName] = useState<string>("");
    // State for name error status
    const [nameError, setNameError] = useState<string>("");
    // State for description of api key
    const [expiresAt, setExpiresAt] = useState<Dayjs | null>(null);

    /**
     * Handle the change payload in props
     * @effect
     */
    useEffect(() => {
        if (edit) loadEditFields();
    }, [props.payload]);

    /**
     * Function that load edit fields
     * @function
     */
    const loadEditFields = async () => {
        setLoading(true);
        if (props.payload && props.payload.id) {
            setName(props.payload.name);
            if (props.payload.expiresAt)
                setExpiresAt(dayjs(props.payload.expiresAt));
        } else {
            props.onCancel();
            setLoading(false);
            feedback.open(true, "error", "Erro", undefined, "Ocorreu um erro ao editar a chave de API!");
        }
        setLoading(false);
    };

    /**
     * Handle the change text of setText passed on args
     * @function
     */
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => {
        setText(event.target.value);
    }

    /**
     * Validation routine to check fields
     * @function
     */
    const validation = () => {
        let isValid: boolean = true;
        setNameError("");

        if (name == "") {
            isValid = false;
            setNameError("Preenchimento obrigatório")
        }
        else if (name == "Chave-Padrão") {
            isValid = false;
            setNameError("Esse nome é reservado pelo sistema")
        }

        return isValid;
    };

    /**
     * Sends request to save api key in back-end
     * @function
     */
    const save = async () => {
        if (!auth.client || !auth.client.id) return;
        if (!auth.user || !auth.user.id) return;
        if (!validation()) return;

        try {
            setLoading(true);

            let payload: ApiKeyProps = {
                clientId: auth.client.id,
                name,
                expiresAt: expiresAt == null ? undefined : expiresAt.toDate()
            };

            let informationMessage: string = "Chave de API cadastrada com sucesso!";
            if (edit && props.payload && props.payload.id) {
                await apiKeys.editEntity(props.payload.id, payload);
                informationMessage = "Chave de API alterada com sucesso!";
            } else
                await apiKeys.createNewEntity(payload, false);
            cleanData();
            props.onCancel();
            setLoading(false);
            feedback.open(true, "success", "Sucesso", undefined, informationMessage);
        } catch (err: any) {
            console.log(err);
            feedback.open(true, "error", "Erro", undefined, err.message as string);
            setLoading(false);
        }
    }

    /**
     * Cleans all data fields
     * @function
     */
    const cleanData = () => {
        setName("");
        setExpiresAt(null);
    };

    return (
        <RegisterTemplate
            title={edit ? "Editar chave de API" : "Nova chave de API"}
            subtitle="Crie uma chave de API para poder efetuar seus uploads de documentos através de nossa API."
            cancelClick={() => props.onCancel()}
            okClick={() => save()}>
            <Grid container display="flex" rowSpacing="24px" sx={{
                maxHeight: "60vh",
                overflowY: "auto"
            }}>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="Nome da chave de API"
                        placeholder="Nome descritivo da chave"
                        value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName)}
                        error={nameError !== ""} helperText={nameError} />
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider
                        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pt-br">
                        <DatePicker
                            label="Data de expiração"
                            value={dayjs(expiresAt)}
                            onChange={(newValue) => setExpiresAt(newValue)}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    fullWidth: true,
                                    helperText: 'Opcional',
                                    placeholder: "Informe a data de expiração",
                                    error: false
                                },
                            }} />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </RegisterTemplate>
    )
}

export default ApiKeysRegister;