import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonBase, Card, CardActions, CardContent, CardMedia, SvgIconTypeMap, SxProps, Theme, Typography } from '@mui/material';

import { useAuth } from 'src/hooks/auth';
import IHomeFeatureButton from 'src/types/components/molecules/v2/home';
import theme from 'src/components/theme';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useResponsive } from 'src/hooks/responsive';

/**
 * This component returns the feature button of home page
 * It uses mui styles
 * @component
 */
const HomeFeatureButton: React.FC<IHomeFeatureButton> = (props: IHomeFeatureButton) => {
    // Feature button icon
    const HeaderIcon: OverridableComponent<SvgIconTypeMap> = props.headerIcon;

    // Responsive hooks for app layout
    const responsive = useResponsive();

    // State for width of feature button
    // const [width, setWidth] = useState<string>(defaultWidthLg);
    const [maxWidth, setMaxWidth] = useState<string>("100%");

    /** 
     * Effect to resize button width
     * @effect
    */
    useEffect(() => {
        if (responsive.xlUp) setMaxWidth(defaultWidthXl);
        else if (responsive.lgUp) setMaxWidth(defaultWidthLg);
        else if (responsive.mdUp) setMaxWidth(defaultWidthMd);
        else if (responsive.smUp) setMaxWidth(defaultWidthSm);
        else setMaxWidth(defaultWidthXs);
    }, [responsive.xsUp, responsive.smUp, responsive.mdUp, responsive.lgUp, responsive.lg2Up, responsive.xlUp]);

    return (
        <ButtonBase {...props}>
            <Card elevation={10}
                sx={{
                    width: "100%",
                    maxWidth: maxWidth,
                    height: "252px",
                    maxHeight: "252px",
                    background: theme.palette.background.paper,
                    borderRadius: "12px",
                    "&:hover": {
                        background: "var(--m-3-state-layers-dark-surface-variant-opacity-008, rgba(73, 69, 79, 0.08))"
                    }
                }}>
                <CardMedia
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "140px",
                        background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                        opacity: props.disabled ? 0.4 : 1
                    }}
                >
                    <HeaderIcon sx={{ color: theme.palette.background.paper, width: "64px", height: "64px" }} />
                    <Typography color={theme.palette.background.paper} textAlign="center" fontSize="16px" fontWeight={700}
                        lineHeight="24px" letterSpacing="0.15px" paddingTop="6px">
                        {props.headerText.toUpperCase()}
                    </Typography>
                </CardMedia>
                <CardContent sx={{ alignSelf: "stretch" }}>
                    {props.feature &&
                        <Typography color={theme.palette.text.secondary} fontSize="16px" fontStyle="normal" fontWeight={600}
                            lineHeight="20px" letterSpacing="0.25px">
                            {props.feature}
                        </Typography>
                    }
                    <Typography color={theme.palette.text.secondary} fontSize="14px" fontStyle="normal" fontWeight={400}
                        lineHeight="20px" letterSpacing="0.25px" textAlign="start">
                        {props.description}
                    </Typography>
                </CardContent>
            </Card>
        </ButtonBase>
    );
}

export default HomeFeatureButton;

// Const of width of button when screen size > 0px
const defaultWidthXs: string = "450px";
// Const of width of button when screen size > 600px
const defaultWidthSm: string = "320px";
// Const of width of button when screen size > 960px
const defaultWidthMd: string = "350px";
// Const of width of button when screen size > 1350px
const defaultWidthLg: string = "450px";
// Const of width of button when screen size > 1920px
const defaultWidthXl: string = "450px";
