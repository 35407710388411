import React, { FC, createContext, useContext, useState } from "react";

import { DocumentsModelsFieldsServices } from "../services/documentModelFields";
import { IDocumentModelField, IDocumentModelFieldContext, IHookProvider } from "../types";

const DocumentModelFieldsContext = createContext<IDocumentModelFieldContext>({} as IDocumentModelFieldContext);

export const DocumentModelFieldsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const service = new DocumentsModelsFieldsServices();
    const [entities, setEntities] = useState<IDocumentModelField[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchEntity, setSearchEntity] = useState<string>("");
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    const [paginate, setPaginate] = useState<number>(0);

    const createNewEntity = async (_entity: IDocumentModelField) => {
        try {
            const entity = await service.createEntity(_entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const editEntity = async (_id: string, _entity: IDocumentModelField) => {
        try {
            const entity = await service.updateEntity(_id, _entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const deleteEntity = async (_id: string) => {
        try {
            const entity = await service.destroyEntity(_id);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        try {
            const entities: IDocumentModelField[] = await service.getEntities();
            setEntities([...entities]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchEntity = async (_id: string) => {
        const entity: IDocumentModelField = await service.getEntity(_id);
        return entity;
    };

    const fetchEntitiesByDocumentType = async (_documentType: number) => {
        try {
            const auxEntities: IDocumentModelField[] = await service.getDocumentModelsbyDocumentType(_documentType.toString());
            return auxEntities;
        } catch (_err) {
            console.log(_err);
            return [];
        }
    };

    return (
        <DocumentModelFieldsContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                fetchEntitiesByDocumentType,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,
            }}
        >
            {_params.children}
        </DocumentModelFieldsContext.Provider>
    );
};

export function useDocumentModelFields() {
    const context = useContext(DocumentModelFieldsContext);

    if (!context) {
        throw new Error(
            "useDocumentModelFields must be used within an DocumentModelFieldsProvider"
        );
    }

    return context;
}
