import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Grid, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import {
    Add as AddIcon,
    CheckOutlined as CheckIcon,
    DeleteOutlineOutlined as DeleteIcon,
    FileUploadOutlined as FileUploadIcon,
    NoteAddOutlined as DocumentsCustomsModelsIcon,
    RemoveRedEyeOutlined as DocumentPreviewIcon,
    Star as FavoriteIcon,
    StarBorder as UnFavoriteIcon,
} from '@mui/icons-material';

import { DefaultTemplate, MenuTabSelect, theme } from 'src/components';
import DocumentsPreview from 'src/components/molecules/v2/documents/preview';
import DocumentsCustomsModelsRegisterModal from 'src/components/molecules/v2/documents/customsModels/crud/register/modal';
import DragDropFiles from 'src/components/molecules/v2/dragDropFiles';
import { useAuth } from 'src/hooks/auth';
import { useDocuments } from 'src/hooks/documents';
import { useDocumentsCustomsModels } from 'src/hooks/documentsCustomsModels';
import { useDocumentModels } from 'src/hooks/documentModels';
import { useDocumentModelFavorites } from 'src/hooks/documentModelFavorites';
import { useFeedback } from 'src/hooks/feedback';
import { useResponsive } from 'src/hooks/responsive';
import { IDocument, IDocumentModel, IDocumentModelFavorite, IDocumentPayload, SubscriptionPlanProps, SubscriptionUsageRecordProps } from 'src/types';
import { IMenuTabSelectItem, IMenuTabSelectOptionChildren } from 'src/types/components/atoms/menuTabSelect';
import { toBase64 } from 'src/utils/converters';
import { isUUID } from 'src/utils/stringFormatters';

/**
 * This component returns the Documents upload page
 * It uses mui styles
 * @component
 */
const DocumentsUpload: FC = () => {
    // Auth hooks that contains current user data
    const auth = useAuth();
    // Document hooks for upload and types data
    const documents = useDocuments();
    // Document models hooks for upload and types data
    const documentModels = useDocumentModels();
    // Document customs models hooks for users models
    const documentsCustomsModelsHooks = useDocumentsCustomsModels();
    // Documents types favorites hooks that contain data of logged user
    const documentModelFavorites = useDocumentModelFavorites();
    // Information hooks for feedback
    const feedback = useFeedback();
    // Navigate hooks to change route based on path
    const navigate = useNavigate();
    // Responsive hooks to get xs layout
    const responsive = useResponsive();

    // State for user credits balance
    const [creditsBalance, setCreditsBalance] = useState<string | number | undefined>(undefined);
    // State for loading user credits balance
    const [creditsBalanceLoading, setCreditsBalanceLoading] = useState<boolean>(false);
    // State for model types select tabs
    const [modelsTypesTabs, setModelsTypesTabs] = useState<string[]>([]);
    // State for model types select options
    const [modelsTypesOptions, setModelsTypesOptions] = useState<IMenuTabSelectItem[][]>([[]]);
    // State for model types select options
    const [existsModelTypesFavorites, setExistsModelTypesFavorites] = useState<boolean>(false);
    // State for document selected type
    const [documentTypeSelected, setDocumentType] = useState<string>("");
    // State for custom fields
    const [customFields, setCustomFields] = useState<string[]>([""]);

    // State if custom model is selected
    const [isCustomModelSelected, setIsCustomModelSelected] = useState<boolean>(false);
    // State for selected files
    const [files, setFiles] = useState<any[]>([]);
    // State for pages input
    const [pages, setPages] = useState<string>("");
    // State for pages input regex error
    const [pagesError, setPagesError] = useState<string | undefined>(undefined);
    // State for loading processing
    const [loading, setLoading] = useState<boolean>(false);
    // State for custom model register modal
    const [isCustomModelRegisterOpen, setIsCustomModelRegisterOpen] = useState<boolean>(false);
    // State for preview modal open status
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);


    /**
     * Effect to fetch initial data
     * @effect
     */
    useEffect(() => {
        if (!auth.subscription) return;

        fetchData();
    }, [auth.subscription]);

    /**
     * Effect to fetch models
     * @effect
     */
    useEffect(() => {
        fillModelTypesOptions();
    }, [
        documentModels.entities,
        documentsCustomsModelsHooks.entities,
        documentModels.entities,
        documentModelFavorites.entities
    ]);

    /** 
     * Function to fetch initial data
     * @function
     */
    const fetchData = async () => {
        if (!auth.subscription || !auth.subscription.subscriptionPlan) return;

        const subscriptionPlan: SubscriptionPlanProps = auth.subscription.subscriptionPlan;
        if (!subscriptionPlan.isTrial) {
            // If subscription plan is not trial, the balance calcule is not required
            setCreditsBalanceLoading(false);
            setCreditsBalance("Assinatura operacional");
        } else {
            // Fetch user credit balance to show to the user
            try {
                setCreditsBalanceLoading(true);
                const subscriptionUsageRecord: SubscriptionUsageRecordProps = await auth.getUsageRecord();
                if (subscriptionPlan.maxRequests && subscriptionPlan.maxRequests > 0) {
                    setCreditsBalance(subscriptionPlan.maxRequests - subscriptionUsageRecord.totalRequests);
                } else {
                    setCreditsBalance(subscriptionPlan.monthlyRequests - subscriptionUsageRecord.totalRequests);
                }
            } finally {
                setCreditsBalanceLoading(false);
            }
        }

        // Fetch user custom models
        documentModels.fetchEntities();

        // Fetch user custom models
        documentsCustomsModelsHooks.fetchEntities();

        // Fetch user favorites vision models
        documentModelFavorites.fetchEntities();
    }

    /** 
     * Function to creates the matrix of models types options
     * Adding the vision types, the custom models and the favorites options
     * @function
     */
    const fillModelTypesOptions = () => {
        const models: IMenuTabSelectItem[] = documentModels.entities.map(item => {
            return {
                label: item.abbreviation ?? item.name,
                sublabel: item.description,
                value: item.name
            }
        })
        .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
        const customModels: IMenuTabSelectItem[] =
            documentsCustomsModelsHooks.entities
                .filter(item => item.id !== undefined)
                .map(item => {
                    return {
                        label: item.name,
                        value: item.id ?? ""
                    }
                })
                .sort((a, b) => (a.label || '').localeCompare(b.label || ''));
        const modelFavorites: IMenuTabSelectItem[] =
            documentModels.entities.map((item: IDocumentModel) => {
                return {
                    label: item.name,
                    sublabel: item.description,
                    value: item.name
                }
            }).filter(item => documentModelFavorites.entities.map(item => item.modelName).includes(item.value));
        const customModelFavorites: IMenuTabSelectItem[] =
        documentsCustomsModelsHooks.entities
        .map(item => {
            return {
                label: item.name,
                value: item.id ?? ""
            }
        })
        .filter(item => documentModelFavorites.entities.map(item => item.documentCustomModelId).includes(item.value));
        const favorites: IMenuTabSelectItem[] = modelFavorites.concat(customModelFavorites).sort((a: any, b: any) => {
            if (a.description > b.description) return 1;
            else if (a.description < b.description) return -1;
            return 0;
        }).sort((a, b) => (a.label || '').localeCompare(b.label || ''));

        // If user cannot has favorite models, the tab will not appear
        // Otherwise, favorite models will be the first tab
        if (favorites.length == 0) {
            setModelsTypesTabs(["Todos", "Meus modelos"]);
            setModelsTypesOptions([models, customModels]);
        }
        else {
            setModelsTypesTabs(["Todos", "Meus modelos", "Favoritos"]);
            setModelsTypesOptions([models, customModels, favorites]);
        }
    }

    /** 
     * Function that renders menu tab select children for
     * possibility of favorite an model or custom model
     * @function
     */
    function documentModelSelectItem(): IMenuTabSelectOptionChildren[] {
        /** 
         * Function that returns the item children component
         * @function
         */
        function documentModelSelectItemChildren(item: IMenuTabSelectItem, isFavorited: boolean): React.ReactNode {
            return (
                <MenuItem value={item.value} sx={{marginBottom: "16px"}}>
                    <Grid container>
                        <Grid item xs={11} display="flex" alignItems="center">
                            <Box>
                                <Typography>{item.label}</Typography>
                                <Typography fontSize={12} color="grey">{item.sublabel}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1} display="flex" alignItems="center">
                            <IconButton size="medium" onClick={(event) => toggleDocumentModelFavorite(event, item)}>
                                {isFavorited ? <FavoriteIcon fontSize="small" color="primary" sx={{ width: "15px", height: "15px" }} />
                                    : <UnFavoriteIcon fontSize="small" color="primary" sx={{ width: "15px", height: "15px" }} />
                                }
                            </IconButton>
                        </Grid>
                    </Grid>
                </MenuItem>
            );
        }

        return [
            (item: IMenuTabSelectItem) => {
                return documentModelSelectItemChildren(item, documentModelFavorites.entities.find(el => el.modelName === item.value) !== undefined || false);
            },
            (item: IMenuTabSelectItem) => {
                return documentModelSelectItemChildren(item, documentModelFavorites.entities.find(el => el.documentCustomModelId === item.value) !== undefined || false);
            },
            (item: IMenuTabSelectItem) => {
                return documentModelSelectItemChildren(item, true);
            },
        ];
    }

    /** 
     * Function to toggle favorite model active/inactive
     * @function
     */
    const toggleDocumentModelFavorite = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, selectedItem: IMenuTabSelectItem) => {
        if (!auth.user) return;
        event.stopPropagation();

        const auxDocumentModelFavorite: IDocumentModelFavorite = {
            userId: auth.user.id,
            modelName: !isUUID(selectedItem.value) ? selectedItem.value : undefined,
            documentCustomModelId: isUUID(selectedItem.value) ? selectedItem.value : undefined
        }
        // If favorites don`t exists, an row will be created
        // Otherwise, the row will be deleted
        const documentModelFavorite = documentModelFavorites.entities.find(item => item.modelName === selectedItem.value || item.documentCustomModelId === selectedItem.value);
        if (!documentModelFavorite) {
            await documentModelFavorites.createNewEntity(auxDocumentModelFavorite, true);
        } else if (documentModelFavorite.id) {
            await documentModelFavorites.deleteEntity(documentModelFavorite.id);
            documentModelFavorites.fetchEntities();
        }
    }

    /** 
     * Function to handle document type change
     * It can be an Document Type or Document Custom Model
     * @function
     */
    const onChangeDocumentType = (selectedTab: number, selectedValue: string) => {
        setIsCustomModelSelected(selectedTab === DOCUMENT_MODEL_SELECT_TAB_MODELS);
        setDocumentType(selectedValue);
    }

    /**
     * Handle the add routine of additional fields
     * @function
     */
    const addField = () => {
        let auxFields: string[] = customFields;
        auxFields.push("")
        setCustomFields([...auxFields]);
    }

    /**
     * Handle the removal routine of additional fields
     * @function
     */
    const removeField = (_index: number) => {
        let auxFields: string[] = customFields;
        auxFields.splice(_index, 1);
        setCustomFields([...auxFields]);
    }

    /** 
     * Function to handle file selection
     * @function
     */
    const onSelectFile = async (event: any) => {
        event.persist();

        if (event.target.files.length == 1) {
            const file = event.target.files[0];
            setFiles([...files, file]);
        } else {
            setFiles([...files, ...event.target.files]);
        }
    }

    /** 
     * Function to handle pages extraction input
     * @function
     */
    const handlePages = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value != "") {
            const reg = new RegExp(/^\b\d+(?:-\d+)?(?:,\d+(?:-\d+)?)*\b$/);
            if (!reg.test(event.target.value))
                setPagesError("As páginas informadas devem seguir o formato 1,2,3-5");
            else
                setPagesError(undefined)
        } else setPagesError(undefined)
        setPages(event.target.value);
    };

    /** 
     * Function to upload document to back-end
     * @function
     */
    const process = async () => {
        try {
            setLoading(true);
            const isCustomized: boolean = documentTypeSelected == "UNKNOWN";
            const isEventBadge: boolean = documentTypeSelected == "102";
            for (let i = 0; i < files.length; i++) {
                let fileType = undefined;
                let fileBase64: any = undefined;
                if (files[i]) {
                    fileType = files[i].name.split(".").pop();
                    fileBase64 = await toBase64(files[i]);
                }
                if (!fileBase64) return;
                let payload: IDocumentPayload = {
                    file: fileBase64,
                    fileType,
                    pages: pages == "" ? undefined : pages
                }

                if (isCustomized && customFields.length > 0)
                    payload.fields = customFields
                
                let type: number | undefined = undefined;
                if (documentsCustomsModelsHooks.entities.find(item => item.id == documentTypeSelected)) {
                    type = 0;
                    payload.documentCustomModelId = documentTypeSelected;
                } else {
                    type = documentModels.entities.find(item => item.name == documentTypeSelected)?.type
                }
                if (type === undefined) {
                    feedback.open(true, "error", "Erro", undefined, "Erro ao efetuar o upload do documento. Entre em contato com a equipe Idexa.");
                    setLoading(false);
                    return;
                }

                const document: IDocument = await documents.uploadDocument(payload, type);
                if (!document) feedback.open(true, "error", "Erro", undefined, "Erro ao efetuar o upload do documento.");
            }
            setLoading(false);
            feedback.open(true, "success", "Leitura em andamento", undefined, "Processando as informações do(s) documento(s). Você será redirecionado para a área do datalog.", undefined, undefined,
                ["Continuar"], ["contained"], [() => {
                    navigate("/documents");
                    feedback.close();
                }]);
        } catch (_err) {
            console.log(_err)
            if ((_err as any).statusCode && (_err as any).statusCode == 403 && (_err as any).data) {
                const monthlyRequests: number = (_err as any)?.data.monthlyRequests ?? 0;
                const freeTial: boolean = (_err as any)?.data.freeTial ?? false;

                let subtitle: string = "Você já alcançou o limite de requisições do seu plano.";
                let message: string = "Entre em contato com a nossa equipe comercial através do e-mail faleconosco@pixforce.com.br";
                if (monthlyRequests > 0) {
                    if (freeTial) {
                        subtitle = "Você já realizou a leitura de $1 documentos gratuitos.".replace("$1", monthlyRequests.toString());
                        message = "Se deseja continuar utilizando o serviço, efetue a compra da licença. Entre em contato com a nossa equipe comercial através do e-mail faleconosco@pixforce.com.br";
                    } else {
                        subtitle = "Você já realizou a leitura de $1 documentos.".replace("$1", monthlyRequests.toString());
                        message = "Se deseja continuar utilizando o serviço, renove sua licença. Entre em contato com a nossa equipe comercial através do e-mail faleconosco@pixforce.com.br";
                    }
                }

                feedback.open(true, "warning", subtitle, undefined, message, undefined, undefined,
                    ["Voltar", "Ver planos"], ["text", "contained"], [() => {
                        feedback.close();
                    }, () => {
                        navigate("/my-account?change_subscription=1");
                        feedback.close();
                    }]);
            }
            else if ((_err as any).message) {
                if ((_err as any).message == "Seu período de avaliação de 7 dias chegou ao fim. Para continuar aproveitando nossos serviços , explore nossos planos e escolha a opção que melhor atenda às suas necessidades")
                    feedback.open(true, "warning", "Atenção", undefined, (_err as any).message, undefined, undefined,
                        ["Voltar", "Ver planos"], ["text", "contained"], [() => {
                            feedback.close();
                        }, () => {
                            navigate("/my-account?change_subscription=1");
                            feedback.close();
                        }]);
                else
                    feedback.open(true, "warning", "Atenção", undefined, (_err as any).message, undefined, undefined,
                        ["Voltar"], ["text"], [() => {
                            feedback.close();
                        }]);
            }
            else feedback.open(true, "error", "Erro", undefined, "Ocorreu um erro ao efetuar a requisição.");
            setLoading(false);
        }
    }

    return (
        <DefaultTemplate title="Leitura de documentos" subtitle="Selecione o tipo de documento que você deseja extrair os dados, carregue os arquivos desejados e processe seus documentos.">
            <Box display="flex" paddingTop="48px">
                <Card
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        flexGrow: 1,
                        alignItems: "center",
                        paddingX: "32px",
                        paddingY: "24px"
                    }}>
                    <CardContent sx={{ width: "100%" }}>
                        <Box display="flex" flexDirection="column" flex={1} flexGrow={1} alignItems="center">
                            <Box display="flex" flexDirection="row" flex={1} justifyContent="center"
                                width="100%" sx={{ backgroundColor: "#FCFCFC" }}>
                                <Box display="flex" alignItems="center" justifyContent="center" height="64px">
                                    <Typography color="primary.light" fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px">
                                        {creditsBalanceLoading ? "Carregando créditos" :
                                            typeof creditsBalance === "string" ?
                                                creditsBalance :
                                                `Créditos: ${creditsBalance} página${creditsBalance === 1 ? "" : "s"}`
                                        }
                                    </Typography>
                                </Box>
                                {/* <Box display="flex" alignItems="center" justifyContent="center" height="64px"
                                    paddingLeft="24px">
                                    <Button variant="text" disabled startIcon={<UpgradeIcon />} sx={{ height: "48px" }}>
                                        <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                            Fazer upgrade de plano
                                        </Typography>
                                    </Button>
                                </Box> */}
                            </Box>

                            <Grid container paddingLeft="12px" paddingTop="24px" columnSpacing="24px" rowSpacing="24px">
                                <Grid item xs={0.5} display="flex" alignItems="flex-start" justifyContent="flex-end"
                                    marginTop="8px">
                                    <Box
                                        display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                        minWidth="32px" minHeight="32px"
                                        borderRadius="100px"
                                        sx={{ background: theme.palette.primary.main }}>
                                        <Typography color="background.paper" fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                            1
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={11.5} sm={8} lg={9.5} display="flex" alignItems="center">
                                    <MenuTabSelect disabled={loading}
                                        label="Modelo de documento"
                                        placeholder="Selecione o modelo de documento"
                                        tabs={modelsTypesTabs} options={modelsTypesOptions}
                                        defaultTab={existsModelTypesFavorites ? 2 : 0}
                                        optionsChildren={documentModelSelectItem()}
                                        onChangeOption={(selectedTab: number, selectedValue: string) => onChangeDocumentType(selectedTab, selectedValue)}
                                        sx={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3.5} lg={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
                                    <Button variant="text" startIcon={<DocumentsCustomsModelsIcon />}
                                        onClick={() => setIsCustomModelRegisterOpen(true)}
                                        sx={{ height: "48px" }}>
                                        <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                            Criar modelo
                                        </Typography>
                                    </Button>
                                </Grid>

                                {documentTypeSelected == "UNKNOWN" &&
                                    <>
                                        <Grid item xs={0.5} display="flex" alignItems="flex-start" justifyContent="flex-end"
                                            marginTop="8px">
                                            <Box
                                                display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                                minWidth="32px" minHeight="32px"
                                                borderRadius="100px"
                                                sx={{ background: theme.palette.primary.main }}>
                                                <Typography color="background.paper" fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                                    2
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={11} display="flex" alignItems="center">
                                            <Box display="flex" flexDirection="column" flex={1}
                                                maxHeight="140px" overflow="auto">
                                                <Grid container rowSpacing="6px" marginTop="2px">
                                                    {customFields.map((item: string, index: number) => {
                                                        return (
                                                            <Grid item xs={12}>
                                                                <TextField variant="outlined" fullWidth size="small"
                                                                    label={`Campo ${index + 1}`}
                                                                    placeholder="Informe o nome do campo"
                                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        let auxFields: string[] = customFields;
                                                                        auxFields[index] = event.target.value;
                                                                        setCustomFields([...auxFields]);
                                                                    }}
                                                                    InputProps={{
                                                                        endAdornment: index == 0 ? null :
                                                                            <IconButton color="error"
                                                                                onClick={() => removeField(index)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                    }} />
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={0.5} />

                                        <Grid item xs={12} display="flex" alignItems="center" justifyContent="center"
                                            paddingTop="8px">
                                            <Button variant="text" color="primary" startIcon={<AddIcon />}
                                                onClick={() => addField()}>
                                                <Typography
                                                    font-size="14px" font-style="normal" font-weight="500"
                                                    line-height="20px" letter-spacing="0.1px" textTransform="initial">
                                                    Adicionar campo
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </>
                                }

                                {documentTypeSelected &&
                                    <>
                                        <Grid item xs={0.5} display="flex" alignItems="flex-start" justifyContent="flex-end"
                                            marginTop="8px">
                                            <Box
                                                display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                                minWidth="32px" minHeight="32px"
                                                borderRadius="100px"
                                                sx={{ background: theme.palette.primary.main }}>
                                                <Typography color="background.paper" fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                                    {documentTypeSelected == "UNKNOWN" ? "3" : "2"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10.5} display="flex" alignItems="center">
                                            <DragDropFiles
                                                files={files} setFiles={setFiles}
                                                onSelectFile={onSelectFile}
                                                loading={loading}>
                                                <Grid container rowSpacing="0px">
                                                    <Grid item xs={6} display="flex" alignItems="flex-end" justifyContent="flex-end"
                                                        onClick={(event) => files.length > 0 && event.stopPropagation()}>
                                                        <Button disabled={files.length == 0} startIcon={<DocumentPreviewIcon color="inherit" />}
                                                            onClick={() => setPreviewOpen(true)}>
                                                            <Typography color="inherit"
                                                                fontSize="16px" fontStyle="normal" fontWeight="400"
                                                                lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                                                Preview dos documentos
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6} display="flex" alignItems="flex-end" justifyContent="flex-start">
                                                        <Button startIcon={<FileUploadIcon color="inherit" />}
                                                            onClick={(event: any) => onSelectFile(event)}>
                                                            <Typography color="inherit"
                                                                fontSize="16px" fontStyle="normal" fontWeight="400"
                                                                lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                                                Carregar documentos
                                                            </Typography>
                                                        </Button>
                                                        <Box display="flex" flex={1} flexGrow={1} height="100%"
                                                            onClick={(event) => files.length > 0 && event.stopPropagation()} />
                                                    </Grid>
                                                </Grid>
                                            </DragDropFiles>
                                        </Grid>
                                        <Grid item xs={1} />
                                    </>
                                }

                                {files.length > 0 &&
                                    <>
                                        <Grid item xs={0.5} display="flex" alignItems="flex-start" justifyContent="flex-end"
                                            marginTop="8px">
                                            <Box
                                                display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                                                minWidth="32px" minHeight="32px"
                                                borderRadius="100px"
                                                sx={{ background: theme.palette.primary.main }}>
                                                <Typography color="background.paper" fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                                    {documentTypeSelected == "UNKNOWN" ? "4" : "3"}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={10.5} display="flex" alignItems="center">
                                            <TextField size="small" variant="outlined" fullWidth disabled={loading}
                                                label="Informe quais páginas deseja extrair" placeholder="Ex: 1,3-5"
                                                value={pages} onChange={handlePages}
                                                error={pagesError !== undefined} helperText={pagesError === undefined ? "Opcional" : pagesError} />
                                        </Grid>
                                        <Grid item xs={0.5} />
                                    </>
                                }

                                <Grid item xs={12} display="flex" alignItems="flex-end" justifyContent="flex-end"
                                    marginTop="8px">
                                    <Button variant="contained"
                                        disabled={pagesError !== undefined || files.length == 0 || loading}
                                        startIcon={<CheckIcon color="inherit" />}
                                        onClick={() => process()}>
                                        <Typography color="inherit"
                                            fontSize="16px" fontStyle="normal" fontWeight="400"
                                            lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                                            Processar
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>

                <DocumentsCustomsModelsRegisterModal
                    isOpen={isCustomModelRegisterOpen}
                    onCancel={() => {
                        if (auth.client && auth.client.id)
                            documentsCustomsModelsHooks.fetchEntitiesByClientId(auth.client.id, 0, 0);
                        setIsCustomModelRegisterOpen(false);
                    }} />
                <DocumentsPreview
                    isOpen={previewOpen}
                    files={files}
                    onCancel={() => setPreviewOpen(false)} />
            </Box>
        </DefaultTemplate >
    );
}

export default DocumentsUpload;

const DOCUMENT_MODEL_SELECT_TAB_TYPES: number = 0;
const DOCUMENT_MODEL_SELECT_TAB_MODELS: number = 1;
