import api, { API_FINANCES_URL } from "src/services";
import CRUDBaseService from "src/services/base";
import { ProductProps } from "src/types";
import { parseStringToDate } from "src/utils/dates";

export default class ProductService extends CRUDBaseService<ProductProps> {
  constructor() {
    super(api, `${API_FINANCES_URL}/products`);
  }

  override convertApiResponseToIEntity(_data: any): ProductProps {
    return {
      id: _data.id,

      name: _data.name,
      description: _data.description,
      status: _data.status,
      categories: _data.categories,
      usePaymentApi: _data.usePaymentApi,
      paymentApiId: _data.paymentApiId,
      paymentApiName: _data.paymentApiName,

      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
