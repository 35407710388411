import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";

import { theme } from "src/components";
import Drawer from "src/components/molecules/v2/drawer";
import Header from "src/components/molecules/v2/header";
import { AppProvider } from "src/hooks";
import { useAuth } from "src/hooks/auth";
import { useResponsive } from "src/hooks/responsive";
import { ClientsDetailed, Help } from "src/pages";
import {
    Account, ApiKeys, Clients, Documents, DocumentsCustomsModels, DocumentsModels,
    DocumentsResults, DocumentsUpload, Home, Invoices, Products,
    Subscription, SubscriptionsPlans, Users, Dashboard
} from "src/pages/v2";


/**
 * This component returns all the routes availables in this app
 * It uses mui styles
 * @component
 */
const AppRoutes: React.FC = () => {
    // Auth hooks for user management
    const auth = useAuth();
    // Responsive hooks for layout purpose
    const responsive = useResponsive();

    /** 
     * Effect of component init
     * Get data from logged user
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, []);

    /** 
     * Function that fetch important data
     * @function
    */
    const fetchData = async () => {
        auth.fetchLoggedUser();
    }

    /** 
     * Function that return routes items
     * @function
     */
    function items() {
        return (
            <Routes>
                <Route path="/" element={<Home />} />
                {<Route path="/account" element={<Account />} />}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/my-account/payment-methods/success" element={<ClientsDetailed isOnMyAccount={true} />} />
                <Route path="/help" element={<Help />} />

                {auth.hasRights?.("view-api_keys") && <Route path="/api-keys" element={<ApiKeys />} />}
                {auth.hasRights?.("view-documents") && <Route path="/documents" element={<Documents />} />}
                {auth.hasRights?.("view-documents") && <Route path="/documents/:id" element={<DocumentsResults />} />}
                {auth.hasRights?.("view-documents_customs_models") && <Route path="/documents-customs-models" element={<DocumentsCustomsModels />} />}
                {auth.hasRights?.("manage-document_models") && <Route path="/document-models" element={<DocumentsModels />} />}
                {auth.hasRights?.("view-documents__upload") && <Route path="/documents/upload" element={<DocumentsUpload />} />}
                {auth.hasRights?.("view-invoices") && <Route path="/invoices" element={<Invoices />} />}
                {auth.hasRights?.("view-subscriptions") && <Route path="/subscription" element={<Subscription />} />}
                {auth.hasRights?.("view-clients__users") && <Route path="/users" element={<Users />} />}

                {auth.isSuperAdmin() && <Route path="/clients/:id" element={<ClientsDetailed />} />}
                {auth.isSuperAdmin() && <Route path="/clients" element={<Clients />} />}
                {auth.isSuperAdmin() && <Route path="/products" element={<Products />} />}
                {auth.isSuperAdmin() && <Route path="/subscriptions-plans" element={<SubscriptionsPlans />} />}

                <Route path="*" element={<Home />} />
            </Routes>
        );
    }

    if (!auth.keycloak || auth.keycloak.authenticated === false) return null;
    return (
        <AppProvider>
            {responsive.mdUp && (
                <Drawer>
                    {items()}
                </Drawer>
            )}
            {responsive.mdDown &&
                <>
                    <Header>
                        <></>
                    </Header>

                    <Box display="flex" flexDirection="column" flex={1} flexGrow={1}
                        paddingTop="63px"
                        width="100%" minHeight="95vh"
                        sx={{ backgroundColor: theme.palette.background.default }}>
                        {items()}
                    </Box>
                </>
            }
        </AppProvider>
    );
};

export default AppRoutes;
