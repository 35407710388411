import { AxiosResponse } from "axios";

import SubscriptionService from "./subscriptions";
import SubscriptionPlanService from "./subscriptionPlans";
import api, { API_FINANCES_URL } from "src/services";
import CRUDBaseService from "src/services/base";
import { ClientHasUsersProps, ClientProps, IEntityPaginated, SubscriptionPlanProps, SubscriptionUsageRecordProps, UserProps } from "src/types";
import { parseStringToDate } from "src/utils/dates";

export default class ClientService extends CRUDBaseService<ClientProps> {
  constructor() {
    super(api, `${API_FINANCES_URL}/clients`);
  }
  
  async getByUserId(userId: string): Promise<ClientProps> {
    try {
      const response: AxiosResponse<ClientProps> = await this.api.get(`${this.baseURL}/users/${userId}`);
      return this.convertApiResponseToIEntity(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }
  
  async getUsers(id: string): Promise<UserProps[]> {
    try {
      const response: AxiosResponse<UserProps[]> = await this.api.get(`${this.baseURL}/${id}/users`);
      const users: UserProps[] = [];
      for (let i = 0; i < response.data.length; i++) {
        users.push(this.convertApiResponseToUserProps(response.data[i]));
      }
      return users;
    } catch (error) {
      this.handleError(error);
    }
  }
  
  async createHasUsers(payload: ClientHasUsersProps): Promise<ClientHasUsersProps> {
    try {
      const response: AxiosResponse<UserProps[]> = await this.api.post(`${this.baseURL}/users`, payload);
      const hasUser: ClientHasUsersProps = this.convertApiResponseToClientHasUserProps(response.data);
      return hasUser;
    } catch (error) {
      this.handleError(error);
    }
  }
  
  override convertApiResponseToIEntity(_data: any): ClientProps {
    return {
      id: _data.id,
      name: _data.name,
      email: _data.email,
      country: _data.country,
      postalCode: _data.postalCode,
      street: _data.street,
      number: _data.number,
      additionalInformation: _data.additionalInformation,
      district: _data.district,
      state: _data.state,
      cityCode: _data.cityCode,
      cityName: _data.cityName,
      canChangeSubscription: _data.canChangeSubscription ?? false,
      
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
  
  convertApiResponseToClientHasUserProps(_data: any): ClientHasUsersProps {
    return {
      clientId: _data.clientId,
      userId: _data.userId,
    };
  }
  
  convertApiResponseToUserProps(_data: any): UserProps {
    return {
      id: _data.id,
      username: _data.username,
      email: _data.email,
      enabled: _data.enabled,
      emailVerified: _data.emailVerified,
      firstName: _data.firstName,
      lastName: _data.lastName,
      isActive: _data.isActive,
    };
  }
}
