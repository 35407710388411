import { AxiosError, AxiosResponse } from "axios";

import api from ".";
import { IEntity } from "../types";

export class EntityServices<T extends IEntity> {
  entityForLog: string;
  baseUrl: string;

  constructor(_entity: string, _baseUrl: string) {
    this.entityForLog = _entity;
    this.baseUrl = _baseUrl;
  }

  async createEntity(_payload: T): Promise<T> {
    const response: AxiosResponse = await api.post(`${this.baseUrl}`, _payload);
    if (response.data && response?.request?.status === 200) {
      let entity: T = this.convertApiResponseToIEntity(response.data);

      if (!entity.id) throw new Error(`Erro ao criar um(a) ${this.entityForLog}.`);
      return entity;
    } else if ((response as any).response && (response as any).response.data && (response as any).response?.status === 400) {
      throw new Error((response as any).response.data.message);
    }
    throw new Error(`Não foi possível criar um(a) ${this.entityForLog}.`);
  }

  async updateEntity(_id: string, _payload: T): Promise<T | any> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${_id}`, _payload);

    if (response.data && response?.request?.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    } else if (response?.request?.status === 204) {
      return true;
    }
    throw new Error(`Não foi possível editar um(a) ${this.entityForLog}.`);
  }

  async destroyEntity(_id: string): Promise<T | any> {
    const response: AxiosResponse = await api.delete(`${this.baseUrl}/${_id}`);

    if (response.data && response?.request?.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    }
    if (response?.request?.status === 204) {
      return true;
    } else if ((response as any)?.response?.status === 400) {
      const data = (response as any)?.response?.data;
      if (data.error) {
        throw new Error(data.error);
      }
    }
    throw new Error(`Não foi possível remover um(a) ${this.entityForLog}.`);
  }

  async getEntity(_id: string, _query: string = ""): Promise<T> {
    let response: AxiosResponse | any = await api.get(`${this.baseUrl}/${_id}${_query}`);
    if ((response as any).response != undefined) response = (response as any).response;
    if (response.data && response.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    } else if (response.status == 403) {
      if (response.data.error) {
        if (response.data.error == "The user does not have access rights to document")
          throw new Error("Você não possui permissões para acessar o documento.");
      }
    }
    throw new Error(`Não foi possível buscar o(a) ${this.entityForLog}`);
  }

  async getEntityByUserId(_userId: string): Promise<T> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/by_user_id/${_userId}`);

    if (response.data && response?.request?.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    }
    throw new Error(`Não foi possível buscar o(a) ${this.entityForLog}`);
  }

  async getEntities(_query: string = ""): Promise<T[]> {
    if (_query === "") _query = "?per_page=0";
    else _query += "&per_page=0";

    const response: AxiosResponse = await api.get(`${this.baseUrl}${_query}`);

    if (response.data && response?.request?.status === 200) {
      const entities: T[] = [];

      if (response.data.items) {
        for (let i = 0; i < response.data.items.length; i++) {
          const entity: T = this.convertApiResponseToIEntity(response.data.items[i]);
          entities.push(entity);
        }
        return entities;
      }
      for (let i = 0; i < response.data.length; i++) {
        const entity: T = this.convertApiResponseToIEntity(response.data[i]);
        entities.push(entity);
      }
      return entities;
    }
    throw new Error(`Não foi possível buscar os(as) ${this.entityForLog}s`);
  }

  // ATENÇÃO: Este método deve sempre ser sobrescrita pelas classes filhas
  convertApiResponseToIEntity(_data: any): T {
    const entity: T = _data;
    return entity;
  }
}
