import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { Container, Modal, Spacing, Text } from '@pix-force/react-atoms';
import { BORDER_RADIUS, PADDING } from '@pix-force/react-atoms/lib/utils/consts';

import { theme } from 'src/components';
import { IFeedbackConfirmation } from 'src/types';

const FeedbackConfirmation: FC<IFeedbackConfirmation> = (params: IFeedbackConfirmation) => {
    // if (params.children) return (
    //     <Modal isOpened={params.open} onClose={params.onClose}>
    //         {params.children}
    //     </Modal>
    // );

    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid hasShadow veryPadded color={theme.palette.secondary.main} borderRadius={BORDER_RADIUS * 2} width="350px" height="auto">
                {params.title &&
                    <Container fluid veryPadded>
                        <Text size={17} bold center color={theme.palette.background.paper}>{params.title}</Text>
                    </Container>
                }

                <Container fluid flex veryPadded height="auto">
                    <Spacing top={PADDING} />
                    {params.message &&
                        <Text bold center size={15} color={theme.palette.background.paper}>{params.message}</Text>
                    }
                    {params.confirmMessage &&
                        <Container fluid veryPadded centered>
                            <Spacing top={20} />
                            <Text bold center color={theme.palette.background.paper}>
                                {params.confirmMessage}
                            </Text>
                        </Container>
                    }
                    {params.children}
                </Container>

                <Container inline spacedAround veryPadded>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={params.loading}
                        disabled={params.loading}
                        style={{ backgroundColor: params.loading ? theme.palette.grayLight.main : theme.palette.primary.main, minWidth: 120, height: 35, borderRadius: 0 }}
                        onClick={params.onConfirm}>
                        <Text bold size={15} color={theme.palette.background.paper}>{params.confirmLabel}</Text>
                    </LoadingButton>
                    <Spacing left={30} />
                    <LoadingButton
                        variant="outlined"
                        size="small"
                        style={{ backgroundColor: params.loading ? theme.palette.grayLight.main : theme.palette.primary.main, minWidth: 140, height: 35, borderRadius: 0 }}
                        loading={params.loading}
                        disabled={params.loading}
                        onClick={params.onCancel}>
                        <Text bold size={15} color={theme.palette.background.paper}>{params.cancelLabel}</Text>
                    </LoadingButton>
                </Container>
            </Container>
        </Modal >
    )
}

export default FeedbackConfirmation;