import React, { FC, createContext, useState, useContext } from 'react'

import { IConfirmationContext } from '../types'

const ConfirmationContext = createContext<IConfirmationContext>({} as IConfirmationContext);


export const ConfirmationProvider: FC<any> = ({ children }) => {
    const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
    const [confirmationTitle, setConfirmationTitle] = useState<string | undefined>(undefined);
    const [confirmationMessage, setConfirmationMessage] = useState<string | undefined>(undefined);
    const [confirmationConfirmLabel, setConfirmationConfirmLabel] = useState<string | undefined>(undefined);
    const [confirmationCancelLabel, setConfirmationCancelLabel] = useState<string | undefined>(undefined);
    const [confirmationOnConfirmClick, setConfirmationOnConfirmClick] = useState<(Function | undefined)>(undefined);
    const [confirmationOnCancelClick, setConfirmationOnCancelClick] = useState<(Function | undefined)>(undefined);
    const [confirmationChildren, setConfirmationChildren] = useState<React.ReactNode | undefined>(undefined);

    const showConfirmation = (_message: string, _title?: string, _confirmLabel?: string, _cancelLabel?: string, _onConfirmClick?: Function, _onCancelClick?: Function, _confirmationChildren?: React.ReactNode) => {
        if (_title) setConfirmationTitle(_title);
        setConfirmationMessage(_message);
        if (_confirmLabel) setConfirmationConfirmLabel(_confirmLabel);
        if (_cancelLabel) setConfirmationCancelLabel(_cancelLabel);

        if (_onConfirmClick) {
            setConfirmationOnConfirmClick(() => () => _onConfirmClick());
        }
        if (_onCancelClick) setConfirmationOnCancelClick(() => () => _onCancelClick());
        if (_confirmationChildren) setConfirmationChildren(_confirmationChildren);

        setConfirmationOpen(true);
    }

    const hideConfirmation = () => {
        setConfirmationTitle(undefined);
        setConfirmationMessage(undefined);
        setConfirmationConfirmLabel(undefined);
        setConfirmationCancelLabel(undefined);
        setConfirmationOnConfirmClick(undefined);
        setConfirmationOnCancelClick(undefined);
        setConfirmationChildren(undefined);

        setConfirmationOpen(false);
    }


    return (
        <ConfirmationContext.Provider value={{
            confirmationOpen, confirmationTitle, confirmationMessage,
            confirmationConfirmLabel, confirmationCancelLabel,
            showConfirmation, hideConfirmation,
            confirmationOnConfirmClick, confirmationOnCancelClick,
            confirmationChildren
        }}>{children}</ConfirmationContext.Provider>
    );
}

export function useConfirmation() {
    const context = useContext(ConfirmationContext);

    if (!context) {
        throw new Error('useConfirmation must be used within an ConfirmationProvider')
    }

    return context;
}