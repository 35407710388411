const colors = {
  primary: "#435E91",
  primaryLight: "#6490E2",
  primaryDark: "#2C58A0",

  secondary: "#2FB1B9",
  secondaryDark: "#028393",
  tertiary: "#ECF2FF",

  textColor: "#1A1B20",
  textSecondaryColor: "#44474E",
  textInfoColor: "#676767",
  background: "#F5F5F5",
  backgroundLighter: "#FFFFFF",
  backgroundPaperSecondary: "#FCFCFC",

  backgroundStrokeActiveDark: "#A4A4A4",
  backgroundStrokeActive: "#DDD",
  backgroundStrokeActiveLight: "#C4C6D0",
  backgroundElementsDarker: "#D9D9D9",
  backgroundElements: "#F9F9FF",
  backgroundElementsLighter: "#E3E3E3",

  error: "#BA1A1A",
  success: "#27871D",

  gray: "#919191",
  grayLight: "#D9D9D9",
  grayLighter: "#E4E4E4",
};

export { colors };
