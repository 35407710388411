import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
    DeleteOutlineOutlined as DeleteIcon,
} from '@mui/icons-material';

import { useAuth } from "src/hooks/auth";
import { useDocumentsCustomsModels } from "src/hooks/documentsCustomsModels";
import { useFeedback } from "src/hooks/feedback";
import { IDocumentsCustomsModelsActionsCell } from "src/types/components/molecules/v2/documentsCustomsModels";
import { IDocumentCustomModel } from "src/types";

/**
 * This component returns the documents customs models action cell
 * Can be used in documents data grid
 * It uses mui styles
 * @component
 */
const DocumentsCustomsModelsActionsCell: FC<IDocumentsCustomsModelsActionsCell> = (props: IDocumentsCustomsModelsActionsCell) => {
    // Auth hooks to get the user rights
    const auth = useAuth();
    // Document hooks for service operations
    const documentsCustomsModels = useDocumentsCustomsModels();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // Document id (the const never will be number, we treat id as UUID),
    // but the x-data-grid treats that way
    const id: string | number | undefined = props.cellParams?.id ?? undefined;

    /**
     * Function that downloads the document data
     * @function
     */
    const deleteDocumentCustomModel = () => {
        if (!id) return null;

        feedback.open(true, "info", "Informação", undefined, "Deseja realmente excluir esse modelo?", undefined, undefined,
            ["Cancelar", "Excluir"], ["text", "contained"], [() => {
                feedback.close();
            }, async () => {
                if (id && typeof id === "string") {
                    const auxDocument: IDocumentCustomModel = await documentsCustomsModels.deleteEntity(id);
                    if (auth.client && auth.client.id)
                        documentsCustomsModels.fetchEntities();
                    if (auxDocument) {
                        feedback.close();
                        feedback.open(true, "success", "Sucesso", undefined, "Modelo excluído com sucesso.");
                        return;
                    }
                }
            }]);
    }

    if (!id) return null;
    return (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
                {auth.hasRights?.("manage-documents_customs_models") &&
                    <IconButton color="error" onClick={() => deleteDocumentCustomModel()}>
                        <DeleteIcon color="error" />
                    </IconButton>
                }
            </Box>
        </Box >
    );
}

export default DocumentsCustomsModelsActionsCell;
