import React, { FC } from "react";
import { Grid } from "@mui/material";
import { Container, Text } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { theme } from "src/components";

const SubscriptionsListHeader: FC = () => {
    return (
        <Container fluid flex>
            <Container fluid centered minHeight="25px" veryPadded color={theme.palette.backgroundStrokeActive.main}
                sx={{
                    border: `solid 1px ${theme.palette.backgroundStrokeActive.dark}`,
                    borderRadius: "4px",
                    paddingTop: VERY_PADDING,
                    paddingBottom: VERY_PADDING
                }}>
                <Grid container rowSpacing={2} columnSpacing={2} display="flex">
                    <Grid item xs={3} display="flex" justifyContent="center">
                        <Text>
                            Plano
                        </Text>
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="center">
                        <Text>
                            Total de páginas
                        </Text>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center">
                        <Text>
                            Vencimento
                        </Text>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center">
                        <Text>
                            Valor
                        </Text>
                    </Grid>
                    <Grid item xs={2} display="flex" justifyContent="center">
                        <Text>
                            Status
                        </Text>
                    </Grid>
                    <Grid item xs={3} display="flex" justifyContent="center">
                        <Text>
                            Ações
                        </Text>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default SubscriptionsListHeader;
