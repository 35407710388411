import React, { FC, useState } from "react";
import { Container, Text } from "@pix-force/react-atoms";

import InvoicesListItem from "../listItem";
import { InvoiceListHeader, Pagination } from "src/components";
import { useResponsive } from "src/hooks/responsive";
import { IInvoicesList } from "src/types";

const InvoicesList: FC<IInvoicesList> = (params: IInvoicesList) => {
    const { screenHeight } = useResponsive();

    const pageCount = Math.ceil((screenHeight * 0.6) / 120);
    const documentsCount = params.invoices ? params.invoices.length : 0;

    const [currPage, setCurrPage] = useState<number>(0);

    return (
        <Container fluid flex width="100%" minHeight="80vh">
            <Container fluid width="100%" minHeight="70vh">
                <Container fluid width="100%">
                    <InvoiceListHeader />
                </Container>
                {((params.invoices && params.invoices.length == 0) ?? !params.invoices) &&
                    <Container fluid flex centered>
                        <Text>Nenhuma assinatura encontrada.</Text>
                    </Container>}
                {params.invoices && params.invoices.slice((currPage * pageCount), ((currPage * pageCount) + pageCount)).map(item =>
                    <Container fluid width="100%" verticalPadded>
                        <InvoicesListItem invoice={item} />
                    </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(documentsCount / pageCount)} setPage={setCurrPage} />
        </Container>
    );
}

export default InvoicesList;