import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip } from "@mui/material";
import {
    DeleteOutlineOutlined as DeleteIcon,
    Details as DetailsIcon,
    EditOutlined as EditIcon,
} from '@mui/icons-material';

import { useClients } from "src/hooks/clients";
import { useAuth } from "src/hooks/auth";
import { useFeedback } from "src/hooks/feedback";
import { IClientsActionsCell } from "src/types/components/molecules/v2/clients";
import { IClient, isClient } from "src/types/hooks/clients";

/**
 * This component returns the documents customs models action cell
 * Can be used in documents data grid
 * It uses mui styles
 * @component
 */
const ClientsActionsCell: FC<IClientsActionsCell> = (props: IClientsActionsCell) => {
    // Auth hooks to get the user rights
    const auth = useAuth();
    // Client hooks for service operations
    const clients = useClients();
    // Information hooks for user feedback
    const feedback = useFeedback();
    // Navigate hooks for change pages
    const navigate = useNavigate();

    // Client id (the const never will be number, we treat id as UUID),
    // but the x-data-grid treats that way
    const id: string | number | undefined = props.cellParams?.id ?? undefined;

    /**
     * Function that edit the row
     * @function
     */
    const edit = () => {
        if (props.cellParams && isClient(props.cellParams.row))
            props.onEdit(props.cellParams.row)
    }

    /**
     * Function that delete the row
     * @function
     */
    const deleteClient = () => {
        if (!id) return null;

        feedback.open(true, "info", "Informação", undefined, "Deseja realmente excluir esse cliente?", undefined, undefined,
            ["Cancelar", "Excluir"], ["text", "contained"], [() => {
                feedback.close();
            }, async () => {
                if (id && typeof id === "string") {
                    const auxDocument: IClient = await clients.deleteEntity(id);
                    clients.fetchEntities();
                    if (auxDocument) {
                        feedback.close();
                        feedback.open(true, "success", "Sucesso", undefined, "Cliente excluído com sucesso.");
                        return;
                    }
                }
            }]);
    }

    if (!id) return null;
    return (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
                {auth.hasRights?.("manage-clients") &&
                    <Tooltip title="Detalhar cliente">
                        <IconButton color="success" onClick={() => navigate(`/clients/${id}`)}>
                            <DetailsIcon />
                        </IconButton>
                    </Tooltip>
                }
                {auth.hasRights?.("manage-clients") &&
                    <IconButton color="info" onClick={() => edit()}>
                        <EditIcon />
                    </IconButton>
                }
                {auth.hasRights?.("manage-clients") &&
                    <IconButton color="error" onClick={() => deleteClient()}>
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
        </Box >
    );
}

export default ClientsActionsCell;
