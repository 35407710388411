import React, { FC } from "react"
import IconButton from '@mui/material/IconButton';
import PaginationPrior from '@mui/icons-material/KeyboardArrowLeft';
import PaginationNext from '@mui/icons-material/KeyboardArrowRight';
import PaginationFirst from '@mui/icons-material/KeyboardDoubleArrowLeft';
import PaginationLast from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Container, Text } from "@pix-force/react-atoms";

import { theme } from "src/components";
import { IPagination } from "src/types";

const Pagination: FC<IPagination> = (props: IPagination) => {
    return (
        <Container inline flex centered width="100%">
            <Container inline flex centered spacedEvenly width="100%" maxWidth="800px">
                <IconButton disabled={props.page === 0} onClick={() => props.setPage(0)}>
                    <Container fluid centered>
                        <PaginationFirst width="14px" height="14px" sx={{ color: props.colorButton ?? theme.palette.action.active }} />
                    </Container>
                </IconButton>
                <IconButton disabled={props.page === 0} onClick={() => props.setPage(props.page - 1)}>
                    <Container fluid centered>
                        <PaginationPrior width="14px" height="14px" sx={{ color: props.colorButton ?? theme.palette.action.active }} />
                    </Container>
                </IconButton>
                {Array(props.pageCount).fill(0).map((item, index) => {
                    let hideButton: boolean = true;
                    let showEllipsis: boolean = false;

                    if (index >= props.page && index - props.page <= 2) {
                        hideButton = false;
                    }
                    else if (index < props.page && props.page - index <= 2) {
                        hideButton = false;
                    }
                    else if (props.pageCount - index <= 2) hideButton = false;

                    if (hideButton) {
                        if (index > props.page && index === props.page + 3)
                            showEllipsis = true;
                        else if (index < props.page && index === props.page - 3)
                            showEllipsis = true;
                    }


                    if (showEllipsis) return (
                        <Container centered>
                            <Text medium color={props.colorButton ?? theme.palette.text.disabled} size={14} style={{ userSelect: "none" }}>...</Text>
                        </Container>
                    );

                    if (hideButton) return null;
                    return (
                        <IconButton id={`${item}-${index}`} onClick={() => props.setPage(index)}>
                            <Text extraBold={props.page === index} medium={props.page !== index} color={props.page === index ? props.colorActiveButton ?? theme.palette.primary.main : props.colorButton ?? theme.palette.text.disabled} size={14} style={{ userSelect: "none" }}>
                                {index + 1}
                            </Text>
                        </IconButton>
                    );
                })}
                <IconButton disabled={props.page === props.pageCount - 1} onClick={() => props.setPage(props.page + 1)}>
                    <Container fluid centered>
                        <PaginationNext width="14px" height="14px" sx={{ color: props.colorButton ?? theme.palette.action.active }} />
                    </Container>
                </IconButton>
                <IconButton disabled={props.page === props.pageCount - 1} onClick={() => props.setPage(props.pageCount - 1)}>
                    <Container fluid centered>
                        <PaginationLast width="14px" height="14px" sx={{ color: props.colorButton ?? theme.palette.action.active }} />
                    </Container>
                </IconButton>
            </Container>
        </Container>
    )
}

export default Pagination;
