import { FC, createContext, useContext } from "react";
import { IHookProvider } from "../types";
import { AccountContextProps, AccountCredentialsProps, AccountProps } from "src/types/hooks/account";
import { AccountService } from "src/services/financesApi/account";

/**
 * Creates the account context for hooks use
 */
const AccountContext = createContext<AccountContextProps>({} as AccountContextProps);

/**
 * This component creates the hooks provider for account model
 * Returns:
 *    updateClientAccount: Updates the client account information in Keycloak
 * @component
 */
export const AccountProvider: FC<IHookProvider> = (props: IHookProvider) => {
  // Creates a new instance of account service that communicates with back-end
  const accountService = new AccountService();

  /**
   * Functions that call service routine to update client account
   * @function
   */
  const updateClientAccount = async (userId: string, payload: AccountProps) => {
    const account: AccountProps = await accountService.updateClientAccount(userId, payload);
    return account;
  };

  /**
   * Functions that call service routine to reset logged user password
   * @function
   */
  const resetPassword = async (userId: string, payload: AccountCredentialsProps) => {
    const account: AccountProps = await accountService.resetPassword(userId, payload);
    return account;
  };

  /**
   * Functions that call service routine to upload new avatar for user
   * @function
   */
  const uploadAvatar = async (userId: string, payload: FormData) => {
    await accountService.uploadAvatar(userId, payload);
  };

  return (
    <AccountContext.Provider
      value={{
        updateClientAccount,
        resetPassword,
        uploadAvatar,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

/**
 * Hooks function to use all Account provider related methods
 * @function
 */
export function useAccount() {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }

  return context;
}
