import { EntityServices } from "./entities";
import { IDocumentModelFavorite } from "src/types";
import { parseStringToDate } from "src/utils/dates";
import { API_DOCUMENTS_URL } from ".";

export class DocumentModelFavoriteServices extends EntityServices<IDocumentModelFavorite> {
  constructor() {
    super("Modelos favoritos", `${API_DOCUMENTS_URL}/document_model_favorites`);
  }

  override convertApiResponseToIEntity(_data: any): IDocumentModelFavorite {
    return {
      id: _data.id,
      userId: _data.userId,
      modelName: _data.modelName,
      documentCustomModelId: _data.documentCustomModelId,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
