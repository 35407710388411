import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import { BaseProps } from "src/types";

class CRUDBaseService<T extends BaseProps> {
  baseURL: string;
  api: AxiosInstance;

  constructor(api: AxiosInstance, baseURL: string) {
    this.baseURL = baseURL;
    this.api = api;
  }

  handleError(error: unknown): never {
    if (error instanceof AxiosError)
      if (error.response) {
        console.error("Erro na requisição:", error.response.status, error.response.data);
      } else if (error.request) {
        console.error("Sem resposta do servidor:", error.request);
      } else {
        console.error("Erro ao processar requisição:", error.message);
      }
    throw error;
  }

  async getAll(): Promise<T[]> {
    try {
      const response: AxiosResponse<T[] | any> = await this.api.get(this.baseURL);
      if(response.data.items) return response.data.items
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getById(id: string): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.get(`${this.baseURL}/${id}`);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async create(entity: T): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.post(this.baseURL, entity);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async update(entity: T): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.put(`${this.baseURL}/${entity.id}`, entity);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async delete(id: string): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.api.delete(`${this.baseURL}/${id}`);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  convertApiResponseToIEntity(_data: any): T {
    throw new Error("The method convertApiResponseToIEntity is not implemented.");
  }
}

export default CRUDBaseService;
