import React, { FC, createContext, useContext, useState } from "react";

import { DocumentCustomModelServices } from "../services/documentsCustomsModels";
import { IDocumentCustomModel, IDocumentCustomModelContext, IEntityPaginated, IHookProvider } from "../types";

const DocumentsCustomsModelsContext = createContext<IDocumentCustomModelContext>({} as IDocumentCustomModelContext);

const blankEntitiesPaginated: IEntityPaginated<IDocumentCustomModel> = { items: [], pages: 0, page: 0, total: 0 };

export const DocumentsCustomsModelsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const service = new DocumentCustomModelServices();
    const [entities, setDocumentsCustomsModels] = useState<IDocumentCustomModel[]>([]);
    const [entitiesPaginated, setEntitiesPaginated] = useState<IEntityPaginated<IDocumentCustomModel>>(blankEntitiesPaginated);

    const [loading, setLoading] = useState<boolean>(false);
    const [searchEntity, setSearchEntity] = useState<string>("");
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    const [paginate, setPaginate] = useState<number>(0);

    const [queryString, setQueryString] = useState<string>("");

    const createNewEntity = async (_entity: IDocumentCustomModel) => {
        try {
            const entity = await service.createEntity(_entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const editEntity = async (_id: string, _entity: IDocumentCustomModel) => {
        try {
            const entity = await service.updateEntity(_id, _entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const deleteEntity = async (_id: string) => {
        try {
            const entity = await service.destroyEntity(_id);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        try {
            const documentsCustomsModels: IDocumentCustomModel[] = await service.getEntities();
            setDocumentsCustomsModels([...documentsCustomsModels]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchEntity = async (_id: string, _query?: string) => {
        const entity: IDocumentCustomModel = await service.getEntity(_id, _query);
        return entity;
    };

    const fetchEntitiesByClientId = async (_clientId: string, page: number = 1, perPage: number = 10) => {
        setLoading(true);
        try {
            setEntitiesPaginated({ ...blankEntitiesPaginated });
            let query: string = `?search_relationships=1`;
            if(page !== 0) query += `&page=${page}`;
            if(perPage !== 0) query += `&per_page=${perPage}`;
            
            const entitiesPaginated: IEntityPaginated<IDocumentCustomModel> = await service.getEntitiesByClientId(_clientId, query);
            setEntitiesPaginated({ ...entitiesPaginated });
            setLoading(false);
            return entitiesPaginated;
        } catch (_err) {
            console.log(_err);
            throw _err;
        }
    };

    return (
        <DocumentsCustomsModelsContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,

                entitiesPaginated, setEntitiesPaginated,

                fetchEntitiesByClientId,

                queryString
            }}
        >
            {_params.children}
        </DocumentsCustomsModelsContext.Provider>
    );
};

export function useDocumentsCustomsModels() {
    const context = useContext(DocumentsCustomsModelsContext);

    if (!context) {
        throw new Error(
            "useDocumentsCustomsModels must be used within an DocumentsCustomsModelsProvider"
        );
    }

    return context;
}
