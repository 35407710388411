import { AxiosResponse } from "axios";

import SubscriptionPlanService from "./subscriptionPlans";
import api, { API_FINANCES_URL, APP_URL } from "src/services";
import CRUDBaseService from "src/services/base";
import { ClientProps, SubscriptionPlanProps, SubscriptionProps, SubscriptionUsageRecordDetailProps, SubscriptionUsageRecordProps } from "src/types";
import { parseStringToDate } from "src/utils/dates";
import ClientService from "./clients";
import { SubscriptionFreeTrial } from "src/types/hooks/financesApi/subscriptions";
import moment from "moment";

export default class SubscriptionService extends CRUDBaseService<SubscriptionProps> {
  static convertApiResponseToIEntity(subscription: any): SubscriptionProps | undefined {
    throw new Error("Method not implemented.");
  }
  constructor() {
    super(api, `${API_FINANCES_URL}/subscriptions`);
  }

  async getCurrentByClientId(clientId: string): Promise<SubscriptionProps> {
    try {
      const response: AxiosResponse<SubscriptionProps> = await this.api.get(`${this.baseURL}/clients/${clientId}/current?relationship=1`);
      return this.convertApiResponseToIEntity(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  async getUsageRecords(id: string): Promise<SubscriptionUsageRecordProps> {
    try {
      const response: AxiosResponse<SubscriptionUsageRecordProps> = await this.api.get(`${this.baseURL}/${id}/usage_record`);
      return this.convertApiResponseToSubscriptionUsageRecordProps(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  async createFreeTrial(name: string, email: string, company: string): Promise<SubscriptionProps> {
    try {
      const payload: SubscriptionFreeTrial = {
        Nome: name,
        "E-mail": email,
        Celular: "",
        Empresa: company,
        "Segmento de Mercado": "",
        "Número de Funcionários": "",
        "Qual plano melhor atende sua necessidade?": "Plano Starter (menos de 1.000 páginas)",
        Data: moment().format("DD/MM/YYYY"),
        Horário: moment().format("HH:mm:ss"),
        "URL da página": APP_URL,
        "Agente de usuário": "",
        "Desenvolvido por": "",
        form_id: "",
        form_name: "",
      };
      const response: AxiosResponse<SubscriptionUsageRecordProps> = await this.api.post(`${this.baseURL}/clients/create_account_trial`, payload);
      return this.convertApiResponseToIEntity(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  override convertApiResponseToIEntity(_data: any): SubscriptionProps {
    let subscriptionPlan: SubscriptionPlanProps | undefined = undefined;
    if (_data.subscriptionPlan) subscriptionPlan = new SubscriptionPlanService().convertApiResponseToIEntity(_data.subscriptionPlan);
    let client: ClientProps | undefined = undefined;
    if (_data.client) client = new ClientService().convertApiResponseToIEntity(_data.client);

    return {
      id: _data.id,

      subscriptionPlanId: _data.subscriptionPlanId,
      subscriptionPlan,
      clientId: _data.clientId,
      client,
      initialDate: _data.initialDate,
      finalDate: _data.finalDate,
      status: _data.status,
      paid: _data.paid,
      cancelAtPeriodEnd: _data.cancelAtPeriodEnd,
      currency: _data.currency,
      collectionMethod: _data.collectionMethod,
      paymentApiId: _data.paymentApiId,

      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }

  convertApiResponseToSubscriptionUsageRecordDetailProps(_data: any): SubscriptionUsageRecordDetailProps {
    return {
      yearMonth: _data.yearMonth,
      uploadCount: _data.uploadCount
    };
  }

  convertApiResponseToSubscriptionUsageRecordProps(_data: any): SubscriptionUsageRecordProps {
    const usages: SubscriptionUsageRecordDetailProps[] = [];
    for (let i = 0; i < _data.usages; i++) {
      usages.push(this.convertApiResponseToSubscriptionUsageRecordDetailProps(_data.usages));
    }
    const currentUsage: SubscriptionUsageRecordDetailProps = this.convertApiResponseToSubscriptionUsageRecordDetailProps(_data.usages);

    return {
      totalRequests: _data.totalRequests,
      finishedCount: _data.finishedCount,
      usages,
      currentUsage,
      lastUpload: _data.lastUpload
    };
  }
}
