export const parseJwt = (_token: string) => {
  try {
    const decodedToken = decodeURIComponent(
      atob(_token.split('.')[1])
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(decodedToken);
  } catch (e) {
    return null;
  }
};