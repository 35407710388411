import { FC, useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Card, CardContent, Typography } from "@mui/material";
import { CameraAltOutlined as CameraIcon, CloudUpload as UploadIcon } from "@mui/icons-material";

import { AccountForm, AccountResetPassword, DefaultTemplate } from "src/components";
import { useAccount } from "src/hooks/account";
import { useAuth } from "src/hooks/auth";
import { useFeedback } from "src/hooks/feedback";
import { AccountProps } from "src/types/hooks/account";
import { useClients } from "src/hooks/clients";
import styled from "styled-components";

/**
 * This component returns the logged user account page
 * It uses mui styles
 * @component
 */
const Account: FC = () => {
  // Inits the auth hooks
  const auth = useAuth();
  if (!auth.user || !auth.client) return null;

  // Inits the account hooks
  const account = useAccount();
  // Inits the clients hooks
  const clients = useClients();
  // Inits the feedback hooks
  const feedback = useFeedback();
  // Inits the avatar file ref
  const avatarFile = useRef(null);

  // State that controls the form disable value
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  // State that controls the open status of reset password modal
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState<boolean>(false);

  /**
   * Reflects when component inits
   * @effect
   */
  useEffect(() => {}, []);


  /**
   * Function that handle when user select a new avatar
   * @function
   */
  const onImageSelect = async (event: any) => {
    if (!auth.user) return;

    try {
      setFormDisabled(true);
      event.stopPropagation();
      event.preventDefault();
      const { files } = event.target;
      if (files && files.length) {
        const file = files[0];

        const payload = new FormData();
        payload.append("file", file);
        await account.uploadAvatar(auth.user.id, payload);
      }
      feedback.open(
        true,
        "success",
        "",
        undefined,
        "Foto de perfil atualizada com sucesso.",
        undefined,
        undefined,
        ["Confirmar"],
        ["contained"],
        [
          () => {
            feedback.close();
            window.location.reload();
          },
        ]
      );
    } catch (err) {
      feedback.open(
        true,
        "error",
        "Atenção",
        undefined,
        "Ocorreu um erro ao atualizar a foto de perfil.",
        undefined,
        undefined,
        ["Confirmar"],
        ["contained"],
        [
          () => {
            setFormDisabled(false);
            feedback.close();
          },
        ]
      );
    } finally {
      setFormDisabled(false);
      auth.onRefreshToken();
    }
  };

  /**
   * Function that update the account information
   * @function
   */
  const updateAccountData = async (payload: AccountProps, clientName: string) => {
    if (!auth.user || !auth.client || !auth.client.id) return;

    try {
      setFormDisabled(true);

      await account.updateClientAccount(auth.user.id, payload);
      await clients.editEntity(auth.client.id, { name: clientName, email: auth.client.email });

      feedback.open(
        true,
        "success",
        "",
        undefined,
        "Dados atualizados com sucesso.",
        undefined,
        undefined,
        ["Confirmar"],
        ["contained"],
        [
          () => {
            feedback.close();
            window.location.reload();
          },
        ]
      );
    } catch (err) {
      feedback.open(
        true,
        "error",
        "Atenção",
        undefined,
        "Ocorreu um erro ao salvar os dados.",
        undefined,
        undefined,
        ["Confirmar"],
        ["contained"],
        [
          () => {
            setFormDisabled(false);
            feedback.close();
          },
        ]
      );
    } finally {
      setFormDisabled(false);
      auth.onRefreshToken();
    }
  };

  return (
    <DefaultTemplate title="Meus dados" subtitle="Gerencie aqui seus dados">
      <Box display="flex" paddingTop="48px">
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            flexGrow: 1,
            width: "100%",
            alignItems: "center",
            paddingX: "32px",
            paddingY: "24px",
            borderRadius: "12px",
          }}
        >
          <CardContent sx={{ width: "100%", maxWidth: "100%" }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar src={auth.user.avatar} sx={{ width: "80px", height: "80px" }} />
              <Button component="label" role={undefined} tabIndex={-1} disabled={formDisabled} startIcon={<CameraIcon />} sx={{ marginTop: "22px" }}>
                <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                  Alterar imagem
                </Typography>
                <input hidden ref={avatarFile} type="file" accept="image/*" onChange={(e) => onImageSelect(e)} />
              </Button>
            </Box>

            <AccountForm
              disabled={formDisabled}
              user={auth.user}
              client={auth.client}
              onSubmit={updateAccountData}
              onResetPasswordClick={() => setResetPasswordModalOpen(true)}
            />
          </CardContent>
        </Card>
      </Box>

      <AccountResetPassword open={resetPasswordModalOpen} onClose={() => setResetPasswordModalOpen(false)} userId={auth.user.id} />
    </DefaultTemplate>
  );
};

export default Account;
