import React from "react";
import { createTheme } from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";
import { ptBR } from "@mui/material/locale";
import { ptBR as DataGridPtBR } from "@mui/x-data-grid";
import { ptBR as DatePickersPtBR } from "@mui/x-date-pickers/locales";

import { colors } from "./styles";

const theme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1350,
        xl: 1920,
      },
    },
    shadows: Array(25).fill("None") as Shadows,
    shape: {
      borderRadius: 6,
    },
    palette: {
      primary: {
        main: colors.primary,
        light: colors.primaryLight,
        dark: colors.primaryDark,
      },
      secondary: {
        main: colors.secondary,
        dark: colors.secondaryDark,
      },
      tertiary: { main: colors.tertiary },
      background: {
        default: colors.background,
        paper: colors.backgroundLighter,
      },

      error: { main: colors.error },
      success: { main: colors.success },

      gray: { main: colors.gray },
      grayLight: { light: colors.grayLighter, main: colors.grayLight },

      text: { primary: colors.textColor, secondary: colors.textSecondaryColor },
      textInfo: { main: colors.textInfoColor },
      backgroundStrokeActive: {
        dark: colors.backgroundStrokeActiveDark,
        main: colors.backgroundStrokeActive,
        light: colors.backgroundStrokeActiveLight,
      },
      backgroundElements: { dark: colors.backgroundElementsDarker, main: colors.backgroundElements, light: colors.backgroundElementsLighter },
    },
    typography: {
      fontFamily: "Roboto, Open Sans",
    },
    components: {
      MuiButton: {
        defaultProps: {
          size: "medium",
        },
        styleOverrides: {
          sizeMedium: {
            height: "40px",
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          sizeSmall: {
            minHeight: "40px",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            background: "white",
            backdropFilter: "blur(10px)",
            borderRadius: "4px",
            boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
    },
  },
  ptBR,
  DataGridPtBR,
  DatePickersPtBR
);

export default theme;
