import { FC, useEffect, useState } from "react";
import { Grid, Switch, TextField, Typography } from "@mui/material";
import 'dayjs/locale/pt-br';

import { DocumentsTypesMultiSelect } from "src/components";
import RegisterTemplate from "src/components/templates/registerTemplate";
import { useAuth } from "src/hooks/auth";
import { useDocumentModels } from "src/hooks/documentModels";
import { useProducts } from "src/hooks/products";
import { useFeedback } from "src/hooks/feedback";
import { IDocumentModel, IDocumentType } from "src/types";
import { IProductsRegister } from "src/types/components/molecules/v2/products";
import { IProduct } from "src/types/hooks/products";

const ProductsRegister: FC<IProductsRegister> = (props: IProductsRegister) => {
    // Const about edit mode
    const edit: boolean = props.payload !== undefined;

    // Auth hooks to get client information
    const auth = useAuth();
    // Document models hooks for upload and types data
    const documentModels = useDocumentModels();
    // Products hooks to create or update data
    const products = useProducts();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // State for loading control
    const [loading, setLoading] = useState<boolean>(false);
    // State for name of product
    const [name, setName] = useState<string>("");
    // State for name error status
    const [nameError, setNameError] = useState<string>("");
    // State for description of product
    const [description, setDescription] = useState<string>("");
    // State for payment api uses status
    const [usePaymentApi, setUsePaymentApi] = useState<boolean>(false);
    // State for public product name
    const [paymentApiName, setPaymentApiName] = useState<string>("");
    // State for selected categories (documents types)
    const [selectedCategories, setSelectedCategories] = useState<IDocumentModel[]>([]);
    // State for multi select categories
    const [categoriesExpanded, setCategoriesExpanded] = useState<boolean>(false);
    // State for multi select categories error
    const [selectedCategoriesError, setSelectedCategoriesError] = useState<string | undefined>(undefined);

    /**
     * Reflects component init
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Handle the change payload in props
     * @effect
     */
    useEffect(() => {
        if (edit) loadEditFields();
    }, [props.payload]);


    /**
     * Function that fetch necessary data
     * @function
     */
    const fetchData = async () => {
        documentModels.fetchEntities();
        // documents.fetchAvailableTypes();
    };


    /**
     * Function that load edit fields
     * @function
     */
    const loadEditFields = async () => {
        setLoading(true);
        if (props.payload && props.payload.id) {
            setName(props.payload.name);
            if (props.payload.description) setDescription(props.payload.description);
            if (props.payload.usePaymentApi) setUsePaymentApi(props.payload.usePaymentApi);
            if (props.payload.paymentApiName) setPaymentApiName(props.payload.paymentApiName);
            let auxCategories: IDocumentModel[] = [];
            for (let i = 0; i < props.payload.categories.split(',').length; i++) {
                const category: number = parseInt(props.payload.categories.split(',')[i]);
                const documentType: IDocumentModel | undefined = documentModels.entities.find(item => item.type == category);
                if (documentType)
                    auxCategories.push(documentType);
            }
            setSelectedCategories(auxCategories);
        } else {
            props.onCancel();
            setLoading(false);
            feedback.open(true, "error", "Erro", undefined, "Ocorreu um erro ao editar a produto.");
        }
        setLoading(false);
    };

    /**
     * Handle the change text of setText passed on args
     * @function
     */
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => {
        setText(event.target.value);
    }

    /**
     * Validation routine to check fields
     * @function
     */
    const validation = () => {
        let isValid: boolean = true;
        setNameError("");
        setSelectedCategoriesError(undefined);

        if (name == "") {
            isValid = false;
            setNameError("Preenchimento obrigatório")
        }
        if (selectedCategories.length == 0) {
            setSelectedCategoriesError("Favor selecionar pelo menos uma categoria.");
            isValid = false;
        }

        return isValid;
    };

    /**
     * Sends request to save product in back-end
     * @function
     */
    const save = async () => {
        if (!auth.client || !auth.client.id) return;
        if (!auth.user || !auth.user.id) return;
        if (!validation()) return;

        try {
            setLoading(true);

            let payload: IProduct = {
                name: name,
                description: description,
                categories: selectedCategories.map(item => item.type).join(','),
                usePaymentApi,
                paymentApiName: paymentApiName == "" ? undefined : paymentApiName,
                status: 1,
            };

            let id: string | undefined = undefined;
            let informationMessage: string = "Produto cadastrado com sucesso!";
            if (edit && props.id) {
                id = props.id;
                await products.editEntity(props.id, payload);
                informationMessage = "Produto alterado com sucesso!";
            } else {
                const auxSubscriptionPlan: IProduct =
                    await products.createNewEntity(payload, false);
                id = auxSubscriptionPlan.id;
            }

            cleanData();
            props.onCancel();
            setLoading(false);
            feedback.open(true, "success", "Sucesso", undefined, informationMessage, undefined, false,
                ["Continuar"], ["contained"], [() => {
                    products.fetchEntities();
                    feedback.close();
                }]);
        } catch (err: any) {
            console.log(err);
            feedback.open(true, "error", "Erro", undefined, err.message as string);
            setLoading(false);
        }
    }

    /**
     * Cleans all data fields
     * @function
     */
    const cleanData = () => {
        setName("");
        setDescription("");
        setUsePaymentApi(false);
        setPaymentApiName("");
    };

    return (
        <RegisterTemplate
            title={edit ? "Editar produto" : "Novo produto"}
            subtitle="Os produtos do Idexa servem para agrupar tipos e modelos de documentos para que seja possível limitar o uso do cliente. Somente super administradores da plataforma conseguem gerenciar."
            cancelClick={() => props.onCancel()}
            okClick={() => save()}>
            <Grid container display="flex" rowSpacing="24px" sx={{
                maxHeight: "60vh",
                overflowY: "auto"
            }}>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="Nome"
                        placeholder="Nome do produto"
                        value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName)}
                        error={nameError !== ""} helperText={nameError} />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="Descrição"
                        placeholder="Descrição do produto"
                        value={description} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setDescription)}
                        helperText="Opcional" />
                </Grid>
                <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                    <Typography>Utiliza API de pagamento</Typography>
                    <Switch checked={usePaymentApi} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsePaymentApi(event.target.checked)} />
                </Grid>
                {usePaymentApi &&
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Nome público"
                            placeholder="Informe o nome que irá aparecer na fatura do cliente"
                            value={paymentApiName} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setPaymentApiName)}
                            helperText="Opcional (caso não seja informado, será utilizado o nome principal)" />
                    </Grid>
                }
                <Grid item xs={12}>
                    <DocumentsTypesMultiSelect
                        data={documentModels.entities}
                        selectedTypes={selectedCategories}
                        setSelectedTypes={setSelectedCategories}
                        expanded={categoriesExpanded}
                        setExpanded={setCategoriesExpanded}
                        placeholder="Categoria de documento"
                        placeholderInput="Pesquisar por categorias"
                        sx={{
                            border: "solid 1px rgba(0, 0, 0, 0.23)",
                            borderRadius: '6px',
                            padding: "16.5px 14px"
                        }} />
                    {selectedCategoriesError && (
                        <Typography color="error">
                            {selectedCategoriesError}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </RegisterTemplate>
    )
}

export default ProductsRegister;