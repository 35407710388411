import { FC, useEffect, useState } from "react";
import { Box, Grid, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import {
    ContentCopy as CopyIcon,
    EditOutlined as EditIcon,
    MoreVert as TextFieldOptions,
    Save as SaveIcon
} from '@mui/icons-material';

import { IDocumentsResultDataShowItem } from "src/types/components/molecules/v2/documents";
import { copyToClipboard } from "src/utils/utils";

/**
 * This component returns the documents item data information
 * It uses mui styles
 * @component
 */
const DocumentsResultDataShowItem: FC<IDocumentsResultDataShowItem> = (props: IDocumentsResultDataShowItem) => {
    // State for edit mode on each key of response data object
    const [isEdit, setIsEdit] = useState<boolean>(false);
    // State for value of response data attribute
    const [value, setValue] = useState<string>("");
    // State for menu button anchor field
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    // Const of boolean anchorElva lue
    const open = Boolean(anchorEl);

    /**
     * Reflects when component inits
     * @effect
     */
    useEffect(() => {
        if (!props.value) setValue("");
        setValue(props.value);
    }, []);

    /**
     * Function that handle menu button click
     * @function
     */
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Function that handle menu button close
     * @function
    */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    /**
     * Function that copy the value of data to clipboard
     * @function
     */
    const copyValue = () => {
        if (!value) return;
        copyToClipboard(value.toString());
        handleMenuClose();
    }

    /**
     * Function that handle value edit
     * @function
     */
    const handleValueEdit = () => {
        setIsEdit(true);
        handleMenuClose();
    };

    return (
        <>
            <Grid id={`data-field-${props.attr}`} key={`${props.attr}`} item xs={12}>
                <TextField autoFocus={isEdit} size="small" variant="outlined" fullWidth
                    label={props.attr}
                    value={value} onChange={(event) => setValue(event.target.value)}
                    multiline={(value && value.toString().length > 80) || false}
                    InputProps={{
                        readOnly: !isEdit,
                        endAdornment: (
                            isEdit ?
                                <IconButton color="primary" onClick={() => {
                                    setIsEdit(false);
                                    props.onSave(props.attr, value)
                                }}>
                                    <SaveIcon />
                                </IconButton> :
                                <IconButton color="primary" onClick={(event) => handleMenuClick(event)}>
                                    <TextFieldOptions />
                                </IconButton>
                        ),
                    }} />
            </Grid>

            <Menu id={`data-field-menu-${props.attr} `}
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleMenuClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem onClick={() => copyValue()}>
                    <Box display="flex">
                        <CopyIcon />
                        <Typography fontSize="16px" fontStyle="normal" fontWeight="400"
                            lineHeight="24px" letterSpacing="0.5px"
                            sx={{ paddingX: "12px" }}>
                            Copiar
                        </Typography>
                    </Box>
                </MenuItem>
                <MenuItem onClick={() => handleValueEdit()}>
                    <Box display="flex">
                        <EditIcon />
                        <Typography fontSize="16px" fontStyle="normal" fontWeight="400"
                            lineHeight="24px" letterSpacing="0.5px"
                            sx={{ paddingX: "12px" }}>
                            Editar
                        </Typography>
                    </Box>
                </MenuItem>
            </Menu>
        </>
    );
}

export default DocumentsResultDataShowItem;