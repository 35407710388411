import { FC } from "react";
import { Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import theme from "src/components/theme";
import { ISubscriptionInformationAditional } from "src/types";

const SubscriptionInformationAditional: FC<ISubscriptionInformationAditional> = (params: ISubscriptionInformationAditional) => {
    return (
        <Container fluid flexStart>
            <Container inline centered>
                <Container width="5px" height="5px" color={theme.palette.secondary.main} />
                <Spacing left={PADDING / 2} />
                <Text size={params.fontSize ?? 15} light color={theme.palette.background.paper}>
                    {params.text}
                </Text>
            </Container>
        </Container>
    )
}

export default SubscriptionInformationAditional;
