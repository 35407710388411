import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import {
    ArrowForward as SeeAllIcon,
    ArticleOutlined as DocumentsIcon,
    DocumentScannerOutlined as DocumentsUploadIcon,
    InsertDriveFileOutlined as NoRowsIcon,
    LeaderboardOutlined as DashboardIcon,
} from "@mui/icons-material";

import { documentsDataGridColumns } from '../documents';
import { DefaultTemplate, theme } from 'src/components';
import CRUDDataGrid from 'src/components/molecules/v2/crud/dataGrid';
import HomeFeatureButton from 'src/components/molecules/v2/home/featureButton';
import { useAuth } from 'src/hooks/auth';
import { useDocuments } from 'src/hooks/documents';
import { useFeedback } from 'src/hooks/feedback';
import { useResponsive } from 'src/hooks/responsive';

/**
 * This component returns the Home page
 * It uses mui styles
 * @component
 */
const Home: React.FC = () => {
    // Auth hooks that contains current user data
    const auth = useAuth();
    // Document hooks to fetch documents list
    const documents = useDocuments();
    const feedback = useFeedback();
    // Navigate hooks to change routes
    const navigate = useNavigate();
    // Responsive hooks for app layout
    const responsive = useResponsive();

    /**
     * Reflects when component inits
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Reflects when client data is loaded
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, [auth.client]);

    /**
     * Function to fetch all necessary data of the page
     * @effect
     */
    const fetchData = (_currPage: number = 0) => {
        documents.fetchEntitiesPaginated(_currPage + 1, 5, "");
    }

    return (
        <DefaultTemplate title={`Olá, ${auth.user?.firstName}.`}>
            <Box display="flex" flexDirection="column" flex={1} flexGrow={1}>
                <Grid container display="flex" flexDirection="row" rowSpacing="40px" columnSpacing="20px" paddingY="40px">
                    <Grid item xs={12} sm={6} md={6} lg={4}
                        display="flex"
                        alignItems={responsive.lgUp ? "flex-start" : "center"} justifyContent={responsive.lgUp ? "flex-start" : "center"}>
                        <HomeFeatureButton headerIcon={DocumentsUploadIcon}
                            headerText="Ler documento"
                            description="Escolha um modelo de documento entre os diversos disponíveis, selecione seus arquivos e extraia os dados."
                            onClick={() => navigate("/documents/upload")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}
                        display="flex"
                        alignItems="center" justifyContent="center">
                        <HomeFeatureButton headerIcon={DocumentsIcon}
                            headerText="Meus documentos"
                            description="Todos os documentos que já foram lidos e os respectivos dados processados."
                            onClick={() => navigate("/documents")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4}
                        display="flex"
                        alignItems={responsive.lgUp ? "flex-end" : "center"} justifyContent={responsive.lgUp ? "flex-end" : "center"}>
                        <HomeFeatureButton disabled headerIcon={DashboardIcon}
                            headerText="Estatísticas"
                            description="Acompanhe os dados relacionados a utilização da sua conta através de gráficos."
                            onClick={() => navigate("/dashboard")} />
                    </Grid>

                    <Grid item xs={12}>
                        <Box display="flex" flex={1} flexGrow={1}>
                            <Card elevation={10}
                                sx={{
                                    width: "100%",
                                    height: "auto",
                                    background: theme.palette.background.paper,
                                    borderRadius: "12px"
                                }}>
                                <CardContent>
                                    <Box display="flex" flexDirection="row" flex={1} alignItems="center" justifyContent="space-between"
                                        paddingBottom="24px">
                                        <Typography fontSize="22px" fontStyle="normal" fontWeight="400" lineHeight="28px"
                                            color={theme.palette.primary.main}>
                                            Últimos documentos lidos
                                        </Typography>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Button variant="text" startIcon={<SeeAllIcon color="inherit" />}
                                                onClick={() => navigate("/documents")}>
                                                <Typography color="inherit" fontSize="14px" fontStyle="normal" textTransform="initial"
                                                    fontWeight="500" lineHeight="20px" letterSpacing="0.1px">
                                                    Ver todos
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>

                                    {(documents.entitiesPaginated.total > 0 || documents.loading) ?
                                        <CRUDDataGrid loading={documents.loading} rows={documents.entitiesPaginated.items} columns={documentsDataGridColumns}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: {
                                                        pageSize: 3
                                                    },
                                                },
                                            }}
                                            checkboxSelection={false}
                                            hideFooter hideFooterPagination hideFooterSelectedRowCount 
                                            autoHeight/>
                                        :
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                                            paddingY="16px"
                                            width="100%" height="auto">
                                            <NoRowsIcon color="primary" sx={{ width: 70, height: 70 }} />
                                            <Box width="50%" paddingY="8px">
                                                <Typography fontSize="18px" fontStyle="normal" fontWeight="400" lineHeight="28px" textAlign="center">
                                                    Você ainda não tem documentos para exibir aqui. Faça a leitura do seu primeiro documento.
                                                </Typography>
                                            </Box>

                                            <Box display="flex" flexDirection="row" width="100%" alignItems="center" justifyContent={documents.entitiesPaginated.total == 0 ? "center" : "flex-end"}
                                                paddingTop="16px">
                                                <Button variant="contained" startIcon={<DocumentsUploadIcon color="inherit" />}
                                                    onClick={() => navigate("/documents/upload")}>
                                                    <Typography color="inherit" fontSize="14px" fontStyle="normal" fontWeight="500" textTransform="initial"
                                                        lineHeight="20px" letterSpacing="0.1px">
                                                        Ler novos documentos
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Box>
                                    }
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </DefaultTemplate>
    );
}

export default Home;
