import React, { FC } from "react";
import { Modal } from "@pix-force/react-atoms";
import { IApiKeyRegisterModal } from "src/types/components/molecules/apiKeys";
import ApiKeyRegister from ".";


const ApiKeyRegisterModal: FC<IApiKeyRegisterModal> = (params: IApiKeyRegisterModal) => {
    return (
        <Modal isOpened={params.isOpen} onClose={() => params.onClose()}>
            <ApiKeyRegister clientId={params.clientId} onClose={() => params.onClose()} onRefresh={params.onRefresh} />
        </Modal>
    )
}

export default ApiKeyRegisterModal;
