import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormControl, Grid, MenuItem, InputLabel, Select, Switch } from "@mui/material";
import moment from "moment";
import { Button, Container, Divider, Input, Spacing, Text, } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { InputAdornmentUser, InputAdornmentAddress, theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { useClients } from "src/hooks/clients";
import { useInformation } from "src/hooks/information";
import { useResponsive } from "src/hooks/responsive";
import { useSubscriptions } from "src/hooks/subscriptions";
import { useSubscriptionsPlans } from "src/hooks/subscriptionsPlans";
import { IClient, IClientsRegister, ISelectValue, SubscriptionProps } from "src/types";
import citiesData from "src/utils/municipios.json";
import statesData from "src/utils/estados.json";
import countriesData from "src/utils/paises.json"

const ClientsRegister: FC<IClientsRegister> = (params: IClientsRegister) => {
  const Cities = citiesData.data;
  const States = statesData.data;
  const edit: boolean = params.id !== undefined;
  const addingRequiredFields: boolean = params.isAddingRequiredFields !== undefined && params.isAddingRequiredFields !== false;
  const cnpjMask = '##.###.###/####-##';
  const postalCodeMask = '#####-###';

  const authHooks = useAuth();
  const clientHook = useClients();
  const { register, handleSubmit, setValue } = useForm();
  const { hideInformation, showInformation } = useInformation();
  const { mdDown } = useResponsive();
  const subscriptionHook = useSubscriptions();
  const subscriptionPlanHook = useSubscriptionsPlans();

  const [loading, setLoading] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | undefined>(undefined);
  const [cpfCnpjError, setcpfCnpjError] = useState<string | undefined>(undefined);
  const [cityCodeError, setCityCodeError] = useState<string | undefined>(undefined);
  const [cityNameError, setCityNameError] = useState<string | undefined>(undefined);
  const [countryError, setCountryError] = useState<string | undefined>(undefined);
  const [streetError, setStreetError] = useState<string | undefined>(undefined);
  const [postalCodeError, setPostalCodeError] = useState<string | undefined>(undefined);
  const [numberError, setNumberError] = useState<string | undefined>(undefined);
  const [stateError, setStateError] = useState<string | undefined>(undefined);
  const [districtError, setDistrictError] = useState<string | undefined>(undefined);

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [cpfCnpj, setCpfCnpj] = useState<string>("");
  const [country, setCountry] = useState<string>("BR");
  const [postalCode, setPostalCode] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [number, setNumber] = useState<string>("");
  const [additionalInformation, setAdditionalInformation] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [cityCode, setCityCode] = useState<string>("");
  const [cityName, setCityName] = useState<string>("");
  const [canChangeSubscription, setCanChangeSubscription] = useState<boolean>(false);

  const [subscriptionType, setSubscriptionType] = useState<ISelectValue | undefined>(undefined);
  const [subscriptionPlan, setSubscriptionPlan] = useState<any>(undefined);
  const [subscriptionPlanName, setSubscriptionPlanName] = useState<string>("");
  const [subscriptionPlanError, setSubscriptionPlanError] = useState<string | undefined>(undefined);
  const [initialSubscriptionDate, setInitialSubscriptionDate] = useState<Date | null>(null);
  const [initialSubscriptionDateError, setInitialSubscriptionDateError] = useState<string | undefined>(undefined);
  const [finalSubscriptionDate, setFinalSubscriptionDate] = useState<Date | null>(null);
  const [finalSubscriptionDateError, setFinalSubscriptionDateError] = useState<string | undefined>(undefined);

  const filteredCitiesByUf = Cities.filter((city) => city.Uf == state);

  const formatPostalCode = (value: string) => {
    const numbersOnly = value.replace(/[^\d]/g, '');
    let formattedValue = '';

    for (let i = 0; i < numbersOnly.length && i < postalCodeMask.length; i++) {
      if (postalCodeMask[i] === '#') {
        formattedValue += numbersOnly[i] ?? '';
      } else {
        formattedValue += postalCodeMask[i];
        if (numbersOnly[i]) {
          formattedValue += numbersOnly[i];
        }
      }
    }

    return formattedValue;
  };

  const formatCnpj = (value: string) => {
    const numbersOnly = value.replace(/[^\d]/g, '');
    let formattedValue = '';
    let valueIndex = 0;

    // Apply the mask to the CNPJ value
    for (let i = 0; i < cnpjMask.length; i++) {
      if (cnpjMask[i] === '#') {
        formattedValue += numbersOnly[valueIndex] ?? '';
        valueIndex++;
      } else {
        formattedValue += cnpjMask[i];
      }
    }

    return formattedValue;
  };

  useEffect(() => {
    fetchData();
    if (edit) loadEditFields();
  }, []);

  const fetchData = async () => {
    subscriptionPlanHook.fetchEntities();

    if (edit && params.id) {
      try {
        setLoading(true);

        const client: IClient = await clientHook.fetchEntity(params.id);
        setName(client.name);
        if (client.cpfCnpj) setCpfCnpj(client.cpfCnpj);
        if (client.cityCode) setCityCode(client.cityCode);
        if (client.cityName) setCityName(client.cityName);
        if (client.country) client.country == "BRA" ? setCountry("BR") : setCountry(client.country);
        if (client.district) setDistrict(client.district);
        if (client.state) setState(client.state)
        if (client.number) setNumber(client.number);
        if (client.postalCode) setPostalCode(client.postalCode);
        if (client.street) setStreet(client.street);
        if (client.additionalInformation) setAdditionalInformation(client.additionalInformation);
        if (client.canChangeSubscription) setCanChangeSubscription(client.canChangeSubscription);

        setLoading(false);
      } catch (_err) {
        console.log(_err);
        setLoading(false);
      }
    }
  };
  const loadEditFields = () => {
    if (params.client) {
      setName(params.client.name);
      setValue("name", params.client.name)
      if (params.client.email) {
        setEmail(params.client.email);
        setValue("email", params.client.email)
      }
      if (params.client.cpfCnpj) setCpfCnpj(params.client.cpfCnpj);
      if (params.subscription) {
        if (params.subscription.subscriptionPlan) {
          setSubscriptionPlan({
            id: params.subscription.subscriptionPlan.id,
            label: params.subscription.subscriptionPlan.name,
          });
          setSubscriptionPlanName(params.subscription.subscriptionPlan.name);
        }
        if (params.subscription.initialDate)
          setInitialSubscriptionDate(
            moment(params.subscription.initialDate).toDate()
          );
        if (params.subscription.finalDate)
          setFinalSubscriptionDate(
            moment(params.subscription.finalDate).toDate()
          );
      }
    } else {
      showInformation(
        "Ocorreu um erro ao editar o cliente!",
        undefined,
        undefined,
        () => {
          hideInformation();
          params.onClose();
        }
      );
    }
  };

  const validation = () => {
    let isValid: boolean = true;
    if (name === "" || !(/^[a-zA-Z0-9]+(?:[\s-][a-zA-Z0-9]+)*$/i.test(name))) {
      isValid = false;
      setNameError("*Campo incorreto");
    }
    if (cityName === "" || !(/^[\p{L}]+(?:[\s-][\p{L}]+)*$/u.test(cityName))) {
      isValid = false;
      setCityNameError("*Campo incorreto");
    }
    if (country === "") {
      isValid = false;
      setCountryError("*Campo incorreto");
    }
    if (street === "" || !(/^[\p{L}]+(?:[\s-][\p{L}]+)*$/u.test(street))) {
      isValid = false;
      setStreetError("*Campo incorreto");
    }
    if (postalCode === "") {
      isValid = false;
      setPostalCodeError("*Campo incorreto");
    }
    if (number === "" || !(/^([1-9][0-9]*)$/i.test(number))) {
      isValid = false;
      setNumberError("*Campo incorreto");
    }

    if (district === "" || !(/^[\p{L}]+(?:[\s-][\p{L}]+)*$/u.test(district))) {
      isValid = false;
      setDistrictError("*Campo incorreto");
    }
    return isValid;
  };

  const save = async () => {
    try {
      if (!validation()) return;
      setLoading(true);
      let payload: IClient = {
        name,
        email,
        cpfCnpj: cpfCnpj.replace(/[^\d]/g, ''),
        avatarUri: undefined,
        country: country == "BR" ? "BRA" : country,
        postalCode: postalCode.replace(/[^\d]/g, ''),
        street,
        number,
        additionalInformation,
        district,
        state,
        cityCode,
        cityName,
        canChangeSubscription
      };


      let informationMessage: string = "Cliente cadastrado com sucesso!";
      if (edit && params.id) {
        await clientHook.editEntity(params.id, payload);
        if (
          params.subscription &&
          params.subscription.id &&
          initialSubscriptionDate &&
          finalSubscriptionDate
        ) {
          const subscriptionPayload: SubscriptionProps = {
            clientId: params.id,
            subscriptionPlanId: subscriptionPlan.id,
            initialDate: initialSubscriptionDate,
            finalDate: finalSubscriptionDate,
            paid: false,
            status: 1,
          };
          subscriptionHook.editEntity(
            params.subscription.id,
            subscriptionPayload
          );
        }
        informationMessage = "Cliente alterado com sucesso!";
      } else {
        const auxClient: IClient = await clientHook.createNewEntity(
          payload,
          true
        );
        if (auxClient.id && initialSubscriptionDate && finalSubscriptionDate) {
          const subscriptionPayload: SubscriptionProps = {
            clientId: auxClient.id,
            subscriptionPlanId: subscriptionPlan.id,
            initialDate: initialSubscriptionDate,
            finalDate: finalSubscriptionDate,
            paid: false,
            status: 1,
          };
          subscriptionHook.createNewEntity(subscriptionPayload, true);
        }
      }
      showInformation(informationMessage, undefined, "Continuar", () => {
        cleanData();
        hideInformation();
        params.onClose();
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const cleanData = () => {
    setName("");
    setNameError(undefined);
    setCpfCnpj("");
    setSubscriptionType(undefined);
    setSubscriptionPlan(undefined);
    setSubscriptionPlanName("");
    setSubscriptionPlanError(undefined);
    setInitialSubscriptionDate(null);
    setInitialSubscriptionDateError(undefined);
    setFinalSubscriptionDate(null);
    setFinalSubscriptionDateError(undefined);
    setCountry("")
    setPostalCode("")
    setStreet("")
    setNumber("")
    setAdditionalInformation("")
    setDistrict("")
    setState("")
    setCityCode("")
    setCityName("")
  };

  return (
    <Container fluid veryPadded width={mdDown ? "90%" : "400px"} height="800px" color={theme.palette.backgroundElements.main} spacedBetween>
      <Container flex fluid veryPadded>
        {!edit && (
          <Text medium size={24}>
            Cadastrar cliente
          </Text>
        )}
        {edit && !addingRequiredFields && (
          <Text medium size={24}>
            Editar cliente
          </Text>
        )}
        {edit && addingRequiredFields && (
          <Text medium size={24}>
            Preencher campos obrigatórios
          </Text>
        )}
        {
          addingRequiredFields ? (
            <Container>
              <Spacing top={VERY_PADDING} />
              <Text>Está faltando dados obrigatórios em sua empresa. Por favor preencha para continuar.</Text>
              <Spacing top={VERY_PADDING} />
            </Container>) :
            <Spacing top={VERY_PADDING} />
        }
        <form onSubmit={handleSubmit(save)} style={{ display: "flex", flex: 1, height: "auto" }}>
          <Container flex fluid spacedAround>
            <Grid container padding={0} rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    autoFocus
                    id="client-name"
                    placeholder="Nome da empresa"
                    size="small"
                    value={name}
                    disabled={loading}
                    startAdornment={<InputAdornmentUser color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("name", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setName(event.target.value),
                      })
                    }}
                  />
                  {nameError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {nameError}
                    </Text>
                  )}
                </Container>
              </Grid>
              {country == "BR" &&
                <Grid item xs={12}>
                  <Container fluid width="100%">
                    <Input
                      id="client-cpfCnpj"
                      placeholder="Cnpj da empresa"
                      size="small"
                      value={cpfCnpj}
                      disabled={loading}
                      backgroundColor={theme.palette.backgroundElements.light}
                      startAdornment={<InputAdornmentUser color={theme.palette.backgroundStrokeActive.dark} />}
                      sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                      register={{
                        ...register("cpfCnpj", {
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setCpfCnpj(formatCnpj(event.target.value)),
                        })
                      }}
                    />
                    {cpfCnpjError && (
                      <Text
                        size={13}
                        color={theme.palette.error.main}
                        style={{ marginBottom: 3 }}
                      >
                        {cpfCnpjError}
                      </Text>
                    )}
                  </Container>
                </Grid>
              }
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    id="client-email"
                    placeholder="E-mail da empresa"
                    size="small"
                    value={email}
                    disabled={loading}
                    backgroundColor={theme.palette.backgroundElements.light}
                    startAdornment={<InputAdornmentUser color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("email", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setEmail(event.target.value),
                        pattern: {
                          value:
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: `Favor informar um e-mail válido`,
                        },
                      })
                    }}
                  />
                  {emailError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {emailError}
                    </Text>
                  )}
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12}>
                <Container fluid>
                  <FormControl variant="outlined">
                    <InputLabel>País</InputLabel>
                    <Select
                      size="small"
                      id='country-select'
                      value={country}
                      label="País"
                      onChange={(event) => {
                        setCountry(event.target.value);
                      }
                      }
                      sx={{ width: '100%', maxHeight: '40px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: 0 }, borderRadius: 0, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    >
                      {countriesData.map((_country) => {
                        return <MenuItem value={_country.sigla}>{_country.nome_pais}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                  {countryError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {countryError}
                    </Text>
                  )}
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    id="client-postalCode"
                    placeholder="CEP, zip code"
                    size="small"
                    value={postalCode}
                    disabled={loading}
                    backgroundColor={theme.palette.backgroundElements.light}
                    startAdornment={<InputAdornmentAddress color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("postalCode", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (country == "BR")
                            setPostalCode(formatPostalCode(event.target.value));
                          else
                            setPostalCode(event.target.value);
                        },
                      })
                    }}
                  />
                  {postalCodeError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {postalCodeError}
                    </Text>
                  )}
                </Container>
              </Grid>
              {country == "BR" &&
                <Grid item xs={12}>
                  <Container fluid>
                    <FormControl variant="outlined">
                      <InputLabel>Uf</InputLabel>
                      <Select
                        size="small"
                        id='uf-select'
                        value={state}
                        label="uf"
                        onChange={(event) => setState(event.target.value)}
                        sx={{ width: '100%', maxHeight: '40px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: 0 }, borderRadius: 0, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                      >
                        {States.map((_state) => {
                          return <MenuItem value={_state.Uf}>{_state.Nome}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    {stateError && (
                      <Text
                        size={13}
                        color={theme.palette.error.main}
                        style={{ marginBottom: 3 }}
                      >
                        {stateError}
                      </Text>
                    )}
                  </Container>
                </Grid>
              }
              {country == "BR" ?
                <Grid item xs={12}>
                  <Container fluid>
                    <FormControl variant="outlined">
                      <InputLabel>Cidade</InputLabel>
                      <Select
                        size="small"
                        id='city-select'
                        value={cityName}
                        label="Cidade"
                        onChange={(event) => {
                          setCityName(event.target.value);
                          for (let i = 0; i < filteredCitiesByUf.length; i++) {
                            const _city = filteredCitiesByUf[i];
                            if (_city.Nome == event.target.value) {
                              setCityCode(_city.Codigo.toString());
                            }
                          }
                        }
                        }
                        sx={{ width: '100%', maxHeight: '40px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: 0 }, borderRadius: 0, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                      >
                        {filteredCitiesByUf.map((_city) => {
                          return <MenuItem value={_city.Nome}>{_city.Nome}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    {cityNameError && (
                      <Text
                        size={13}
                        color={theme.palette.error.main}
                        style={{ marginBottom: 3 }}
                      >
                        {cityNameError}
                      </Text>
                    )}
                  </Container>
                </Grid>
                :
                <Grid item xs={12}>
                  <Container fluid width="100%">
                    <Input
                      id="client-city"
                      placeholder="Cidade da empresa"
                      size="small"
                      value={cityName}
                      disabled={loading}
                      backgroundColor={theme.palette.backgroundElements.light}
                      startAdornment={<InputAdornmentAddress color={theme.palette.backgroundStrokeActive.dark} />}
                      sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                      register={{
                        ...register("cityName", {
                          onChange: (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setCityName(event.target.value)
                        })
                      }}
                    />
                    {cityNameError && (
                      <Text
                        size={13}
                        color={theme.palette.error.main}
                        style={{ marginBottom: 3 }}
                      >
                        {cityNameError}
                      </Text>
                    )}
                  </Container>
                </Grid>
              }
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    id="client-district"
                    placeholder="Bairro, Distrito, etc."
                    size="small"
                    value={district}
                    disabled={loading}
                    backgroundColor={theme.palette.backgroundElements.light}
                    startAdornment={<InputAdornmentAddress color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("district", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setDistrict(event.target.value),
                      })
                    }}
                  />
                  {districtError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {districtError}
                    </Text>
                  )}
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    id="client-street"
                    placeholder="Logradouro"
                    size="small"
                    value={street}
                    disabled={loading}
                    backgroundColor={theme.palette.backgroundElements.light}
                    startAdornment={<InputAdornmentAddress color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("street", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setStreet(event.target.value),
                      })
                    }}
                  />
                  {streetError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {streetError}
                    </Text>
                  )}
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    id="client-number"
                    placeholder="Número"
                    size="small"
                    value={number}
                    disabled={loading}
                    backgroundColor={theme.palette.backgroundElements.light}
                    startAdornment={<InputAdornmentAddress color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("number", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setNumber(event.target.value),
                      })
                    }}
                  />
                  {numberError && (
                    <Text
                      size={13}
                      color={theme.palette.error.main}
                      style={{ marginBottom: 3 }}
                    >
                      {numberError}
                    </Text>
                  )}
                </Container>
              </Grid>
              <Grid item xs={12}>
                <Container fluid width="100%">
                  <Input
                    id="client-additionalInformation"
                    placeholder="Complemento"
                    size="small"
                    value={additionalInformation}
                    disabled={loading}
                    backgroundColor={theme.palette.backgroundElements.light}
                    startAdornment={<InputAdornmentAddress color={theme.palette.backgroundStrokeActive.dark} />}
                    sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                    register={{
                      ...register("additionalInformation", {
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setAdditionalInformation(event.target.value)
                      })
                    }}
                  />
                </Container>
              </Grid>
              {authHooks.isSuperAdmin() &&
                <Grid item xs={12}>
                  <Text subtitle>Permissão para alterar assinatura</Text>
                  <Switch checked={canChangeSubscription} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCanChangeSubscription(event.target.checked)} />
                </Grid>
              }
            </Grid>
            <Grid container padding={0} rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12} md={6} display="flex" justifyContent="flex-start">
                <Button sx={{ minWidth: 120 }} type="submit" loading={loading} disabled={loading}>
                  <Text color={theme.palette.background.default}>
                    {edit ? "Salvar" : "Cadastrar cliente"}
                  </Text>
                </Button>
              </Grid>
              <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
                {!addingRequiredFields &&
                  <Button
                    onClick={() => params.onClose()}
                    color={theme.palette.backgroundStrokeActive.dark}
                    loading={loading}
                    disabled={loading}
                    sx={{ minWidth: 120 }}
                  >
                    <Text color={theme.palette.background.default}>
                      {edit ? "Cancelar" : "Cancelar cadastro"}
                    </Text>
                  </Button>
                }
              </Grid>
            </Grid>
          </Container>
        </form>
      </Container>

    </Container>
  );
};

export default ClientsRegister;
