import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
    DeleteOutlineOutlined as DeleteIcon,
    EditOutlined as EditIcon
} from '@mui/icons-material';

import { useSubscriptionsPlans } from "src/hooks/subscriptionsPlans";
import { useAuth } from "src/hooks/auth";
import { useFeedback } from "src/hooks/feedback";
import { ISubscriptionsPlansActionsCell } from "src/types/components/molecules/v2/subscriptionsPlans";
import { ISubscriptionPlan, isSubscriptionPlan } from "src/types/hooks/subscriptionsPlans";

/**
 * This component returns the documents customs models action cell
 * Can be used in documents data grid
 * It uses mui styles
 * @component
 */
const SubscriptionsPlansActionsCell: FC<ISubscriptionsPlansActionsCell> = (props: ISubscriptionsPlansActionsCell) => {
    // Auth hooks to get the user rights
    const auth = useAuth();
    // Document hooks for service operations
    const subscriptionsPlans = useSubscriptionsPlans();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // Document id (the const never will be number, we treat id as UUID),
    // but the x-data-grid treats that way
    const id: string | number | undefined = props.cellParams?.id ?? undefined;

    /**
     * Function that edit the row
     * @function
     */
    const edit = () => {
        if (props.cellParams && isSubscriptionPlan(props.cellParams.row))
            props.onEdit(props.cellParams.row)
    }

    /**
     * Function that delete the row
     * @function
     */
    const deleteSubscriptionPlan = () => {
        if (!id) return null;

        feedback.open(true, "info", "Informação", undefined, "Deseja realmente excluir esse produto?", undefined, undefined,
            ["Cancelar", "Excluir"], ["text", "contained"], [() => {
                feedback.close();
            }, async () => {
                if (id && typeof id === "string") {
                    const auxDocument: ISubscriptionPlan = await subscriptionsPlans.deleteEntity(id);
                    subscriptionsPlans.fetchEntities();
                    if (auxDocument) {
                        feedback.close();
                        feedback.open(true, "success", "Sucesso", undefined, "Produto excluído com sucesso.");
                        return;
                    }
                }
            }]);
    }

    if (!id) return null;
    return (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
                {auth.hasRights?.("manage-subscriptions_plans") &&
                    <IconButton color="info" onClick={() => edit()}>
                        <EditIcon />
                    </IconButton>
                }
                {auth.hasRights?.("manage-subscriptions_plans") &&
                    <IconButton color="error" onClick={() => deleteSubscriptionPlan()}>
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
        </Box >
    );
}

export default SubscriptionsPlansActionsCell;
