import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import {
  AssignmentInd as ClientsIcon,
  AttachMoneyOutlined as InvoicesIcon,
  ArticleOutlined as DocumentsIcon,
  CategoryOutlined as ProductsIcon,
  CodeOutlined as ApiKeysIcon,
  ExitToAppOutlined as LogoutIcon,
  Description as DocumentModelsIcon,
  DocumentScannerOutlined as DocumentsUploadIcon,
  HelpOutlineOutlined as HelpIcon,
  HomeOutlined as HomeIcon,
  NoteAddOutlined as DocumentsCustomsModelsIcon,
  LeaderboardOutlined as DashboardIcon,
  LoyaltyOutlined as SubscriptionPlanIcon,
  PeopleAltOutlined as UsersIcon,
  PermIdentityOutlined as AccountIcon,
  SettingsOutlined as SettingsIcon,
  WidgetsOutlined as SubscriptionIcon,
  WorkspacesOutlined as WorkspaceIcon,
} from "@mui/icons-material";

import { DrawerBox } from "./styles";
import logo from "src/assets/logo.png";
import { theme } from "src/components";
import DrawerButton from "src/components/atoms/v2/drawer/button";
import { IDrawer } from "src/types/components/molecules/v2/drawer";
import { useAuth } from "src/hooks/auth";
import { useDrawer } from "src/hooks/drawer";
import { useResponsive } from "src/hooks/responsive";
import { IDrawerButtonMenuItem } from "src/types/components/atoms/v2/drawer/button";

/**
 * This component returns the drawer of the app
 * It uses mui styles
 * @component
 */
const Drawer: FC<IDrawer> = (props: IDrawer) => {
  // Auth hooks that contains current user data
  const auth = useAuth();
  // Drawer hooks that contain current informations
  const drawer = useDrawer();
  // Navigate hooks to change routes
  const navigate = useNavigate();
  // Responsive hooks for app layout
  const responsive = useResponsive();

  // State for current drawer width
  const [drawerWidth, setDrawerWidth] = useState<string>(defaultDrawerWidth);

  /**
   * Effect to get responsive changes
   * @effect
   */
  useEffect(() => {
    if (responsive.mdDown) setDrawerWidth("300px");
    else if (responsive.mdUp) setDrawerWidth(defaultDrawerWidth);
  }, [responsive.mdUp, responsive.mdDown]);

  /**
   * Function to add settings menu items
   * @function
   */
  const fillUpSettingsItems = () => {
    const items: IDrawerButtonMenuItem[] = [];
    items.push({ icon: UsersIcon, text: "Gerenciar usuários", path: "/users" });
    if (auth.isSuperAdmin()) items.push({ icon: ClientsIcon, text: "Clientes", path: "/clients" });
    items.push({ icon: ApiKeysIcon, text: "Chaves API", path: "/api-keys" });
    // items.push({ icon: SubscriptionIcon, text: "Gerenciar plano", path: "/subscription" });
    // items.push({ icon: InvoicesIcon, text: "Pagamentos", path: "/invoices" });
    if (auth.isSuperAdmin()) items.push({ icon: DocumentModelsIcon, text: "Modelos padrões", path: "/document-models" });
    if (auth.isSuperAdmin()) items.push({ icon: ProductsIcon, text: "Produtos", path: "/products" });
    if (auth.isSuperAdmin()) items.push({ icon: SubscriptionPlanIcon, text: "Tipos de planos", path: "/subscriptions-plans" });
    return items;
  };

  return (
    <Box display="flex" flex={1} flexGrow={1} width="99.9%" maxWidth="99.9%" minHeight="100vh" color={theme.palette.background.paper}>
      <Box
        display="flex"
        flexDirection="column"
        minWidth={defaultDrawerWidth}
        maxWidth={defaultDrawerWidth}
        minHeight="99.9vh"
        padding="0px"
        margin="0px"
        position="static"
      >
        <Box
          display="flex"
          flexDirection="column"
          minWidth={defaultDrawerBoxWidth}
          maxWidth={defaultDrawerBoxWidth}
          minHeight="95.7vh"
          padding="16px"
          paddingLeft="24px"
          paddingRight="16px"
          position="fixed"
        >
          {responsive.mdUp && (
            <Box
              display="flex"
              position="absolute"
              left={0}
              top={0}
              width="8px"
              height="100vh"
              maxHeight="100vh"
              sx={{
                background: `linear-gradient(0deg, #2FB1B9 0%, #2C58A0 100%);`,
              }}
            />
          )}

          <img src={logo} style={{ cursor: "pointer", width: 73.39, height: 73.39 / 2.48 }} onClick={() => navigate("/")} />

          <Box display="flex" flex={1} flexGrow={1} paddingTop="24px" alignItems="flex-start" width={defaultDrawerBoxWidth}>
            <Grid container rowSpacing="6px" width={defaultDrawerBoxWidth}>
              <Grid item xs={12}>
                <DrawerButton text="Home" icon={HomeIcon} path="/" />
              </Grid>
              <Grid item xs={12}>
                <DrawerButton text="Ler documento" icon={DocumentsUploadIcon} path="/documents/upload" />
              </Grid>
              <Grid item xs={12}>
                <DrawerButton text="Meus documentos" icon={DocumentsIcon} path="/documents" />
              </Grid>
              <Grid item xs={12}>
                <DrawerButton text="Modelos" icon={DocumentsCustomsModelsIcon} path="/documents-customs-models" />
              </Grid>
            </Grid>
          </Box>

          <Grid container display="flex" rowSpacing="6px">
            <Grid item xs={12}>
              <DrawerButton text="Configurações" icon={SettingsIcon} listItems={fillUpSettingsItems()} />
            </Grid>
            <Grid item xs={12}>
              <DrawerButton
                text={auth.user?.fullName ?? "Home"}
                icon={AccountIcon}
                avatar={auth.user?.avatar ? <Avatar src={auth.user?.avatar} sx={{ width: "24px", height: "24px" }} /> : undefined}
                listItems={[
                  {
                    icon: AccountIcon,
                    text: "Meus dados",
                    path: "/account",
                  },
                  {
                    icon: HelpIcon,
                    text: "Ajuda",
                    path: "/help",
                  },
                  {
                    icon: LogoutIcon,
                    text: "Sair",
                    path: "/logout",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {responsive.mdUp && (
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          flex={1}
          flexGrow={1}
          paddingBottom="16px"
          minWidth="75%"
          minHeight="100vh"
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <>
            {props.children}

            <Box position="absolute" left={0} bottom={0} width="100%" sx={{ backgroundColor: theme.palette.background.default }}>
              <Box display="flex" flexDirection="row" justifyContent="center" width="100%" paddingBottom="20px">
                <Typography fontSize="12px" fontWeight={400} color="#9E9E9E">
                  Powered by
                </Typography>
                <Typography fontSize="12px" fontWeight={700} color="#9E9E9E" paddingLeft="4px">
                  Pix Force
                </Typography>
              </Box>
            </Box>
          </>
        </Box>
      )}
    </Box>
  );
};

// Default width for drawer
const defaultDrawerWidth: string = "238px";
// Default width for drawer box
const defaultDrawerBoxWidth: string = "196px";

export default Drawer;
