import React, { FC } from "react";
import { Modal } from "@pix-force/react-atoms";

import SubscriptionsRegister from ".";
import { ISubscriptionsRegisterModal } from "src/types";

const SubscriptionsRegisterModal: FC<ISubscriptionsRegisterModal> = (params: ISubscriptionsRegisterModal) => {
    return (
        <Modal isOpened={params.isOpen} onClose={() => params.onClose()}>
            <SubscriptionsRegister id={params.id} clientId={params.clientId} subscription={params.subscription} onClose={() => params.onClose()} />
        </Modal>
    )
}

export default SubscriptionsRegisterModal;
