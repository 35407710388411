import { FC, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, IconButton, Switch, TextField, Typography } from "@mui/material";

import RegisterTemplate from "src/components/templates/registerTemplate";
import { useAuth } from "src/hooks/auth";
import { useSubscriptionsPlans } from "src/hooks/subscriptionsPlans";
import { useFeedback } from "src/hooks/feedback";
import { ISubscriptionsPlansRegister } from "src/types/components/molecules/v2/subscriptionsPlans";
import { IProduct, ISelectValue, ISubscriptionPlanPrice, SubscriptionPlanPaymentSettingProps, SubscriptionPlanProps } from "src/types";
import { theme } from "src/components";
import { useProducts } from "src/hooks/products";
import { Container, Select, Spacing } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import { Delete } from "@mui/icons-material";

const SubscriptionsPlansRegister: FC<ISubscriptionsPlansRegister> = (props: ISubscriptionsPlansRegister) => {
    // Const about edit mode
    const edit: boolean = props.payload !== undefined;

    // Auth hooks to get client information
    const auth = useAuth();
    // Products hooks to get categories information
    const products = useProducts();
    // Subscriptions plans hooks to create or update data
    const subscriptionsPlans = useSubscriptionsPlans();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // State for loading control
    const [loading, setLoading] = useState<boolean>(false);
    const [hasSubscription, setHasSubscription] = useState<boolean>(false);
    const [hasPaymentApi, setHasPaymentApi] = useState<boolean>(false);
    const [product, setProduct] = useState<any>(undefined);
    const [productName, setProductName] = useState<string>("");
    const [productError, setProductError] = useState<string | undefined>(undefined);
    const [name, setName] = useState<string>("");
    const [nameError, setNameError] = useState<string | undefined>("");
    const [description, setDescription] = useState<string>("");
    const [isTrial, setIsTrial] = useState<boolean>(false);
    const [monthlyRequests, setMonthlyRequests] = useState<number>(0);
    const [monthlyRequestsError, setMonthlyRequestsError] = useState<string | undefined>(undefined);
    const [availableToPublic, setAvailableToPublic] = useState<boolean>(false);
    const [priceModel, setPriceModel] = useState<string | number>("");
    const [priceModelError, setPriceModelError] = useState<string | undefined>(undefined);
    const [recurringInterval, setRecurringInterval] = useState<string | number>(0);
    const [recurringIntervalCount, setRecurringIntervalCount] = useState<number | undefined>(1);
    const [recurringIntervalCountError, setRecurringIntervalCountError] = useState<string | undefined>(undefined);
    const [recurringUsageType, setRecurringUsageType] = useState<string | number>("");
    const [type, setType] = useState<string | number>("");
    const [typeError, setTypeError] = useState<string | undefined>(undefined);
    const [tierMode, setTierMode] = useState<string | number>("");
    const [tierModeError, setTierModeError] = useState<string | undefined>(undefined);
    const [currency, setCurrency] = useState<string | number>("");
    const [currencyError, setCurrencyError] = useState<string | undefined>(undefined);
    const [unitAmount, setUnitAmount] = useState<number | undefined>(undefined);
    const [unitAmountError, setUnitAmountError] = useState<string | undefined>(undefined);
    const [useApi, setUseApi] = useState<boolean>(false);

    const [prices, setPrices] = useState<ISubscriptionPlanPrice[]>([]);
    const [pricesError, setPricesError] = useState<string | undefined>(undefined);
    const [minRequests, setMinRequests] = useState<number | undefined>(undefined);
    const [minRequestsError, setMinRequestsError] = useState<string | undefined>(undefined);
    const [maxRequests, setMaxRequests] = useState<number | undefined>(undefined);
    const [maxRequestsError, setMaxRequestsError] = useState<string | undefined>(undefined);
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [priceError, setPriceError] = useState<string | undefined>(undefined);

    /**
     * Reflects component init
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Handle the change payload in props
     * @effect
     */
    useEffect(() => {
        if (edit) loadEditFields();
    }, [props.payload]);


    /**
     * Function that fetch necessary data
     * @function
     */
    const fetchData = async () => {
        products.fetchEntities();
    };


    /**
     * Function that load edit fields
     * @function
     */
    const loadEditFields = async () => {
        setLoading(true);
        if (props.payload && props.payload.id) {
            setName(props.payload.name);
            if (props.payload.description) setDescription(props.payload.description);
            setIsTrial(props.payload.isTrial);
            setMonthlyRequests(props.payload.monthlyRequests);
            setAvailableToPublic(props.payload.availableToPublic || false);

            if (props.payload.product) {
                if (props.payload.product.usePaymentApi && props.payload.product.paymentApiId != "")
                    setHasPaymentApi(true);
                setProduct({
                    id: props.payload.product.id,
                    label: props.payload.product.name
                })
                setProductName(props.payload.product.name);
            }
            if (props.payload.useApi)
                setUseApi(props.payload.useApi);
            else
                setUseApi(false);

            if (props.payload.paymentSettings && props.payload.paymentSettings[0].billingScheme)
                setPriceModel(props.payload.paymentSettings && props.payload.paymentSettings[0].billingScheme == "per_unit" ? 0 : 1);
            if (props.payload.paymentSettings && props.payload.paymentSettings[0].recurringInterval)
                setRecurringInterval((props.payload.paymentSettings && props.payload.paymentSettings[0].recurringInterval == "month" ? 0 : props.payload.paymentSettings && props.payload.paymentSettings[0].recurringInterval == "year" ? 1 : props.payload.paymentSettings && props.payload.paymentSettings[0].recurringInterval == "month" ? 2 : 3));
            if (props.payload.paymentSettings && props.payload.paymentSettings[0].recurringIntervalCount)
                setRecurringIntervalCount(props.payload.paymentSettings && props.payload.paymentSettings[0].recurringIntervalCount);
            if (props.payload.paymentSettings && props.payload.paymentSettings[0].recurringUsageType)
                setRecurringUsageType(props.payload.paymentSettings && props.payload.paymentSettings[0].recurringUsageType == "metered" ? 0 : 1);
            if (props.payload.paymentSettings && props.payload.paymentSettings[0].type)
                setType(props.payload.paymentSettings && props.payload.paymentSettings[0].type == "one_time" ? 0 : 1);
            if (props.payload.paymentSettings && props.payload.paymentSettings[0].unitAmount)
                setUnitAmount(props.payload.paymentSettings && props.payload.paymentSettings[0].unitAmount);
            if (props.payload.paymentSettings && props.payload.paymentSettings[0].currency)
                setCurrency("brl" ? 0 : 1);
        } else {
            props.onCancel();
            setLoading(false);
            feedback.open(true, "error", "Erro", undefined, "Ocorreu um erro ao editar a plano.");

        }
        setLoading(false);
    };

    /**
     * Handle the change text of setText passed on args
     * @function
     */
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => {
        setText(event.target.value);
    }
    const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>, setNumber: Function) => {
        if (Number.isNaN(event.target.value)) setNumber(undefined);
        else setNumber(parseInt(event.target.value))
    };
    const handleChangeFloat = (event: React.ChangeEvent<HTMLInputElement>, setFloat: Function) => {
        if (Number.isNaN(event.target.value)) setFloat(undefined);
        else setFloat(parseFloat(event.target.value))
    }

    const validationPrices = () => {
        let isValid: boolean = true;

        if (minRequests === undefined) {
            if (minRequests != 0) {
                isValid = false;
                setMinRequestsError("Preenchimento obrigatório")
            }
        } else {
            const findPrices: ISubscriptionPlanPrice | undefined = prices.find(item => {
                if (item.maxRequests && parseInt((item as any).maxRequests) >= minRequests) {
                    return item;
                }
                return undefined;
            });
            if (findPrices) {
                isValid = false;
                feedback.open(true, "warning", "Atenção", undefined, "Valor mínimo de requisições deve ser maior do que os valores máximos de requisições já informados");
            }
        }
        if (isValid) {
            if (minRequests && maxRequests) {
                if (minRequests > maxRequests) {
                    isValid = false;
                    feedback.open(true, "warning", "Atenção", undefined, "Valor mínimo de requisições deve ser menor do que valor máximo de requisições");
                }
            }
        }
        return isValid;
    }

    const addPrice = () => {
        if (!validationPrices()) return;
        if (!minRequests && minRequests != 0) return;

        const subscriptionPlanPrice: ISubscriptionPlanPrice = {
            subscriptionPlanId: "",
            minRequests: minRequests,
            maxRequests: Number.isNaN(maxRequests) ? undefined : maxRequests,
            price: price ?? 0
        }
        const auxPrices = [];
        for (let i = 0; i < prices.length; i++) {
            let auxPrice: ISubscriptionPlanPrice = prices[i];
            if (i == prices.length - 1) {
                if (auxPrice.maxRequests == undefined)
                    auxPrice.maxRequests = minRequests - 1;
            }
            auxPrices.push(prices[i]);
        }
        auxPrices.push(subscriptionPlanPrice);
        setPrices(auxPrices);

        cleanPrices();
    }

    const removePrice = (_index: number) => {
        let auxPrices: ISubscriptionPlanPrice[] = prices;
        auxPrices.splice(_index, 1);
        setPrices([...auxPrices]);
    }

    const cleanPrices = () => {
        setMinRequests(undefined);
        setMinRequestsError(undefined);
        setMaxRequests(undefined);
        setMaxRequestsError(undefined);
        setPrice(0);
        setPriceError(undefined);
    }

    /**
     * Validation routine to check fields
     * @function
     */
    const validation = () => {
        let isValid: boolean = true;
        if (name == "") {
            setNameError("Favor informar o nome do plano");
            isValid = false;
        }
        if (isTrial) {
            if (monthlyRequests === undefined || monthlyRequests == 0) {
                isValid = false;
                setMonthlyRequestsError("Preenchimento obrigatório");
            }
        } else {
            if (product == undefined) {
                setProductError("Favor informar o produto");
                isValid = false;
            }
            if (typeof currency == "string" && currency.length === 0) {
                setCurrencyError("Favor informar uma moeda");
                isValid = false;
            }
            if (typeof priceModel == "string" && priceModel.length === 0) {
                setPriceModelError("Favor informar um modelo de preço")
                isValid = false;
            }
            if (priceModel == "0") {
                if (typeof type == "string" && type.length === 0) {
                    setTypeError("Favor informar um método de pagamento")
                    isValid = false;
                } else if (type == "1") {
                    if (recurringIntervalCount && recurringIntervalCount <= 0) {
                        setRecurringIntervalCountError("Favor informar um intervalo válido")
                        isValid = false;
                    }
                }
            } else
                if (prices.length == 0) {
                    isValid = false
                    setPricesError("Informe pelo menos um preço");
                }
        }

        if (isValid) {
            setMonthlyRequestsError(undefined);
            setCurrencyError(undefined);
            setPriceModelError(undefined);
            setUnitAmountError(undefined);
            setTypeError(undefined);
            setRecurringIntervalCountError(undefined);
            setPricesError(undefined);
        }

        return isValid;
    };

    /**
     * Sends request to save product in back-end
     * @function
     */
    const save = async () => {
        if (!auth.client || !auth.client.id) return;
        if (!auth.user || !auth.user.id) return;
        if (!validation()) return;

        try {
            setLoading(true);
            const tiers: any[] = [];
            if (priceModel != "0")
                for (let i = 0; i < prices.length; i++) {
                    const tier: any = {
                        up_to: prices[i].maxRequests == undefined ? "inf" : prices[i].maxRequests,
                        unit_amount_decimal: prices[i].price
                    }
                    tiers.push(tier);
                }

            let payload: SubscriptionPlanProps | undefined = undefined;
            if (isTrial) {
                payload = {
                    productId: product.id,
                    name: name,
                    description: description,
                    monthlyRequests: monthlyRequests,
                    isTrial: isTrial,
                    availableToPublic: availableToPublic,
                    useApi,
                    priority: 0,
                };
            } else {
                const paymentSettings: SubscriptionPlanPaymentSettingProps[] = [
                    {
                        billingScheme: priceModel == "0" ? "per_unit" : "tiered",
                        recurringInterval: type == "0" ? undefined : recurringInterval == "0" ? "month" : recurringInterval == "1" ? "year" : recurringInterval == "2" ? "month" : "day",
                        recurringIntervalCount: type == "0" ? undefined : recurringIntervalCount,
                        recurringUsageType: type == "0" ? undefined : "metered",
                        type: type == "0" ? "one_time" : "recurring",
                        unitAmount: priceModel == "0" ? unitAmount : undefined,
                        unitAmountDecimal: undefined,
                        tiersMode: priceModel == "0" ? undefined : "graduated",
                        tiers,
                        currency: currency == "0" ? "brl" : "usd",
                    }
                ];
                payload = {
                    name: name,
                    description: description,
                    monthlyRequests: monthlyRequests,
                    isTrial: isTrial,
                    availableToPublic: availableToPublic,
                    productId: product ? product.id : undefined,
                    useApi,
                    priority: 0,
                    paymentSettings: paymentSettings
                };
            }

            let id: string | undefined = undefined;
            let informationMessage: string = "Plano cadastrado com sucesso!";
            if (edit && props.id) {
                id = props.id;
                await subscriptionsPlans.editEntity(props.id, payload);
                informationMessage = "Plano alterado com sucesso!";
            } else {
                const auxSubscriptionPlan: SubscriptionPlanProps =
                    await subscriptionsPlans.createNewEntity(payload, false);
                id = auxSubscriptionPlan.id;
            }

            if (id) {
                cleanData();
                props.onCancel();
                feedback.open(true, "success", "Sucesso", undefined, informationMessage, undefined, false,
                    ["Continuar"], ["contained"], [() => {
                        subscriptionsPlans.fetchEntities();
                        feedback.close();
                    }]);
            }
            setLoading(false);
        } catch (err: any) {
            console.log(err);
            feedback.open(true, "error", "Erro", undefined, err.message as string);

            setLoading(false);
        }
    }

    /**
     * Cleans all data fields
     * @function
     */
    const cleanData = () => {
        setName("");
        setDescription("");
        setIsTrial(false);
        setMonthlyRequests(0);
        setMonthlyRequestsError(undefined);
        setAvailableToPublic(false);
        setPrices([]);
        setPricesError(undefined);
    };

    return (
        <RegisterTemplate
            title={edit ? "Editar plano" : "Novo plano"}
            subtitle="Os planos servem para definir como será a forma de pagamento, quando os mesmos utilizem a API de pagamento, e também para vincular o usuário logado aos seus privilégios dentro do Idexa. Somente super administradores da plataforma conseguem gerenciar."
            cancelClick={() => props.onCancel()}
            okClick={() => save()}>
            <Box display="flex" maxHeight="650px" >
                <Grid container display="flex" rowSpacing="24px" sx={{
                    maxHeight: "60vh",
                    overflowY: "auto"
                }}>
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        {hasSubscription &&
                            <Typography>
                                Este plano possui pelo menos uma assinatura, logo alguns campos estão desabilitados para edição.
                            </Typography>
                        }
                        {!hasSubscription && hasPaymentApi &&
                            <Typography>
                                Este plano possui vinculação com uma API de pagamento, logo alguns campos estão desabilitados para edição.
                            </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        <Typography>Versão trial</Typography>
                        <Switch checked={isTrial} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setIsTrial(event.target.checked)} />
                    </Grid>
                    {!isTrial && <Grid item xs={12}>
                        <Autocomplete
                            value={product}
                            onChange={(event: any, newValue: string | null) => {
                                if (newValue) {
                                    const auxProduct: IProduct | undefined = products.entities.find(item => item.name == newValue)
                                    if (auxProduct) setProduct(auxProduct);
                                }
                            }}
                            inputValue={productName}
                            onInputChange={(event: any, newValue: string | null) => {
                                if (event) {
                                    if (newValue) setProductName(newValue);
                                    else setProductName("");
                                }
                            }}
                            options={products.entities.map(item => item.name)}
                            placeholder="Informe o produto" disabled={loading}
                            renderInput={(params) => <TextField {...params} label="Produto"
                                error={productError !== undefined} helperText={productError} />}
                        />
                        {productError && (
                            <Typography color="error">
                                {productError}
                            </Typography>
                        )}
                    </Grid>}
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Nome"
                            placeholder="Nome do plano"
                            value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName)}
                            error={nameError !== ""} helperText={nameError} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Descrição"
                            placeholder="Descrição do plano"
                            value={description} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setDescription)}
                            helperText="Opcional" />
                    </Grid>
                    {isTrial &&
                        <Grid item xs={12}>
                            <TextField label="Requisições mensais" placeholder="Requisições mensais" size="medium" type="number" disabled={loading}
                                value={monthlyRequests}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeNumber(event, setMonthlyRequests)} />
                            {monthlyRequestsError && (
                                <Typography>
                                    {monthlyRequestsError}
                                </Typography>
                            )}
                        </Grid>}
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        <Typography>Disponível para o público</Typography>
                        <Switch checked={availableToPublic} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAvailableToPublic(event.target.checked)} />
                    </Grid>
                    <Grid item xs={12} display="flex" flexDirection="row" alignItems="center">
                        <Typography>Utiliza API</Typography>
                        <Switch checked={useApi} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUseApi(event.target.checked)} />
                    </Grid>
                    {!isTrial && <Grid item xs={12}>
                        <Select
                            placeholder="Selecione uma moeda"
                            value={currency as any}
                            setValue={setCurrency}
                            items={subscriptionsPlans.currencies.map((item: ISelectValue) => ({
                                label: item.label,
                                value: parseInt(item.id)
                            }))}
                            sx={{ width: "100%", borderRadius: "6px", padding: "7px" }}
                            disabled={loading ?? hasSubscription ?? hasPaymentApi} />
                        {currencyError && (
                            <Typography>
                                {currencyError}
                            </Typography>
                        )}
                    </Grid>
                    }
                    {!isTrial && <Grid item xs={12}>
                        <Select
                            placeholder="Selecione um modelo de preço"
                            value={priceModel as any}
                            setValue={setPriceModel}
                            items={subscriptionsPlans.priceModels.map((item: ISelectValue) => ({
                                label: item.label,
                                value: parseInt(item.id)
                            }))}
                            sx={{ width: "100%", borderRadius: "6px", padding: "7px" }}
                            disabled={loading ?? hasSubscription ?? hasPaymentApi} />
                        {priceModelError && (
                            <Typography>
                                {priceModelError}
                            </Typography>
                        )}
                    </Grid>}
                    {!isTrial && priceModel == "0" &&
                        <Grid item xs={12}>
                            <TextField placeholder="Preço" size="medium" type="number" fullWidth
                                value={unitAmount}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeFloat(event, setUnitAmount)}
                                error={unitAmountError != undefined} disabled={loading ?? hasSubscription ?? hasPaymentApi}
                            />
                            {unitAmountError &&
                                <Typography>
                                    {unitAmountError}
                                </Typography>
                            }
                        </Grid>
                    }
                    {!isTrial && priceModel == "0" && <Grid item xs={12}>
                        <Select
                            placeholder="Selecione o método de pagamento"
                            value={type as any}
                            setValue={setType}
                            items={subscriptionsPlans.types.map((item: ISelectValue) => ({
                                label: item.label,
                                value: parseInt(item.id)
                            }))}
                            sx={{ width: "100%", borderRadius: "6px", padding: "7px" }}
                            disabled={loading ?? hasSubscription ?? hasPaymentApi} />
                        {typeError && (
                            <Typography>
                                {typeError}
                            </Typography>
                        )}
                    </Grid>}
                    {!isTrial && type != "" && type != "0" &&
                        <>
                            <Grid item xs={2} display="flex" alignItems="center">
                                <Typography>A cada</Typography>
                            </Grid>
                            <Grid item xs={4} display="flex" justifyContent="center">
                                <TextField placeholder="Intervalo do período" size="medium" type="number" fullWidth
                                    value={recurringIntervalCount}
                                    error={recurringIntervalCountError != undefined} disabled={loading ?? hasSubscription ?? hasPaymentApi}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeNumber(event, setRecurringIntervalCount)}
                                />
                                {recurringIntervalCountError && (
                                    <Typography>
                                        {(recurringIntervalCountError as any).message}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6} paddingLeft="16px">
                                <Select
                                    placeholder="Período de recorrência"
                                    value={recurringInterval as any}
                                    setValue={setRecurringInterval}
                                    items={subscriptionsPlans.recurringIntervals.map((item: ISelectValue) => ({
                                        label: item.label,
                                        value: parseInt(item.id)
                                    }))}
                                    disabled={loading ?? hasSubscription}
                                    sx={{ width: "100%", borderRadius: "6px", padding: "7px" }} />
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        {isTrial && <Typography>Preços não disponíveis em versões Trial</Typography>}
                        {!isTrial && priceModel != "" && priceModel != "0" &&
                            <Container fluid>
                                <Typography>Preços</Typography>
                                <Spacing top={PADDING} />
                                <Grid container columnSpacing={2} rowSpacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <TextField placeholder="Min Req." size="medium" type="number"
                                            value={minRequests}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeNumber(event, setMinRequests)}
                                            error={minRequestsError != undefined} disabled={loading ?? hasSubscription ?? hasPaymentApi}
                                        />
                                        {minRequestsError &&
                                            <Typography>
                                                {minRequestsError}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField placeholder="Max Req." size="medium" type="number"
                                            value={maxRequests}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeNumber(event, setMaxRequests)}
                                            error={maxRequestsError != undefined} disabled={loading ?? hasSubscription ?? hasPaymentApi}
                                        />
                                        {maxRequestsError &&
                                            <Typography>
                                                {maxRequestsError}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField placeholder="Preço" size="medium" type="number"
                                            value={price}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeFloat(event, setPrice)}
                                            error={priceError != undefined} disabled={loading ?? hasSubscription ?? hasPaymentApi}
                                        />
                                        {priceError &&
                                            <Typography>
                                                {priceError}
                                            </Typography>
                                        }
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
                                        <Button variant="outlined" size="small"
                                            onClick={() => addPrice()}
                                            disabled={loading ?? hasSubscription ?? hasPaymentApi}>
                                            <Typography color={theme.palette.success.main}>Adicionar</Typography>
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} display="flex" flexDirection="column" justifyContent="center">
                                        <Container fluid centered>
                                            {prices.length == 0 && <Typography>Nenhum preço cadastrado</Typography>}
                                            {pricesError && (
                                                <Typography>
                                                    {pricesError}
                                                </Typography>
                                            )}
                                        </Container>
                                        {prices.length > 0 &&
                                            <Container fluid flex hasShadow padded>
                                                <Grid container rowSpacing={2}>
                                                    <>
                                                        <Grid item xs={3}><Typography>Min. Req.</Typography></Grid>
                                                        <Grid item xs={3}><Typography>Max. Req.</Typography></Grid>
                                                        <Grid item xs={3}><Typography>Preço</Typography></Grid>
                                                        <Grid item xs={3}></Grid>
                                                    </>
                                                </Grid>
                                                <Container fluid flex width="100%" maxHeight="80px" sx={{ overflowY: "auto" }}>
                                                    <Grid container>
                                                        {prices.map((item: ISubscriptionPlanPrice, index: number) => {
                                                            return (
                                                                <>
                                                                    <Grid item xs={3}><Typography>{item.minRequests}</Typography></Grid>
                                                                    <Grid item xs={3}><Typography>{item.maxRequests}</Typography></Grid>
                                                                    <Grid item xs={3}><Typography>{`R$ ${item.price.toFixed(2).replace(".", ",")}`}</Typography></Grid>
                                                                    <Grid item xs={3}>
                                                                        {index == prices.length - 1 &&
                                                                            <IconButton size="small" color="error" onClick={() => removePrice(index)}
                                                                                disabled={loading ?? hasSubscription ?? hasPaymentApi}>
                                                                                <Delete fontSize="small" />
                                                                            </IconButton>}
                                                                    </Grid>
                                                                </>
                                                            )
                                                        })}
                                                    </Grid>
                                                </Container>
                                            </Container>
                                        }
                                    </Grid>
                                </Grid>
                            </Container>
                        }
                    </Grid>
                </Grid>
            </Box>
        </RegisterTemplate>
    )
}

export default SubscriptionsPlansRegister;