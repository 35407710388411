import React, { FC, useEffect, useState } from "react";
import { Container, Modal } from "@pix-force/react-atoms";

import UsersRegister from ".";
import { IUsersRegisterModal } from "src/types";

const UsersRegisterModal: FC<IUsersRegisterModal> = (params: IUsersRegisterModal) => {
    return (
        <Modal isOpened={params.isOpen} onClose={() => params.onClose()}>
            <UsersRegister id={params.id} clientId={params.clientId} user={params.user} onClose={() => params.onClose()} />
        </Modal>
    )
}

export default UsersRegisterModal;
