import { IEntityContext } from "./entities";
import { ApiKeyProps } from "./financesApi/apiKeys";

interface IApiKeyContext extends IEntityContext<ApiKeyProps> {
  getByClientId: (clientId: string) => Promise<ApiKeyProps[]>;
}

export function isApiKey(_data: any): _data is ApiKeyProps {
  return "name" in _data;
}

export type { IApiKeyContext };
