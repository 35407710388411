import { AxiosResponse } from "axios";
import api, { API_FINANCES_URL } from "src/services";
import CRUDBaseService from "src/services/base";
import { ApiKeyProps } from "src/types";
import { parseStringToDate } from "src/utils/dates";

export default class ApiKeyService extends CRUDBaseService<ApiKeyProps> {
  constructor() {
    super(api, `${API_FINANCES_URL}/api_keys`);
  }

  async getByClientId(clientId: string): Promise<ApiKeyProps[]> {
    try {
      const response: AxiosResponse<ApiKeyProps[]> = await this.api.get(`${this.baseURL}/clients/${clientId}`);
      const apiKeys: ApiKeyProps[] = [];
      for (let i = 0; i < response.data.length; i++) {
        apiKeys.push(this.convertApiResponseToIEntity(response.data[i]));
      }
      return apiKeys;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getDefaultByClientId(clientId: string): Promise<ApiKeyProps> {
    try {
      const response: AxiosResponse<ApiKeyProps> = await this.api.get(`${this.baseURL}/clients/${clientId}/default`);
      return this.convertApiResponseToIEntity(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  override convertApiResponseToIEntity(_data: any): ApiKeyProps {
    return {
      id: _data.id,
      clientId: _data.clientId,
      name: _data.name,
      value: _data.value,
      expiresAt: _data.expiresAt,

      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
