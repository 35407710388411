import { FC, useState } from "react";
import { Menu, Button, Box } from "@mui/material";
import { DateRange } from "react-day-picker";
import "react-day-picker/dist/style.css";

import DateRangePicker from "../dateRangePicker";
import { DateRangePickerMenuProps } from "src/types";

/**
 * This component return the datepicker component plus buttons to confirm range selected and cancel button
 * @component
 */
const DateRangePickerMenu: FC<DateRangePickerMenuProps> = (
  props: DateRangePickerMenuProps
) => {
  //State for range of dates
  const [range, setRange] = useState<DateRange | undefined>(props.selected);

  /**
   * Function to handle ok button
   * @Function
   */
  const handleOk = () => {
    props.onSelect(range);
    props.onClose();
  };

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={() => props.onClose()}
    >
      <DateRangePicker selected={range} onSelect={setRange} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={() => props.onClose()}
          sx={{ textTransform: "capitalize" }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleOk}
          sx={{ textTransform: "capitalize" }}
          disabled={!range}
        >
          Ok
        </Button>
      </Box>
    </Menu>
  );
};

export default DateRangePickerMenu;
