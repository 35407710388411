import React, { FC, createContext, useContext, useState } from "react";

import { ContactUsFormServices } from "../services/contactUsForms";
import { IContactUsForm, IContactUsFormContext, IEntityPaginated, IHookProvider } from "../types";

const ContactUsFormsContext = createContext<IContactUsFormContext>({} as IContactUsFormContext);

export const ContactUsFormsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const service = new ContactUsFormServices();

    const [entities, setContactUsForms] = useState<IContactUsForm[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchEntity, setSearchEntity] = useState<string>("");
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    const [paginate, setPaginate] = useState<number>(0);

    const createNewEntity = async (_entity: IContactUsForm) => {
        try {
            const entity = await service.createEntity(_entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const editEntity = async (_id: string, _entity: IContactUsForm) => {
        try {
            const entity = await service.updateEntity(_id, _entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const deleteEntity = async (_id: string) => {
        try {
            const entity = await service.destroyEntity(_id);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        try {
            const contactUsForms: IContactUsForm[] = await service.getEntities();
            setContactUsForms([...contactUsForms]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchEntity = async (_id: string, _query?: string) => {
        const entity: IContactUsForm = await service.getEntity(_id, _query);
        return entity;
    };

    return (
        <ContactUsFormsContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate
            }}
        >
            {_params.children}
        </ContactUsFormsContext.Provider>
    );
};

export function useContactUsForms() {
    const context = useContext(ContactUsFormsContext);

    if (!context) {
        throw new Error(
            "useContactUsForms must be used within an ContactUsFormsProvider"
        );
    }

    return context;
}
