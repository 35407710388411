import React, { FC, useState } from "react";
import {
  Button,
  Container,
  DatePicker,
  Input,
  Spacing,
  Text,
} from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import {
  InputAdornmentPencil,
  theme,
} from "src/components";
import { useApiKeys } from "src/hooks/apiKeys";
import { useInformation } from "src/hooks/information";
import { useResponsive } from "src/hooks/responsive";
import { IApiKeyRegister } from "src/types/components/molecules/apiKeys";
import { useAuth } from "src/hooks/auth";
import { ApiKeyProps } from "src/types";

const ApiKeyRegister: FC<IApiKeyRegister> = (params: IApiKeyRegister) => {
  const apiKeysHook = useApiKeys();
  const authHook = useAuth();
  const { hideInformation, showInformation } = useInformation();
  const { mdDown } = useResponsive();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [expiresAt, setExpiresAt] = useState<Date | null>(null);

  const validation = () => {
    let isValid: boolean = true;

    if (name === "") {
      isValid = false;
      setNameError("*Preenchimento obrigatório");
    }
    return isValid;
  };

  const save = async () => {
    if (!authHook.client || !authHook.client.id) return;
    if (!validation()) return;

    const clientId: string = params.clientId ?? authHook.client.id;
    try {
      setLoading(true);
      let payload: ApiKeyProps = {
        clientId,
        name,
        expiresAt: expiresAt ?? undefined
      }

      let informationMessage: string = "Chave cadastrada com sucesso!";
      await apiKeysHook.createNewEntity(payload, true);
      params.onRefresh();

      showInformation(informationMessage, undefined, "Continuar", () => {
        cleanData();
        hideInformation();
        params.onClose();
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const cleanData = () => {
    setName("");
    setExpiresAt(null);
  };

  return (
    <Container
      fluid
      veryPadded
      width={mdDown ? "90%" : "350px"}
      height={mdDown ? "auto" : "300px"}
      color={theme.palette.backgroundElements.main}
      spacedBetween
    >
      <Container flex fluid veryPadded>
        <Text medium size={24}>
          Cadastrar Chave de API
        </Text>

        <Spacing top={VERY_PADDING * 2} />
        <Container fluid>
          <Input
            id="api_key-name"
            placeholder="Nome da chave"
            size="medium"
            value={name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setName(event.target.value)
            }
            startAdornment={
              <InputAdornmentPencil color={theme.palette.backgroundStrokeActive.dark} />}
            error={nameError !== undefined}
            disabled={loading}
            backgroundColor={theme.palette.backgroundElements.light}
            sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
          />
          {nameError && (
            <Text info color={theme.palette.error.main}>
              {nameError}
            </Text>
          )}
        </Container>
        <Spacing top={VERY_PADDING} />
        <Container fluid>
          <DatePicker
            value={expiresAt}
            setValue={setExpiresAt}
            placeholder="Data de expiração da chave"
            disabled={loading}
            sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light, }}
          />
        </Container>
      </Container>

      <Container inline veryPadded spacedBetween>
        <Button onClick={() => save()} loading={loading} disabled={loading}>
          <Text color={theme.palette.background.default}>
            Cadastrar Chave
          </Text>
        </Button>
        <Button
          onClick={() => params.onClose()}
          color={theme.palette.backgroundStrokeActive.dark}
          loading={loading}
          disabled={loading}
        >
          <Text color={theme.palette.background.default}>
            Cancelar cadastro
          </Text>
        </Button>
      </Container>
    </Container>
  );
};

export default ApiKeyRegister;
