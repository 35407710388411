import { FC, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  DeleteOutlineOutlined as DeleteIcon,
  DocumentScannerOutlined as DocumentsUploadIcon,
  FileDownloadOutlined as DownloadIcon,
  InsertDriveFileOutlined as NoRowsIcon,
  IosShareOutlined as ExportIcon,
} from "@mui/icons-material";
import moment from "moment";
import { DateRange } from "react-day-picker";

import DateRangePickerMenu from "src/components/molecules/dateRange/dateRangePickerMenu";
import { CRUDTemplate } from "src/components";
import DocumentsStatusCell from "src/components/molecules/v2/documents/crud/statusCell";
import { useAuth } from "src/hooks/auth";
import { useDocuments } from "src/hooks/documents";
import { useFeedback } from "src/hooks/feedback";
import { IDocument } from "src/types";
import CRUDFilter from "src/components/molecules/v2/crud/filter";
import { Dayjs } from "dayjs";

/**
 * This component returns the Documents CRUD page
 * It uses mui styles
 * @component
 */
const Documents: FC = () => {
  // Auth hooks to get client information
  const auth = useAuth();
  // Document hooks to fetch documents list
  const documents = useDocuments();
  // Information hooks for user feedback
  const feedback = useFeedback();
  // Navigate hooks for change routes
  const navigate = useNavigate();

  // State for get the timeout id
  const [filterTimeoutId, setFilterTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );
  // State for documents selecteds in data grid
  const [dataGridSelecteds, setDataGridSelecteds] = useState<string[]>([]);
  //State
  const [rangeAnchor, setRangeAnchor] = useState<null | HTMLElement>(null);
  //State for anchor datepicker component to the combobox
  const selectRangeRef = useRef(null);
  //State for timeout
  const [typingTimeout, setTypingTimeout] = useState<any>(null);

  /**
   * Reflects when filter has changes
   * @effect
   */
  useEffect(() => {
    if (
      documents.filterSearch == "" &&
      documents.filterInitialDate == null &&
      documents.filterFinalDate == null
    )
      return;
    if (filterTimeoutId) clearTimeout(filterTimeoutId);

    const newTimeoutID = setTimeout(() => {
      let auxQuery: string = "";
      if (documents.filterSearch != "")
        auxQuery += `&search=${documents.filterSearch}`;
      if (documents.filterInitialDate)
        auxQuery += `&initial_date=${moment(documents.filterInitialDate).format(
          "YYYY-MM-DD"
        )}`;
      if (documents.filterFinalDate)
        auxQuery += `&final_date=${moment(documents.filterFinalDate).format(
          "YYYY-MM-DD"
        )}`;
      fetchData(
        documents.dataGridPage,
        documents.dataGridRowsPerPage,
        auxQuery
      );
      documents.setDataGridQuery(auxQuery);
    }, 500);

    setFilterTimeoutId(newTimeoutID);
  }, [
    documents.filterSearch,
    documents.filterInitialDate,
    documents.filterFinalDate,
  ]);

  /**
   * Reflects when detect dateRange change
   * @effect
   */
  useEffect(() => {
    if (documents.period != "5") return;

    let auxQuery = "";
    if (documents.filterSearch != "")
      auxQuery += `&search=${documents.filterSearch}`;
    if (documents.range && documents.range.from && documents.range.to) {
      auxQuery += `&initial_date=${moment(documents.range.from).format(
        "YYYY-MM-DD"
      )}&final_date=${moment(documents.range.to).format("YYYY-MM-DD")}`;
      fetchData(
        documents.dataGridPage,
        documents.dataGridRowsPerPage,
        auxQuery
      );
      documents.setDataGridQuery(auxQuery);
    }
  }, [documents.range]);

  /**
   * Function to fetch all necessary data of the page
   * @function
   */
  const fetchData = (
    _currPage: number = 0,
    dataGridRowsPerPage: number,
    filter: string = ""
  ) => {
    documents.fetchEntitiesPaginated(_currPage, dataGridRowsPerPage, filter);
  };

  /**
   * Function to handle the selected rows of data grid
   * @function
   */
  const onSelectRows = (rowSelectionModel: GridRowSelectionModel) => {
    setDataGridSelecteds([...rowSelectionModel.map((item) => item.toString())]);
  };

  /**
   * Function to download all rows selected
   * @function
   */
  const downloadAllSelected = () => {
    const selectedDocuments: IDocument[] = [];
    for (let i = 0; i < dataGridSelecteds.length; i++) {
      const auxDocument: IDocument | undefined =
        documents.entitiesPaginated.items.find(
          (item) => item.id == dataGridSelecteds[i]
        );
      if (auxDocument && auxDocument.data) selectedDocuments.push(auxDocument);
    }
    // if (selectedDocuments.length == 0) {
    //     information.showInformation("Todos os documentos selecionados não foram finalizados ainda.");
    //     return;
    // }

    const jsonData = JSON.stringify(selectedDocuments.map((item) => item.data));
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement("a");
    link.href = url;
    link.setAttribute("download", `documentos.json`);
    window.document.body.appendChild(link);
    link.click();
  };

  /**
   * Function to delete all rows selected
   * @function
   */
  const deleteAllSelected = () => {
    feedback.open(
      true,
      "info",
      "Informação",
      undefined,
      "Deseja realmente excluir esses documentos?",
      undefined,
      undefined,
      ["Cancelar", "Excluir"],
      ["text", "contained"],
      [
        () => {
          feedback.close();
        },
        async () => {
          for (let i = 0; i < dataGridSelecteds.length; i++)
            await documents.deleteEntity(dataGridSelecteds[i]);
          if (auth.client && auth.client.id)
            documents.fetchDocumentsByClientId(auth.client.id, 1, 5);

          feedback.close();
          feedback.open(
            true,
            "success",
            "Sucesso",
            undefined,
            "Documentos excluídos com sucesso."
          );
        },
      ]
    );
  };

  /**
   * Function to handle change in select component
   * @function
   */
  const handleChange = (event: SelectChangeEvent) => {
    documents.setPeriod(event.target.value as string);
  };

  /**
   * Function to handle click on the selectbox and calculate the date interval
   * @function
   */
  const handleDate = (
    days: number,
    currentMonth: boolean = false,
    currentYear: boolean = false
  ) => {
    let startDate, endDate;

    if (currentMonth) {
      startDate = moment().startOf("month").toDate();
      endDate = moment().endOf("month").toDate();
    } else if (currentYear) {
      startDate = moment().startOf("year").toDate();
      endDate = moment().endOf("year").toDate();
    } else {
      endDate = moment().toDate();
      startDate = moment().subtract(days, "days").toDate();
    }
    documents.setFilterInitialDate(startDate);
    documents.setFilterFinalDate(endDate);
  };
  /**
   * Function to handle click on the 'Personalizado' option of combobox
   */
  const open = Boolean(rangeAnchor);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setRangeAnchor(selectRangeRef.current);
  };
  /**
   * Function to handle when closing modal
   */
  const handleClose = () => {
    setRangeAnchor(null);
  };
  /**
   * Function to handle input search
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    documents.setSearch(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        if (documents.period == "5") {
          if (documents.range && documents.range.from && documents.range.to) {
            documents.setFilterSearch(value);
          }
        } else {
          documents.setFilterSearch(value);
        }
      }, 1000)
    );
  };

  return (
    <CRUDTemplate
      dataGridLoading={documents.loading}
      title="Meus documentos"
      headerTitle=""
      headerChildren={
        <Grid container columnSpacing={1}>
          <Grid item sm={12} md={6} lg={8}>
            <TextField
              variant="outlined"
              label="ID ou modelo"
              size="small"
              onChange={handleInputChange}
              value={documents.search}
              placeholder="Modelo X"
              fullWidth
            />
          </Grid>
          <Grid item sm={6} md={3} lg={2}>
            <Box width="100%">
              <FormControl>
                <InputLabel>Período</InputLabel>
                <Select
                  value={documents.period}
                  label="Período"
                  defaultValue={"30"}
                  onChange={handleChange}
                  ref={selectRangeRef}
                  sx={{ minWidth: "150px" }}
                >
                  <MenuItem onClick={() => handleDate(7)} value={0}>
                    Últimos 7 dias
                  </MenuItem>
                  <MenuItem onClick={() => handleDate(15)} value={1}>
                    Últimos 15 dias
                  </MenuItem>
                  <MenuItem onClick={() => handleDate(30, true)} value={2}>
                    Mês Atual
                  </MenuItem>
                  <MenuItem onClick={() => handleDate(180)} value={3}>
                    Últimos 6 meses
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDate(365, false, true)}
                    value={4}
                  >
                    Este Ano
                  </MenuItem>
                  <MenuItem
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    value={5}
                  >
                    Personalizado
                  </MenuItem>
                </Select>
              </FormControl>
              <DateRangePickerMenu
                anchorEl={rangeAnchor}
                open={open}
                onClose={handleClose}
                selected={documents.range}
                onSelect={documents.setRange}
              />
            </Box>
          </Grid>
          <Grid item sm={6} md={3} lg={2}>
            <Button
              disabled
              variant="contained"
              startIcon={<ExportIcon color="inherit" />}
            >
              <Typography
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="20px"
                letterSpacing="0.1px"
                color="inherit"
                textTransform="initial"
              >
                Exportar dados
              </Typography>
            </Button>
          </Grid>
        </Grid>
      }
      dataGridColumns={documentsDataGridColumns}
      dataGridRows={[...documents.entitiesPaginated.items]}
      dataGridPage={documents.dataGridPage}
      onDataGridSelectRows={onSelectRows}
      rowCount={documents.entitiesPaginated.total}
      paginationMode="server"
      paginationModel={{
        page: documents.dataGridPage - 1,
        pageSize: documents.dataGridRowsPerPage,
      }}
      onPaginationModelChange={(model, details) => {
        documents.setDataGridPage(model.page + 1);
        documents.setDataGridRowsPerPage(model.pageSize);
        fetchData(model.page + 1, model.pageSize, documents.dataGridQuery);
      }}
      labelRowsPerPage="Documentos por página"
      noRowsIcon={<NoRowsIcon color="primary" sx={{ width: 70, height: 70 }} />}
      noRowsText="Você ainda não tem documentos para exibir aqui. Faça a leitura do seu primeiro documento."
      cardFooterChildren={
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="space-between"
        >
          {documents.entitiesPaginated.total > 0 && (
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* <Button disabled={dataGridSelecteds.length === 0} variant="text" startIcon={<DownloadIcon color="inherit" />}
                                onClick={() => downloadAllSelected()}>
                                <Typography fontSize="14px" fontStyle="normal" fontWeight="500" textTransform="initial"
                                    lineHeight="20px" letterSpacing="0.1px">
                                    Download
                                </Typography>
                            </Button> */}
              {auth.hasRights?.("manage-documents") && (
                <Button
                  color="error"
                  disabled={dataGridSelecteds.length === 0}
                  variant="text"
                  startIcon={<DeleteIcon color="inherit" />}
                  onClick={() => deleteAllSelected()}
                >
                  <Typography
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    textTransform="initial"
                    lineHeight="20px"
                    letterSpacing="0.1px"
                  >
                    Excluir
                  </Typography>
                </Button>
              )}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent={
              documents.entitiesPaginated.total == 0 ? "center" : "flex-end"
            }
          >
            <Button
              variant="contained"
              startIcon={<DocumentsUploadIcon color="inherit" />}
              onClick={() => navigate("/documents/upload")}
            >
              <Typography
                color="inherit"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                textTransform="initial"
                lineHeight="20px"
                letterSpacing="0.1px"
              >
                Ler novos documentos
              </Typography>
            </Button>
          </Box>
        </Box>
      }
    >
      <div />
    </CRUDTemplate>
  );
};

export default Documents;

export const documentsDataGridColumns: GridColDef[] = [
  {
    field: "index",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "modelName",
    headerName: "Modelo",
    flex: 3,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row.documentCustomModel)
        return params.row.documentCustomModel.name ?? "Desconhecido";
      else if (params.row.model)
        return params.row.model.description ?? "Desconhecido";
      return params.value;
    },
  },
  {
    field: "createdAt",
    headerName: "Data e hora",
    minWidth: 75,
    flex: 2,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.value instanceof Date)
        return moment(params.value).format("DD/MM/YYYY - HH:mm");
      return params.value;
    },
  },
  {
    field: "status",
    headerName: "Status",
    align: "right",
    headerAlign: "center",
    flex: 2,
    // type: 'number',
    renderCell: (params) => <DocumentsStatusCell cellParams={{ ...params }} />,
    //     `${ params.row.firstName ?? '' } ${ params.row.lastName ?? '' }`,
  },
];
