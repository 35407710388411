import { FC } from "react";
import { Box, Button, Card, CardContent, Grid, Modal, SxProps, Typography } from "@mui/material";
import {
    Cancel as ErrorIcon,
    CheckCircle as SuccessIcon,
    Error as WarningIcon,
    Info as InfoIcon
} from "@mui/icons-material";

import { FeedbackDialogProps } from "src/types/components/molecules/v2/feedback";
import theme from "src/components/theme";

const FeedbackDialog: FC<FeedbackDialogProps> = (props: FeedbackDialogProps) => {
    function getIcon() {
        if (props.variant == "error")
            return <ErrorIcon color="error" />;
        else if (props.variant == "success")
            return <SuccessIcon color="success" />;
        else if (props.variant == "info")
            return <InfoIcon color="info" />;
        else if (props.variant == "warning")
            return <WarningIcon color="warning" />;
    }

    return (
        <Modal open={props.show} onClose={() => props.onClose()}>
            <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center" height="100vh">
                <Box width="359px" minWidth="280px" maxWidth="359px"
                    minHeight="268px">
                    <Card sx={alertCardSx}>
                        <CardContent>
                            <Grid container rowSpacing="16px">
                                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center" paddingTop="8px">
                                    {getIcon()}
                                </Grid>
                                {props.titleNode ?
                                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                        {props.titleNode}
                                    </Grid>
                                    : <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                        <Typography
                                            fontSize="24px" fontStyle="normal" fontWeight="400"
                                            lineHeight="32px"
                                            color={theme.palette.text.primary}>
                                            {props.title}
                                        </Typography>
                                    </Grid>
                                }
                                {props.messageNode ?
                                    <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                        {props.messageNode}
                                    </Grid>
                                    : <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                        <Typography
                                            fontSize="18px" fontStyle="normal" fontWeight="400"
                                            lineHeight="20px" letterSpacing="0.25px"
                                            color={theme.palette.text.secondary}
                                            paddingTop="16px">
                                            {props.message}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
                                    <Box display="flex" flexDirection="row" justifyContent="flex-end" paddingTop="32px"
                                        width="100%" height="45px">
                                        {(!props.buttonsLabels || props.buttonsLabels.length == 0) &&
                                            <Box display="flex" justifyContent="flex-end" paddingLeft="16px">
                                                <Button variant="contained" color="primary"
                                                    onClick={() => props.onClose()}>
                                                    <Typography fontSize="14px" fontStyle="normal" fontWeight="500"
                                                        lineHeight="20px" letterSpacing="0.1px" textTransform="initial">
                                                        Ok
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        }
                                        {props.buttonsLabels?.map((item, index) => {
                                            return (
                                                <Box display="flex" justifyContent="flex-end" paddingLeft="16px">
                                                    <Button variant={props.buttonsVariant?.[index] ?? "text"} color="primary"
                                                        onClick={() => props.buttonsOnClick && props.buttonsOnClick[index]()}>
                                                        <Typography fontSize="14px" fontStyle="normal" fontWeight="500"
                                                            lineHeight="20px" letterSpacing="0.1px" textTransform="initial">
                                                            {item}
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Modal>
    )
}

export default FeedbackDialog;

const alertCardSx: SxProps = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    width: "100%",
    minHeight: "268px",
    paddingX: "32px",
    paddingTop: "24px",
    paddingBottom: "12px",
    borderRadius: "12px"
}