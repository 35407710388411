import { FC, useState } from "react";
import { Container, Text } from "@pix-force/react-atoms";

import SubscriptionsListItem from "../listItem";
import { SubscriptionListHeader, SubscriptionsRegisterModal } from "src/components";
import { SubscriptionProps, SubscriptionPlanProps, ISubscriptionsList, SubscriptionPlanPaymentSettingProps } from "src/types";

const SubscriptionsList: FC<ISubscriptionsList> = (params: ISubscriptionsList) => {
    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false);
    const [selectedSubscription, setSelectedSubscription] = useState<SubscriptionProps | undefined>(undefined);

    const subscriptionPlan: SubscriptionPlanProps | undefined = params.subscription?.subscriptionPlan || undefined;
    if (!subscriptionPlan) return null;

    const getFixedPrice = () => {
        if (!params.subscription?.subscriptionPlan) return 0;

        const subscriptionPlan: SubscriptionPlanProps = params.subscription.subscriptionPlan;
        const paymentSettings: SubscriptionPlanPaymentSettingProps | undefined = subscriptionPlan.paymentSettings?.find(item => item.billingScheme == "per_unit");
        if (!paymentSettings) return 0;

        return paymentSettings.unitAmount;
    }

    const getPagesIncluded = () => {
        if (!params.subscription?.subscriptionPlan) return 0;

        const subscriptionPlan: SubscriptionPlanProps = params.subscription.subscriptionPlan;
        const paymentSettings: SubscriptionPlanPaymentSettingProps | undefined = subscriptionPlan.paymentSettings?.find(item => item.billingScheme == "tiered");
        if (!paymentSettings) return 0;
        if (paymentSettings.tiers.length == 0) return 0;

        try {
            return paymentSettings.tiers[0].upTo;
        } catch (err) {
            return 0;
        }
    }

    const getPricePerPage = () => {
        if (!params.subscription?.subscriptionPlan) return 0;

        const subscriptionPlan: SubscriptionPlanProps = params.subscription.subscriptionPlan;
        const paymentSettings: SubscriptionPlanPaymentSettingProps | undefined = subscriptionPlan.paymentSettings?.find(item => item.billingScheme == "tiered");
        if (!paymentSettings) return 0;
        if (paymentSettings.tiers.length == 0) return 0;

        try {
            return paymentSettings.tiers[1].unitAmountDecimal;
        } catch (err) {
            return 0;
        }
    }

    return (
        <Container fluid flex width="100%" minHeight="80vh">
            <Container fluid width="100%" minHeight="70vh">
                <Container fluid width="100%">
                    <SubscriptionListHeader />
                </Container>
                {params.loading &&
                    <Container fluid flex centered height="100%">
                        <Text>Carregando.</Text>
                    </Container>
                }
                {!params.loading && !params.subscription &&
                    <Container fluid flex centered>
                        <Text>Nenhuma assinatura encontrada.</Text>
                    </Container>
                }
                {!params.loading &&
                    <Container fluid width="100%" verticalPadded>
                        {params.subscription?.id &&
                            <SubscriptionsListItem
                                subscriptionId={params.subscription?.id}
                                subscriptionPlanPublicId={params.subscription.subscriptionPlan?.id}
                                name={params.subscription.subscriptionPlan?.name ?? "Não informado"}
                                description={params.subscription.subscriptionPlan?.description ?? ""}
                                date={params.subscription.initialDate}
                                fixedPrice={getFixedPrice()}
                                pagesIncluded={getPagesIncluded()}
                                pricePerPage={getPricePerPage()}
                                useApi={params.subscription.subscriptionPlan?.useApi ?? false}
                                priority={params.subscription.subscriptionPlan?.priority}
                                virtualSupport={params.subscription.subscriptionPlan?.virtualSupport}
                                customizedSupport={params.subscription.subscriptionPlan?.customizedSupport}
                                useApp={params.subscription.subscriptionPlan?.useApp}
                                unlimitedUsers={params.subscription.subscriptionPlan?.unlimitedUsers} />
                        }
                    </Container>
                }
            </Container>

            <SubscriptionsRegisterModal
                isOpen={isRegisterModalOpen}
                clientId={params.clientId}
                subscription={selectedSubscription}
                id={selectedSubscription?.id}
                onClose={() => {
                    setSelectedSubscription(undefined);
                    params.onRefresh();
                    setIsRegisterModalOpen(false)
                }} />
        </Container >
    );
}

export default SubscriptionsList;