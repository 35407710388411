import { EntityServices } from "./entities";
import { ISubscriptionPlanPrice } from "../types";
import { parseStringToDate } from "../utils/dates";
import api, { API_FINANCES_URL } from ".";
import { AxiosResponse } from "axios";

export class SubscriptionPlanPriceServices extends EntityServices<ISubscriptionPlanPrice> {
  constructor() {
    super("Preços dos planos", `${API_FINANCES_URL}/subscriptions_plans_prices`);
  }

  async destroyBySubscriptionPlan(_subscriptionPlanId: string): Promise<ISubscriptionPlanPrice[]> {
    const response: AxiosResponse = await api.delete(`${this.baseUrl}/by_subscription_plan_id/${_subscriptionPlanId}`);

    if (response.data && response?.request?.status === 200) {
      const subscriptionPlanPrices: ISubscriptionPlanPrice[] = [];
      for (let i = 0; i < response.data.length; i++) {
        subscriptionPlanPrices.push(this.convertApiResponseToIEntity(response.data[i]));
      }
      return subscriptionPlanPrices;
    } else if (response.data && response?.request?.status === 400) {
      throw new Error(response.data as string);
    }
    throw new Error(`Não foi possível remover os ${this.entityForLog}.`);
  }

  override convertApiResponseToIEntity(_data: any): ISubscriptionPlanPrice {
    return {
      id: _data.id,
      subscriptionPlanId: _data.subscriptionPlanId,
      minRequests: _data.minRequests,
      maxRequests: _data.maxRequests,
      price: _data.price,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
