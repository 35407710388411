import { FC } from "react";
import Grid from "@mui/material/Grid";
import { Button, Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import moment from "moment";

import { theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { useResponsive } from "src/hooks/responsive";
import { IPaymentFormsItemCard } from "src/types";

const PaymentFormsItemCard: FC<IPaymentFormsItemCard> = (props: IPaymentFormsItemCard) => {
  const cardStyle: any = {
    border: `solid 1px ${theme.palette.backgroundElements.dark}`,
    minHeight: 100
  }

  const authHooks = useAuth();
  const { mdDown } = useResponsive();

  return (
    <Container fluid flex centered>
      <Container relative fluid flex centered color={theme.palette.backgroundElements.main}
        width="100%" height="100px" sx={cardStyle}>
        <Container absolute fluid width="8px" height="101%" color={theme.palette.secondary.main} sx={{ left: 0, top: -1, zIndex: 10 }} />

        <Grid container rowSpacing={2} columnSpacing={2} display="flex" paddingLeft={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={4} lg={3}>
            {mdDown && <Text subtitle medium>Cartão Principal: </Text>}
            <Spacing left={PADDING} />
            {props.clientCard &&
              <Text subtitle medium>
                {`xxxx xxxx xxxx ${props.clientCard.last4}`}
              </Text>
            }
          </Grid>
          <Grid item xs={12} md={2} lg={1}>
            {mdDown && <Text subtitle medium>Valor: </Text>}
            <Text subtitle medium>
              {`R$ ${(props.upcomingInvoice?.total ?? 0).toLocaleString("pt-BR")}`}
            </Text>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            {mdDown && <Text subtitle medium>Vencimento: </Text>}
            <Text subtitle medium>
              {(props.upcomingInvoice?.periodEnd && moment(props.upcomingInvoice?.periodEnd).format("DD/MM/YYYY")) ?? ""}
            </Text>
          </Grid>
          <Grid item xs={12} md={4} xl={2} display="flex" flexDirection="row" justifyContent="space-around">
            {props.subscription.collectionMethod == "send_invoice" &&
              <a href={props.upcomingInvoice?.url_pdf}>
                <Button
                  size="small"
                  color={props.upcomingInvoice?.url !== undefined ? theme.palette.primary.main : theme.palette.gray.main}
                  disabled={props.upcomingInvoice?.url === undefined}
                  sx={{ width: "120px", height: "40px" }}>
                  <Text semiBold size={12} color={theme.palette.background.paper}>
                    Boleto
                  </Text>
                </Button>
              </a>
            }
            {props.subscription.collectionMethod == "charge_automatically" &&
              <a href={props.upcomingInvoice?.url_pdf}>
                <Button
                  size="small"
                  color={props.upcomingInvoice?.url !== undefined ? theme.palette.primary.main : theme.palette.gray.main}
                  disabled={props.upcomingInvoice?.url === undefined}
                  sx={{ width: "120px", height: "40px" }}>
                  <Text semiBold size={12} color={theme.palette.background.paper}>
                    Cartão
                  </Text>
                </Button>
              </a>
            }
          </Grid>
          <Grid item xs={12} md={6} xl={3} display="flex" alignItems="center" justifyContent="space-around">
            {props.subscription.collectionMethod == "charge_automatically" &&
              <Button
                size="small"
                color={theme.palette.primary.main}
                onClick={() => props.onManageCardClick()}
                sx={{ width: "120px", height: "40px" }}>
                <Text semiBold size={12} color={theme.palette.background.paper}>
                  Adicionar/Alterar Cartão
                </Text>
              </Button>
            }
            <Button
              size="small"
              color={theme.palette.primary.main}
              onClick={() => props.onChangePaymentForm(props.subscription)}
              sx={{ width: "120px", height: "40px" }}>
              <Text semiBold size={12} color={theme.palette.background.paper}>
                Alterar forma de pagamento
              </Text>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default PaymentFormsItemCard;
