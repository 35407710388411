import * as React from "react";
import { IMaskInput } from "react-imask";
import { MaskedTextFieldProps } from "src/types";

/**
 * Components that can inherit Mui Text Field by adding an Phone Mask
 * @component
 */
const PhoneMaskedTextField = React.forwardRef<HTMLInputElement, MaskedTextFieldProps>(function MaskedTextField(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }]}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export default PhoneMaskedTextField;
