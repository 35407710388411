import { FC } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Close as CloseIcon } from "@mui/icons-material";

import theme from "src/components/theme";
import { ICRUDFilter } from "src/types/components/molecules/v2/crud";
import { DatePicker, LocalizationProvider, ptBR } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

/**
 * This component returns an CRUD Data Grid customized
 * It uses mui styles
 * @component
 */
const CRUDFilter: FC<ICRUDFilter> = (props: ICRUDFilter) => {
    /**
     * Function that clean filter
     * @function
     */
    const clearFilter = () => {
        props.setSearch("");
        props.setInitialDate(null);
        props.setFinalDate(null);
    }

    return (
        <Grid container rowSpacing="16px" columnSpacing="16px">
            <Grid item xs={props.hideDates ? 11 : 12} sm={props.hideDates ? 10 : 12} lg={props.hideDates ? 10 : 5.5} xl={props.hideDates ? 10 : 6}
                display="flex" alignItems="flex-end" justifyContent="flex-end">
                <TextField variant="outlined" fullWidth size="small"
                    label={props.searchLabel ?? "Pesquisa"} helperText={null}
                    placeholder={props.searchPlaceholder ?? "Digite uma pesquisa"}
                    value={props.search} onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setSearch(event.target.value)} />
            </Grid>
            {!props.hideDates &&
                <>
                    <Grid item xs={12} sm={5} lg={2.5} xl={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
                        <LocalizationProvider
                            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                            dateAdapter={AdapterDayjs}
                            adapterLocale="pt-br">
                            <DatePicker
                                label="Data inicial"
                                value={dayjs(props.initialDate)}
                                onChange={(newValue) => props.setInitialDate(newValue)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        variant: "outlined",
                                        error: false,
                                        fullWidth: true,
                                        placeholder: "Informe a data inicial"
                                    },
                                }} />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={5} lg={2.5} xl={2} display="flex" alignItems="flex-end" justifyContent="flex-end">
                        <LocalizationProvider
                            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                            dateAdapter={AdapterDayjs}
                            adapterLocale="pt-br">
                            <DatePicker
                                label="Data final"
                                value={dayjs(props.finalDate)}
                                onChange={(newValue) => props.setFinalDate(newValue)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        variant: "outlined",
                                        error: false,
                                        fullWidth: true,
                                        placeholder: "Informe a data final"
                                    },
                                }} />
                        </LocalizationProvider>
                    </Grid>
                </>
            }
            <Grid item xs={props.hideDates ? 1 : 12} sm={props.hideDates ? 2 : 2} lg={1.5} xl={2}
                display="flex" alignItems="center" justifyContent="center">
                <Button variant="text" size="medium"
                    startIcon={<CloseIcon />}
                    onClick={() => clearFilter()}>
                    <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                        Limpar
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}

export default CRUDFilter;
