import { FC, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ContentCopy as ContentCopyIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

import { ApiKeysCRUDKeyValueCellProps } from "src/types/components/molecules/v2/apiKeys";
import { copyToClipboard } from "src/utils/utils";

/**
 * This component returns the api key value cell
 * It uses mui styles
 * @component
 */
const ApiKeysCRUDKeyValueCell: FC<ApiKeysCRUDKeyValueCellProps> = (props: ApiKeysCRUDKeyValueCellProps) => {
    // State ofr key value visible
    const [visible, setVisible] = useState<boolean>(false);

    /**
     * Function that copy content to clipboard
     * @effect
     */
    const contentCopy = (value: any) => {
        if (value) copyToClipboard(value);
    }

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between"
            width="100%">
            <Typography noWrap>
                {visible ? props.cellParams.value : "**********************"}
            </Typography>
            <Box>
                <IconButton onClick={() => setVisible(!visible)}>
                    {visible ?
                        <VisibilityIcon fontSize="small" /> :
                        <VisibilityOffIcon fontSize="small" />
                    }
                </IconButton>
                <IconButton onClick={() => contentCopy(props.cellParams.value)}>
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </Box>
        </Box>
    );
}

export default ApiKeysCRUDKeyValueCell;
