import { FC, createContext, useContext, useState } from "react";

import { DocumentModelServices } from "../services/documentModels";
import { IDocumentModel, IDocumentModelContext, IHookProvider } from "../types";

/**
 * This component is the context of the document models hooks
 * @component
 */
const DocumentModelsContext = createContext<IDocumentModelContext>({} as IDocumentModelContext);

/**
 * This component provides the document models hooks
 * @component
 */
export const DocumentModelsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    // Service that communicate with back-end
    const service = new DocumentModelServices();

    // State for Entities of database
    const [entities, setEntities] = useState<IDocumentModel[]>([]);
    // State for loading status
    const [loading, setLoading] = useState<boolean>(false);
    // State for searching an entity
    const [searchEntity, setSearchEntity] = useState<string>("");
    // State for quantity of entities per row
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    // State for how much paginate the data will return
    const [paginate, setPaginate] = useState<number>(0);

    /**
     * Function to create new entity
     * @function
     */
    const createNewEntity = async (_entity: IDocumentModel) => {
        try {
            const entity = await service.createEntity(_entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    /**
     * Function to update an entity
     * @function
     */
    const editEntity = async (_id: string, _entity: IDocumentModel) => {
        try {
            const entity = await service.updateEntity(_id, _entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    /**
     * Function to delete an entity
     * @function
     */
    const deleteEntity = async (_id: string) => {
        try {
            const entity = await service.destroyEntity(_id);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    /**
     * Function to fetch all entities
     * @function
     */
    const fetchEntities = async (searchRelationships: boolean = false) => {
        setLoading(true);
        try {
            const documentModels: IDocumentModel[] = await service.getEntities(`?search_relationships=${searchRelationships ? 1 : 0}`);
            setEntities([...documentModels]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    /**
     * Function to fetch an entity
     * @function
     */
    const fetchEntity = async (_id: string, _query?: string) => {
        if (!_query) _query = "?search_relationships=1";
        const entity: IDocumentModel = await service.getEntity(_id, _query);
        return entity;
    };

    return (
        <DocumentModelsContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,
            }}
        >
            {_params.children}
        </DocumentModelsContext.Provider>
    );
};

export function useDocumentModels() {
    const context = useContext(DocumentModelsContext);

    if (!context) {
        throw new Error(
            "useDocumentModels must be used within an DocumentModelsProvider"
        );
    }

    return context;
}
