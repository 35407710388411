import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Cached as ResendIcon,
  DeleteOutlineOutlined as DeleteIcon,
  FileDownloadOutlined as DownloadIcon,
  Info as InfoIcon,
  MoreVert as MenuIcon,
  TimelineOutlined as TrackbackIcon,
} from "@mui/icons-material";

import theme from "src/components/theme";
import { useAuth } from "src/hooks/auth";
import { useDocuments } from "src/hooks/documents";
import { useFeedback } from "src/hooks/feedback";
import { IDocument } from "src/types";
import { IDocumentsStatusCell } from "src/types/components/molecules/v2/documents";
import { isDocument } from "src/types/hooks/documents";

// Documents status ENUM
// RECEIVED = 0
// FINISHED = 1
// ERROR = 2
// IN_PROGRESS = 3
// IN_CONVERT = 4
// IN_CROP = 5
// IN_OCR = 6
// IN_ANALYSIS = 7

/**
 * This component returns the documents status cell
 * Can be used in documents data grid
 * It uses mui styles
 * @component
 */
const DocumentsStatusCell: FC<IDocumentsStatusCell> = (
  props: IDocumentsStatusCell
) => {
  // Auth hooks to get the user rights
  const auth = useAuth();
  // Document hooks for service operations
  const documents = useDocuments();
  // Information hooks for user feedback
  const feedback = useFeedback();
  // Navigate hooks to change route based on path
  const navigate = useNavigate();

  // State for document typed
  const [document, setDocument] = useState<IDocument | undefined>(undefined);
  // State for menu button anchor
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  // State for status value
  const [status, setStatus] = useState<number>(-1);

  // Document id (the const never will be number, we treat id as UUID),
  // but the x-data-grid treats that way
  const id: string | number | undefined = props.cellParams?.id ?? undefined;
  // If menu is open or not
  const menuOpen = Boolean(menuAnchor);

  /**
   * Reflects when component inits
   * It gets the document typed from x-data-grid row
   * @component
   */
  useEffect(() => {
    if (props.cellParams) {
      try {
        if (isDocument(props.cellParams.row)) {
          const auxDocument: IDocument = props.cellParams.row;
          setDocument(auxDocument);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  /**
   * Reflects when component inits
   * It gets the document typed from x-data-grid row
   * @component
   */
  useEffect(() => {
    if (props.cellParams) {
      setStatus(props.cellParams.value);

      const auxDocument: IDocument = props.cellParams.row;
      setDocument(auxDocument);
    }
  }, [{ ...props.cellParams }]);

  /**
   * Function that handle menu button click
   * @function
   */
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  /**
   * Function that handle menu button close
   * @function
   */
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  /**
   * Function that downloads the document data
   * @function
   */
  const download = async () => {
    if (!document) return null;

    const jsonData = JSON.stringify(document.data);
    const blob = new Blob([jsonData], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = window.document.createElement("a");
    link.href = url;
    link.setAttribute("download", `documento_${document.index}.json`);
    window.document.body.appendChild(link);
    link.click();

    handleMenuClose();
  };

  /**
   * Function that downloads the document data
   * @function
   */
  const resend = async () => {
    if (!document?.id) return null;

    const auxDocument: IDocument = await documents.resend(document.id);
    if (!auxDocument)
      feedback.open(
        true,
        "error",
        "Erro",
        undefined,
        "Não foi possível reenviar o documento"
      );
    else {
      documents.fetchEntitiesPaginated(
        documents.dataGridPage,
        documents.dataGridRowsPerPage,
        documents.dataGridQuery
      );
      feedback.open(
        true,
        "success",
        "Sucesso",
        undefined,
        "Documento reenviado com sucesso"
      );
    }

    handleMenuClose();
  };

  /**
   * Function that downloads the document data
   * @function
   */
  const deleteDocument = () => {
    if (!document?.id) return null;

    handleMenuClose();
    feedback.open(
      true,
      "info",
      "Informação",
      undefined,
      "Deseja realmente excluir esse documento?",
      undefined,
      undefined,
      ["Cancelar", "Excluir"],
      ["text", "contained"],
      [
        () => {
          feedback.close();
        },
        async () => {
          if (document.id) {
            const auxDocument: IDocument = await documents.deleteEntity(
              document.id
            );
            if (auth.client && auth.client.id)
              documents.fetchDocumentsByClientId(auth.client.id, 1, 5);
            if (auxDocument) {
              feedback.close();
              feedback.open(
                true,
                "success",
                "Sucesso",
                undefined,
                "Documento excluído com sucesso."
              );
              return;
            }
          }
        },
      ]
    );
  };

  if (!id || !document) return null;
  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="100%">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        width="100%"
        maxWidth="170px"
      >
        {status === 1 ? ( // FINISHED
          <>
            <Button
              variant="text"
              color="primary"
              onClick={() => navigate(`/documents/${document.id}`)}
            >
              <Typography
                color="inherit"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="20px"
                letterSpacing="0.1px"
                textTransform="initial"
              >
                Ver detalhes
              </Typography>
            </Button>
            {document.data != undefined && document.data.error != undefined && (
              <Tooltip placement="top" title={document.data?.error ?? ""}>
                <InfoIcon
                  color="error"
                  sx={{ fontSize: 20, marginBottom: 2 }}
                />
              </Tooltip>
            )}
          </>
        ) : status === 2 ? ( // ERROR
          <>
            <Typography
              noWrap
              color="error"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="20px"
              letterSpacing="0.1px"
            >
              Erro
            </Typography>
            <Tooltip
              placement="top"
              title={
                document.data?.error ?? document.statusVision?.errorMessage
              }
            >
              <InfoIcon color="error" sx={{ fontSize: 20, marginBottom: 2 }} />
            </Tooltip>
          </>
        ) : (
          // IN_PROGRESS
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Typography
              noWrap
              color={theme.palette.backgroundStrokeActive.light}
              fontSize="14px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="20px"
              letterSpacing="0.1px"
            >
              Processando...
            </Typography>
            {/* 
                             RECEIVED = 0
                             FINISHED = 1
                             ERROR = 2
                             IN_PROGRESS = 3
                             IN_CONVERT = 4
                             IN_CROP = 5
                             IN_OCR = 6
                             IN_ANALYSIS = 7 
                             */}
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="primary" variant="indeterminate" />
            </Box>
          </Box>
        )}
      </Box>

      <IconButton onClick={handleMenuClick}>
        <MenuIcon color="primary" />
      </IconButton>
      <Menu
        id="crud-document-status-cell-menu-button"
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {status === 1 && (
          <MenuItem onClick={() => download()}>
            <Box display="flex">
              <DownloadIcon />
              <Typography
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.5px"
                sx={{ paddingX: "12px" }}
              >
                Download
              </Typography>
            </Box>
          </MenuItem>
        )}
        {auth.hasRights?.("manage-documents__resend") && (
          <MenuItem onClick={() => resend()}>
            <Box display="flex">
              <ResendIcon />
              <Typography
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.5px"
                sx={{ paddingX: "12px" }}
              >
                Reenviar
              </Typography>
            </Box>
          </MenuItem>
        )}
        {auth.hasRights?.("manage-documents") && (
          <MenuItem onClick={() => deleteDocument()}>
            <Box display="flex">
              <DeleteIcon color="error" />
              <Typography
                color="error"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.5px"
                sx={{ paddingX: "12px" }}
              >
                Excluir
              </Typography>
            </Box>
          </MenuItem>
        )}
        {auth.isSuperAdmin() && (
          <MenuItem
            component={Link}
            href={`https://graylog.pixforce.ai/search?q=%22${document.id}%22+AND+NOT+%22method%22+AND+NOT+%22params%3A%22&rangetype=relative&relative=0`}
            target="_blank"
          >
            <Box display="flex">
              <TrackbackIcon />
              <Typography
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.5px"
                sx={{ paddingX: "12px" }}
              >
                Histórico
              </Typography>
            </Box>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
  // {
  //     props.status === 2 &&
  //         <Container inline>
  //             <Text center bold color={theme.palette.error.main}>
  //                 {props.document.statusVision ? props.document.statusVision.statusMessage : props.document.statusDesc}
  //             </Text>
  //             {(props.document.data != undefined && props.document.data.error != undefined) &&
  //                 <Tooltip placement="top" title={props.document.data?.error ?? ""}>
  //                     <InfoIcon color="error" sx={{ fontSize: 20, marginBottom: 2 }} />
  //                 </Tooltip>
  //             }
  //         </Container>
  // }
};

export default DocumentsStatusCell;
