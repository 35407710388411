import React from "react";
import { Route, Routes, } from "react-router-dom";

import { CreateAccount, Splash } from "../pages";

const AuthRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/create-account" element={<CreateAccount />} />

      <Route path="*" element={<Splash />} />
    </Routes>
  );
};

export default AuthRoutes;
