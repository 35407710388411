import React, { FC, createContext, useContext, useState } from "react";

import { ClientServices } from "src/services/clients";
import { UserServices } from "../services/users";
import { IHookProvider, UserProps, IUserContext } from "../types";

const UsersContext = createContext<IUserContext>({} as IUserContext);

export const UsersProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const userServices = new UserServices();
  const clientServices = new ClientServices();
  const [entities, setUsers] = useState<UserProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_user: UserProps) => {
    try {
      const user = await userServices.createUser(_user);
      fetchEntities();
      return user;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _user: UserProps) => {
    try {
      const user = await userServices.updateUser(_id, _user);
      fetchEntities();
      return user;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const user = await userServices.destroyUser(_id);
      await clientServices.destroyClientHasUserbyUserId(_id);
      fetchEntities();
      return user;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const users: UserProps[] = await userServices.getUsers();
      setUsers([...users]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const user: UserProps = await userServices.getUser(_id);
    return user;
  };

  const fetchUserGroups = async (_id: string) => {
    const groups: any[] = await userServices.getUserGroups(_id);
    return groups;
  }

  const addGroup = async (_userId: string, _groupId: string) => {
    const created: boolean = await userServices.addGroup(_userId, _groupId);
    return created;
  }

  const removeGroup = async (_userId: string, _groupId: string) => {
    const removed: boolean = await userServices.removeGroup(_userId, _groupId);
    return removed;
  }

  return (
    <UsersContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,
        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,

        fetchUserGroups,
        addGroup,
        removeGroup
      }}
    >
      {_params.children}
    </UsersContext.Provider>
  );
};

export function useUsers() {
  const context = useContext(UsersContext);

  if (!context) {
    throw new Error("useUsers must be used within an UsersProvider");
  }

  return context;
}
