import React, { FC, useEffect, useState } from "react";
import { Checkbox, Collapse, Divider, Menu, IconButton, Typography, TextField, Button } from "@mui/material";
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Search as SearchIcon } from "@mui/icons-material";
import { Container, Spacing } from "@pix-force/react-atoms";
import { BORDER_RADIUS, PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import theme from "src/components/theme";
import { useDocuments } from "src/hooks/documents";
import { IDocumentModel, IDocumentsTypesMultiSelect } from "src/types";
import { normalizeString } from "src/utils/stringFormatters";
import { useDocumentModels } from "src/hooks/documentModels";

const TypesMultiSelect: FC<IDocumentsTypesMultiSelect> = (params: IDocumentsTypesMultiSelect) => {
    const documentsTypesSelectId: string = "documents-types-select-id-button";

    const documentsHooks = useDocuments();
    // Document models hooks for upload and types data
    const documentModels = useDocumentModels();

    const [search, setSearch] = useState<string>('')
    const [filteredTypes, setFilteredTypes] = useState<IDocumentModel[]>([]);

    const [anchorDocumentsTypesSelectId, setAnchorDocumentsTypesSelectId] = useState<null | HTMLElement>(null);
    const isDocumentsTypesSelectIdOpen = Boolean(anchorDocumentsTypesSelectId)

    useEffect(() => {
        if (documentsHooks.documentsTypes.length == 0)
            documentsHooks.fetchDocumentsTypes();
    }, []);

    useEffect(() => {
        if (!params.data)
            setFilteredTypes([...documentModels.entities]);
    }, [documentsHooks.documentsTypes]);

    useEffect(() => {
        if (params.data)
            setFilteredTypes([...params.data]);
    }, [params.data]);

    useEffect(() => {
        filterTypes(search);
    }, [search]);

    const handleDocumentsTypesSelectIdMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorDocumentsTypesSelectId(event.currentTarget);
    };

    const handleDocumentsTypesSelectIdMenuClose = () => {
        setAnchorDocumentsTypesSelectId(null);
    };

    const filterTypes = (_search: string) => {
        setFilteredTypes([])
        let auxTypes: IDocumentModel[] = [];
        if (params.data) auxTypes = [...params.data];
        else auxTypes = [...documentModels.entities];

        setTimeout(() => {
            if (_search != '') {
                auxTypes = auxTypes.filter((item: IDocumentModel) =>
                    (item.description && normalizeString(item.description).toLowerCase().includes(normalizeString(_search).toLowerCase())))
            }

            setFilteredTypes([...auxTypes])
        }, 0)
    }

    const handleSelectType = (_documentModel: IDocumentModel, _isChecked: boolean) => {
        const auxTypes: IDocumentModel[] = [];
        for (let i = 0; i < params.selectedTypes.length; i++) {
            const auxType: IDocumentModel = params.selectedTypes[i];
            if (auxType.type == _documentModel.type && !_isChecked) continue;
            auxTypes.push(auxType);
        }
        if (_isChecked) auxTypes.push(_documentModel);

        params.setSelectedTypes([...auxTypes]);
    }

    const renderTypeItem = (_documentModel: IDocumentModel, key: number) => {
        const isChecked: boolean = params.selectedTypes.find(item => item.type == _documentModel.type) != undefined;

        return (
            <Container key={key} className="container-hover" inline spacedBetween horizontalCentered width="100%"
                onClick={() => handleSelectType(_documentModel, !isChecked)}
                sx={{ cursor: 'pointer' }}>
                <Checkbox checked={isChecked} />
                <Container fluid flexStart flex>
                    <Typography>{_documentModel.description}</Typography>
                </Container>
            </Container>
        );
    }

    return (
        <Container fluid flex flexGrow>
            <Container inline horizontalCentered spacedBetween height="30px" onClick={handleDocumentsTypesSelectIdMenuOpen}
                style={{ cursor: 'pointer', paddingLeft: PADDING, paddingRight: PADDING, ...params.sx }}>
                {params.selectedTypes.length == 0 && <Typography>{params.placeholder ?? "Tipos de documentos"}</Typography>}
                {params.selectedTypes.length > 0 &&
                    <Typography>
                        {`${params.selectedTypes[0].description} ${!params.singleSelect && params.selectedTypes.length > 1 ? ' +' + (params.selectedTypes.length - 1) : ''}`}
                    </Typography>
                }
                <IconButton style={{ backgroundColor: 'transparent' }}>
                    {params.expanded && <ExpandLessIcon />}
                    {!params.expanded && <ExpandMoreIcon />}
                </IconButton>
            </Container>

            <Menu
                id={documentsTypesSelectId}
                anchorEl={anchorDocumentsTypesSelectId}
                anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
                transformOrigin={{ vertical: "top", horizontal: "right", }}
                open={isDocumentsTypesSelectIdOpen}
                onClose={handleDocumentsTypesSelectIdMenuClose}
                MenuListProps={{ sx: { width: anchorDocumentsTypesSelectId && anchorDocumentsTypesSelectId.offsetWidth } }}>
                <Container fluid flex padded flexGrow borderRadius={0} sx={{ border: `solid 2px ${theme.palette.backgroundElements.main}` }}>
                    <TextField
                        variant="outlined"
                        value={search}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        placeholder={params.placeholderInput ?? 'Pesquisar tipos de documentos'} />
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container fluid maxHeight="200px" sx={{ overflowY: 'auto' }}>
                        {filteredTypes.map((item, index) => renderTypeItem(item, index))}
                    </Container>
                    <Spacing top={PADDING} />
                    <Divider sx={{ width: "100%" }} />
                    <Spacing top={PADDING} />
                    <Container inline flex flexEnd horizontalCentered>
                        <Button variant="contained" startIcon={<CloseIcon style={{ color: theme.palette.background.default }} />}
                            onClick={() => params.setSelectedTypes([])}>
                            <Typography textTransform="initial">Limpar</Typography>
                        </Button>
                    </Container>
                </Container>
            </Menu>
        </Container >
    );
}

export default TypesMultiSelect