import { FC, useEffect, useState } from "react";
import { Container, Text } from "@pix-force/react-atoms";

import PaymentFormsItemCard from "../listItem";
import PaymentFormsListHeader from "../listItem/header";
import { SubscriptionPlanProps, IPaymentFormsList, IClientCard } from "src/types";

const PaymentFormsList: FC<IPaymentFormsList> = (props: IPaymentFormsList) => {
    const subscriptionPlan: SubscriptionPlanProps | undefined = props.subscription?.subscriptionPlan ?? undefined;
    if (!subscriptionPlan) return null;

    const [defaultClientCard, setDefaultClientCard] = useState<IClientCard | undefined>(undefined);

    useEffect(() => {
        const auxDefaultClientCard: IClientCard | undefined = props.clientCards.find(item => item.isDefault == true);
        if (auxDefaultClientCard) setDefaultClientCard(auxDefaultClientCard);
    }, [props.clientCards]);

    return (
        <Container fluid flex width="100%" minHeight="80vh">
            <Container fluid width="100%" minHeight="70vh">
                <Container fluid width="100%">
                    <PaymentFormsListHeader />
                </Container>
                {props.loading &&
                    <Container fluid centered height="100%">
                        <Text>Carregando.</Text>
                    </Container>
                }
                {!props.loading && !props.subscription &&
                    <Container fluid flex centered>
                        <Text>Nenhuma assinatura encontrada.</Text>
                    </Container>
                }
                {!props.loading &&
                    <Container fluid width="100%" verticalPadded>
                        <PaymentFormsItemCard
                            subscription={props.subscription}
                            upcomingInvoice={props.upcomingInvoice}
                            clientCard={defaultClientCard}
                            onTicketClick={props.onTicketClick}
                            onCardClick={props.onCardClick}
                            onManageCardClick={props.onManageCardClick}
                            onChangePaymentForm={props.onChangePaymentForm} />
                    </Container>
                }
            </Container>
        </Container >
    );
}

export default PaymentFormsList;
