import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Divider, Grid, IconButton, SxProps, TextField } from "@mui/material";
import { Lock as PasswordIcon, Person as UserIcon, Visibility, VisibilityOff } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { Button, Container, Input, Page, Spacing, Text, } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING, } from "@pix-force/react-atoms/lib/utils/consts";

import background from "src/assets/background-image.png";
import brand from "src/assets/brand_white.png";
import { ReactComponent as FacebookIcon } from "src/assets/icons/socialMedia/facebook.svg";
import { ReactComponent as InstagramIcon } from "src/assets/icons/socialMedia/instagram.svg";
import { ReactComponent as LinkedInIcon } from "src/assets/icons/socialMedia/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "src/assets/icons/socialMedia/youtube.svg";
import { theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { useInformation } from "src/hooks/information";
import { useResponsive } from "src/hooks/responsive";
import { AuthenticationServices } from "src/services/auth";
import { IAuthLogin, IAuthResetPassword, ILogin, instanceOfIAuthLogin, instanceOfIAuthResetPassword } from "src/types";
import { LoadingButton } from "@mui/lab";

const Splash: React.FC = () => {
    const responsiveHooks = useResponsive();

    const [brandWidth, setBrandWidth] = useState<number>(605);

    useEffect(() => {
        if (responsiveHooks.lgUp) setBrandWidth(605);
        else if (responsiveHooks.mdUp) setBrandWidth(400);
        else if (responsiveHooks.xsUp) setBrandWidth(350);
    }, [responsiveHooks.lgUp, responsiveHooks.mdUp, responsiveHooks.xsUp]);

    //{responsiveHooks.mdUp && <Container absolute fluid flex sx={{ width: "100%", height: "100vh", background: theme.palette.primary.dark, opacity: 0.70, zIndex: 5 }} />}
    //{responsiveHooks.mdUp && <Container absolute fluid flex sx={{ width: "100%", height: "100vh", background: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} />}

    return (
        <Page>
            <Container fluid flex centered relative color={theme.palette.primary.main}>
                <Grid container sx={{ zIndex: 10 }}>
                    <Grid item xs={12} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <img src={brand} style={{ width: brandWidth, height: brandWidth / 3.01 }} />
                        <Spacing top={VERY_PADDING} />
                        <Container inline centered width="100%">
                            <IconButton href="https://www.linkedin.com/company/pixforce/mycompany/" target="_blank">
                                <LinkedInIcon />
                            </IconButton>
                            <IconButton href="https://www.youtube.com/@pixforce8146" target="_blank">
                                <YoutubeIcon />
                            </IconButton>
                            <IconButton href="https://www.facebook.com/pixforceoficial" target="_blank">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton href="https://www.instagram.com/pix_force/" target="_blank">
                                <InstagramIcon />
                            </IconButton>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
};

export default Splash;
