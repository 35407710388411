import { AxiosResponse } from "axios";
import api, { API_FINANCES_URL } from "src/services";
import CRUDBaseService from "src/services/base";
import { ProductProps, SubscriptionPlanPaymentSettingProps, SubscriptionPlanProps } from "src/types";
import { parseStringToDate } from "src/utils/dates";
import ProductService from "./products";

export default class SubscriptionPlanService extends CRUDBaseService<SubscriptionPlanProps> {
  constructor() {
    super(api, `${API_FINANCES_URL}/subscription_plans`);
  }

  override convertApiResponseToIEntity(_data: any): SubscriptionPlanProps {
    let product: ProductProps | undefined = undefined;
    if (_data.product) product = new ProductService().convertApiResponseToIEntity(_data.product);

    const paymentSettings: SubscriptionPlanPaymentSettingProps[] = [];
    for (let i = 0; i < _data.paymentSettings; i++) {
      paymentSettings.push(this.convertApiResponseToPaymentSettings(_data.paymentSettings));
    }

    return {
      id: _data.id,

      productId: _data.productId,
      product,
      name: _data.name,
      description: _data.description,
      maxRequests: _data.maxRequests,
      monthlyRequests: _data.monthlyRequests,
      priority: _data.priority,
      isTrial: _data.isTrial,
      useApi: _data.useApi,
      virtualSupport: _data.virtualSupport,
      customizedSupport: _data.customizedSupport,
      useApp: _data.useApp,
      unlimitedUsers: _data.unlimitedUsers,
      availableToPublic: _data.availableToPublic,
      paymentSettings: paymentSettings,
      paymentApiId: _data.paymentApiId,

      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }

  convertApiResponseToPaymentSettings(_data: any): SubscriptionPlanPaymentSettingProps {
    return {
      id: _data.id,

      subscriptionPlanId: _data.subscriptionPlanId,
      billingScheme: _data.billingScheme,
      recurringInterval: _data.recurringInterval,
      recurringIntervalCount: _data.recurringIntervalCount,
      recurringUsageType: _data.recurringUsageType,
      type: _data.type,
      unitAmount: _data.unitAmount,
      unitAmountDecimal: _data.unitAmountDecimal,
      tiersMode: _data.tiersMode,
      tiers: _data.tiers,
      currency: _data.currency,

      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
