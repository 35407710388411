import { FC } from "react";
import { Modal } from "@pix-force/react-atoms";

import DocumentMultiSelect from ".";
import { IDocumentMultiSelectModal } from "src/types";

const DocumentMultiSelectModal: FC<IDocumentMultiSelectModal> = (props: IDocumentMultiSelectModal) => {
    return (
        <Modal isOpened={props.isOpen} onClose={() => props.onClose()}>
            <DocumentMultiSelect {...props} />
        </Modal>
    );
}

export default DocumentMultiSelectModal;
