import { IEntity, IEntityContext } from "./entities";

interface IProduct extends IEntity {
  name: string;
  description?: string;
  categories: string;
  status: number;
  statusDesc?: string;
  usePaymentApi?: boolean;
  paymentApiId?: string;
  paymentApiName?: string;
}

interface IProductContext extends IEntityContext<IProduct> {}

export function isProduct(_data: any): _data is IProduct {
  return "name" in _data && "categories" in _data && "status" in _data;
}

export type { IProduct, IProductContext };
