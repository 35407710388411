import React, { FC, useEffect, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Moment } from "moment";
import { Button, Container, Spacing, Text, Input } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { useDocuments } from "src/hooks/documents";
import { IDocumentFilter } from "src/types";
import DatePicker from "src/components/atoms/datePicker";
import { DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import { unknownToMoment } from "src/utils/converters";


const DocumentsFilter: FC<IDocumentFilter> = (params: IDocumentFilter) => {
    const documentsHooks = useDocuments();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<number | null>(null);
    const [initialDate, setInitialDate] = useState<Moment | null>(null);
    const [finalDate, setFinalDate] = useState<Moment | null>(null);
    const [type, setType] = useState<string | null>(null);
    const [index, setIndex] = useState<string | null>(null);

    const idFilter = 1;
    const modelFilter = 2;

    useEffect(() => {
        handleQueryString();
    }, [initialDate, finalDate, type, index]);

    useEffect(() => {
        if (documentsHooks.queryString != "")
            documentsHooks.fetchDocumentsByClientId(params.clientId, documentsHooks.entitiesPaginated.page, parseInt(documentsHooks.entitiesPerRow))
    }, [documentsHooks.queryString]);

    const handleInitialDate = (event: unknown) => {
        const initialDate: Moment | null = unknownToMoment(event);
        if (initialDate == null) return;
        setInitialDate(initialDate);
    }

    const handleFinalDate = (event: unknown) => {
        const finalDate: Moment | null = unknownToMoment(event);
        if (finalDate == null) return;
        setFinalDate(finalDate);
    }

    const handleQueryString = async () => {
        let _queryString: string = '';

        if (initialDate)
            _queryString += `initialDate=${initialDate.toISOString().slice(0, 10)}&`;
        if (finalDate)
            _queryString += `finalDate=${finalDate.toISOString().slice(0, 10)}&`;
        if (type != null && selectedFilter == modelFilter)
            _queryString += `type=${type}&`;
        if (index && selectedFilter == idFilter)
            _queryString += `index=${index}&`;

        documentsHooks.setQueryString(_queryString);
    };

    const clearFilter = async () => {
        setInitialDate(null);
        setFinalDate(null);
        setType(null);
        setIndex(null);
    }


    return (
        <Grid container rowSpacing={1} columnSpacing={2} display="flex" flex="1" alignItems="flex-start">
            <Grid item md={3} xl={2}>
                <DatePicker label="Data inicial"
                    value={undefined}
                    onChange={(value: unknown, context: PickerChangeHandlerContext<DateValidationError>) => handleInitialDate(value)} />
            </Grid>
            <Grid item md={3} xl={2}>
                <DatePicker label="Data final"
                    value={undefined}
                    onChange={(value: unknown, context: PickerChangeHandlerContext<DateValidationError>) => handleFinalDate(value)} />
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
                <Container fluid flex width="100%">
                    {
                        selectedFilter && (
                            selectedFilter === idFilter ? (
                                <Input
                                    placeholder="Id"
                                    size="small"
                                    value={index}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        setIndex(event.target.value)
                                    }
                                    sx={{ width: '100%', borderColor: "#ECE9F1", borderRadius: "4px", maxHeight: 35 }}
                                />
                            ) : selectedFilter === modelFilter ? (
                                <Select
                                    sx={{ maxHeight: 35 }}
                                    id='document-filter-select'
                                    value={type}
                                    onChange={(event: any) => setType(event.target.value)}
                                >
                                    {documentsHooks.documentsTypes.map((_document) => {
                                        return <MenuItem value={_document.value}><Text>{_document.description}</Text></MenuItem>
                                    })}
                                </Select>
                            ) : null
                        )
                    }
                </Container>
            </Grid>
            <Grid item md={3} lg={2}>
                <Container fluid flex>
                    <Container inline>
                        <Container>
                            <Button variant="text" color={"#FFFFFF"} onClick={() => { setOpen(!open) }} sx={{ padding: 0 }}>
                                <Container inline flex flexStart horizontalCentered padded width="100%">
                                    <FilterListIcon fontSize="medium" sx={{ color: "#11263C" }} />
                                    <Spacing right={VERY_PADDING} />
                                    <Text size={15} color="#11263C">Filtros</Text>
                                </Container>
                            </Button>
                            <Collapse in={open} timeout="auto" unmountOnExit sx={{ maxWidth: 100 }}>
                                <Grid container flexDirection="column">
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="text" onClick={() => selectedFilter == idFilter ? setSelectedFilter(null) : setSelectedFilter(idFilter)} sx={{ padding: 0 }}>
                                            <Container inline flex flexStart horizontalCentered padded width="100%">
                                                <Container fluid inline spacedBetween flex>
                                                    <Container centered fluid>
                                                        <Text size={13} color="#11263C">ID</Text>
                                                    </Container>
                                                    <Checkbox size="small" checked={selectedFilter == idFilter} />
                                                </Container>
                                            </Container>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button fullWidth variant="text" onClick={() => selectedFilter == modelFilter ? setSelectedFilter(null) : setSelectedFilter(modelFilter)} sx={{ padding: 0 }}>
                                            <Container inline flex flexStart horizontalCentered padded width="100%">
                                                <Container fluid inline spacedBetween flex>
                                                    <Container centered fluid>
                                                        <Text size={13} color="#11263C">Modelo</Text>
                                                    </Container>
                                                    <Checkbox size="small" checked={selectedFilter == modelFilter} />
                                                </Container>
                                            </Container>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Container>
                        <Spacing right={VERY_PADDING} />
                        <Container maxHeight={40} fluid maxWidth={40} verticalCentered>
                            <IconButton key={0} size='small' onClick={() => clearFilter()} color="error" style={{ height: '18px' }}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Container>
                    </Container>
                </Container>
            </Grid>
        </Grid >
    )
}


export default DocumentsFilter;