import { FC } from 'react';
import { AppBar, Box, Drawer as MuiDrawer, IconButton, Toolbar } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import logo from "src/assets/logo_white.png"
import { theme } from "src/components"
import Drawer from 'src/components/molecules/v2/drawer';
import { useDrawer } from 'src/hooks/drawer';
import { IHeader } from 'src/types/components/molecules/v2/header';
import { drawerWidth } from '../drawer/styles';

/**
 * This component returns the header of the app
 * It uses mui styles
 * @component
 */
const Header: FC<IHeader> = (props: IHeader) => {
    // Drawer hooks that contain opening data
    const drawer = useDrawer();

    return (
        <AppBar color="primary" position="fixed" elevation={10}>
            <Toolbar variant="regular">
                <Box display="flex" flexDirection="row" width="100%" position="relative">
                    <Box display="flex" alignItems="center" position="absolute" top={-9}>
                        <IconButton size="large" onClick={() => drawer.setIsOpen(true)}>
                            <MenuIcon
                                fontSize="large"
                                sx={{ color: theme.palette.background.default }}
                            />
                        </IconButton>
                    </Box>

                    <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center">
                        <img src={logo} style={{ width: 90, height: 90 / 2.48 }} />
                    </Box>
                </Box>
            </Toolbar>

            <MuiDrawer
                anchor="left"
                open={drawer.isOpen} onClose={() => drawer.setIsOpen(false)}
                PaperProps={{ sx: { width: drawerWidth + 40 } }}>
                <Drawer>
                    <></>
                </Drawer>
            </MuiDrawer>
        </AppBar >
    )
}

export default Header;
