import React, { FC, useState } from "react";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Container, CustomTablePagination, Spacing, Table, Text } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import UsersItemCard from "../itemCard";
import { useResponsive } from "src/hooks/responsive";
import { useUsers } from "src/hooks/users"
import { UserProps, IUsersList } from "src/types";
import { Pagination, UsersRegisterModal } from "src/components";

const UsersList: FC<IUsersList> = (params: IUsersList) => {
    const { mdDown, screenHeight } = useResponsive();
    const { deleteEntity } = useUsers();
    const pageCount = Math.ceil((screenHeight * 0.6) / 120);
    const usersCount = params.users.length;

    const [currPage, setCurrPage] = useState<number>(0);

    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<UserProps | undefined>(undefined);

    const editUser = (_user: UserProps) => {
        setSelectedUser(_user);
        setIsRegisterModalOpen(true);
    }

    return (
        <Container fluid flex width="100%" height="80vh">
            <Container fluid width="100%" minHeight="75vh">
                {params.users.length == 0 && <Container fluid flex centered width="100%">
                    <Text>Nenhum usuário encontrado.</Text>
                </Container>
                }
                {params.users.slice((currPage * pageCount), ((currPage * pageCount) + pageCount)).map(item =>
                    <Container fluid width="100%" verticalPadded>
                        <UsersItemCard clientName={params.client?.name ?? ""} userId={item.id} onEdit={editUser} onRefresh={params.onRefresh} />
                    </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(usersCount / pageCount)} setPage={setCurrPage} />

            {mdDown && <Spacing top={VERY_PADDING * 1.5} />}

            <Fab color="primary" sx={{ position: "absolute", right: PADDING, bottom: VERY_PADDING }}
                onClick={() => setIsRegisterModalOpen(true)} >
                <AddIcon />
            </Fab>

            {
                params.client && params.client.id &&
                <UsersRegisterModal
                    isOpen={isRegisterModalOpen}
                    clientId={params.client.id}
                    id={selectedUser?.id}
                    user={selectedUser}
                    onClose={() => {
                        setSelectedUser(undefined);
                        params.onRefresh();
                        setIsRegisterModalOpen(false)
                    }} />
            }
        </Container >
    );
}

export default UsersList;