import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Container, Page, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import {
  API_TAB_INDEX,
  BILLINGS_TAB_INDEX,
  CLIENT_DESC_TAB_INDEX,
  DATALOG_TAB_INDEX,
  PAYMENT_TAB_INDEX,
  SUBSCRIPTION_TAB_INDEX,
  USERS_TAB_INDEX,
  MODEL_TAB_INDEX,
} from "./consts";
import {
  ClientsDetailedInfoCard,
  ClientsRegisterModal,
  theme,
  ClientsDetailedTab,
  ClientsDetailedBottomTab,
  DocumentsFilter,
  SubscriptionPlanChangeModal,
} from "src/components";
import { useApiKeys } from "src/hooks/apiKeys";
import { useAuth } from "src/hooks/auth";
import { useClients } from "src/hooks/clients";
import { useClientCards } from "src/hooks/clientsCards";
import { useConfirmation } from "src/hooks/confirmation";
import { useDocuments } from "src/hooks/documents";
import { useInformation } from "src/hooks/information";
import { useInvoices } from "src/hooks/invoices";
import { useModels } from "src/hooks/models";
import { useResponsive } from "src/hooks/responsive";
import { useSubscriptions } from "src/hooks/subscriptions";
import { IInvoice, IClient, IDocument, IModel, IClientCard, UserProps, SubscriptionProps, ApiKeyProps } from "src/types";
import { IClientsDetailed } from "src/types/pages/clients";
import ClientService from "src/services/financesApi/clients";

const ClientsDetailed: FC<IClientsDetailed> = (props: IClientsDetailed) => {
  const { id } = useParams();

  const { getByClientId } = useApiKeys();
  const auth = useAuth();
  const { fetchEntity } = useClients();
  const clientCardsHooks = useClientCards();
  const confirmationHooks = useConfirmation();
  const { currentDocumentFinished } = useDocuments();
  const informationHooks = useInformation();
  const invoicesHook = useInvoices();
  const modelsHook = useModels();
  const navigate = useNavigate();
  const { mdDown, mdUp } = useResponsive();
  const subscriptionsHooks = useSubscriptions();

  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [invoices, setInvoices] = useState<IInvoice[] | undefined>(undefined);
  const [apiKeys, setApiKeys] = useState<ApiKeyProps[]>([]);
  const [models, setModels] = useState<IModel[]>([]);
  const [currentTab, setCurrentTab] = useState<string>(mdDown ? CLIENT_DESC_TAB_INDEX : USERS_TAB_INDEX);
  const [client, setClient] = useState<IClient | undefined>(undefined);
  const [clientCards, setClientCards] = useState<IClientCard[]>([]);
  const [clientCardsLoading, setClientCardsLoading] = useState<boolean>(false);
  const [clientCardsCheckoutSessionId, setClientCardsCheckoutSessionId] = useState<string | undefined>(undefined);
  const [documents, setDocuments] = useState<IDocument[]>([]);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false);
  const [selectedClientId, setSelectedClientId] = useState<string | undefined>(undefined);
  const [subscription, setSubscription] = useState<SubscriptionProps | undefined>(undefined);
  const [subscriptionLoading, setSubscriptionLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionProps[] | undefined>(undefined);
  const [currentSubscription, setCurrentSubscription] = useState<SubscriptionProps | undefined>(undefined);
  const [subscriptionPlanChangeModalOpen, setSubscriptionPlanChangeModalOpen] = useState<boolean>(false);
  const [usersCount, setUsersCount] = useState<number>(-1);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [paymentFormCardsModalOpen, setPaymentFormCardsModalOpen] = useState<boolean>(false);
  const [paymentFormEditCardsModalOpen, setPaymentFormEditCardsModalOpen] = useState<boolean>(false);
  const [datalogCurrentTab, setDatalogCurrentTab] = useState<string>("0");
  const [changeSubscriptionRoutine, setChangeSubscriptionRoutine] = useState<boolean>(false);
  const [containsRequriedFields, setContainsRequiredFields] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
    fetchDocuments();

    let auxCurrentTab: String | null = new URLSearchParams(window.location.search).get("tab");
    if (auxCurrentTab == "payment") {
      setCurrentTab(PAYMENT_TAB_INDEX);
      let status: string | null = new URLSearchParams(window.location.search).get("status");
      if (status == "success") {
        let checkoutSessionId: string | null = new URLSearchParams(window.location.search).get("checkoutSessionId");
        let clientId: string | null = new URLSearchParams(window.location.search).get("clientId");
        if (checkoutSessionId && clientId) clientCardCheckoutSessionSuccess(clientId, checkoutSessionId);
      } else {
        informationHooks.showInformation("Ocorreu um erro ao salvar o cartão.");
      }
    } else {
      const changeSubscription: number = parseInt(new URLSearchParams(window.location.search).get("change_subscription") ?? "0");
      if (changeSubscription == 1) {
        setChangeSubscriptionRoutine(true);
        setCurrentTab(SUBSCRIPTION_TAB_INDEX);
        setSubscriptionPlanChangeModalOpen(true);
      }
      if (changeSubscription == 2) {
        setCurrentTab(PAYMENT_TAB_INDEX);
        setTimeout(() => {
          setChangeSubscriptionRoutine(true);
          setPaymentFormCardsModalOpen(true);
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (id) setClientId(id);
  }, [id]);

  useEffect(() => {
    if (auth.client && auth.client.id && !id) setClientId(auth.client.id);
  }, [auth.client]);

  useEffect(() => {
    fetchData();
  }, [clientId]);

  useEffect(() => {
    if (currentTab == USERS_TAB_INDEX) fetchUsers();
    else if (currentTab == DATALOG_TAB_INDEX) fetchDocuments();
    else if (currentTab == BILLINGS_TAB_INDEX) fetchInvoices();
    else if (currentTab == PAYMENT_TAB_INDEX) fetchPayments();
    else if (currentTab == API_TAB_INDEX) fetchApiKeys();
    else if (currentTab == MODEL_TAB_INDEX) fetchModels();
  }, [currentTab, clientId]);

  useEffect(() => {
    if (!currentDocumentFinished) return;

    const auxDocuments: IDocument[] = documents.map((item) => {
      if (item.id == currentDocumentFinished.id) return currentDocumentFinished;
      return item;
    });
    setDocuments(auxDocuments);
  }, [currentDocumentFinished]);

  const checkRequiredFields = (_client: IClient) => {
    const requiredFields: any[] = [
      _client.cpfCnpj,
      _client.cityName,
      _client.country,
      _client.street,
      _client.postalCode,
      _client.number,
      _client.district,
    ];
    const containsNull: boolean = requiredFields.some((value) => value === null);
    return containsNull;
  };

  const handleChangeDatalogCurrentTab = (event: React.SyntheticEvent, newValue: string) => {
    setDatalogCurrentTab(newValue);
  };

  const fetchData = async () => {
    if (!clientId) return;

    const auxClient: IClient = await fetchEntity(clientId);
    setClient(auxClient);

    setSubscriptionLoading(true);
    setSubscription(auth.subscription);
    setSubscriptionLoading(false);
  };

  const fetchUsers = async () => {
    if (!clientId) return;

    const auxUsers: UserProps[] = await new ClientService().getUsers(clientId);
    setUsers(auxUsers);
  };

  const fetchDocuments = async () => {
    if (!clientId) return;

    // const auxDocumentsPaginated: IEntityPaginated<IDocument> = await fetchDocumentsByClientId(clientId);
    // setDocuments([...auxDocumentsPaginated.items]);
  };

  const fetchApiKeys = async () => {
    if (!clientId) return;

    const auxApiKeys: ApiKeyProps[] = await getByClientId(clientId);
    setApiKeys(auxApiKeys);
  };

  const fetchModels = async () => {
    if (!id) return;

    const auxModels: IModel[] = await modelsHook.fetchClientModels(id);
    setModels(auxModels);
  };

  const fetchInvoices = async () => {
    if (!clientId) return;

    const auxInvoices: IInvoice[] | undefined = await invoicesHook.fetchInvoicesByClient(clientId);
    setInvoices(auxInvoices);
  };

  const fetchPayments = async (_openClientCardsModal?: boolean, _clientId?: string) => {
    fetchInvoices();

    const auxClientId: string | undefined = _clientId ?? clientId;
    if (!auxClientId) return;

    setClientCardsLoading(true);
    const auxClientsCards: IClientCard[] = await clientCardsHooks.fetchByClientId(auxClientId);
    setClientCards(auxClientsCards);
    setClientCardsLoading(false);

    if (_openClientCardsModal) setPaymentFormCardsModalOpen(true);
  };

  const clientCardCheckoutSessionSuccess = async (_clientId: string, _checkoutSessionId: string) => {
    await clientCardsHooks.checkoutSessionSuccess(_checkoutSessionId);

    fetchPayments(true, _clientId);
  };

  const handleChangeCurrentTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const editInformations = (_clientId: string) => {
    setSelectedClientId(_clientId);
    setIsRegisterModalOpen(true);
  };

  const onCloseRegisterModal = () => {
    fetchData();
    setIsRegisterModalOpen(false);
  };

  const changePlan = (_subscriptionId: string) => {
    if (_subscriptionId !== subscription?.id) return;

    setSubscriptionPlanChangeModalOpen(true);
  };

  const onSubscriptionPlanChangeClose = (_requireRefresh?: boolean) => {
    setSubscriptionPlanChangeModalOpen(false);
    if (_requireRefresh) {
      fetchData();

      if (subscription && changeSubscriptionRoutine) {
        setCurrentTab(PAYMENT_TAB_INDEX);
        changePaymentForm(subscription, (_collectionMethod: string) => {
          if (_collectionMethod == "charge_automatically") {
            setPaymentFormCardsModalOpen(true);
          } else {
            if (client) {
              const _containsRequriedFields: boolean = checkRequiredFields(client);
              setContainsRequiredFields(_containsRequriedFields);
            }
          }
        });
      }
    }
  };

  const changePaymentForm = (_subscription: SubscriptionProps, _callback?: Function) => {
    const changePaymentFormCallback = async (_collectionMethod: string) => {
      if (!_subscription.id) return;

      let payload: SubscriptionProps = {
        ..._subscription,
        subscriptionPlanId: "",
        collectionMethod: _collectionMethod,
      };
      const auxSubscription: SubscriptionProps = await subscriptionsHooks.editEntity(_subscription.id, payload);
      fetchData();
      fetchPayments();
      confirmationHooks.hideConfirmation();
    };

    confirmationHooks.showConfirmation(
      "Alterar forma de pagamento para:",
      undefined,
      "Boleto",
      "Cartão de Crédito",
      () => {
        changePaymentFormCallback("send_invoice");
        if (_callback) _callback("send_invoice");
      },
      () => {
        changePaymentFormCallback("charge_automatically");
        if (_callback) _callback("charge_automatically");
      }
    );
  };

  return (
    <Page width="100%" height="98vh">
      {mdUp && (
        <Container fluid flex flexGrow veryPadded height="100vh">
          <Spacing top={VERY_PADDING} />
          <Grid container rowSpacing={2} columnSpacing={2} display="flex" padding={2}>
            <Grid item xs={3} display="flex" flexDirection="row">
              {!props.isOnMyAccount && (
                <IconButton color="primary" onClick={() => navigate(-1)}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              <Spacing left={VERY_PADDING * 2} />
              <Text
                size={22}
                color={theme.palette.primary.main}
                style={{ width: "fit-content", borderBottom: `solid 3px ${theme.palette.primary.main}` }}
              >
                {props.isOnMyAccount ? "Minha conta" : client && client.name}
              </Text>
            </Grid>

            <Grid item xs={8}>
              {currentTab == DATALOG_TAB_INDEX && client && client.id && <>{datalogCurrentTab == "0" && <DocumentsFilter clientId={client.id} />}</>}
            </Grid>

            <Grid item xs={12} md={3} display="flex" flex="1" alignItems="flex-start" justifyContent="center">
              <ClientsDetailedInfoCard
                client={client}
                subscription={subscription}
                onEditInformationsCallback={editInformations}
                onChangeTabToDatalog={() => setCurrentTab(DATALOG_TAB_INDEX)}
              />
            </Grid>
            <Grid item xs={12} md={9} display="flex" flexDirection="column" flex="1">
              <ClientsDetailedTab
                currentTab={currentTab}
                onHandleChangeCurrentTab={handleChangeCurrentTab}
                users={users}
                onRefreshUsers={() => fetchUsers()}
                client={client}
                clientCardsLoading={clientCardsLoading}
                clientCards={clientCards}
                onPaymentFormAddCards={() => setPaymentFormCardsModalOpen(true)}
                documents={documents}
                onRefreshDocuments={() => fetchDocuments()}
                subscription={subscription}
                subscriptionLoading={subscriptionLoading}
                onRefreshSubscription={() => null}
                invoices={invoices}
                onRefreshInvoices={() => fetchInvoices()}
                apiKeys={apiKeys}
                onRefreshApiKeys={() => fetchApiKeys()}
                models={models}
                onRefreshModels={() => fetchModels()}
                onChangePaymentForm={(_subscription: SubscriptionProps) => changePaymentForm(_subscription)}
                datalogCurrentTab={datalogCurrentTab}
                handleChangeDatalogCurrentTab={handleChangeDatalogCurrentTab}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      {mdDown && (
        <Container fluid flex relative>
          <Container
            fluid
            veryPadded
            width="100%"
            color={theme.palette.background.default}
            sx={{ position: "fixed", top: VERY_PADDING * 1.5, zIndex: 10 }}
          >
            <Spacing top={VERY_PADDING} />
            <Container inline>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIosIcon color="primary" />
              </IconButton>
              <Spacing left={PADDING} />

              {client ? (
                <Text
                  size={22}
                  color={theme.palette.primary.main}
                  style={{
                    width: "fit-content",
                    borderBottom: `solid 3px ${theme.palette.primary.main}`,
                  }}
                >
                  {client.name}
                </Text>
              ) : (
                <Skeleton variant="rectangular" width={200} height={40} />
              )}
            </Container>
          </Container>
          <Spacing top={VERY_PADDING * 3.5} />
          <ClientsDetailedBottomTab
            currentTab={currentTab}
            onHandleChangeCurrentTab={handleChangeCurrentTab}
            usersCount={usersCount}
            onEditInformations={editInformations}
            onChangeTabToDatalog={() => setCurrentTab(DATALOG_TAB_INDEX)}
            users={users}
            onRefreshUsers={() => fetchUsers()}
            client={client}
            clientCardsLoading={clientCardsLoading}
            clientCards={clientCards}
            onPaymentFormAddCards={() => setPaymentFormCardsModalOpen(true)}
            documents={documents}
            onRefreshDocuments={() => fetchDocuments()}
            subscription={subscription}
            onRefreshSubscription={() => null}
            subscriptionLoading={subscriptionLoading}
            invoices={invoices}
            onRefreshInvoices={() => fetchInvoices()}
            apiKeys={apiKeys}
            onRefreshApiKeys={() => fetchApiKeys()}
            models={models}
            onRefreshModels={() => fetchModels()}
            onChangePaymentForm={(_subscription: SubscriptionProps) => changePaymentForm(_subscription)}
          />
        </Container>
      )}

      <ClientsRegisterModal
        id={selectedClientId}
        client={client}
        subscription={subscription}
        isOpen={isRegisterModalOpen}
        onClose={() => onCloseRegisterModal()}
      />
      <ClientsRegisterModal
        id={client?.id}
        client={client}
        isOpen={containsRequriedFields}
        onClose={() => (window.location.href = "/my-account")}
        isAddingRequiredFields={true}
      />
    </Page>
  );
};

export default ClientsDetailed;
