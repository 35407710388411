import React, { FC, createContext, useState, useContext } from 'react'

import { IDrawerContext, IHookProvider } from '../types'

const DrawerContext = createContext<IDrawerContext>({} as IDrawerContext);

export const DrawerProvider: FC<IHookProvider> = (params: IHookProvider) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <DrawerContext.Provider value={{ isOpen, setIsOpen }}>
            {params.children}
        </DrawerContext.Provider>
    );
}

export function useDrawer() {
    const context = useContext(DrawerContext);

    if (!context) {
        throw new Error('useDrawer must be used within an DrawerProvider')
    }

    return context;
}
