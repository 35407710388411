import React, { FC, createContext, useContext, useState } from "react";

import { InvoiceServices } from "src/services/invoices";
import { IInvoice, IInvoiceContext, IInvoiceDocumentCount, IHookProvider } from "../types";

const InvoicesContext = createContext<IInvoiceContext>({} as IInvoiceContext);

export const InvoicesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const invoiceServices = new InvoiceServices();
    const [entities, setInvoices] = useState<IInvoice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchEntity, setSearchEntity] = useState<string>("");
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    const [paginate, setPaginate] = useState<number>(0);

    const createNewEntity = async (_invoice: IInvoice) => {
        try {
            const invoice = await invoiceServices.createEntity(
                _invoice
            );
            fetchEntities();
            return invoice;
        } catch (_err) {
            throw _err;
        }
    };

    const editEntity = async (_id: string, _invoice: IInvoice) => {
        try {
            const invoice = await invoiceServices.updateEntity(
                _id,
                _invoice
            );
            fetchEntities();
            return invoice;
        } catch (_err) {
            throw _err;
        }
    };

    const deleteEntity = async (_id: string) => {
        try {
            const invoice = await invoiceServices.destroyEntity(_id);
            fetchEntities();
            return invoice;
        } catch (_err) {
            throw _err;
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        try {
            const invoices: IInvoice[] =
                await invoiceServices.getEntities();
            setInvoices([...invoices]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchEntity = async (_id: string) => {
        const invoice: IInvoice = await invoiceServices.getEntity(
            _id
        );
        return invoice;
    };

    const fetchInvoicesByClient = async (_clientId: string) => {
        try {
            const invoices: IInvoice[] = await invoiceServices.getInvoicesByClient(_clientId);
            return invoices;
        } catch (_err) {
            console.log(_err);
        }
    };

    const fetchInvoiceDocumentsCount = async (_id: string) => {
        try {
            const documents: IInvoiceDocumentCount[] = await invoiceServices.getInvoiceDocumentsCount(_id);
            return documents;
        } catch (_err) {
            console.log(_err);
        }
    }

    return (
        <InvoicesContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                fetchInvoicesByClient,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,

                fetchInvoiceDocumentsCount
            }}
        >
            {_params.children}
        </InvoicesContext.Provider>
    );
};

export function useInvoices() {
    const context = useContext(InvoicesContext);

    if (!context) {
        throw new Error(
            "useInvoices must be used within an InvoicesProvider"
        );
    }

    return context;
}

export function getDocumentDesc(_type: number) {
    if (_type == 0)
        return "CNH"
    else if (_type == 1)
        return "CRLV"
    else if (_type == 2)
        return "CIPP"
    else if (_type == 3)
        return "NR20"
    else if (_type == 4)
        return "NR35"
    else if (_type == 5)
        return "MOPP"
    else if (_type == 6)
        return "CIV"
    else if (_type == 7)
        return "Certificado"
    else if (_type == 8)
        return "ANTT"
    else if (_type == 9)
        return "CCT"
    else if (_type == 10)
        return "CTF"
    else if (_type == 11)
        return "CVT"
    else if (_type == 12)
        return "Ibama"
    else if (_type == 13)
        return "Comprovante de Residência"
    else if (_type == 14)
        return "Desconhecido"
    else if (_type == 99)
        return "Personalizado"
}
