export const copyToClipboard = async (text: string) => {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    const a = document.execCommand("copy");
    document.body.removeChild(textArea);
  }
};

export function getValueByPath(obj: any, path: string) {
  const attributes = path.split(".");

  // Iterates over the attributes to access the nested object
  let currentValue = obj;
  for (const attribute of attributes) {
    if (currentValue && typeof currentValue === "object" && attribute in currentValue) {
      currentValue = currentValue[attribute];
    } else {
      // If the attribute is not found, returns undefined
      return undefined;
    }
  }

  return currentValue;
}

export function setValueByPath(obj: any, path: string, newValue: any) {
  const attributes = path.split(".");
  let currentObject = obj;

  // Iterates over the attributes to access the nested object
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];

    if (i === attributes.length - 1) {
      // Last attribute, set the new value
      currentObject[attribute] = newValue;
    } else {
      // Not the last attribute, continue accessing the nested object
      if (!currentObject[attribute] || typeof currentObject[attribute] !== "object") {
        currentObject[attribute] = {};
      }
      currentObject = currentObject[attribute];
    }
  }
}

export function replicateObjectWithValues(originalObject: any, value: any = false) {
  const replicatedObject = { ...originalObject };
  for (const key in replicatedObject) {
    if (replicatedObject.hasOwnProperty(key)) {
      replicatedObject[key] = value;
    }
  }

  return replicatedObject;
}
