import React, { FC, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Paper, Tabs, Tab } from '@mui/material';

import { IMenuTabSelect, IMenuTabSelectHeader } from "src/types";
import { IMenuTabSelectItem, IMenuTabSelectOptionChildren } from "src/types/components/atoms/menuTabSelect";

/**
 * This component returns the menu tab select header
 * It uses mui styles
 * @component
 */
const MenuTabSelectHeader: FC<IMenuTabSelectHeader> = (props: IMenuTabSelectHeader) => {
    /**
     * This function handle the tab selected
     * @function
     */
    const handleChangeMenuTabSelectTab = (event: React.SyntheticEvent, newValue: number) => {
        props.handleChangeMenuTabSelectTab(newValue);
    };

    return (
        <Tabs indicatorColor="primary"
            value={props.menuSelectTab} onChange={handleChangeMenuTabSelectTab}>
            {props.tabs.map((item: string, index: number) => <Tab key={item} label={item} value={index} sx={{ textTransform: "initial" }} />)}
        </Tabs>
    )
}

/**
 * This component returns the menu tab select
 * It uses mui styles
 * @component
 */
const MenuTabSelect: FC<IMenuTabSelect> = (props: IMenuTabSelect) => {
    // State for selected tab
    const [menuSelectTab, setMenuSelectTab] = useState<number>(props.defaultTab ?? 0);
    // State for selected value
    const [menuSelectedValue, setMenuSelectedValue] = useState<string>("");

    /**
     * Reflects every change of selected tab
     * When the tab is changed, the selected value is empty
     * @effect
     */
    useEffect(() => {
        setMenuSelectedValue("");
    }, [menuSelectTab]);

    /**
     * Function that returns paper style
     * @effect
     */
    const paperStyle = () => {
        return {
            backgroudColor: props.paperBackgroundColor ?? "white",
            width: props.paperWidth ?? "100%"
        }
    }

    /**
     * Function that returns menu style
     * @effect
     */
    const menuStyle = () => {
        return {
            maxHeight: props.menuMaxHeight ?? "400px",
        }
    }

    /**
     * Function that handle when select is opened
     * @effect
     */
    const handleOnOpenSelect = (event: React.SyntheticEvent<Element, Event>) => {
        if (menuSelectedValue === "")
            setMenuSelectTab(props.defaultTab ?? 0);
    };

    /**
     * Function that handle the value selected
     * @effect
     */
    const handleChangeValue = (event: SelectChangeEvent<unknown>) => {
        setMenuSelectedValue(event.target.value as string);
        props.onChangeOption(menuSelectTab, event.target.value as string);
    };

    return (
        <Paper sx={paperStyle()}>
            <FormControl fullWidth size="medium">
                <InputLabel id="menu-select-label">
                    {props.label}
                </InputLabel>

                <Select size="medium" labelId="menu-select-label" id="menu-select"
                    onOpen={handleOnOpenSelect}
                    value={menuSelectedValue} onChange={handleChangeValue}
                    {...props}
                    MenuProps={{ sx: menuStyle() }}
                    inputProps={{
                        sx: {
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }
                    }}
                    sx={{ height: "48px" }}
                    renderValue={(value: unknown) => {
                        const selectedOptions = props.options[menuSelectTab];
                        const selectedValue = selectedOptions.find(item => item.value == value);
                        return <MenuItem value={selectedValue?.value}>{selectedValue?.label}</MenuItem>
                    }}
                >
                    <MenuTabSelectHeader muiSkipListHighlight
                        menuSelectTab={menuSelectTab} handleChangeMenuTabSelectTab={setMenuSelectTab}
                        tabs={props.tabs} />

                    {props.options.map((items: IMenuTabSelectItem[], index: number) => {
                        if (menuSelectTab !== index) return null;
                        if (props.optionsChildren?.[index]) {
                            const children: IMenuTabSelectOptionChildren = props.optionsChildren[index];
                            return items.map(item => children(item));
                        }
                        return items.map((item, index) => <MenuItem key={item.value} sx={{ height: "48px", border: "solid 1px purple" }} value={item.value}>{item.label}</MenuItem>);
                    })}
                </Select>
            </FormControl>
        </Paper>
    )
}

export default MenuTabSelect;
