import React, { FC, useEffect, useState } from "react";
import { Container, Modal } from "@pix-force/react-atoms";

import ClientsRegister from ".";
import { IClientsRegisterModal } from "src/types";

const ClientsRegisterModal: FC<IClientsRegisterModal> = (params: IClientsRegisterModal) => {
    return (
        <Modal isOpened={params.isOpen} onClose={() => params.onClose()}>
            <ClientsRegister id={params.id} client={params.client} subscription={params.subscription} onClose={() => params.onClose()} isAddingRequiredFields ={params.isAddingRequiredFields} />
        </Modal>
    )
}

export default ClientsRegisterModal;
