import { FC, useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import {
    Add as AddIcon,
    DeleteOutlineOutlined as DeleteIcon,
    Visibility, VisibilityOff
} from '@mui/icons-material';

import RegisterTemplate from "src/components/templates/registerTemplate";
import theme from "src/components/theme";
import { useAuth } from "src/hooks/auth";
import { useDocumentsCustomsModels } from "src/hooks/documentsCustomsModels";
import { useFeedback } from "src/hooks/feedback";
import { IDocumentCustomModel, IDocumentCustomModelField, IPrivilege, UserProps } from "src/types";
import { IUsersRegister } from "src/types/components/molecules/v2/users";
import { useForm } from "react-hook-form";
import { useUsers } from "src/hooks/users";
import { usePrivileges } from "src/hooks/privileges";
import { useClients } from "src/hooks/clients";
import PrivilegesSelect from "src/components/molecules/privileges/select";
import { Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import InputAdornmentPrivilege from "src/components/atoms/inputsAdornments/privilege";

const UsersRegister: FC<IUsersRegister> = (props: IUsersRegister) => {
    // Const about edit mode
    const edit: boolean = props.payload !== undefined;

    // Auth hooks to get client information
    const auth = useAuth();
    // Clients hooks
    const clients = useClients();
    // Form hooks
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    // Information hooks for user feedback
    const feedback = useFeedback();
    // Privileges hooks
    const privileges = usePrivileges();
    // Users hook
    const users = useUsers();

    // State for loading control
    const [loading, setLoading] = useState<boolean>(false);
    // State for name
    const [name, setName] = useState<string>("");
    // State for name error status
    const [nameError, setNameError] = useState<string>("");
    // State for email
    const [email, setEmail] = useState<string>("");
    // State for email error status
    const [emailError, setEmailError] = useState<string>("");
    // State for password
    const [password, setPassword] = useState<string>("");
    // State for password error status
    const [passwordError, setPasswordError] = useState<string>("");
    // State for password show status
    const [showPassword, setShowPassword] = useState<boolean>(false);
    // State for user current privilege
    const [userPrivilegeId, setUserPrivilegeId] = useState<string | undefined>(undefined);
    // State for privilege
    const [privilege, setPrivilege] = useState<any>(undefined);
    // State for privilege name of the privilege selected
    const [privilegeName, setPrivilegeName] = useState<string>("");
    // State for privilege error status
    const [privilegeError, setPrivilegeError] = useState<string | undefined>(undefined);

    /**
     * Component inits
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Handle the change payload in props
     * @effect
     */
    useEffect(() => {
        if (edit) loadEditFields();
    }, [props.payload]);

    /**
     * Handle the change payload in props
     * @effect
     */
    useEffect(() => {
        if (edit) loadEditFields();
    }, [privileges.privileges]);

    /**
     * Function that fetch necessary data
     * @function
     */
    const fetchData = async () => {
        privileges.fetchPrivileges();
    };

    /**
     * Function that load edit fields
     * @function
     */
    const loadEditFields = async () => {
        setLoading(true);
        if (props.payload && props.payload.id) {
            if (props.payload.lastName) {
                setName(`${props.payload.firstName} ${props.payload.lastName}`)
                setValue("name", `${props.payload.firstName} ${props.payload.lastName}`);
            }
            else {
                setName(props.payload.firstName)
                setValue("name", props.payload.firstName);
            }

            setEmail(props.payload.email);
            setValue("email", props.payload.email);

            const auxPrivilege: IPrivilege[] = await users.fetchUserGroups(props.payload.id);
            for (let i = 0; i < auxPrivilege.length; i++) {
                const auxPrivilegeEntity: IPrivilege | undefined = privileges.privileges.find(item => item.id == auxPrivilege[i].id);
                if (auxPrivilegeEntity) {
                    setUserPrivilegeId(auxPrivilege[0].id);
                    setPrivilege(auxPrivilegeEntity.name);
                    setPrivilegeName(auxPrivilege[0].name);
                    break;
                }
            }
        } else {
            props.onCancel();
            setLoading(false);
            feedback.open(true, "error", "Erro", undefined, "Ocorreu um erro ao editar o usuário.");
        }
        setLoading(false);
    };

    /**
     * Handle the change text of setText passed on args
     * @function
     */
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => {
        setText(event.target.value);
    }

    /**
     * Validation routine to check fields
     * @function
     */
    const validation = () => {
        let isValid: boolean = true;

        if (privilege === undefined) {
            isValid = false;
            setPrivilegeError("Informe o privilégio");
        }
        return isValid;
    };

    /**
     * Sends request to save model in back-end
     * @function
     */
    const save = async () => {
        try {
            if (!auth.client || !auth.client.id) return;
            if (!validation()) return;

            setLoading(true);
            let names: string[] = name.split(" ");

            let payload: UserProps = {
                firstName: names.shift() ?? "",
                lastName: names.join(" "),
                username: email,
                email,
                enabled: true,
                emailVerified: false
            };
            if (password) {
                payload.credentials = [
                    {
                        type: "password",
                        value: password,
                        temporary: false
                    }
                ]
            }

            let informationMessage: string = "Usuário cadastrado com sucesso!";
            if (edit && props.payload && props.payload.id) {
                await users.editEntity(props.payload.id, payload);
                if (props.payload) {
                    if (userPrivilegeId && userPrivilegeId != privilege.id) {
                        await users.removeGroup(props.payload.id, userPrivilegeId);
                        await users.addGroup(props.payload.id, privilege.id);
                    }
                }
                informationMessage = "Usuário alterado com sucesso!";
            } else {
                const auxUser: UserProps = await users.createNewEntity(payload, false);
                if (auxUser.id) {
                    await clients.createClientHasUser(auth.client.id, auxUser.id);
                    await users.addGroup(auxUser.id, privilege.id);
                }
            }
            props.onCancel();
            setLoading(false);
            feedback.open(true, "success", "Sucesso", undefined, informationMessage, undefined, false,
                ["Continuar"], ["contained"], [() => {
                    cleanData();
                    feedback.close();
                }]);
        } catch (err: any) {
            props.onCancel();
            setLoading(false);
            feedback.open(true, "error", "Erro", undefined, err.message as string);
        }
    }

    /**
     * Cleans all data fields
     * @function
     */
    const cleanData = () => {
        setName("");
        setEmail("");
        setPassword("");
        setShowPassword(false);
        setPrivilege(undefined);
        setPrivilegeName("");
        setPrivilegeError(undefined);
    };

    return (
        <RegisterTemplate
            title={edit ? "Editar usuário" : "Novo usuário"}
            cancelClick={() => props.onCancel()}
            okClick={() => save()}>
            <Grid container display="flex" rowSpacing="24px" sx={{
                maxHeight: "60vh",
                overflowY: "auto"
            }}>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="Nome"
                        placeholder="Nome do usuário"
                        value={name}
                        error={nameError !== ""} helperText={nameError}
                        {...register("name", {
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName),
                            required: "Informe o nome do usuário",
                        })} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="password" variant="outlined" size="small" disabled={loading}
                        label="Senha"
                        placeholder="Senha do usuário"
                        value={password}
                        error={errors.password != undefined}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: <IconButton
                                size="small"
                                aria-label="passw-togle-visibility"
                                onClick={() => { setShowPassword(!showPassword) }}
                                edge="end"                        >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>,
                        }}
                        FormHelperTextProps={{
                            sx: { position: 'absolute', left: "-12px", top: '-20px', fontSize: "12px", fontWeight: 400 },
                        }}
                        sx={{ width: "100%" }}
                        {
                        ...register("password", {
                            onChange: (
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChangeText(event, setPassword),
                            required: edit ? undefined : "Informe a senha do usuário",
                            pattern: {
                                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:;<>,.?/~`]).{8,}$/i,
                                message: `Senha fraca. 
                                            Ela deve conter:
                                            uma letra maiúscula,
                                            uma letra minúscula,
                                            um dígito,
                                            um caractere especial
                                            e que tenha pelo menos 8 caracteres
                                            `,
                            }
                        })
                        } />
                </Grid>
                <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth size="small"
                        label="E-mail"
                        placeholder="E-mail do usuário"
                        value={email}
                        error={emailError !== ""} helperText={emailError}
                        {
                        ...register("email", {
                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setEmail),
                            required: "Informe o e-mail do usuário",
                            pattern: {
                                value:
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,14}$/i,
                                message: "Informe um endereço de e-mail válido",
                            },
                        })} />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        value={privilege}
                        onChange={(event: any, newValue: string | null) => {
                            if (newValue) {
                                const auxPrivilege: IPrivilege | undefined = privileges.privileges.find(item => item.name == newValue)
                                if (auxPrivilege) setPrivilege(auxPrivilege);
                            }
                        }}
                        inputValue={privilegeName}
                        onInputChange={(event: any, newValue: string | null) => {
                            if (event) {
                                if (newValue) setPrivilegeName(newValue);
                                else setPrivilegeName("");
                            }
                        }}
                        defaultValue={privilege}
                        options={privileges.privileges.filter(item => {
                            if (!auth.isSuperAdmin() && item.name == "super-admin")
                                return false;
                            return true;
                        }).map(item => item.name)}
                        placeholder="Privilégio" disabled={loading}
                        renderInput={(params) => <TextField {...params} label="Privilégio"
                            error={privilegeError !== undefined} helperText={privilegeError} />}
                    />
                </Grid>
            </Grid>
        </RegisterTemplate>
    )
}

export default UsersRegister;