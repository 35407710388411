import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import theme from "src/components/theme";
import { ICRUDDataGrid } from "src/types/components/molecules/v2/crud";

/**
 * This component returns an CRUD Data Grid customized
 * It uses mui styles
 * @component
 */
const CRUDDataGrid: FC<ICRUDDataGrid> = (props: ICRUDDataGrid) => {
    /**
     * Function that returns ReactNode about data grid without rows
     * @function
     */
    const nowRowsOverlay = () => {
        return (
            <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center"
                width="100%" padding="20px">
                <Typography color={theme.palette.primary.main}
                    fontSize="16px" fontStyle="normal" fontWeight="400"
                    lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                    Nenhum registro encontrado.
                </Typography>
            </Box>
        )
    }

    return (
        <DataGrid {...props}
            checkboxSelection={props.checkboxSelection === undefined ? true : props.checkboxSelection}
            disableRowSelectionOnClick disableColumnSelector
            slots={{
                noRowsOverlay: nowRowsOverlay
            }}
            sx={{
                minHeight: "200px",
                border: "hidden",
                outline: "none",
                "& .MuiDataGrid-columnHeaders": {
                    outline: "none",
                    border: "none",
                    backgroundColor: `var(--M3-sys-light-surface, ${theme.palette.backgroundElements.main})`
                },
                "& .MuiDataGrid-columnHeaders:focus": {
                    outline: "none",
                    border: "none",
                    backgroundColor: `var(--M3-sys-light-surface, ${theme.palette.backgroundElements.main})`
                },
                "& .MuiDataGrid-filterForm": {
                    backgroundColor: `var(--M3-sys-light-surface, ${theme.palette.backgroundElements.main})`
                }
            }}
        />
    );
}

export default CRUDDataGrid;
