import { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import {
  DeleteOutlineOutlined as DeleteIcon,
  FileUploadOutlined as FileUploadIcon,
} from "@mui/icons-material";

import { IDragDropFiles } from "src/types/components/molecules/v2/dragDropFiles";
import theme from "src/components/theme";
import DocumentsPreview from "../documents/preview";

/**
 * This component returns the drag and drop files routine
 * @component
 */
const DragDropFiles: FC<IDragDropFiles> = (props: IDragDropFiles) => {
  /**
   * Function that uses callback from onDrop trigger
   * @callback
   */
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      props.setFiles([...props.files, ...acceptedFiles]);
    },
    [props.files]
  );

  // Dropzone hooks for drop routine
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: props.loading,
    // accept: { accept: ['*'] }, // Define o tipo de arquivo que você deseja aceitar
  });

  /**
   * Function that handle the remove file routine
   * @function
   */
  const removeFile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    _index: number
  ) => {
    event?.stopPropagation();

    const auxFiles: any[] = [];
    props.files.forEach((element: any, index: number) => {
      if (_index != index) auxFiles.push(element);
    });
    props.setFiles(auxFiles);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      flexGrow={1}
      height={props.height ?? defaultDragDropFilesHeight}
      border="2px dashed var(--M3-sys-light-outline, #74777F)"
      sx={{
        backgroundColor: theme.palette.backgroundElements.main,
      }}
    >
      <div
        {...getRootProps()}
        style={{
          display: "flex",
          flex: 1,
          flexGrow: 1,
          cursor: props.files.length > 0 ? "default" : "pointer",
          height: props.height ?? defaultDragDropFilesHeight,
        }}
      >
        <input {...getInputProps()} />
        {props.files.length == 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              color={theme.palette.text.secondary}
              fontSize="16px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="24px"
              letterSpacing="0.5px"
            >
              Arraste seus arquivos aqui, ou utilize o botão abaixo.
            </Typography>

            <Box paddingTop="24px">
              <Button startIcon={<FileUploadIcon color="primary" />}>
                <Typography
                  color={theme.palette.primary.main}
                  fontSize="16px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="24px"
                  letterSpacing="0.5px"
                  textTransform="initial"
                >
                  Carregar documentos
                </Typography>
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            flexGrow={1}
            paddingX="16px"
            paddingY="24px"
            width="100px"
          >
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              flexGrow={1}
              onClick={(event) =>
                props.files.length > 0 && event.stopPropagation()
              }
              sx={{ overflowY: "auto" }}
            >
              {props.files.map((file: any, index: number) => {
                const firstOne: boolean = index === 0;
                const lastOne: boolean = index === props.files.length - 1;

                return (
                  <Box
                    position="relative"
                    display="flex"
                    alignItems="center"
                    width="100%"
                    maxHeight="46px"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      borderTopLeftRadius: firstOne ? "6px" : "0px",
                      borderTopRightRadius: firstOne ? "6px" : "0px",
                      borderBottomLeftRadius: lastOne ? "6px" : "0px",
                      borderBottomRightRadius: lastOne ? "6px" : "0px",
                    }}
                  >
                    <Grid
                      container
                      paddingX="16px"
                      paddingY="4px"
                      rowSpacing="0px"
                    >
                      <Grid item xs={9.5} display="flex" alignItems="center">
                        <Typography
                          noWrap
                          color={theme.palette.text.secondary}
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="24px"
                          letterSpacing="0.5px"
                        >
                          {file.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <Typography
                          color={theme.palette.text.secondary}
                          fontSize="16px"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="24px"
                          letterSpacing="0.5px"
                          noWrap
                        >
                          {(file.size / 1000).toFixed(0)} kb
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                      >
                        <IconButton
                          size="small"
                          onClick={(event) => removeFile(event, index)}
                          disabled={props.loading}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {!lastOne && (
                      <Box
                        position="absolute"
                        left={0}
                        bottom={0}
                        width="100%"
                        height="1px"
                        sx={{
                          backgroundColor:
                            theme.palette.backgroundStrokeActive.light,
                        }}
                      />
                    )}
                  </Box>
                );
              })}
            </Box>

            {props.children}
          </Box>
        )}
      </div>
    </Box>
  );
};

export default DragDropFiles;

const defaultDragDropFilesHeight: string = "250px";
