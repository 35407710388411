import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import {
  AddCircleOutlineOutlined as NewIcon,
  DeleteOutlineOutlined as DeleteIcon,
} from "@mui/icons-material";

import { CRUDTemplate } from "src/components";
import ProductsActionsCell from "src/components/molecules/v2/products/crud/actionsCell";
import ProductsRegisterModal from "src/components/molecules/v2/products/crud/register/modal";
import { useAuth } from "src/hooks/auth";
import { useProducts } from "src/hooks/products";
import { useFeedback } from "src/hooks/feedback";
import { IProduct } from "src/types/hooks/products";
import { Dayjs } from "dayjs";
import CRUDFilter from "src/components/molecules/v2/crud/filter";

/**
 * This component returns the Documents Custom Models CRUD page
 * It uses mui styles
 * @component
 */
const Products: FC = () => {
  // Auth hooks to get client information
  const auth = useAuth();
  // Products hooks to fetch list
  const products = useProducts();
  // Information hooks for user feedback
  const feedback = useFeedback();
  // Navigate hooks for change routes
  const navigate = useNavigate();

  // State for current data grid page
  const [dataGridRowsPerPage, setDataGridRowsPerPage] = useState<number>(5);
  // State for current data grid page
  const [dataGridPage, setDataGridPage] = useState<number>(0);
  // State for documents selecteds in data grid
  const [dataGridSelecteds, setDataGridSelecteds] = useState<string[]>([]);
  // State for selected row
  const [selectedRow, setSelectedRow] = useState<IProduct | undefined>(
    undefined
  );
  // State for control the register modal open status
  const [isRegisterModalOpen, setIsRegisterModalOpen] =
    useState<boolean>(false);
  // State for filtered entities
  const [entities, setEntities] = useState<IProduct[]>([]);
  // State for current crud filter search
  const [filterSearch, setFilterSearch] = useState<string>("");
  // State for current crud filter initial date
  const [filterInitialDate, setFilterInitialDate] = useState<Dayjs | null>(
    null
  );
  // State for current crud filter final date
  const [filterFinalDate, setFilterFinalDate] = useState<Dayjs | null>(null);

  /**
   * Reflects when component inits
   * @effect
   */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Reflects when client data is loaded
   * @effect
   */
  useEffect(() => {
    fetchData();
  }, [auth.client]);

  /**
   * Reflects when register modal is closed
   * Used to set undefined to selected row
   * @effect
   */
  useEffect(() => {
    if (!isRegisterModalOpen) setSelectedRow(undefined);
  }, [isRegisterModalOpen]);

  /**
   * Reflects when rows per page state has changed
   * @effect
   */
  useEffect(() => {
    let auxEntities: IProduct[] = products.entities;
    if (filterSearch !== "") {
      auxEntities = auxEntities.filter(
        (item) =>
          item.name.toLowerCase().indexOf(filterSearch.toLowerCase()) >= 0 ??
          (item.description &&
            item.description
              .toLowerCase()
              .indexOf(filterSearch.toLowerCase()) >= 0)
      );
    }
    setEntities([...auxEntities]);
  }, [products.entities]);

  /**
   * Reflects when filter has changes
   * @effect
   */
  useEffect(() => {
    let auxEntities: IProduct[] = products.entities;
    if (filterSearch !== "") {
      auxEntities = auxEntities.filter(
        (item) =>
          item.name.toLowerCase().indexOf(filterSearch.toLowerCase()) >= 0 ??
          (item.description &&
            item.description
              .toLowerCase()
              .indexOf(filterSearch.toLowerCase()) >= 0)
      );
    }
    setEntities([...auxEntities]);
  }, [filterSearch]);

  /**
   * Function to fetch all necessary data of the page
   * @function
   */
  const fetchData = () => {
    if (!auth.client || !auth.client.id) return;

    products.fetchEntities();
  };

  /**
   * Function to handle the selected rows of data grid
   * @function
   */
  const onSelectRows = (rowSelectionModel: GridRowSelectionModel) => {
    setDataGridSelecteds([...rowSelectionModel.map((item) => item.toString())]);
  };

  /**
   * Function to edit row
   * @function
   */
  const edit = (payload: IProduct) => {
    setSelectedRow(payload);
    setIsRegisterModalOpen(true);
  };

  /**
   * Function to delete all rows selected
   * @function
   */
  const deleteAllSelected = () => {
    feedback.open(
      true,
      "info",
      "Informação",
      undefined,
      "Deseja realmente excluir esses produtos?",
      undefined,
      undefined,
      ["Cancelar", "Excluir"],
      ["text", "contained"],
      [
        () => {
          feedback.close();
        },
        async () => {
          for (let i = 0; i < dataGridSelecteds.length; i++)
            await products.deleteEntity(dataGridSelecteds[i]);
          fetchData();

          feedback.close();
          feedback.open(
            true,
            "success",
            "Sucesso",
            undefined,
            "Produtos excluídos com sucesso."
          );
        },
      ]
    );
  };

  return (
    <>
      <CRUDTemplate
        dataGridLoading={products.loading}
        title="Produtos"
        headerTitle=""
        headerChildren={
          <Grid container rowSpacing="16px" columnSpacing="16px">
            <Grid item xs={12} md={9} lg={10}>
              <CRUDFilter
                searchLabel="Nome ou descrição do produto"
                searchPlaceholder="Modelo X"
                search={filterSearch}
                setSearch={setFilterSearch}
                initialDate={filterInitialDate}
                setInitialDate={setFilterInitialDate}
                finalDate={filterFinalDate}
                setFinalDate={setFilterFinalDate}
                hideDates
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              lg={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              paddingLeft="8px"
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<NewIcon color="inherit" />}
                onClick={() => setIsRegisterModalOpen(true)}
              >
                <Typography
                  color="inherit"
                  fontSize="14px"
                  fontStyle="normal"
                  textTransform="initial"
                  fontWeight="500"
                  lineHeight="20px"
                  letterSpacing="0.1px"
                >
                  Cadastrar
                </Typography>
              </Button>
            </Grid>
          </Grid>
        }
        dataGridColumns={productsDataGridColumns(edit, fetchData)}
        dataGridRows={entities}
        onDataGridSelectRows={onSelectRows}
        paginationModel={{
          page: dataGridPage - 1,
          pageSize: dataGridRowsPerPage,
        }}
        onPaginationModelChange={(model, details) => {
          setDataGridPage(model.page + 1);
          setDataGridRowsPerPage(model.pageSize);
        }}
        cardFooterChildren={
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              alignItems="center"
              justifyContent="flex-start"
            >
              {auth.hasRights?.("manage-api_keys") && (
                <Button
                  color="error"
                  disabled={dataGridSelecteds.length === 0}
                  size="large"
                  variant="text"
                  startIcon={<DeleteIcon color="inherit" />}
                  onClick={() => deleteAllSelected()}
                >
                  <Typography
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    textTransform="initial"
                    lineHeight="20px"
                    letterSpacing="0.1px"
                  >
                    Excluir
                  </Typography>
                </Button>
              )}
            </Box>
          </Box>
        }
      >
        <div />
      </CRUDTemplate>

      <ProductsRegisterModal
        isOpen={isRegisterModalOpen}
        payload={selectedRow}
        onCancel={() => {
          fetchData();
          setIsRegisterModalOpen(false);
        }}
      />
    </>
  );
};

export default Products;

export const productsDataGridColumns = (
  onEdit: Function,
  onRefresh: Function
): GridColDef[] => [
  {
    field: "name",
    headerName: "Nome",
    minWidth: 75,
    flex: 2,
  },
  {
    field: "description",
    headerName: "Descrição",
    minWidth: 75,
    flex: 1,
  },
  {
    field: "isDeleted",
    headerName: "Ação",
    align: "right",
    headerAlign: "center",
    minWidth: 75,
    renderCell: (params) => (
      <ProductsActionsCell
        cellParams={params}
        onEdit={(payload: IProduct) => onEdit(payload)}
        onRefresh={() => onRefresh()}
      />
    ),
  },
];
