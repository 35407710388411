import Keycloak from "keycloak-js";

import api from "./";
import { IAuth, IAuthLogin, IAuthPasswordRecovery, IAuthResetPassword, IAuthUserLogged, IAuthUserLoggedProfile } from "../types";
import { parseStringToDate } from "../utils/dates";
import { EntityServices } from "./entities";

export class AuthenticationServices extends EntityServices<IAuth> {
  baseUrl: string = "api_manager/auth";

  async login(_email: string, _password: string): Promise<IAuthLogin | IAuthResetPassword> {
    const payload: any = {
      email: _email.toLowerCase(),
      username: _email.toLowerCase(),
      password: _password,
    };
    const response: any = await api.post(`${this.baseUrl}/login`, payload);
    if (response?.request && response.request?.status === 200) {
      if (response.data.action && response.data.action.indexOf("UPDATE_PASSWORD") >= 0)
        return this.convertApiResponseToIAuthResetPasword(response.data);
      return this.convertApiResponseToIAuthLogin(response.data);
    } else if (response?.request?.status == 401) {
      if (response.response?.data?.error === "Email or password invalid") throw new Error(response.response?.data?.error);
      if (response.response?.data?.error === "Inactive user") throw new Error(response.response?.data?.error);
    }
    throw new Error("Não foi possível efetuar o login.");
  }

  async refreshToken(_refreshToken: string) {
    const response: any = await api.post("/api_manager/auth/refresh_token", {
      refreshToken: _refreshToken,
    });
    if (response?.request && response.request?.status === 200) {
      if (response.data.action && response.data.action.indexOf("UPDATE_PASSWORD") >= 0)
        return this.convertApiResponseToIAuthResetPasword(response.data);
      return this.convertApiResponseToIAuthLogin(response.data);
    } else if (response?.request?.status == 401) {
      if (response.response?.data?.error === "Email or password invalid") throw new Error(response.response?.data?.error);
      if (response.response?.data?.error === "Inactive user") throw new Error(response.response?.data?.error);
    }
    return undefined;
  }

  async inactiveAuth(_email: string): Promise<IAuth> {
    const response: any = await api.put(`${this.baseUrl}/inactive/${_email}`);

    if (response?.request && response.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível obter o usuário logado.");
  }

  async getLoggedUser(): Promise<IAuthUserLogged> {
    const response: any = await api.get(`${this.baseUrl}/logged_user`);

    if (response?.request && response.request?.status === 200) {
      return this.convertToIAuthUserLogged(response.data);
    }
    throw new Error("Não foi possível obter o usuário logado.");
  }

  updatePasswordWithKey = async (_key: string, _password: string) => {
    const response = await api
      .put(`${this.baseUrl}/forgot_password/${_key}`, {
        type: "password",
        value: _password,
        temporary: false,
      })
      .catch((_err: any) => {
        throw _err;
      });

    if (response && response.request) {
      const status = response.request.status;
      if (status == 200) return true;
      else if (status == 400 && (response as any).response && (response as any).response.data) {
        if ((response as any).response.data.error == "E-mail not found on database") throw new Error((response as any).response.data.error);
        throw new Error((response as any).response.data as string);
      }
    }

    return false;
  };

  convertApiResponseToIAuthLogin(_data: any): IAuthLogin {
    return {
      accessToken: _data.accessToken,
      expiresIn: _data.expiresIn ?? 0,
      refreshExpiresIn: _data.refreshExpiresIn,
      refreshToken: _data.refreshToken,
      tokenType: _data.tokenType,
      notBeforePolicy: _data.notBeforePolicy,
      sessionState: _data.sessionState,
      scope: _data.scope,
    };
  }

  convertApiResponseToIAuthResetPasword(_data: any): IAuthResetPassword {
    return {
      key: _data.key,
      action: _data.action,
    };
  }

  convertToIAuthUserLoggedProfile(_data: any): IAuthUserLoggedProfile {
    return {
      id: _data.id,
      userId: _data.userId,
      firstName: _data.firstName,
      lastName: _data.lastName,
      surname: _data.surname,
      avatarUri: _data.avatarUri,
      createdAt: parseStringToDate(_data.createdAt),
      updatedAt: parseStringToDate(_data.updatedAt),
    };
  }

  convertToIAuthPasswordRecovery(_data: any): IAuthPasswordRecovery {
    return {
      id: _data.id,
      userId: _data.userId,
      token: _data.token,
      used: _data.used,
      frontEndUrl: _data.frontEndUrl,
      createdAt: parseStringToDate(_data.createdAt),
      updatedAt: parseStringToDate(_data.updatedAt),
    };
  }

  convertToIAuthUserLogged(_data: any): IAuthUserLogged {
    return {
      id: _data.id || _data.sub,
      emailVerified: _data.emailVerified || _data.email_verified,
      fullName: _data.fullName || _data.name,
      firstName: _data.firstName || _data.given_name,
      lastName: _data.lastName || _data.family_name,
      preferredUserName: _data.preferredUserName || _data.preferred_username,
      email: _data.email,
      // user attributes
      phoneNumber: _data.phoneNumber || _data.phone_number,
      receiveNotifications: _data.receiveNotifications || _data.receive_notifications,
      avatar: _data.avatar,
    };
  }

  convertKeycloakToIAuthLogin(keycloak: Keycloak): IAuthLogin {
    const authLogin: IAuthLogin = {
      accessToken: keycloak.token ?? "",
      expiresIn: keycloak.tokenParsed?.exp ?? 0,
      refreshExpiresIn: keycloak.refreshTokenParsed?.exp ?? 0,
      refreshToken: keycloak.refreshToken ?? "",
      tokenType: "",
      notBeforePolicy: 0,
      sessionState: "",
      scope: "",
    };
    return authLogin;
  }
}
