import React, { FC, useEffect, useState } from "react";
import { SxProps, Tab, Theme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container } from "@pix-force/react-atoms";

import InvoicesList from "src/components/molecules/invoices/list";
import DocumentsDatalogList from "src/components/molecules/documents/datalog/list";
import SubscriptionsList from "src/components/molecules/subscriptions/list";
import ApiKeysList from "src/components/molecules/apiKeys/list";
import ModelsList from "src/components/molecules/documentsCustomsModels/list";
import { useAuth } from "src/hooks/auth";
import UsersList from "src/components/molecules/users/list";
import { BILLINGS_TAB_INDEX, DATALOG_TAB_INDEX, SUBSCRIPTION_TAB_INDEX, USERS_TAB_INDEX, API_TAB_INDEX, MODEL_TAB_INDEX, PAYMENT_TAB_INDEX } from "src/pages/clients/detailed/consts";
import { IClientsDetailedTab, IDocumentCustomModel } from "src/types";
import PaymentFormsList from "src/components/molecules/paymentForms/list";
import Datalog from "src/components/molecules/datalog";
import DocumentsCustomsModelsList from "src/components/molecules/documentsCustomsModels/list";
import { useConfirmation } from "src/hooks/confirmation";
import { useDocumentsCustomsModels } from "src/hooks/documentsCustomsModels";
import { useLocation } from "react-router-dom";

const tabPanelStyle: SxProps<Theme> = {
    display: "flex", flex: 1, width: "100%", padding: 0
}

const ClientsDetailedTab: FC<IClientsDetailedTab> = (params: IClientsDetailedTab) => {
    const { hasRights, userCanUseApiKey } = useAuth();
    const confirmationHooks = useConfirmation();
    const documentsCustomsModelsHooks = useDocumentsCustomsModels();
    const location = useLocation();

    const [refreshDocumentsCustomsModelsList, setRefreshDocumentsCustomsModelsList] = useState<boolean>(false);

    const actionTriggered = (_documentCustomModel: IDocumentCustomModel, _action: "DELETE") => {
        if (!_documentCustomModel.id) return;

        const id: string = _documentCustomModel.id;
        if (_action.indexOf("DELETE") >= 0) {
            confirmationHooks.showConfirmation(
                "Deseja realmente excluir esse modelo?",
                "Atenção",
                "Excluir", "Não",
                async () => {
                    await documentsCustomsModelsHooks.deleteEntity(id);
                    setRefreshDocumentsCustomsModelsList(true);
                    confirmationHooks.hideConfirmation();
                }
            )
        }
    }

    return (
        <TabContext value={params.currentTab}>
            <Container fluid flex>
                <TabList onChange={(event: React.SyntheticEvent, newValue: string) => params.onHandleChangeCurrentTab(event, newValue)}>
                    {location.pathname.indexOf("/clients") >= 0 && hasRights && hasRights("view-clients__users") &&
                        <Tab
                            label="Usuários"
                            value={USERS_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    }
                    {hasRights && hasRights("view-clients__datalog") &&
                        <Tab
                            label="Datalog"
                            value={DATALOG_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    }
                    {hasRights && hasRights("view-clients__subscriptions") &&
                        <Tab
                            label="Assinaturas"
                            value={SUBSCRIPTION_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    }
                    {/* {hasRights && hasRights("view-clients__payment") &&
                        <Tab
                            label="Pagamento"
                            value={PAYMENT_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    }
                    {hasRights && hasRights("view-clients__invoices") &&
                        <Tab
                            label="Faturas"
                            value={BILLINGS_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    } */}
                    {location.pathname.indexOf("/clients") >= 0 && hasRights && hasRights("view-clients__api_keys") &&
                        userCanUseApiKey() &&
                        <Tab
                            label="Api"
                            value={API_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    }
                    {location.pathname.indexOf("/clients") >= 0 && hasRights && hasRights("view-clients__models") &&
                        <Tab
                            label="Modelos"
                            value={MODEL_TAB_INDEX}
                            sx={{ textTransform: "capitalize" }}
                        />
                    }
                </TabList>
            </Container>

            {params.currentTab == USERS_TAB_INDEX && (
                <TabPanel value={USERS_TAB_INDEX} tabIndex={parseInt(USERS_TAB_INDEX)} sx={tabPanelStyle}>
                    <UsersList users={params.users} client={params.client} onRefresh={() => params.onRefreshUsers()} />
                </TabPanel>
            )}
            {params.currentTab == DATALOG_TAB_INDEX && params.client && params.client.id && (
                <TabPanel value={DATALOG_TAB_INDEX} tabIndex={parseInt(DATALOG_TAB_INDEX)} sx={tabPanelStyle}>
                    <Datalog clientId={params.client.id} currentTab={params.datalogCurrentTab} onHandleChangeCurrentTab={params.handleChangeDatalogCurrentTab} />
                </TabPanel>
            )}
            {params.currentTab == SUBSCRIPTION_TAB_INDEX && params.client && params.client.id && (
                <TabPanel value={SUBSCRIPTION_TAB_INDEX} tabIndex={parseInt(SUBSCRIPTION_TAB_INDEX)} sx={tabPanelStyle}>
                    <SubscriptionsList clientId={params.client.id}
                        subscription={params.subscription} loading={params.subscriptionLoading}
                        onRefresh={() => params.onRefreshSubscription()} />
                </TabPanel>
            )}
            {params.currentTab == PAYMENT_TAB_INDEX && params.client && params.client.id && params.subscription && (
                <TabPanel value={PAYMENT_TAB_INDEX} tabIndex={parseInt(PAYMENT_TAB_INDEX)} sx={tabPanelStyle}>
                    <PaymentFormsList
                        loading={params.clientCardsLoading}
                        subscription={params.subscription}
                        upcomingInvoice={(params.invoices && params.invoices.length > 0 && params.invoices[0]) || undefined}
                        clientCards={params.clientCards}
                        onTicketClick={() => alert("boleto")}
                        onCardClick={() => alert("Cartao")}
                        onManageCardClick={() => params.onPaymentFormAddCards()}
                        onChangePaymentForm={params.onChangePaymentForm} />
                </TabPanel>
            )}
            {params.currentTab == BILLINGS_TAB_INDEX && params.client && params.client.id && (
                <TabPanel value={BILLINGS_TAB_INDEX} tabIndex={parseInt(BILLINGS_TAB_INDEX)} sx={tabPanelStyle}>
                    <InvoicesList clientId={params.client.id} invoices={params.invoices} onRefresh={() => params.onRefreshInvoices()} />
                </TabPanel>
            )}
            {params.currentTab == API_TAB_INDEX && params.client && params.client.id && (
                <TabPanel value={API_TAB_INDEX} tabIndex={parseInt(API_TAB_INDEX)} sx={tabPanelStyle}>
                    <ApiKeysList clientId={params.client.id} apiKeys={params.apiKeys} onRefresh={() => params.onRefreshApiKeys()} isOnClients={true} />
                </TabPanel>
            )}
            {params.currentTab == MODEL_TAB_INDEX && params.client && params.client.id && (
                <TabPanel value={MODEL_TAB_INDEX} tabIndex={parseInt(MODEL_TAB_INDEX)} sx={tabPanelStyle}>
                    <DocumentsCustomsModelsList
                        clientId={params.client.id}
                        onActionTriggered={actionTriggered}
                        refreshList={refreshDocumentsCustomsModelsList}
                        setRefreshList={setRefreshDocumentsCustomsModelsList} />
                </TabPanel>
            )}
        </TabContext>
    );
}

export default ClientsDetailedTab;
