import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useAuth } from 'src/hooks/auth';
import { useContactUsForms } from 'src/hooks/contactUsForms';
import { useInformation } from 'src/hooks/information';
import { IContactUsForm } from 'src/types';

const Help: React.FC = () => {
    const authHook = useAuth();
    const contactUsFormsHooks = useContactUsForms();
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const informationHooks = useInformation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
    const handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => setSubject(event.target.value);
    const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => setMessage(event.target.value);

    const onSubmit = async () => {
        if (!authHook.user?.id) return;

        try {
            setLoading(true);

            const payload: IContactUsForm = {
                userId: authHook.user?.id,
                name: authHook.user?.firstName,
                email,
                subject,
                message
            }
            const contactUsForm: IContactUsForm = await contactUsFormsHooks.createNewEntity(payload, false);
            if (contactUsForm) {
                informationHooks.showInformation(`Sua dúvida foi enviada com sucesso. Em breve iremos entrar em contato no e-mail ${email}.`,
                    "Atenção", undefined, () => {
                        informationHooks.hideInformation();
                        navigate("/");
                    });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            navigate("/");
        }
    }

    return (
        <Box display="flex" flexDirection="column" height="100vh" alignItems="center" justifyContent="space-evenly">
            <Typography variant="h4" color="primary">
                Fale conosco
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container display="flex" alignItems="center" rowSpacing={2}>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <TextField id="email" size="small" variant="outlined" disabled={loading}
                            label="E-mail" placeholder="Informe o seu e-mail"
                            value={email} error={errors.email !== undefined} helperText={errors.email && (errors.email as any).message}
                            {...register("email", {
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeEmail(event),
                                required: "Informe seu e-mail",
                                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Insira um e-mail válido" }
                            })} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <TextField id="subject" size="small" variant="outlined" disabled={loading}
                            label="Assunto" placeholder="Informe o assunto"
                            value={subject} error={errors.subject !== undefined} helperText={errors.subject && (errors.subject as any).message}
                            {...register("subject", {
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeSubject(event),
                                required: "Informe o assunto",
                            })} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <TextField id="message" size="small" variant="outlined" disabled={loading}
                            label="Dúvida" placeholder="Informe a sua dúvida"
                            value={message} error={errors.message !== undefined} helperText={errors.message && (errors.message as any).message}
                            multiline minRows={3} maxRows={10}
                            {...register("message", {
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeMessage(event),
                                required: "Informe a sua dúvida",
                            })} sx={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <LoadingButton variant="contained" type="submit" sx={{ width: "100px" }} disabled={loading}>
                            <Typography variant="h6" color="white" sx={{ textTransform: "capitalize" }}>
                                Enviar
                            </Typography>
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}

export default Help;
