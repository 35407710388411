import { FC, useEffect } from "react";
import { SxProps, Tab, Theme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container } from "@pix-force/react-atoms";

import { ANALYSES_TAB_INDEX, COMPARISONS_TAB_INDEX, READINGS_TAB_INDEX } from "./consts";
import DocumentsDatalogList from "../documents/datalog/list";
import theme from "src/components/theme";
import { useAuth } from "src/hooks/auth";
import { IDatalog } from "src/types";

const tabStyle = (theme: Theme, selected: boolean = false): SxProps<Theme> => {
    return {
        backgroundColor: theme.palette.primary.dark,
        opacity: selected ? 100 : 0.65,
        minWidth: "150px",
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
        color: "white",
        textTransform: "capitalize",
    }
}

const tabPanelStyle = (theme: Theme): SxProps<Theme> => {
    return {
        display: "flex", flex: 1, width: "100%", padding: 2,
        backgroundColor: theme.palette.primary.dark,
    }
}

const Datalog: FC<IDatalog> = (props: IDatalog) => {
    let currentTab: String | null = new URLSearchParams(window.location.search).get('datalog_tab');

    const authHooks = useAuth();

    useEffect(() => {
        if (currentTab && currentTab == READINGS_TAB_INDEX)
            props.onHandleChangeCurrentTab(null, READINGS_TAB_INDEX);
        else if (currentTab && currentTab == COMPARISONS_TAB_INDEX)
            props.onHandleChangeCurrentTab(null, COMPARISONS_TAB_INDEX);
        else if (currentTab && currentTab == ANALYSES_TAB_INDEX)
            props.onHandleChangeCurrentTab(null, ANALYSES_TAB_INDEX);
    }, []);

    return (
        <Container fluid veryPadded width="95%" minHeight="80vh">
            <TabContext value={props.currentTab}>
                <Container fluid>
                    <TabList
                        textColor="inherit" indicatorColor="secondary"
                        onChange={(event: React.SyntheticEvent, newValue: string) => props.onHandleChangeCurrentTab(event, newValue)}>
                        {/* {authHooks.hasRights && authHooks.hasRights("view-clients__users") && } */}
                        <Tab
                            label="Leitura"
                            value={READINGS_TAB_INDEX}
                            sx={tabStyle(theme, props.currentTab == READINGS_TAB_INDEX)}
                        />
                        <Tab
                            label="Comparação"
                            value={COMPARISONS_TAB_INDEX}
                            sx={tabStyle(theme, props.currentTab == COMPARISONS_TAB_INDEX)}
                        />
                        <Tab
                            label="Análise"
                            value={ANALYSES_TAB_INDEX}
                            sx={tabStyle(theme, props.currentTab == ANALYSES_TAB_INDEX)}
                        />
                    </TabList>
                </Container>

                {props.currentTab == READINGS_TAB_INDEX && (
                    <TabPanel value={READINGS_TAB_INDEX} tabIndex={parseInt(READINGS_TAB_INDEX)} sx={tabPanelStyle(theme)}>
                        <DocumentsDatalogList clientId={props.clientId} />
                    </TabPanel>
                )}
            </TabContext>
        </Container>
    );
}

export default Datalog;
