import { AxiosResponse } from "axios";

import api, { API_DOCUMENTS_URL } from "./"
import { EntityServices } from "./entities";
import { IModel } from "../types";
import { parseStringToDate } from "../utils/dates";

export class ModelServices extends EntityServices<IModel> {
  constructor() {
    super("Modelo", `${API_DOCUMENTS_URL}/documents_models`);
  }

  async getModelsByClient(clientId : string) : Promise<IModel[]>{
    const response: AxiosResponse = await api.get(`${this.baseUrl}/all_by_client_id/${clientId}`);
    if (response.data && response?.request?.status === 200) {
        const entities: IModel[] = [];
        for (let i = 0; i < response.data.length; i++) {
          const entity: IModel = this.convertApiResponseToIEntity(response.data[i]);
          entities.push(entity);
        }
        return entities;
      }
      return [];
  }
  override convertApiResponseToIEntity(_data: any): IModel {
    return {
      id: _data.id,
      clientId : _data.clientId,
      name: _data.name,
      description: _data.description,
      documentType: _data.documentType,
      fields : _data.fields,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}