import { AxiosResponse } from "axios";

import api, { API_KEYCLOAK_URL, KEYCLOAK_REALM } from "./";
import { UserProps } from "../types";

export class UserServices {
  baseUrl: string = `${API_KEYCLOAK_URL}/admin/realms/${KEYCLOAK_REALM}/users`;

  async createUser(_payload: UserProps): Promise<UserProps> {
    const response: AxiosResponse = await api.post(`${this.baseUrl}`, _payload);
    if (response.status == 201) {
      const response: AxiosResponse = await api.get(`${this.baseUrl}?email=${_payload.email}&exact=true`);
      const status = response.status;
      if (status == 200 && response.data) {
        const data = response.data;
        if (data.length >= 1) {
          const user: UserProps = this.convertApiResponseToUserProps(data[0]);
          return user;
        }
      }
    } else {
      const errorResponse = (response as any).response;
      if (errorResponse) throw new Error(errorResponse.data.errorMessage as string);
    }
    throw new Error("Não foi possível criar um Usuário.");
  }

  async updateUser(_id: string, _payload: UserProps): Promise<UserProps> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${_id}`, _payload);
    if (response.status === 204) {
      const response: AxiosResponse = await api.get(`${this.baseUrl}?email=${_payload.email}&exact=true`);
      const status = response.status;
      if (status == 200 && response.data) {
        const data = response.data;
        if (data.length >= 1) {
          const user: UserProps = this.convertApiResponseToUserProps(data[0]);
          return user;
        }
      }
    }
    throw new Error("Não foi possível editar um usuário");
  }

  async destroyUser(_id: string): Promise<UserProps | any> {
    const response: AxiosResponse = await api.delete(`${this.baseUrl}/${_id}`);
    if (response.data && response.status === 200) {
      return this.convertApiResponseToUserProps(response.data);
    }
    if (response.status === 204) {
      return true;
    } else if ((response as any)?.response?.status === 400) {
      const data = (response as any)?.response?.data;
      if (data.error) {
        throw new Error(data.error);
      }
    }
    throw new Error(`Não foi possível remover um usuário.`);
  }

  async getUser(_id: string): Promise<UserProps> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/${_id}`);

    if (response.data && response.status === 200) {
      return this.convertApiResponseToUserProps(response.data);
    }
    throw new Error(`Não foi possível buscar o usuário`);
  }

  async getUserByUserId(_userId: string): Promise<UserProps> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/by_user_id/${_userId}`);

    if (response.data && response.status === 200) {
      return this.convertApiResponseToUserProps(response.data);
    }
    throw new Error(`Não foi possível buscar o usuário`);
  }

  async getUsers(): Promise<UserProps[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}`);
    if (response.data && response.status === 200) {
      const users: UserProps[] = [];
      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        users.push(this.convertApiResponseToUserProps(data));
      }
      return users;
    }
    throw new Error(`Não foi possível buscar os usuárioƒs`);
  }

  async getUserGroups(_id: string): Promise<any> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/${_id}/groups`);

    if (response.data && response.status === 200) {
      const groups: any[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        groups.push(data);
      }
      return groups;
    }
    throw new Error(`Não foi possível os grupos`);
  }

  async addGroup(_userId: string, _groupId: string): Promise<boolean> {
    const response: AxiosResponse = await api.put(`${this.baseUrl}/${_userId}/groups/${_groupId}`);

    if (response.data && (response.status === 200 || response.status === 204)) {
      return true;
    }
    return false;
  }

  async removeGroup(_userId: string, _groupId: string): Promise<boolean> {
    const response: AxiosResponse = await api.delete(`${this.baseUrl}/${_userId}/groups/${_groupId}`);

    if (response.data && (response.status === 200 || response.status === 204)) {
      return true;
    }
    return false;
  }

  convertApiResponseToUserProps(_data: any): UserProps {
    return {
      id: _data.id,
      username: _data.username,
      enabled: _data.enabled,
      emailVerified: _data.emailVerified,
      firstName: _data.firstName,
      lastName: _data.lastName,
      email: _data.email
    };
  }
}
