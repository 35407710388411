import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import {
    DeleteOutlineOutlined as DeleteIcon,
    EditOutlined as EditIcon
} from '@mui/icons-material';

import { useApiKeys } from "src/hooks/apiKeys";
import { useAuth } from "src/hooks/auth";
import { useFeedback } from "src/hooks/feedback";
import { IApiKeysActionsCell } from "src/types/components/molecules/v2/apiKeys";
import { isApiKey } from "src/types/hooks/apiKeys";
import { ApiKeyProps } from "src/types";

/**
 * This component returns the documents customs models action cell
 * Can be used in documents data grid
 * It uses mui styles
 * @component
 */
const ApiKeysActionsCell: FC<IApiKeysActionsCell> = (props: IApiKeysActionsCell) => {
    // Auth hooks to get the user rights
    const auth = useAuth();
    // Document hooks for service operations
    const apiKeys = useApiKeys();
    // Information hooks for user feedback
    const feedback = useFeedback();

    // Document id (the const never will be number, we treat id as UUID),
    // but the x-data-grid treats that way
    const id: string | number | undefined = props.cellParams?.id ?? undefined;

    /**
     * Function that edit the row
     * @function
     */
    const edit = () => {
        if (props.cellParams && isApiKey(props.cellParams.row))
            props.onEdit(props.cellParams.row)
    }

    /**
     * Function that delete the row
     * @function
     */
    const deleteApiKey = () => {
        if (!id) return null;

        if (props.cellParams?.row?.name == "Chave-Padrão") {
            feedback.open(true, "warning", "Atenção", undefined, "Não é possível excluir a chave padrão.");
            return;
        }

        feedback.open(true, "info", "Informação", undefined, "Deseja realmente excluir essa chave de API?", undefined, undefined,
            ["Cancelar", "Excluir"], ["text", "contained"], [() => {
                feedback.close();
            }, async () => {
                if (id && typeof id === "string") {
                    const auxDocument: ApiKeyProps = await apiKeys.deleteEntity(id);
                    props.onRefresh();
                    if (auxDocument) {
                        feedback.close();
                        feedback.open(true, "success", "Sucesso", undefined, "Chave de API excluída com sucesso.");
                        return;
                    }
                }
            }]);
    }

    if (!id) return null;
    return (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
                {auth.hasRights?.("manage-api_keys") &&
                    <IconButton color="info" onClick={() => edit()}>
                        <EditIcon />
                    </IconButton>
                }
                {auth.hasRights?.("manage-api_keys") &&
                    <IconButton color="error" onClick={() => deleteApiKey()}>
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
        </Box >
    );
}

export default ApiKeysActionsCell;
