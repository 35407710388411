import { FC } from "react";
import { Box, Modal } from "@mui/material";

import DocumentsCustomsModelsRegister from ".";
import { IDocumentsCustomsModelsRegisterModal } from "src/types/components/molecules/v2/documentsCustomsModels";

const DocumentsCustomsModelsRegisterModal: FC<IDocumentsCustomsModelsRegisterModal> = (props: IDocumentsCustomsModelsRegisterModal) => {
    return (
        <Modal open={props.isOpen} onClose={() => props.onCancel()}>
            <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center" height="90vh">
                <Box width="560px" minWidth="280px" maxWidth="560px"
                    minHeight="300px">
                    <DocumentsCustomsModelsRegister {...props} />
                </Box >
            </Box>
        </Modal>
    )
}

export default DocumentsCustomsModelsRegisterModal;
