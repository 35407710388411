import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AvatarTypeMap,
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  SvgIconTypeMap,
  Theme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { createTheme } from "@mui/material/styles";

import theme from "src/components/theme";
import { IDrawerButton, IDrawerButtonMenuItem } from "src/types/components/atoms/v2/drawer/button";
import { useAuth } from "src/hooks/auth";
import { useDrawer } from "src/hooks/drawer";
import { useResponsive } from "src/hooks/responsive";

/**
 * This component returns the drawer button of the app drawer
 * It uses mui styles
 * @component
 */
const DrawerButton: FC<IDrawerButton> = (props: IDrawerButton) => {
  // Default color for background button
  const defaultColor: string = theme.palette.background.paper;
  // Default color of typography
  const defaultFontColor: string = theme.palette.primary.main;
  // Drawer button icon
  const Icon: OverridableComponent<SvgIconTypeMap> = props.icon;
  // If the component has menu list items
  const hasMenuListItems: boolean = (props.listItems && props.listItems.length > 0) ?? false;

  //Auth hooks for user management
  const auth = useAuth();
  // Drawer hooks that contain opening data
  const drawer = useDrawer();
  // Location hooks to get the current pathname
  const location = useLocation();
  // Navigate hooks to change route based on path
  const navigate = useNavigate();
  // Responsive hooks for layout purpose
  const responsive = useResponsive();

  // State for current drawer button theme
  const [drawerButtonTheme, setDrawerButtonTheme] = useState<Theme>(defaultDrawerButtonTheme);
  // State for current background color
  const [color, setColor] = useState<string>(defaultColor);
  // State for current typography color
  const [fontColor, setFontColor] = useState<string>(defaultFontColor);
  // State to handle anchor for menu list
  const [anchorMenuList, setAnchorMenuList] = useState<null | HTMLElement>(null);
  // State for current value of selected based on pathname
  const [selected, setSelected] = useState<boolean>(false);

  // Const of menu list open status
  const menuListOpen = Boolean(anchorMenuList);

  /**
   * Effect to check if drawer button is selected
   * @effect
   */
  useEffect(() => {
    if (props.path || props.listItems)
      setSelected(props.path === location.pathname || props.listItems?.find((item) => item.path === location.pathname) !== undefined);
    if (props.additionalPaths) {
      if (props.additionalPaths?.find((item) => item === location.pathname)) setSelected(true);
    }
  }, [props.path, location.pathname]);

  /**
   * Effect to change colors based on selected state
   * @effect
   */
  useEffect(() => {
    setColor(defaultColor);
    setFontColor(defaultFontColor);
    setDrawerButtonTheme(defaultDrawerButtonTheme);
    if (selected) {
      setColor(theme.palette.primary.main);
      setFontColor(theme.palette.background.paper);
      setDrawerButtonTheme(theme);
    }
  }, [selected]);

  /**
   * Function to handle drawer button click
   * @function
   */
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) props.onClick(event);
    else if (props.path) {
      navigate(props.path);
      if (responsive.mdDown) drawer.setIsOpen(false);
    } else if (hasMenuListItems) {
      setAnchorMenuList(event.currentTarget);
    }
  };

  /**
   * Function to handle menu list close
   * @function
   */
  const handleMenuListClose = () => {
    setAnchorMenuList(null);
  };

  /**
   * Function to handle menu list item click trigger
   * @function
   */
  const handleMenuListItemClick = (item: IDrawerButtonMenuItem) => {
    handleMenuListClose();

    if (item.path === "/logout") {
      auth.keycloak?.logout();
      return null;
    }

    navigate(item.path);
    if (responsive.mdDown) drawer.setIsOpen(false);
  };

  return (
    <ThemeProvider theme={drawerButtonTheme}>
      <Button
        {...props}
        disableElevation
        variant={props.variant ?? "contained"}
        color={props.color ?? "primary"}
        fullWidth={props.fullWidth ?? true}
        startIcon={props.avatar ? props.avatar : <Icon width="24px" height="24px" sx={{ color: fontColor }} />}
        endIcon={hasMenuListItems ? <ArrowRightOutlinedIcon /> : null}
        onClick={handleOnClick}
        sx={{ backgroundColor: color, height: "40px" }}
      >
        <Box display="flex" flex={1} flexGrow={1} alignItems="flex-start">
          <Typography
            noWrap
            fontSize={14}
            fontWeight={500}
            letterSpacing={0.1}
            maxWidth={props.listItems && "116px"}
            sx={{
              textTransform: "initial",
              color: fontColor,
            }}
          >
            {props.text}
          </Typography>
        </Box>
      </Button>

      {props.listItems && (
        <Menu
          anchorEl={anchorMenuList}
          open={menuListOpen}
          onClose={handleMenuListClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuList>
            {props.listItems.map((item) => {
              const MenuItemIcon: OverridableComponent<SvgIconTypeMap> | undefined = item.icon;

              return (
                <MenuItem key={item.path} onClick={() => handleMenuListItemClick(item)}>
                  {MenuItemIcon && (
                    <ListItemIcon>
                      <MenuItemIcon />
                    </ListItemIcon>
                  )}
                  <ListItemText>{item.text}</ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}
    </ThemeProvider>
  );
};

export default DrawerButton;

// Default theme for button without being selected
const defaultDrawerButtonTheme: Theme = {
  ...createTheme(),
  palette: {
    ...createTheme().palette,
    primary: {
      main: theme.palette.background.paper,
      light: theme.palette.background.paper,
      dark: theme.palette.background.default,
      contrastText: theme.palette.primary.main,
    },
  },
};
