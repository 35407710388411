import React, { FC, createContext, useContext, useState } from "react";

import { ModelServices } from "../services/models";
import { IModel, IModelContext, IHookProvider } from "../types";

const ModelsContext = createContext<IModelContext>({} as IModelContext);

export const ModelsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    const modelServices = new ModelServices();
    const [entities, setModels] = useState<IModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchEntity, setSearchEntity] = useState<string>("");
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    const [paginate, setPaginate] = useState<number>(0);

    const createNewEntity = async (_model: IModel) => {
        try {
            const model = await modelServices.createEntity(_model);
            fetchEntities();
            return model;
        } catch (_err) {
            throw _err;
        }
    };

    const editEntity = async (_id: string, _model: IModel) => {
        try {
            const model = await modelServices.updateEntity(_id, _model);
            fetchEntities();
            return model;
        } catch (_err) {
            throw _err;
        }
    };

    const deleteEntity = async (_id: string) => {
        try {
            const model = await modelServices.destroyEntity(_id);
            fetchEntities();
            return model;
        } catch (_err) {
            throw _err;
        }
    };

    const fetchEntities = async () => {
        setLoading(true);
        try {
            const models: IModel[] = await modelServices.getEntities();
            setModels([...models]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    const fetchEntity = async (_id: string) => {
        const model: IModel = await modelServices.getEntity(_id);
        return model;
    };

    const fetchClientModels = async (clientId: string)=> {
        try {
            const models: IModel[] = await modelServices.getModelsByClient(clientId);
            return models

        } catch (_err) {
            console.log(_err);
        }
        return []
    }
    return (
        <ModelsContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,
                fetchClientModels,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,
            }}
        >
            {_params.children}
        </ModelsContext.Provider>
    );
};

export function useModels() {
    const context = useContext(ModelsContext);

    if (!context) {
        throw new Error(
            "useProducts must be used within an ProductsProvider"
        );
    }

    return context;
}
