import React, { FC, useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";
import { Button, Container, DatePicker, Spacing, Text, } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { SubscriptionsPlansSelect, theme, } from "src/components";
import { useInformation } from "src/hooks/information";
import { useResponsive } from "src/hooks/responsive";
import { useSubscriptions } from "src/hooks/subscriptions";
import { useSubscriptionsPlans } from "src/hooks/subscriptionsPlans";
import { ISubscriptionsRegister, SubscriptionProps, SubscriptionPlanProps } from "src/types";

const paymentMethods: any[] = [
  {
    label: "Envio de fatura",
    value: "send_invoice"
  },
  {
    label: "Cobrança automática (Cartão padrão)",
    value: "charge_automatically"
  }
]

const SubscriptionsRegister: FC<ISubscriptionsRegister> = (params: ISubscriptionsRegister) => {
  const edit: boolean = params.id !== undefined;
  const selectSx: any = { width: '100%', maxHeight: '40px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0, borderRadius: 0 }, borderRadius: 0, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light };

  const { hideInformation, showInformation } = useInformation();
  const { mdDown } = useResponsive();
  const subscriptionHook = useSubscriptions();
  const subscriptionPlanHook = useSubscriptionsPlans();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState<any>(undefined);
  const [selectedSubscriptionPlanName, setSelectedSubscriptionPlanName] = useState<string>("");
  const [subscriptionPlansExpanded, setSubscriptionPlansExpanded] = useState<boolean>(false);
  const [selectedSubscriptionPlanError, setSelectedSubscriptionPlanError] = useState<string | undefined>(undefined);
  const [initialSubscriptionDate, setInitialSubscriptionDate] = useState<Date | null>(null);
  const [initialSubscriptionDateError, setInitialSubscriptionDateError] = useState<string | undefined>(undefined);
  const [finalSubscriptionDate, setFinalSubscriptionDate] = useState<Date | null>(null);
  const [finalSubscriptionDateError, setFinalSubscriptionDateError] = useState<string | undefined>(undefined);
  const [paymentMethod, setPaymentMethod] = useState<string>("send_invoice"); // charge_automatically, send_invoice

  useEffect(() => {
    fetchData();
    if (edit) loadEditFields();
  }, []);

  const fetchData = async () => {
    subscriptionPlanHook.fetchEntities();
  };

  const loadEditFields = () => {
    if (params.subscription && params.subscription) {
      if (params.subscription) {
        if (params.subscription.subscriptionPlan)
          setSelectedSubscriptionPlan(params.subscription.subscriptionPlan);
        if (params.subscription.initialDate)
          setInitialSubscriptionDate(
            moment(params.subscription.initialDate).toDate()
          );
        if (params.subscription.finalDate)
          setFinalSubscriptionDate(
            moment(params.subscription.finalDate).toDate()
          );
        if (params.subscription.collectionMethod)
          setPaymentMethod(params.subscription.collectionMethod);
      }
    } else {
      showInformation(
        "Ocorreu um erro ao editar o subscriptione!",
        undefined,
        undefined,
        () => {
          hideInformation();
          params.onClose();
        }
      );
    }
  };

  const validation = () => {
    let isValid: boolean = true;

    if (!selectedSubscriptionPlan) {
      isValid = false;
      setSelectedSubscriptionPlanError("Selecione um plano");
    }
    if (initialSubscriptionDate === null) {
      isValid = false;
      setInitialSubscriptionDateError("*Preenchimento obrigatório");
    }
    if (initialSubscriptionDate !== null && finalSubscriptionDate !== null) {
      if (initialSubscriptionDate > finalSubscriptionDate) {
        isValid = false;
        setFinalSubscriptionDateError("Data final deve ser maior do que data inicial");
      }
    }
    return isValid;
  };

  const save = async () => {
    try {
      if (!validation()) return;
      if (!initialSubscriptionDate) return;

      setLoading(true);
      let payload: SubscriptionProps = {
        clientId: params.clientId,
        subscriptionPlanId: "",
        initialDate: moment(initialSubscriptionDate).toDate(),
        finalDate: finalSubscriptionDate ? moment(finalSubscriptionDate).toDate() : undefined,
        paid: false,
        status: 1,
        subscriptionPlan: selectedSubscriptionPlan,
        collectionMethod: paymentMethod
      };

      let informationMessage: string = "Assinatura cadastrada com sucesso!";
      if (edit && params.id) {
        await subscriptionHook.editEntity(params.id, payload);
        informationMessage = "Assinatura alterada com sucesso!";
      } else {
        await subscriptionHook.createNewEntity(payload, true);
      }
      showInformation(informationMessage, undefined, "Continuar", () => {
        cleanData();
        hideInformation();
        params.onClose();
        setLoading(false);
      });
    } catch (err) {
      if ((err as any).message == "Initial date must be great than current client subscription")
        showInformation("Data inicial deve ser maior do que assinatura atual do cliente.");
      else if ((err as any).message == "Final date must be great than current client subscription")
        showInformation("Data final deve ser maior do que assinatura atual do cliente.");
      else showInformation("Ocorreu um erro.");

      setLoading(false);
    }
  };

  const cleanData = () => {
    setSelectedSubscriptionPlan(undefined);
    setSelectedSubscriptionPlanError(undefined);
    setInitialSubscriptionDate(null);
    setInitialSubscriptionDateError(undefined);
    setFinalSubscriptionDate(null);
    setFinalSubscriptionDateError(undefined);
  };

  return (
    <Container fluid veryPadded width={mdDown ? "90%" : "400px"} height="auto"
      color={theme.palette.backgroundStrokeActive.main} spacedBetween>
      <Container flex fluid veryPadded>
        {!edit && (<Text medium size={24}>Cadastrar assinatura</Text>)}
        {edit && (<Text medium size={24}>Editar assinatura</Text>)}
        <Spacing top={VERY_PADDING * 2} />

        {edit && (<Text medium size={16}>Alguns campos são bloqueados para edição.</Text>)}
        {edit && (<Text medium size={16}>Se necessário, recrie/solicite uma nova assinatura.</Text>)}
        <Grid container padding={2} rowSpacing={2}>
          <Grid item xs={12}>
            <SubscriptionsPlansSelect
              value={selectedSubscriptionPlan}
              setValue={setSelectedSubscriptionPlan}
              inputValue={selectedSubscriptionPlanName} setInputValue={setSelectedSubscriptionPlanName}
              placeholder="Selecionar planos"
              disabled={loading ?? edit}
              sx={{
                border: "solid 1.5px #878787",
                borderRadius: '0px',
                backgroundColor: theme.palette.backgroundElements.light,
                height: "35px"
              }} />
            {selectedSubscriptionPlanError && (
              <Text size={13} color={theme.palette.error.main} style={{ paddingTop: VERY_PADDING }}>
                {selectedSubscriptionPlanError}
              </Text>
            )}
          </Grid>
          <Grid item xs={12}>
            <Container fluid width="100%">
              <DatePicker
                value={initialSubscriptionDate}
                setValue={setInitialSubscriptionDate}
                placeholder="Data de início do plano"
                disabled={loading ?? edit}
                sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                error={initialSubscriptionDateError}
              />
              {initialSubscriptionDateError && (
                <Text info color={theme.palette.error.main}>
                  {initialSubscriptionDateError}
                </Text>
              )}
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container fluid width="100%">
              <DatePicker
                value={finalSubscriptionDate}
                setValue={setFinalSubscriptionDate}
                placeholder="Data de expiração do plano"
                disabled={true}
                sx={{ borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                error={finalSubscriptionDateError}
              />
              {finalSubscriptionDateError && (
                <Text info color={theme.palette.error.main}>
                  {finalSubscriptionDateError}
                </Text>
              )}
            </Container>
          </Grid>
          <Grid item xs={12}>
            <Container fluid width="100%">
              <FormControl variant="outlined">
                <InputLabel>Método de pagamento</InputLabel>
                <Select size="small" id="payment-method-select" value={paymentMethod} label="Método de pagamento" disabled={loading ?? !edit}
                  onChange={(event) => {
                    setPaymentMethod(event.target.value)
                  }} sx={selectSx}>
                  {paymentMethods.map((_paymentMethod) => {
                    return (
                      <MenuItem value={_paymentMethod.value}>
                        {_paymentMethod.label}
                      </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </Container>

            <Grid item xs={12} paddingTop={2}>
              <Container inline spacedBetween width="100%">
                <Button onClick={() => save()} loading={loading} disabled={loading}>
                  <Text color={theme.palette.background.default}>
                    Salvar cadastro
                  </Text>
                </Button>

                <Button onClick={() => params.onClose()} color={theme.palette.backgroundElements.dark}
                  loading={loading} disabled={loading}>
                  <Text color={theme.palette.background.default}>
                    Cancelar cadastro
                  </Text>
                </Button>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default SubscriptionsRegister;
