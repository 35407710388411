import React, { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Switch from "@mui/material/Switch";
import { Button, Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { ReactComponent as CalendarIcon } from "src/assets/icons/calendar2.svg";
import { ReactComponent as UserIcon } from "src/assets/icons/user2.svg";
import theme from "src/components/theme";
import { useAuth } from "src/hooks/auth";
import { useResponsive } from "src/hooks/responsive";
import { IClientsDetailedInfoCard } from "src/types";
import { DATE_FORMAT, parseDateToString } from "src/utils/dates";

const ClientsDetailedInfoCard: FC<IClientsDetailedInfoCard> = (params: IClientsDetailedInfoCard) => {
  const authHooks = useAuth();
  const responsive = useResponsive();

  const [active, setActive] = useState<boolean>(false);

  const loadingClient: boolean = params.client == undefined;

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const tabArg: string | null = query.get('tab');
    if (tabArg == 'datalog') params.onChangeTabToDatalog();
  }, []);

  useEffect(() => {
    if (params.subscription) setActive(params.subscription.status === 1);
  }, [params.subscription]);

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked);
  };

  return (
    <Container fluid veryPadded width={responsive.lgUp ? "300px" : "250px"} height={responsive.mdDown ? "70vh" : "auto"}
      color={theme.palette.backgroundStrokeActive.main} sx={{ border: `solid 1px ${theme.palette.backgroundElements.main}` }} borderRadius={13}>
      <Grid container rowSpacing={responsive.lgUp ? 3 : 2} columnSpacing={2} display="flex" padding={responsive.lgUp ? 1 : 0}>
        <Grid item xs={12}>
          {params.client ? (
            <Text light size={responsive.lgDown ? 18 : responsive.mdDown ? 15 : 23} color={theme.palette.primary.main}>
              {params.client?.name}
            </Text>
          ) : (
            <Skeleton variant="rectangular" width={responsive.mdDown ? 150 : 200} height={responsive.mdDown ? 25 : 40} />
          )}
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="row">
          <Text light size={responsive.lgUp ? 15 : 12} color={theme.palette.primary.main}>CNPJ:</Text>
          <Spacing right={PADDING} />
          <Text light size={responsive.lgUp ? 15 : 12} color={theme.palette.textInfo.main}>
            {params.client ? params.client?.cpfCnpj : "CPF/CNPJ Não informado"}
          </Text>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="row">
          <Text light size={responsive.lgUp ? 15 : 12} color={theme.palette.primary.main}>Assinatura:</Text>
          <Spacing right={PADDING} />
          {params.subscription?.subscriptionPlan ?
            <Text light size={responsive.lgUp ? 15 : 12} color={theme.palette.textInfo.main}>
              {params.subscription?.subscriptionPlan?.name}
            </Text> :
            <Text color={theme.palette.textInfo.main} light size={responsive.lgUp ? 15 : 12}>Assinatura não informada</Text>
          }
        </Grid>
        {params.subscription && params.subscription.finalDate instanceof Date &&
          <Grid item xs={12}>
            <Container inline centered spacedBetween width="100%">
              <Text size={responsive.lgUp ? 15 : 12} color={theme.palette.primary.main}>
                Plano expira em:
              </Text>

              <>
                <CalendarIcon
                  fill={theme.palette.primary.main}
                  stroke={theme.palette.primary.main}
                  style={{ width: responsive.lgUp ? 20 : 16, height: responsive.lgUp ? 20 : 16 }}
                />

                <Text size={responsive.lgUp ? 15 : 12} color={theme.palette.textInfo.main}>
                  {parseDateToString(params.subscription.finalDate, DATE_FORMAT)}
                </Text>
              </>
            </Container>
          </Grid>
        }

        {
          authHooks.hasRights && authHooks.hasRights("view-clients") &&
          <Grid item xs={12} md={12} lg={6} display="flex" justifyContent="center">
            <Button onClick={() => params.onEditInformationsCallback(params.client?.id)} color={loadingClient ? theme.palette.gray.main : theme.palette.secondary.main}
              disabled={loadingClient} sx={{ minWidth: "135px" }}>
              <Text bold size={responsive.lgUp ? 12 : 10} color={theme.palette.background.default}>
                Editar informações
              </Text>
            </Button>
          </Grid>
        }
        <Grid item xs={12} md={12} lg={6} display="flex" justifyContent="center">
          <Button onClick={() => params.onChangeTabToDatalog()} color={loadingClient ? theme.palette.gray.main : theme.palette.secondary.main}
            disabled={loadingClient} sx={{ minWidth: "135px" }}>
            <Text bold size={responsive.lgUp ? 12 : 10} color={theme.palette.background.default}>Ver Datalog</Text>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ClientsDetailedInfoCard;
