import { FC } from "react";
import { Box, Modal } from "@mui/material";

import ClientsRegister from ".";
import { IClientsRegisterModal } from "src/types/components/molecules/v2/clients";

const ClientsRegisterModal: FC<IClientsRegisterModal> = (props: IClientsRegisterModal) => {
    return (
        <Modal open={props.isOpen} onClose={() => props.onCancel()}>
            <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center" height="90vh">
                <Box width="560px" minWidth="280px" maxWidth="560px"
                    minHeight="400px">
                    <ClientsRegister {...props} />
                </Box>
            </Box>
        </Modal>
    )
}

export default ClientsRegisterModal;
