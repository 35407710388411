import React, { FC, useState } from "react";
import { Grid, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useResponsive } from "src/hooks/responsive";
import { Container, Spacing, Text, } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import moment from "moment";
import theme from "src/components/theme";
import { IapiKeylistItem } from "src/types/components/molecules/apiKeys";
import { useApiKeys } from "src/hooks/apiKeys";
import { useConfirmation } from "src/hooks/confirmation";
import { useAuth } from "src/hooks/auth";

const ApiKeysListItem: FC<IapiKeylistItem> = (params: IapiKeylistItem) => {
  const { hasRights } = useAuth();
  const { mdDown } = useResponsive();
  const { deleteEntity } = useApiKeys();
  const { showConfirmation, hideConfirmation } = useConfirmation();

  const [visible, setVisible] = useState<boolean>(false);

  const deleteApiKey = async () => {
    showConfirmation("Deseja realmente excluir essa chave?", undefined, undefined, undefined, async () => {
      hideConfirmation();
      if (params.apiKey.id) await deleteEntity(params.apiKey.id);
      params.onRefresh();
    });
  }

  if (params.apiKey == undefined) return null;

  return (
    <Container fluid flex veryPadded color={theme.palette.backgroundElements.main} sx={{ border: `solid 1px ${theme.palette.backgroundElements.dark}` }} >
      <Grid container rowSpacing={2} columnSpacing={2} display="flex">
        <Grid item xs={12} md={3}>
          {mdDown && <Text subtitle medium>Valor da chave: </Text>}
          <Text subtitle medium>
            {params.apiKey.name}
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          {mdDown && <Text subtitle medium>Data de Expiração: </Text>}
          <Text subtitle medium>
            {params.apiKey.expiresAt ?
              moment(params.apiKey.expiresAt).format("DD/MM/YYYY") :
              "Sem expiração"}
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          {mdDown && <Text subtitle medium>Código da chave: </Text>}
          {visible ? <Text size={12} style={{ wordWrap: "break-word" }}>{params.apiKey.value}</Text> : <Text subtitle medium>**********************</Text>}

        </Grid>
        <Grid item xs={12} md={3} display="inline-flex" flexDirection="row">
          <Tooltip title="Copiar chave">
            <IconButton key={0} size='small' onClick={() => { if (params.apiKey.value) navigator.clipboard.writeText(params.apiKey.value); }} color="primary" style={{ height: '18px', backgroundColor: 'transparent' }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Spacing right={PADDING} />
          <Tooltip title="Esconder/Mostrar chave">
            <IconButton key={0} size='small' onClick={() => setVisible(!visible)} color="primary" style={{ height: '18px', backgroundColor: 'transparent' }}>
              {visible ? <VisibilityIcon fontSize="small" /> : <VisibilityOffIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
          <Spacing right={PADDING} />
          {hasRights && hasRights('manage-api_keys') && params.apiKey.name != "Chave-Padrão" &&
            <Tooltip title="Deletar Chave">
              <IconButton key={0} size='small' onClick={() => deleteApiKey()} color="primary" style={{ height: '18px', backgroundColor: 'transparent' }}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default ApiKeysListItem;
