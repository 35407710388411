import React, { FC, useEffect } from "react";
import { Autocomplete, Container } from "@pix-force/react-atoms";

import { InputAdornmentUser, theme } from "src/components";
import { useSubscriptionsPlans } from "src/hooks/subscriptionsPlans";
import { ISelect } from "src/types";

const SubscriptionsPlansSelect: FC<ISelect> = (params: ISelect) => {
  const subscriptionsPlansHook = useSubscriptionsPlans();

  return (
    <Container fluid sx={{ flexGrow: 1 }}>
      <Autocomplete
        id="positions"
        required={params.required}
        value={params.value}
        setValue={params.setValue}
        inputValue={params.inputValue}
        setInputValue={params.setInputValue}
        variant={params.variant ?? "standard"}
        options={params.values ?? subscriptionsPlansHook.entities}
        placeHolder={params.placeholder ?? "Tipo de plano"}
        backgroundColor={theme.palette.grayLight.light}
        disabled={params.disabled}
        error={params.error}
        requiredExists
        startAdornment={params.startAdornment}
      />
    </Container>
  );
};

export default SubscriptionsPlansSelect;
