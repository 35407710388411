import React, { FC } from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Container, Modal, Spacing, Text } from '@pix-force/react-atoms';
import { BORDER_RADIUS } from '@pix-force/react-atoms/lib/utils/consts';

import { theme } from 'src/components';
import { IFeedbackInformation } from 'src/types';

const FeedbackInformation: FC<IFeedbackInformation> = (params: IFeedbackInformation) => {
    return (
        <Modal isOpened={params.open} onClose={params.onClose}>
            <Container fluid hasShadow veryPadded color={theme.palette.secondary.main} borderRadius={BORDER_RADIUS * 2} width="350px" height="auto">
                {params.title &&
                    <Container inline centered width="100%">
                        <Container style={{ flexGrow: 1 }} />
                        <Text center size={16} bold color={theme.palette.background.paper}>{params.title}</Text>
                        <Container style={{ flexGrow: 1 }} />
                        <IconButton size="small" style={{}} onClick={params.onClose}>
                            <CloseIcon fontSize="small" style={{ color: theme.palette.background.paper }} />
                        </IconButton>
                    </Container>
                }

                <Container fluid spacedAround veryPadded centered sx={{ maxWidth: 400 }}>
                    <Spacing top={10} />
                    {params.message && <Text bold center color={theme.palette.background.paper}>{params.message}</Text>}
                    {params.children}

                    <Spacing top={30} />
                    <Button variant="contained" size="small" sx={{ backgroundColor: theme.palette.primary.main, minWidth: '130px', borderRadius: 0 }}
                        onClick={params.onConfirm}>
                        <Text bold size={12} color={theme.palette.background.paper}>Continuar</Text>
                    </Button>
                </Container>
            </Container>
        </Modal>
    )
}

export default FeedbackInformation;