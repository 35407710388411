import { FC } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DayPicker } from "react-day-picker";
import ptBR from "date-fns/locale/pt-BR";
import "react-day-picker/dist/style.css";

import "./picker.css";
import { DateRangePickerProps } from "src/types";

/**
 * This component returns a datepicker from react-day-picker library
 * @component
 */
const DateRangePicker: FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  return (
    <LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
      <DayPicker
        mode="range"
        selected={props.selected}
        onSelect={props.onSelect}
        locale={ptBR}
      />
    </LocalizationProvider>
  );
};

export default DateRangePicker;
