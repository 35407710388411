import axios, { AxiosResponse } from "axios";

export const APP_VERSION = require("../../package.json").version;

let appURL: string = process.env.REACT_APP_URL ?? "http://localhost:3000";
let baseAPIDocumentsURL: string | undefined = process.env.REACT_APP_API_DOCUMENTS_URL ?? undefined;
let baseAPIDocumentsSocketURL: string = process.env.REACT_APP_SOCKET_URL ?? "ws://localhost:8000/";
let baseAPIFinancesURL: string | undefined = process.env.REACT_APP_API_FINANCES_URL ?? undefined;
let baseAPIAssistsURL: string | undefined = process.env.REACT_APP_API_ASSISTS_URL ?? undefined;
let baseAPIKeycloakURL: string | undefined = process.env.REACT_APP_API_KEYCLOAK_URL ?? undefined;
if (process.env.NODE_ENV == "production") {
  baseAPIDocumentsURL = "/api/api_documents";
  baseAPIDocumentsSocketURL = `wss://socket.documents.${window.location.hostname.replace("web", "api")}/`;
  baseAPIFinancesURL = "/api/api_finances";
  baseAPIAssistsURL = "/api/api_assists";
  baseAPIKeycloakURL = "/api/api_keycloak";
}

export const APP_URL = appURL;
export const API_DOCUMENTS_URL = baseAPIDocumentsURL;
export const API_DOCUMENTS_SOCKET_URL = baseAPIDocumentsSocketURL;
export const API_FINANCES_URL = baseAPIFinancesURL;
export const API_ASSISTS_URL = baseAPIAssistsURL;
export const API_KEYCLOAK_URL = baseAPIKeycloakURL;

let keycloakUrl: string = process.env.REACT_APP_KEYCLOAK_URL ?? "http://0.0.0.0:8080";
if (process.env.NODE_ENV == "production") {
  keycloakUrl = `https://${window.location.hostname.replace("web", "keycloak")}/`;
}
export const KEYCLOAK_URL = keycloakUrl;
export const KEYCLOAK_REALM: string = process.env.REACT_APP_KEYCLOAK_REALM ?? "pix_docs";
export const KEYCLOAK_CLIENT_ID: string = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "pix_docs";

const api = axios.create();

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    let statusCode = error?.response?.status ?? 401;

    if (statusCode == 401) {
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    }
    return Promise.resolve(error);
  }
);

export default api;
