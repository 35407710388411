import React, { FC, useEffect } from "react";
import { Container } from "@pix-force/react-atoms";

import { theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { usePrivileges } from "src/hooks/privileges";
import { ISelect } from "src/types";
import { Autocomplete, TextField } from "@mui/material";

const PrivilegesSelect: FC<ISelect> = (params: ISelect) => {
  const authHook = useAuth();
  const privilegesHook = usePrivileges();

  useEffect(() => {
    privilegesHook.fetchPrivileges();
  }, []);

  return (
    <Container fluid sx={{ flexGrow: 1 }}>
      <Autocomplete
        id="positions"
        value={params.value}
        onChange={(event: any, newValue: string | null) => {
          if (newValue) {
            params.setValue(newValue);
          }
        }}
        inputValue={params.inputValue}
        onInputChange={(event, newInputValue) => {
          params.setInputValue(newInputValue);
        }}
        options={params.values ?? privilegesHook.privileges.filter(item => {
          if (!authHook.isSuperAdmin() && item.name == "super-admin")
            return false;
          return true;
        }).map(item => item.attributes?.description[0] ?? item.name)}
        placeholder={params.placeholder ?? "Privilégios"}
        disabled={params.disabled}
        renderInput={(params) => <TextField {...params} label="Privilégio" />}
      />
    </Container>
  );
};

export default PrivilegesSelect;
