import { FC, createContext, useContext, useState } from "react";

import { ClientCardServices } from "../services/clientsCards";
import { IClientCard, IClientCardContext, IHookProvider } from "../types";

const ClientCardsContext = createContext<IClientCardContext>({} as IClientCardContext);

export const ClientCardsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const clientServices = new ClientCardServices();
  const [entities, setEntities] = useState<IClientCard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_clientCard: IClientCard) => {
    try {
      const clientCard = await clientServices.createEntity(_clientCard);
      fetchEntities();
      return clientCard;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _clientCard: IClientCard) => {
    try {
      const clientCard = await clientServices.updateEntity(_id, _clientCard);
      fetchEntities();
      return clientCard;
    } catch (_err) {
      throw _err;
    }
  };

  const editCardInfo = async (_id: string, payload: any) => {
    try {
      const clientCard = await clientServices.updateEntity(_id, payload);
      fetchEntities();
      return clientCard;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const clientCard = await clientServices.destroyEntity(_id);
      fetchEntities();
      return clientCard;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const clientsCards: IClientCard[] = await clientServices.getEntities();
      setEntities([...clientsCards]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const clientCard: IClientCard = await clientServices.getEntity(_id);
    return clientCard;
  };

  const fetchByClientId = async (_id: string) => {
    const clientsCards: IClientCard[] = await clientServices.getClientCardByClientId(_id);
    return clientsCards;
  };

  const checkoutSession = async (_clientId: string) => {
    const checkoutData: any = await clientServices.paymentApiCheckoutSession(_clientId);
    return checkoutData;
  };

  const checkoutSessionSuccess = async (_checkoutSessionId: string) => {
    const clientCard: IClientCard = await clientServices.paymentApiCheckoutSessionSuccess(_checkoutSessionId);
    return clientCard;
  };

  const makeItDefault = async (_id: string) => {
    const clientCard: IClientCard = await clientServices.makeItDefault(_id);
    return clientCard;
  };



  return (
    <ClientCardsContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        editCardInfo,
        deleteEntity,

        fetchByClientId,
        checkoutSession,
        checkoutSessionSuccess,
        makeItDefault,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate
      }}
    >
      {_params.children}
    </ClientCardsContext.Provider>
  );
};

export function useClientCards() {
  const context = useContext(ClientCardsContext);

  if (!context) {
    throw new Error("useClientCards must be used within an ClientCardsProvider");
  }

  return context;
}
