import React, { FC } from "react";
import { Grid } from "@mui/material";
import { Container, Text } from "@pix-force/react-atoms";
import { VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { theme } from "src/components";

const ApiKeyListHeader: FC = () => {
    return (
        <Container fluid flex>
            <Container fluid centered minHeight="25px" veryPadded color={theme.palette.backgroundStrokeActive.main}
                sx={{
                    border: `solid 1px ${theme.palette.backgroundStrokeActive.dark}`,
                    borderRadius: "4px",
                    paddingTop: VERY_PADDING,
                    paddingBottom: VERY_PADDING
                }}>
                <Grid container rowSpacing={2} columnSpacing={2} display="flex">
                    <Grid item xs={3}>
                        <Text>Nome da chave</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text>Data de expiração</Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text>
                            Valor da chave
                        </Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Text>Ações</Text>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default ApiKeyListHeader;
