import { EntityServices } from "./entities";
import { IProduct } from "../types";
import { parseStringToDate } from "../utils/dates";
import { API_FINANCES_URL } from ".";

export class ProductServices extends EntityServices<IProduct> {
  constructor() {
    super("Produto", `${API_FINANCES_URL}/products`);
  }

  override convertApiResponseToIEntity(_data: any): IProduct {
    return {
      id: _data.id,
      name: _data.name,
      description: _data.description,
      categories: _data.categories,
      status: _data.status,
      statusDesc: _data.statusDesc,
      usePaymentApi: _data.usePaymentApi,
      paymentApiId: _data.paymentApiId,
      paymentApiName: _data.paymentApiName,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
