import { FC } from "react";
import { Box, IconButton } from "@mui/material";
import {
    EditOutlined as EditIcon,
    DeleteOutlineOutlined as DeleteIcon,
} from '@mui/icons-material';

import { useAuth } from "src/hooks/auth";
import { useFeedback } from "src/hooks/feedback";
import { useUsers } from "src/hooks/users";
import { IUsersActionsCell } from "src/types/components/molecules/v2/users";
import { IUser } from "src/types";
import { isUser } from "src/types/hooks/users";

/**
 * This component returns the documents customs models action cell
 * Can be used in documents data grid
 * It uses mui styles
 * @component
 */
const UsersActionsCell: FC<IUsersActionsCell> = (props: IUsersActionsCell) => {
    // Auth hooks to get the user rights
    const auth = useAuth();
    // Information hooks for user feedback
    const feedback = useFeedback();
    // Users hooks for service operations
    const users = useUsers();

    // User id (the const never will be number, we treat id as UUID),
    // but the x-data-grid treats that way
    const id: string | number | undefined = props.cellParams?.id ?? undefined;

    /**
     * Function that deletes User
     * @function
     */
    const deleteUser = () => {
        if (!id) return null;

        feedback.open(true, "info", "Informação", undefined, "Deseja realmente excluir esse usuário?", undefined, undefined,
            ["Cancelar", "Excluir"], ["text", "contained"], [() => {
                feedback.close();
            }, async () => {
                if (id && typeof id === "string") {
                    const auxUser: IUser = await users.deleteEntity(id);
                    if (auth.client && auth.client.id)
                        users.fetchEntities();
                    if (auxUser) {
                        feedback.close();
                        feedback.open(true, "success", "Sucesso", undefined, "Usuário excluído com sucesso.");
                        window.location.reload();
                    }
                }
            }]);
    }

    if (!id) return null;
    return (
        <Box display="flex" flexDirection="row" alignItems="center" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
                {auth.hasRights?.("manage-clients__users") &&
                    <IconButton color="info" onClick={() => {
                        if (props.cellParams && isUser(props.cellParams.row))
                            props.onEdit(props.cellParams.row)
                    }}>
                        <EditIcon />
                    </IconButton>
                }
                {auth.hasRights?.("manage-clients__users") &&
                    <IconButton color="error" onClick={() => deleteUser()}>
                        <DeleteIcon />
                    </IconButton>
                }
            </Box>
        </Box >
    );
}

export default UsersActionsCell;
