import { FC, useState, useEffect } from "react";
import { Container, Text, Spacing } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import DocumentsDatalogItemCard from "../itemCard";
import DocumentsDatalogItemCardHeader from "../itemCard/header";
import Pagination from "src/components/molecules/table/pagination";
import theme from "src/components/theme";
import { useDocumentsCustomsModels } from "src/hooks/documentsCustomsModels";
import { useResponsive } from "src/hooks/responsive";
import { IDocumentCustomModel, IDocumentCustomModelList } from "src/types";

const DocumentsCustomsModelsList: FC<IDocumentCustomModelList> = (props: IDocumentCustomModelList) => {
    const documentsCustomsModelsHooks = useDocumentsCustomsModels();
    const { mdUp, screenHeight } = useResponsive();

    const [currPage, setCurrPage] = useState<number>(0);

    const perPage = Math.ceil((screenHeight * (mdUp ? 0.5 : 0.45)) / (mdUp ? 55 : 100));

    useEffect(() => {
        const auxCurrPage = parseInt(new URLSearchParams(window.location.search).get('datalogPage') ?? "1") - 1;
        if (auxCurrPage)
            setCurrPage(auxCurrPage);

        fetchData(auxCurrPage)

        documentsCustomsModelsHooks.setEntitiesPerRow(perPage.toString());
    }, []);

    useEffect(() => {
        fetchData(currPage);
    }, [currPage]);

    useEffect(() => {
        if (props.refreshList) {
            fetchData();
            props.setRefreshList && props.setRefreshList(false);
        }
    }, [props.refreshList])

    const fetchData = (_currPage: number = 0) => {
        documentsCustomsModelsHooks.fetchEntitiesByClientId(props.clientId, _currPage + 1, perPage);
    }

    return (
        <Container fluid flex spacedBetween width="100%" height="70vh" color={theme.palette.primary.dark} sx={{ padding: VERY_PADDING * 1.5 }}>
            <Spacing top={VERY_PADDING} />
            {mdUp &&
                <>
                    <DocumentsDatalogItemCardHeader />
                    <Spacing top={PADDING} />
                </>
            }
            <Container fluid height="100%">
                {documentsCustomsModelsHooks.entitiesPaginated.items.length == 0 &&
                    <Container fluid flex centered>
                        <Text bold size={17} color={theme.palette.background.paper}>Nenhum modelo encontrado.</Text>
                    </Container>
                }
                {props.clientId &&
                    documentsCustomsModelsHooks.entitiesPaginated.items.map(item =>
                        <DocumentsDatalogItemCard
                            clientId={props.clientId}
                            isOnClient={false}
                            onActionTriggered={(_documentCustomModel: IDocumentCustomModel, _action: "DELETE") => props.onActionTriggered(_documentCustomModel, _action)}
                            documentCustomModel={item}
                            currPage={currPage + 1} />
                    )
                }
            </Container>
            <Pagination page={currPage} pageCount={documentsCustomsModelsHooks.entitiesPaginated.pages} setPage={setCurrPage} colorButton={theme.palette.background.paper} colorActiveButton={theme.palette.secondary.main} />
        </Container >
    );
}

export default DocumentsCustomsModelsList;
