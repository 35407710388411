import { Axios, AxiosResponse } from "axios";

import api, { API_FINANCES_URL, APP_URL } from "./";
import { EntityServices } from "./entities";
import { IClient, IUser } from "../types";
import { parseStringToDate } from "../utils/dates";
import { UserServices } from "./users";

export class ClientServices extends EntityServices<IClient> {
  constructor() {
    super("Cliente", `${API_FINANCES_URL}/clients`);
  }
  clientsHasUsersUrl: string = `${API_FINANCES_URL}/clients_has_users`;

  async createClientHasUser(_clientId: string, _userId: string): Promise<boolean> {
    const response: AxiosResponse = await api.post(`${this.clientsHasUsersUrl}`, {
      clientId: _clientId,
      userId: _userId,
    });
    if (response.data && response.status === 200) {
      return true;
    }
    return false;
  }

  async getClientUsersId(_id: string): Promise<string[]> {
    const response: AxiosResponse = await api.get(`${this.clientsHasUsersUrl}/users_by_client/${_id}`);

    if (response.data && response?.request?.status === 200) {
      return response.data.usersId ?? [];
    }
    throw new Error("Não foi possível buscar os usuários do Cliente.");
  }

  async getClientUsersCount(_id: string): Promise<number> {
    const response: AxiosResponse = await api.get(`${this.clientsHasUsersUrl}/users_counts_by_client/${_id}`);
    if (response.data && response?.request?.status === 200) {
      return response.data.usersCount ?? 0;
    }
    return 0;
  }

  async getClientIdByUserId(_userId: string): Promise<string | undefined> {
    const response: AxiosResponse = await api.get(`${this.clientsHasUsersUrl}/client_id/${_userId}`);
    if (response.data && response?.request?.status === 200) {
      return response.data.clientId ?? undefined;
    }
    return undefined;
  }

  async getClientByUserId(_userId: string): Promise<IClient | undefined> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/by_user_id/${_userId}`);
    if (response.data && response?.request?.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    }
    return undefined;
  }

  async destroyClientHasUserbyUserId(_userId: string) {
    await api.delete(`${this.clientsHasUsersUrl}/client_id/${_userId}`);
  }

  async createCreditCard(_id: string) {
    const response: AxiosResponse = await api.post(`/api_finances/stripe_webhooks/checkout/${_id}`, {
      successUrl: `${APP_URL}/my-account/payment-methods/success?checkoutSessionId={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${APP_URL}/my-account/payment-methods/cancel`,
    });
    if (response.data && response?.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível buscar a quantidade de usuários do Cliente.");
  }

  override convertApiResponseToIEntity(_data: any): IClient {
    return {
      id: _data.id,
      name: _data.name,
      email: _data.email,
      cpfCnpj: _data.cpfCnpj,
      avatarUri:
        _data.avatarUri ??
        "https://static.wixstatic.com/media/0aa8f1_9cc5541cc1054b9fa5be86b667d38108~mv2.png/v1/fill/w_580,h_345,al_c/0aa8f1_9cc5541cc1054b9fa5be86b667d38108~mv2.png",
      country: _data.country,
      postalCode: _data.postalCode,
      street: _data.street,
      number: _data.number,
      additionalInformation: _data.additionalInformation,
      district: _data.district,
      state: _data.state,
      cityCode: _data.cityCode,
      cityName: _data.cityName,
      canChangeSubscription: _data.canChangeSubscription ?? false,

      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
