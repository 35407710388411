import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { useResponsive } from 'src/hooks/responsive';
import { IDefaultTemplate } from 'src/types';

/**
 * This component returns the Home page
 * It uses mui styles
 * @component
 */
const DefaultTemplate: FC<IDefaultTemplate> = (props: IDefaultTemplate) => {
    // Responsive hooks for layout changes
    const responsive = useResponsive();

    return (
        <Box display="flex" flexDirection="column" flex={1} flexGrow={1} alignItems="center" padding="40px">
            <Box display="flex" flexDirection="column" flex={1} flexGrow={1} maxWidth="1400px" width="100%">
                <Box display="flex" flexDirection="row" maxHeight="48px">
                    {props.headerStart}
                    <Typography color="primary" fontSize="28px" fontWeight="400" lineHeight="36px">
                        {props.title}
                    </Typography>
                    {responsive.smUp && props.headerEnd}
                </Box>
                {responsive.smDown && props.headerEnd}
                <Typography color="text.secondary" fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px"
                    paddingTop="16px">
                    {props.subtitle}
                </Typography>

                <Box display="flex" flexDirection="column" flex={1} flexGrow={1}>
                    {props.children}
                </Box>
            </Box>
        </Box>
    );
}

export default DefaultTemplate;
