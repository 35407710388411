import { FC, createContext, useContext, useState } from "react";

import SubscriptionService from "src/services/financesApi/subscriptions";
import { ISubscriptionContext, IHookProvider, SubscriptionProps } from "../types";

const SubscriptionsContext = createContext<ISubscriptionContext>({} as ISubscriptionContext);

export const SubscriptionsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const subscriptionService = new SubscriptionService();
  const [entities, setSubscriptions] = useState<SubscriptionProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_subscription: SubscriptionProps) => {
    try {
      const subscription = await subscriptionService.create(_subscription);
      fetchEntities();
      return subscription;
    } catch (_err) {
      if ((_err as any).message == "Subscription not create because product is not created on Payment API")
        throw ("Não foi possível criar a assinatura porque o produto do plano não está cadastrado na API de Pagamentos.");
      throw _err;
    }
  };

  const editEntity = async (_id: string, _subscription: SubscriptionProps) => {
    try {
      _subscription.id = _id;
      const subscription = await subscriptionService.update(_subscription);
      fetchEntities();
      return subscription;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const subscription = await subscriptionService.delete(_id);
      fetchEntities();
      return subscription;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const subscriptions: SubscriptionProps[] =
        await subscriptionService.getAll();
      setSubscriptions([...subscriptions]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const subscription: SubscriptionProps = await subscriptionService.getById(_id);
    return subscription;
  };

  const fetchCurrentSubscriptionOfClient = async (_clientId: string) => {
    const subscription: SubscriptionProps = await subscriptionService.getCurrentByClientId(_clientId);
    return subscription;
  };

  return (
    <SubscriptionsContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        fetchCurrentSubscriptionOfClient,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </SubscriptionsContext.Provider>
  );
};

export function useSubscriptions() {
  const context = useContext(SubscriptionsContext);

  if (!context) {
    throw new Error(
      "useSubscriptions must be used within an SubscriptionsProvider"
    );
  }

  return context;
}
