import { FC } from 'react';
import { Box, Button, Card, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

import { theme } from 'src/components';
import { IRegisterTemplate } from 'src/types';

/**
 * This component returns the Register Template
 * It uses mui styles
 * @component
 */
const RegisterTemplate: FC<IRegisterTemplate> = (props: IRegisterTemplate) => {
    return (
        <Box display="flex" paddingTop="48px">
            <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    flexGrow: 1,
                    width: "100%",
                    alignItems: "center",
                    paddingX: "32px",
                    paddingY: "24px",
                    borderRadius: "12px"
                }}>
                <CardContent sx={{ width: "100%", maxWidth: "100%" }}>
                    <Box display="flex" flexDirection="row" flex={1} alignItems="flex-start" justifyContent="space-between">
                        <Typography
                            fontSize="24px" fontStyle="normal" fontWeight="400"
                            lineHeight="32px"
                            color={theme.palette.text.primary}>
                            {props.title}
                        </Typography>
                        {props.subtitle &&
                            <IconButton sx={{ paddingBottom: "8px" }}>
                                <Tooltip title={props.subtitle}>
                                    <InfoIcon color="primary" />
                                </Tooltip>
                            </IconButton>
                        }

                        {/* {props.subt itle &&
                            <Typography
                                fontSize="16px" fontStyle="normal" fontWeight="400"
                                lineHeight="20px" letterSpacing="0.25px"
                                color={theme.palette.text.secondary}
                                paddingTop="16px">
                                {props.subtitle}
                            </Typography>
                        } */}
                    </Box>
                    <Box display="flex" flexDirection="row" flex={2} alignItems="center" paddingY="24px" maxWidth="100%"
                        sx={{ overflowX: "auto" }}>
                        {props.children}
                    </Box>
                    <Box display="flex" flexDirection="row" flex={3} justifyContent="flex-end" paddingTop="24px" width="100%"
                        sx={{ overflowX: "auto" }}>
                        <Button variant="text" color="primary"
                            onClick={() => props.cancelClick && props.cancelClick()}>
                            <Typography fontSize="14px" fontStyle="normal" fontWeight="500"
                                lineHeight="20px" letterSpacing="0.1px" textTransform="initial">
                                {props.cancelButtonLabel ?? "Cancelar"}
                            </Typography>
                        </Button>

                        <Button variant="contained" color="primary"
                            onClick={() => props.okClick && props.okClick()}
                            sx={{ marginLeft: "16px" }}>
                            <Typography fontSize="14px" fontStyle="normal" fontWeight="500"
                                lineHeight="20px" letterSpacing="0.1px" textTransform="initial">
                                {props.okButtonLabel ?? "Salvar"}
                            </Typography>
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}

export default RegisterTemplate;
