import { FC } from "react";
import { DatePicker as MuiDatePicker, DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ptBR } from '@mui/x-date-pickers/locales';

import theme from "src/components/theme";

const definedStyles = (theme: any) => {
    return {
        'height': "40px",
        '& .MuiOutlinedInput-root': {
            'height': "40px",
            '& fieldset': {
                'height': "40px",
                borderWidth: "3px",
                borderColor: theme.palette.primary.main
            },
            '& label.Mui-focused': {
                opacity: 1, // Torna o rótulo (placeholder) sempre visível
            },
        },
    }
}

const definedInputPropsStyles = (theme: any) => {
    return {
        color: theme.palette.primary.main,
        '&::placeholder': {
            color: theme.palette.primary.main
        },
        '& label.Mui-focused': {
            opacity: 1, // Torna o rótulo (placeholder) sempre visível
        },
    }
}

const DatePicker: FC<DatePickerProps<Date>> = (props: DatePickerProps<Date>) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}
            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
            <MuiDatePicker {...props}
                sx={{ ...definedStyles(theme), ...props.sx }} />
        </LocalizationProvider>
    );
}

export default DatePicker;