import { AxiosResponse } from "axios";

import api, { API_FINANCES_URL } from ".";
import { ClientServices } from "./clients";
import { EntityServices } from "./entities";
import { IInvoice, IInvoiceDocumentCount, IClient, SubscriptionProps, SubscriptionPlanProps, IInvoiceItem } from "../types";
import { parseStringToDate } from "src/utils/dates";
import { getDocumentDesc } from "src/hooks/invoices";
import SubscriptionService from "./financesApi/subscriptions";
import SubscriptionPlanService from "./financesApi/subscriptionPlans";

export class InvoiceServices extends EntityServices<IInvoice> {
  constructor() {
    super("Inscrição", `${API_FINANCES_URL}/invoices`);
  }

  async getInvoicesByClient(_clientId: string): Promise<IInvoice[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/by_client/${_clientId}`);

    if (response.data && response?.request?.status === 200) {
      const entities: IInvoice[] = [];
      for (let i = 0; i < response.data.length; i++) {
        const entity: IInvoice = this.convertApiResponseToIEntity(response.data[i]);
        entities.push(entity);
      }
      return entities;
    }
    return [];
  }

  async getInvoiceDocumentsCount(_id: string): Promise<IInvoiceDocumentCount[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/${_id}/documents_count`);

    if (response.data && response?.request?.status === 200) {
      const entities: IInvoiceDocumentCount[] = [];
      for (let i = 0; i < response.data.length; i++) {
        const entity: IInvoiceDocumentCount = this.convertApiResponseToIInvoiceDocumentCount(response.data[i]);
        entities.push(entity);
      }
      return entities;
    }
    return [];
  }

  async getInvoiceDocumentsCountCurrentClient(_client_id: string): Promise<IInvoiceDocumentCount[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/by_client_documents_count/${_client_id}`);

    if (response.data && response?.request?.status === 200) {
      const entities: IInvoiceDocumentCount[] = [];
      for (let i = 0; i < response.data.length; i++) {
        const entity: IInvoiceDocumentCount = this.convertApiResponseToIInvoiceDocumentCount(response.data[i]);
        entities.push(entity);
      }
      return entities;
    }
    return [];
  }

  override convertApiResponseToIEntity(_data: any): IInvoice {
    let client: IClient | undefined = undefined;
    if (_data?.client) {
      const clientServices = new ClientServices();
      client = clientServices.convertApiResponseToIEntity(_data.client);
    }
    let subscription: SubscriptionProps | undefined = undefined;
    if (_data?.subscription) {
      subscription = new SubscriptionService().convertApiResponseToIEntity(_data.subscription);
    }

    const items: IInvoiceItem[] = [];
    for (let i = 0; i < _data.items; i++) {
      items.push(this.convertApiResponseToIInvoiceItem(_data.items[i], (i + 1).toString()));
    }

    return {
      id: _data.id ?? "upcoming",
      subscriptionId: _data.subscriptionId,
      subscription,
      paymentApiId: _data.paymentApiId,
      periodEnd: parseStringToDate(_data.periodEnd, "YYYY-MM-DD"),
      periodStart: parseStringToDate(_data.periodStart, "YYYY-MM-DD"),
      amountDue: _data.amountDue,
      amountPaid: _data.amountPaid,
      amountRemaining: _data.amountRemaining,
      reason: _data.reason,
      reasonDesc: _data.reasonDesc,
      paid: _data.paid,
      subtotal: _data.subtotal,
      total: _data.total,
      paidAt: _data.paidAt ? parseStringToDate(_data.paidAt, "YYYY-MM-DD") : undefined,
      status: _data.status,
      statusDesc: _data.statusDesc,
      items: _data.items,
      url: _data.url,
      url_pdf: _data.urlPdf,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }

  convertApiResponseToIInvoiceItem(_data: any, _id?: string): IInvoiceItem {
    let client: IClient | undefined = undefined;
    if (_data?.client) {
      const clientServices = new ClientServices();
      client = clientServices.convertApiResponseToIEntity(_data.client);
    }
    let subscriptionPlan: SubscriptionPlanProps | undefined = undefined;
    if (_data?.subscriptionPlan) {
      subscriptionPlan = new SubscriptionPlanService().convertApiResponseToIEntity(_data.subscription);
    }

    return {
      id: _id ?? "",
      invoiceId: _data.invoiceId,
      subscriptionPlanId: _data.subscriptionPlanId,
      subscriptionPlan,
      paymentApiId: _data.paymentApiId,
      description: _data.description,
      amount: _data.amount,
      quantity: _data.quantity,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }

  convertApiResponseToIInvoiceDocumentCount(_data: any): IInvoiceDocumentCount {
    return {
      id: _data.id,
      pages: _data.pages,
      documentType: _data.documentType,
      documentDesc: getDocumentDesc(_data.documentType),
    };
  }
}
