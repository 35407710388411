import { IEntity, IEntityContext } from "./entities";
import { ClientHasUsersProps, UserProps } from "./financesApi/clients";

interface IClient extends IEntity {
  name: string;
  email?: string;
  cpfCnpj?: string;
  avatarUri?: string;
  country?: string;
  postalCode?: string;
  street?: string;
  number?: string;
  additionalInformation?: string;
  district?: string;
  state?: string;
  cityCode?: string;
  cityName?: string;
  canChangeSubscription?: boolean;
}

interface IClientContext extends IEntityContext<IClient> {
  fetchClientUsersId: (_id: string) => Promise<string[]>;
  fetchClientUsersCount: (_id: string) => Promise<number>;
  fetchClientByUserId: (_userId: string) => Promise<IClient | undefined>;

  search: string;
  setSearch: (_search: string) => void;
  filteredEntities: IClient[];

  getUsers: (id: string) => Promise<UserProps[]>;
  createClientHasUser: (_clientId: string, _userId: string) => Promise<ClientHasUsersProps>;
}

export function isClient(_data: any): _data is IClient {
  return "name" in _data;
}

export type { IClient, IClientContext };
