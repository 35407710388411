import React, { FC, createContext, useContext, useState } from "react";

import { SubscriptionPlanPriceServices } from "src/services/subscriptionsPlansPrices";
import { ISubscriptionPlanPrice, ISubscriptionPlanPriceContext, IHookProvider } from "src/types";

const SubscriptionsPlansPricesContext = createContext<ISubscriptionPlanPriceContext>({} as ISubscriptionPlanPriceContext);

export const SubscriptionsPlansPricesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const subscriptionPlanPriceServices = new SubscriptionPlanPriceServices();
  const [entities, setSubscriptionsPlansPrices] = useState<ISubscriptionPlanPrice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_subscriptionPlanPrice: ISubscriptionPlanPrice) => {
    try {
      const subscriptionPlanPrice = await subscriptionPlanPriceServices.createEntity(_subscriptionPlanPrice);
      fetchEntities();
      return subscriptionPlanPrice;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _subscriptionPlanPrice: ISubscriptionPlanPrice) => {
    try {
      const subscriptionPlanPrice = await subscriptionPlanPriceServices.updateEntity(_id, _subscriptionPlanPrice);
      fetchEntities();
      return subscriptionPlanPrice;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const subscriptionPlanPrice = await subscriptionPlanPriceServices.destroyEntity(_id);
      fetchEntities();
      return subscriptionPlanPrice;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteBySubscriptionPlan = async (_subscriptionPlanId: string) => {
    try {
      const subscriptionPlanPrice = await subscriptionPlanPriceServices.destroyBySubscriptionPlan(_subscriptionPlanId);
      fetchEntities();
      return subscriptionPlanPrice;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const subscriptionsPlansPrices: ISubscriptionPlanPrice[] = await subscriptionPlanPriceServices.getEntities();
      setSubscriptionsPlansPrices([...subscriptionsPlansPrices]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const subscriptionPlanPrice: ISubscriptionPlanPrice = await subscriptionPlanPriceServices.getEntity(_id);
    return subscriptionPlanPrice;
  };

  return (
    <SubscriptionsPlansPricesContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,
        deleteBySubscriptionPlan,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </SubscriptionsPlansPricesContext.Provider>
  );
};

export function useSubscriptionsPlansPrices() {
  const context = useContext(SubscriptionsPlansPricesContext);

  if (!context) {
    throw new Error(
      "useSubscriptionsPlansPrices must be used within an SubscriptionsPlansPricesProvider"
    );
  }

  return context;
}
