import { FC, createContext, useContext, useState } from "react";

import { DocumentModelFavoriteServices } from "../services/documentModelFavorites";
import { IDocumentModelFavorite, IDocumentModelFavoriteContext, IHookProvider } from "../types";

/**
 * This component is the context of the Document model favorites hooks
 * @component
 */
const DocumentModelFavoritesContext = createContext<IDocumentModelFavoriteContext>({} as IDocumentModelFavoriteContext);

/**
 * This component provides the Document model favorites hooks
 * @component
 */
export const DocumentModelFavoritesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
    // Service that communicate with back-end
    const service = new DocumentModelFavoriteServices();

    // State for Entities of database
    const [entities, setEntities] = useState<IDocumentModelFavorite[]>([]);
    // State for loading status
    const [loading, setLoading] = useState<boolean>(false);
    // State for searching an entity
    const [searchEntity, setSearchEntity] = useState<string>("");
    // State for quantity of entities per row
    const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
    // State for how much paginate the data will return
    const [paginate, setPaginate] = useState<number>(0);

    /**
     * Function to create new entity
     * @function
     */
    const createNewEntity = async (_entity: IDocumentModelFavorite) => {
        try {
            const entity = await service.createEntity(_entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    /**
     * Function to update an entity
     * @function
     */
    const editEntity = async (_id: string, _entity: IDocumentModelFavorite) => {
        try {
            const entity = await service.updateEntity(_id, _entity);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    /**
     * Function to delete an entity
     * @function
     */
    const deleteEntity = async (_id: string) => {
        try {
            const entity = await service.destroyEntity(_id);
            fetchEntities();
            return entity;
        } catch (_err) {
            throw _err;
        }
    };

    /**
     * Function to fetch all entities
     * @function
     */
    const fetchEntities = async () => {
        setLoading(true);
        try {
            const documentModelFavorites: IDocumentModelFavorite[] = await service.getEntities();
            setEntities([...documentModelFavorites]);

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        } catch (_err) {
            console.log(_err);
            setLoading(false);
        }
    };

    /**
     * Function to fetch an entity
     * @function
     */
    const fetchEntity = async (_id: string, _query?: string) => {
        const entity: IDocumentModelFavorite = await service.getEntity(_id, _query);
        return entity;
    };

    return (
        <DocumentModelFavoritesContext.Provider
            value={{
                entities,
                loading,
                fetchEntities,
                fetchEntity,
                createNewEntity,
                editEntity,
                deleteEntity,

                searchEntity,
                setSearchEntity,
                entitiesPerRow,
                setEntitiesPerRow,
                paginate,
                setPaginate,
            }}
        >
            {_params.children}
        </DocumentModelFavoritesContext.Provider>
    );
};

export function useDocumentModelFavorites() {
    const context = useContext(DocumentModelFavoritesContext);

    if (!context) {
        throw new Error(
            "useDocumentModelFavorites must be used within an DocumentModelFavoritesProvider"
        );
    }

    return context;
}
