import { FC, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Container, Spacing, Text } from "@pix-force/react-atoms";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import theme from "src/components/theme";
import { IDocumentMultiSelect } from "src/types";
import DocumentMultiSelectItem from "./item";
import { LoadingButton } from "@mui/lab";

const DocumentMultiSelect: FC<IDocumentMultiSelect> = (props: IDocumentMultiSelect) => {
    const [selectedDocumentsIds, setSelectedDocumentsIds] = useState<string[]>([]);

    const selectAll = () => {
        if (selectedDocumentsIds.length == props.documents.length)
            setSelectedDocumentsIds([]);
        else
            setSelectedDocumentsIds([...props.documents.map(item => item.id ?? "")]);
    }

    const selectDocument = (_id: string) => {
        if (selectedDocumentsIds.indexOf(_id) >= 0) {
            const auxSelectedDocumentsIds: string[] = [];
            selectedDocumentsIds.forEach(item => {
                if (item != _id) auxSelectedDocumentsIds.push(item);
            });
            setSelectedDocumentsIds(auxSelectedDocumentsIds);
        } else
            setSelectedDocumentsIds([...selectedDocumentsIds, _id]);
    }

    return (
        <Container relative fluid centered color={theme.palette.background.paper} width="80%" height="90vh" borderRadius={BORDER_RADIUS}>
            <Container absolute color={theme.palette.primary.dark} width="100%" height="50vh" borderBottomRadius={BORDER_RADIUS} sx={{ left: 0, bottom: 0, zIndex: 1, opacity: 1 }} />

            <Container fluid flex width="99%" height="90vh" sx={{ zIndex: 2 }}>
                <Container fluid flex horizontalCentered width="100%">
                    <Container relative centered fluid veryPadded width="100%">
                        <Spacing top={VERY_PADDING} />
                        <Text extraBold color={theme.palette.primary.dark} size={35}>
                            Download de extração de documentos
                        </Text>
                        <Text size={25}>
                            Selecione todos os documentos que deseja fazer download
                        </Text>

                        <IconButton size="small" onClick={() => props.onClose()} sx={{ color: theme.palette.primary.dark, position: "absolute", top: -5, right: PADDING, }}>
                            <CloseIcon sx={{ fontSize: 45 }} />
                        </IconButton>
                    </Container>

                    <Container fluid flex centered width="100%" height="100%">
                        <Container fluid centered veryVerticalPadded color={theme.palette.secondary.main} width="90%" height="90%" borderRadius={20}
                            sx={{ paddingTop: PADDING }}>
                            <Container fluid color={theme.palette.secondary.dark} width="98%" height="85%" borderRadius={10}>
                                <Container fluid sx={{ paddingInline: VERY_PADDING * 2 }}>
                                    <Grid container paddingTop={3} paddingBottom={3} alignItems="center">
                                        <Grid item xs={2}>
                                            <Text semiBold size={18} color={theme.palette.background.paper} style={{ cursor: "pointer", "userSelect": "none" }}
                                                onClick={() => selectAll()}>
                                                Selecionado
                                            </Text>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Text semiBold size={18} color={theme.palette.background.paper}>
                                                ID
                                            </Text>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Text semiBold size={18} color={theme.palette.background.paper}>
                                                Modelo
                                            </Text>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Text semiBold size={18} color={theme.palette.background.paper}>
                                                Data
                                            </Text>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Text semiBold size={18} color={theme.palette.background.paper}>
                                                Hora
                                            </Text>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container fluid color={theme.palette.primary.dark} width="100%" maxHeight="60vh" borderRadius={10} sx={{ overflowY: "scroll" }}>
                                    {props.documents.filter(item => item.status == 1).map(item => <DocumentMultiSelectItem
                                        selectedDocumentsId={selectedDocumentsIds}
                                        document={item}
                                        onSelect={(_id: string) => selectDocument(_id)}
                                    />)}
                                </Container>
                            </Container>
                            <Spacing top={VERY_PADDING} />
                            <LoadingButton variant="contained" color="primary" sx={{ backgroundColor: theme.palette.primary.dark }}
                                disabled={props.loading ?? selectedDocumentsIds.length == 0} onClick={() => props.onAction(selectedDocumentsIds)}>
                                <Text bold size={16} color={theme.palette.background.paper}>
                                    {props.actionLabel ?? "Confirmar"}
                                </Text>
                            </LoadingButton>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Container>
    );
}

export default DocumentMultiSelect;
