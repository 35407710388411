import React from 'react';
import { Page } from "@pix-force/react-atoms";

const Invoices: React.FC = () => {
    return (
        <Page>
            <>
            </>
        </Page>
    );
}

export default Invoices;
