import { FC } from "react";
import { Grid } from "@mui/material";
import { Container, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { theme } from "src/components";
import { useResponsive } from "src/hooks/responsive";

const DocumentsDatalogItemCardHeader: FC = () => {
    const responsiveHooks = useResponsive();

    const textFontSize: number = responsiveHooks.lgUp ? 15 : 12;

    return (
        <Container fluid flex>
            <Container fluid centered minHeight="25px" color={theme.palette.secondary.main}
                sx={{
                    borderRadius: "2px",
                    paddingTop: PADDING,
                    paddingBottom: PADDING
                }}>
                <Grid container rowSpacing={2} columnSpacing={2} display="flex" paddingLeft={2} alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={6} md={2} display="flex">
                        <Text size={textFontSize} color={theme.palette.background.paper}>
                            Data de criação
                        </Text>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} display="flex">
                        <Text size={textFontSize} color={theme.palette.background.paper}>
                            Nome
                        </Text>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} display="flex">
                        <Text size={textFontSize} color={theme.palette.background.paper}>
                            Descrição
                        </Text>
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} display="flex" justifyContent="center">
                        <Text size={textFontSize} color={theme.palette.background.paper}>
                            Ação
                        </Text>
                    </Grid>
                </Grid>
            </Container>
        </Container >
    );
};

export default DocumentsDatalogItemCardHeader;
