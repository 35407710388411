import { EntityServices } from "./entities";
import { IContactUsForm } from "src/types";
import { parseStringToDate } from "src/utils/dates";
import { API_ASSISTS_URL } from ".";

export class ContactUsFormServices extends EntityServices<IContactUsForm> {
  constructor() {
    super("Fale conosco", `${API_ASSISTS_URL}/contact_us_forms`);
  }

  override convertApiResponseToIEntity(_data: any): IContactUsForm {
    return {
      id: _data.id,
      userId: _data.userId,
      name: _data.name,
      email: _data.email,
      subject: _data.subject,
      message: _data.message,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
