import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Slide, SlideProps, Snackbar } from '@mui/material';
import { Button, Text } from '@pix-force/react-atoms';

import AppRoutesV2 from './v2/app';
import AuthRoutes from "./auth";
import { FeedbackConfirmation, FeedbackInformation, theme } from 'src/components';
import FeedbackDialog from 'src/components/molecules/v2/feedbackDialog';
import { useAuth } from 'src/hooks/auth';
import { useFeedback } from 'src/hooks/feedback';
import { useSnackbar } from 'src/hooks/snackbar';
import { Splash } from 'src/pages';
import { useConfirmation } from 'src/hooks/confirmation';
import { useInformation } from 'src/hooks/information';

const PUBLIC_ROUTES: string[] = ["/create-account"];

const Routes: React.FC = () => {
    const authHooks = useAuth();
    const locationHooks = useLocation();

    const [isPublicRoutes, setIsPublicRoutes] = useState<boolean>(false);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        init(false);
    }, [locationHooks.pathname]);

    const init = (_initKeycloak: boolean = true) => {
        const auxIsPublicRoutes: boolean = PUBLIC_ROUTES.find(item => item === locationHooks.pathname) !== undefined ?? false;
        setIsPublicRoutes(auxIsPublicRoutes);
        if (_initKeycloak && !auxIsPublicRoutes)
            authHooks.keycloakInit();
    }

    if (isPublicRoutes)
        return (
            <RoutesProvider>
                <AuthRoutes />
            </RoutesProvider>
        );

    if (authHooks.keycloak === undefined) return <Splash />;

    return (
        <RoutesProvider>
            {authHooks.keycloak.authenticated ? <AppRoutesV2 /> : <AuthRoutes />}
        </RoutesProvider>
    )
}

interface IRoutesProvider {
    children: React.ReactNode;
}

const RoutesProvider: React.FC<IRoutesProvider> = (props: IRoutesProvider) => {
    // Feedback hooks for user alert control
    const feedback = useFeedback();
    const {
        confirmationOpen, confirmationTitle, confirmationMessage,
        confirmationConfirmLabel, confirmationCancelLabel,
        confirmationOnConfirmClick, confirmationOnCancelClick,
        confirmationChildren, hideConfirmation } = useConfirmation();
    const { informationOpen, informationTitle, informationMessage, informationOnConfirmClick, hideInformation } = useInformation();
    const snackbarHooks = useSnackbar();

    const [confirmationLoadingAction, setConfirmationLoadingAction] = useState<boolean>(false);

    return (
        <>
            {props.children}

            <FeedbackDialog show={feedback.show} variant={feedback.variant} onClose={() => feedback.close()}
                title={feedback.title}
                message={feedback.message}
                buttonsLabels={feedback.buttonsLabels}
                buttonsVariant={feedback.buttonsVariant}
                buttonsOnClick={feedback.buttonsOnClick} />

            <FeedbackConfirmation
                open={confirmationOpen}
                title={confirmationTitle}
                message={confirmationMessage}
                onClose={() => {
                    if (confirmationOnCancelClick) confirmationOnCancelClick();
                    else hideConfirmation();
                }}
                cancelLabel={confirmationCancelLabel ? confirmationCancelLabel : "Cancelar"}
                onCancel={() => {
                    if (confirmationOnCancelClick) confirmationOnCancelClick();
                    else hideConfirmation();
                }}
                loading={confirmationLoadingAction}
                onConfirm={async () => {
                    setConfirmationLoadingAction(true);
                    if (confirmationOnConfirmClick != undefined) {
                        await confirmationOnConfirmClick();
                    }
                    else hideConfirmation();
                    setConfirmationLoadingAction(false);
                }}
                confirmLabel={confirmationConfirmLabel ? confirmationConfirmLabel : "Confirmar"}
                children={confirmationChildren} />
            <FeedbackInformation
                open={informationOpen}
                title={informationTitle}
                message={informationMessage}
                onClose={() => hideInformation()}
                onConfirm={() => {
                    if (informationOnConfirmClick) informationOnConfirmClick();
                    else hideInformation();
                }} />

            <Snackbar
                TransitionComponent={(props: SlideProps) => <Slide {...props} direction="up" />}
                open={snackbarHooks.snackbarOpen}
                autoHideDuration={10 * 1000}
                onClose={() => snackbarHooks.hideSnackbar()}>
                <Alert onClose={() => snackbarHooks.hideSnackbar()} severity={snackbarHooks.snackbarSeverity ?? "success"} sx={{ width: '100%' }}
                    action={<Button variant="text" color="primary" size="small" onClick={() => snackbarHooks.snackbarOnActionClick && snackbarHooks.snackbarOnActionClick()}>
                        <Text bold color={theme.palette.primary.main}>
                            {snackbarHooks.snackbarActionLabel}
                        </Text>
                    </Button>}>
                    <Text>{snackbarHooks.snackbarMessage}</Text>
                </Alert>
            </Snackbar>
        </ >
    );
}

export default Routes;
