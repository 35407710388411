import React from 'react';
import { Page } from "@pix-force/react-atoms";

const Dashboard: React.FC = () => {
    return (
        <Page>
            <>
            </>
        </Page>
    );
}

export default Dashboard;
