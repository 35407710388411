import { FC, useState } from "react";
import { Box, Button, Fab } from "@mui/material";
import { AddIcCallOutlined, ZoomIn, ZoomOut } from "@mui/icons-material";

import { PdfReaderProps } from "src/types";

const PdfReader: FC<PdfReaderProps> = (props: PdfReaderProps) => {
  //State for zoom
  const [zoom, setZoom] = useState<number>(props.miniature ? 0 : 50);
  /**
   * Function to handle click on zoom out button and decrese 50 from zoom state
   * @function
   */
  const handleZoomIn = () => {
    setZoom(zoom + 50);
  };
  /**
   * Function to handle click on zoom in button and add 50 to zoom state
   * @function
   */
  const handleZoomOut = () => {
    setZoom(zoom - 50);
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexGrow={1}
      flexDirection="column"
      justifyContent="flex-end"
      height={props.height}
    >
      <iframe
        src={`${props.fileUrl}#toolbar=0&zoom=${zoom}&page=${
          props.page || "1"
        }`}
        key={`${props.fileUrl}#toolbar=0&zoom=${zoom}&page=${
          props.page || "1"
        }`}
        width="100%"
        height="100%"
      />
      {!props.miniature && (
        <>
          <Fab
            size="small"
            onClick={() => handleZoomIn()}
            disabled={zoom >= 450 || props.miniature}
            sx={{
              position: "absolute",
              right: 40,
              bottom: 10,
              marginRight: "6px",
            }}
          >
            <ZoomIn color="primary" fontSize="medium" />
          </Fab>
          <Fab
            size="small"
            onClick={() => handleZoomOut()}
            disabled={zoom <= 50 || props.miniature}
            sx={{
              position: "absolute",
              right: 0,
              bottom: 10,
            }}
          >
            <ZoomOut color="primary" fontSize="medium" />
          </Fab>
        </>
      )}
    </Box>
  );
};

export default PdfReader;
