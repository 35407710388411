import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DateRange } from "react-day-picker";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { DocumentServices } from "src/services/documents";
import { useSnackbar } from "src/hooks/snackbar";
import { API_DOCUMENTS_SOCKET_URL } from "src/services";
import {
  IDocument,
  IDocumentContext,
  IDocumentType,
  IDocumentPayload,
  IHookProvider,
  IEntityPaginated,
  ApiKeyProps,
} from "src/types";
import { useAuth } from "./auth";
import {
  DocumentResponseProps,
  isDocumentResponse,
} from "src/types/hooks/documents";

//Variable to initialize range state
const initialRange: DateRange = {
  from: moment().startOf("month").toDate(),
  to: new Date(),
};

const DocumentsContext = createContext<IDocumentContext>(
  {} as IDocumentContext
);

const SOCKET_DOCUMENT_FINISHED_URL: string = "documents/ws/document_finished";

const blankEntitiesPaginated: IEntityPaginated<IDocument> = {
  items: [],
  pages: 0,
  page: 0,
  total: 0,
};

export const DocumentsProvider: FC<IHookProvider> = (
  _params: IHookProvider
) => {
  const documentServices = new DocumentServices();
  const authHooks = useAuth();
  const navigate = useNavigate();
  const snackbarHooks = useSnackbar();

  // Document finished websocket controls
  // Ref for WebSocket object
  const socketDocumentFinished = useRef<WebSocket>();
  // State that marks if the socket will try to reconnect until the max attempts
  const [socketDocumentFinishedShouldReconnect, setSocketDocumentFinishedShouldReconnect] = useState(true); // Controla se deve reconectar
  // State that saves the amount of attempts to reconnect document finished websocket
  const [socketDocumentFinishedreconnectAttempts, setSocketDocumentFinishedReconnectAttempts] = useState<number>(0);

  const [entities, setEntities] = useState<IDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);
  const [entitiesPaginated, setEntitiesPaginated] = useState<
    IEntityPaginated<IDocument>
  >(blankEntitiesPaginated);

  const [availableTypes, setAvailableTypes] = useState<IDocumentType[]>([]);
  const [documentsTypes, setDocumentsTypes] = useState<IDocumentType[]>([]);
  const [documentCount, setDocumentCount] = useState<number>(0);
  const [documentsYear, setDocumentsYear] = useState<number>(
    new Date().getFullYear()
  );
  const [currentDocumentFinished, setCurrentDocumentFinished] = useState<
    IDocument | undefined
  >(undefined);

  // State for current data grid page
  const [dataGridRowsPerPage, setDataGridRowsPerPage] = useState<number>(5);
  // State for current data grid page
  const [dataGridPage, setDataGridPage] = useState<number>(1);
  // State for input search
  const [dataGridQuery, setDataGridQuery] = useState<string>("");
  // State for current crud filter initial date
  const [filterInitialDate, setFilterInitialDate] = useState<Date | null>(
    moment().startOf("month").toDate()
  );
  // State for current crud filter final date
  const [filterFinalDate, setFilterFinalDate] = useState<Date | null>(
    moment().endOf("month").toDate()
  );
  //State for search input
  const [search, setSearch] = useState<string>("");
  //State for initial range
  const [range, setRange] = useState<DateRange | undefined>(undefined);
  //State for select component
  const [period, setPeriod] = useState<string>("2");
  // State for filterSearch
  const [filterSearch, setFilterSearch] = useState<string>("");

  const [queryString, setQueryString] = useState<string>("");

  useEffect(() => {
    if (authHooks.subscription && authHooks.user?.id) {
      connectDocumentFinishedSocket(
        authHooks.subscription.clientId,
        authHooks.user.id
      );
    }
  }, [authHooks.subscription, authHooks.user]);

  useEffect(() => {
    if (!currentDocumentFinished) return;

    let auxDocumentsPaginated: IEntityPaginated<IDocument> = entitiesPaginated;
    auxDocumentsPaginated.items = entitiesPaginated.items.map((item) => {
      if (item.id == currentDocumentFinished.id) return currentDocumentFinished;
      return item;
    });
    setEntitiesPaginated({ ...auxDocumentsPaginated });
  }, [currentDocumentFinished]);

  const connectDocumentFinishedSocket = (clientId: string, userId: string) => {
    if (!socketDocumentFinishedShouldReconnect) return;

    socketDocumentFinished.current = new WebSocket(
      `${API_DOCUMENTS_SOCKET_URL}${SOCKET_DOCUMENT_FINISHED_URL}/${clientId}/${userId}`
    );
    socketDocumentFinished.current.onopen = (ev: Event) => {
      setSocketDocumentFinishedReconnectAttempts(0);

      if (
        socketDocumentFinished.current &&
        socketDocumentFinished.current.readyState === 1
      )
        socketDocumentFinished.current.send("FETCH");
    };
    (socketDocumentFinished.current as WebSocket).onmessage = (
      ev: MessageEvent<any>
    ) => {
      onDocumentFinishedSocketMessage(ev.data);
    };
    socketDocumentFinished.current.onclose = (ev: Event) => {
      // if the socket is closed for some reason, the reconnect method will try to reconnect
      reconnectDocumentFinishedSocket(clientId, userId);
    };

    const wsCurrent = socketDocumentFinished.current;
    return () => {
      wsCurrent.close();
    };
  };

  /**
   * Method to reconnect document finished socket when the connection is closed
   * The max of reconnects attempts is set to 5, with an exponencial delay with 180s top
   * @param clientId string
   * @param userId string
   */
  const reconnectDocumentFinishedSocket = (clientId: string, userId: string) => {
    const maxReconnectAttempts = 10;
    const reconnectDelay = Math.min(5000 * Math.pow(2, socketDocumentFinishedreconnectAttempts), 180000);

    if (socketDocumentFinishedreconnectAttempts < maxReconnectAttempts) {
      setTimeout(() => {
        setSocketDocumentFinishedReconnectAttempts((prev) => prev + 1);
        connectDocumentFinishedSocket(clientId, userId);
      }, reconnectDelay);
    } else setSocketDocumentFinishedShouldReconnect(false);
  };

  const onDocumentFinishedSocketMessage = async (message: string) => {
    const documentId: string = message;
    const document: IDocument | undefined = await documentServices.getEntity(
      documentId
    );
    if (!document) return;

    if (document.status == 1) {
      snackbarHooks.showSnackbar(
        `O documento ${document.index} foi finalizado`,
        "Ver detalhes",
        () => {
          navigate(`/documents/${document.id}`);
          snackbarHooks.hideSnackbar();
        }
      );
    } else {
      snackbarHooks.showSnackbar(
        `Um documento não foi processado corretamente`,
        "Ver detalhes",
        () => {
          navigate(`/documents`);
          snackbarHooks.hideSnackbar();
        },
        "error"
      );
    }
    setCurrentDocumentFinished(document);
  };

  const uploadDocument = async (
    _payload: IDocumentPayload,
    _documentType: number
  ) => {
    if (!authHooks.apiKeyDefault || !authHooks.apiKeyDefault.value)
      throw new Error("Não foi encontrada a chave de API do cliente.");

    try {
      const payload: IDocumentPayload = {
        ..._payload,
        type: _documentType,
      };
      const document = await documentServices.uploadDocument(
        payload,
        authHooks.apiKeyDefault.value
      );
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const resend = async (id: string) => {
    try {
      const document = await documentServices.resend(id);
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const viewDataEdit = async (id: string, viewData: any) => {
    try {
      const document = await documentServices.viewDataEdit(id, viewData);
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const createNewEntity = async (_document: IDocument) => {
    try {
      const document = await documentServices.createEntity(_document);
      fetchEntities();
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _document: IDocument) => {
    try {
      const document = await documentServices.updateEntity(_id, _document);
      fetchEntities();
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const document = await documentServices.destroyEntity(_id);
      fetchEntities();
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const documents: IDocument[] = await documentServices.getEntities();
      setEntities([...documents]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    try {
      let query: string = `?extra_fields=*`;
      query += `&search_relationships=1`;
      query += `&search_user=1`;

      const document: IDocument = await documentServices.getEntity(_id, query);
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchDocumentByIndex = async (_index: number) => {
    try {
      let query: string = `?extra_fields=*`;
      query += `&search_relationships=1`;
      query += `&search_user=1`;

      const document: IDocument = await documentServices.getDocumentByIndex(
        _index,
        query
      );
      return document;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntitiesPaginated = async (
    page: number = 1,
    perPage: number = 10,
    filter: string = ""
  ) => {
    try {
      setLoading(true);

      let query: string = `?extra_fields=*`;
      query += `&search_relationships=1`;
      query += `&page=${page}&per_page=${perPage}`;
      query += `${filter}`;

      const documentsPaginated: IEntityPaginated<IDocument> =
        await documentServices.getDocuments(query);
      setEntitiesPaginated({ ...documentsPaginated });
    } catch (_err) {
      setLoading(false);
      console.log(_err);
      throw _err;
    } finally {
      setLoading(false);
    }
  };

  const fetchDocumentsByClientId = async (
    _clientId: string,
    page: number = 1,
    perPage: number = 10
  ) => {
    try {
      setLoading(true);
      setEntitiesPaginated({ ...blankEntitiesPaginated });
      let query: string = `?extra_fields=*&search_relationships=1&search_user=0&page=${page}&per_page=${perPage}&${queryString}`;
      const documentsPaginated: IEntityPaginated<IDocument> =
        await documentServices.getDocumentsByClientId(_clientId, query);
      setEntitiesPaginated({ ...documentsPaginated });
      setLoading(false);
      return documentsPaginated;
    } catch (_err) {
      setLoading(false);
      console.log(_err);
      throw _err;
    }
  };

  const countAllDocumentsTypeByMonth = async (
    _clientId: string,
    _month: number,
    _year: number
  ) => {
    const count: number = await documentServices.countAllTypesDocumentsByMonth(
      _clientId,
      _month,
      _year
    );
    return count;
  };

  const countAllDocumentstypesAllMonths = async (
    _clientId: string,
    _year: number
  ) => {
    const data: any = await documentServices.countAllTypesAllMonths(
      _clientId,
      _year
    );
    return data;
  };

  const countTypeDocumentsByMonth = async (
    _clientId: string,
    _type: number,
    _month: number,
    _year: number
  ) => {
    const count: number = await documentServices.countTypeDocumentsByMonth(
      _clientId,
      _type,
      _month,
      _year
    );
    return count;
  };

  const countTypeDocumentsAllMonths = async (
    _clientId: string,
    _type: number,
    _year: number
  ) => {
    const data: any = await documentServices.countTypeDocumentsAllMonths(
      _clientId,
      _type,
      _year
    );
    return data;
  };

  const fetchDocumentsTypes = async () => {
    const documentsTypes: IDocumentType[] =
      await documentServices.getDocumentsTypes();
    setDocumentsTypes(documentsTypes);
    return documentsTypes;
  };

  const fetchAvailableTypes = async () => {
    const documentsTypes: IDocumentType[] =
      await documentServices.getAvailablesDocumentsTypes();
    setAvailableTypes(documentsTypes);
    return documentsTypes;
  };

  const convertDocumentType = (_type: number) => {
    let description: string = "Desconhecido";
    const type: IDocumentType | undefined = availableTypes.find(
      (item) => item.value == _type
    );
    if (type) description = type.description;
    return description;
  };

  return (
    <DocumentsContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        fetchEntitiesPaginated,
        entitiesPaginated,
        setEntitiesPaginated,

        fetchDocumentByIndex,
        fetchDocumentsByClientId,

        countAllDocumentsTypeByMonth,
        countAllDocumentstypesAllMonths,
        countTypeDocumentsByMonth,
        countTypeDocumentsAllMonths,
        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
        documentsTypes,
        availableTypes,
        documentsYear,
        documentCount,
        queryString,

        dataGridQuery,
        setDataGridQuery,
        dataGridPage,
        setDataGridPage,
        dataGridRowsPerPage,
        setDataGridRowsPerPage,
        filterInitialDate,
        setFilterInitialDate,
        filterFinalDate,
        setFilterFinalDate,
        search,
        setSearch,
        range,
        setRange,
        period,
        setPeriod,
        filterSearch,
        setFilterSearch,

        setDocumentCount,
        setDocumentsYear,
        setQueryString,
        fetchDocumentsTypes,
        fetchAvailableTypes,
        convertDocumentType,
        uploadDocument,
        resend,
        viewDataEdit,

        currentDocumentFinished,
      }}
    >
      {_params.children}
    </DocumentsContext.Provider>
  );
};

export function useDocuments() {
  const context = useContext(DocumentsContext);

  if (!context) {
    throw new Error("useDocuments must be used within an DocumentsProvider");
  }

  return context;
}
