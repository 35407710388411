import React, { FC, createContext, useState, useContext } from 'react'
import { FeedbackDialogProps } from 'src/types/components/molecules/v2/feedback';

import { IFeedbackContext } from 'src/types/hooks/feedback';

const defaultFeedbackProps: FeedbackDialogProps = {
    show: false,
    variant: "info",
    title: undefined,
    titleNode: undefined,
    message: undefined,
    messageNode: undefined,
    loading: false,
    buttonsLabels: [],
    buttonsVariant: [],
    buttonsOnClick: [],
    onClose: () => { },
}

const FeedbackContext = createContext<IFeedbackContext>({} as IFeedbackContext);

export const FeedbackProvider: FC<any> = ({ children }) => {
    const [show, setShow] = useState<boolean>(defaultFeedbackProps.show);
    const [variant, setVariant] = useState<"error" | "success" | "info" | "warning">(defaultFeedbackProps.variant);
    const [title, setTitle] = useState<string | undefined>(defaultFeedbackProps.title);
    const [titleNode, setTitleNode] = useState<React.ReactNode>(defaultFeedbackProps.titleNode);
    const [message, setMessage] = useState<string | undefined>(defaultFeedbackProps.message);
    const [messageNode, setMessageNode] = useState<React.ReactNode>(defaultFeedbackProps.messageNode);
    const [loading, setLoading] = useState<React.ReactNode>(defaultFeedbackProps.loading);
    const [buttonsLabels, setButtonsLabels] = useState<string[] | undefined>(defaultFeedbackProps.buttonsLabels);
    const [buttonsVariant, setButtonsVariant] = useState<("text" | "outlined" | "contained")[]>([]);
    const [buttonsOnClick, setButtonsOnClick] = useState<Function[] | undefined>(defaultFeedbackProps.buttonsOnClick);

    const open = (
        show: boolean,
        variant: "error" | "success" | "info" | "warning",
        title?: string,
        titleNode?: React.ReactNode,
        message?: string,
        messageNode?: React.ReactNode,
        loading?: React.ReactNode,
        buttonsLabels?: string[],
        buttonsVariant?: ("text" | "outlined" | "contained")[],
        buttonsOnClick?: Function[]) => {
        setShow(show);
        setVariant(variant);
        setTitle(title);
        setTitleNode(titleNode);
        setMessage(message);
        setMessageNode(messageNode);
        setLoading(loading);
        setButtonsLabels(buttonsLabels);
        if (buttonsVariant)
            setButtonsVariant(buttonsVariant);
        setButtonsOnClick(buttonsOnClick);
    }

    const close = () => {
        setShow(defaultFeedbackProps.show);
        setVariant(defaultFeedbackProps.variant);
        setTitle(defaultFeedbackProps.title);
        setTitleNode(defaultFeedbackProps.titleNode);
        setMessage(defaultFeedbackProps.message);
        setMessageNode(defaultFeedbackProps.messageNode);
        setLoading(defaultFeedbackProps.loading);
        setButtonsLabels(defaultFeedbackProps.buttonsLabels);
        setButtonsVariant([]);
        setButtonsOnClick(defaultFeedbackProps.buttonsOnClick);
    }


    return (
        <FeedbackContext.Provider value={{
            show,
            variant,
            title,
            titleNode,
            message,
            messageNode,
            loading,
            buttonsLabels,
            buttonsVariant,
            buttonsOnClick,

            open, close, onClose: close
        }}>{children}</FeedbackContext.Provider>
    );
}

export function useFeedback() {
    const context = useContext(FeedbackContext);

    if (!context) {
        throw new Error('useFeedback must be used within an FeedbackProvider')
    }

    return context;
}