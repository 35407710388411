import React, { FC, useState, useEffect } from "react";
import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import { Container, Text, Spacing } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import DocumentsDatalogItemCard from "../itemCard";
import DocumentsDatalogItemCardHeader from "../itemCard/header";
import DocumentMultiSelectModal from "../../multiSelectModal/modal";
import Pagination from "src/components/molecules/table/pagination";
import theme from "src/components/theme";
import { useResponsive } from "src/hooks/responsive";
import { useDocuments } from "src/hooks/documents";
import { DocumentServices } from "src/services/documents";
import { IDocumentsDatalogList } from "src/types";

const DocumentsDatalogList: FC<IDocumentsDatalogList> = (props: IDocumentsDatalogList) => {
    const documentsHooks = useDocuments();
    const { mdUp, screenHeight } = useResponsive();

    const [currPage, setCurrPage] = useState<number>(0);
    const [documentsMultiSelectModalIsOpen, setDocumentsMultiSelectModalIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const perPage = Math.ceil((screenHeight * (mdUp ? 0.5 : 0.45)) / (mdUp ? 80 : 130));

    useEffect(() => {
        const auxCurrPage = parseInt(new URLSearchParams(window.location.search).get('datalogPage') ?? "1") - 1;
        if (auxCurrPage)
            setCurrPage(auxCurrPage);

        fetchData(auxCurrPage)

        documentsHooks.setEntitiesPerRow(perPage.toString());
    }, []);

    useEffect(() => {
        fetchData(currPage);
    }, [currPage]);

    const fetchData = (_currPage: number = 0) => {
        documentsHooks.fetchDocumentsByClientId(props.clientId, _currPage + 1, perPage);
    }

    const downloadDocuments = async (_ids: string[], _callback?: Function) => {
        try {
            setLoading(true);

            const services: DocumentServices = new DocumentServices();
            const data: any = await services.downloadResponses(_ids);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'inferences.zip');
            document.body.appendChild(link);
            link.click();

            if (_callback) _callback();
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <Container fluid flex spacedBetween width="100%" height="70vh" color={theme.palette.primary.dark}>
            {mdUp &&
                <Container fluid flexEnd width="100%">
                    <Container inline>
                        <Text color={theme.palette.background.paper} style={{ marginTop: 4, textAlign: "end", width: "170px" }}>
                            Selecionar e baixar multiplos documentos
                        </Text>
                        <Spacing left={PADDING} />
                        <LoadingButton variant="contained" onClick={() => setDocumentsMultiSelectModalIsOpen(true)} disabled={documentsHooks.entitiesPaginated.items.length == 0}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "4px",
                                paddingInline: 10,
                                width: "100px"
                            }}>
                            <Text bold size={15} color={theme.palette.background.paper} style={{ lineHeight: "initial", }}>
                                Selecionar Documentos
                            </Text>
                        </LoadingButton>
                    </Container>
                </Container>
            }
            <Spacing top={VERY_PADDING} />
            {mdUp &&
                <>
                    <DocumentsDatalogItemCardHeader />
                    <Spacing top={PADDING} />
                </>
            }
            <Container fluid height="100%">
                {documentsHooks.entitiesPaginated.items.length == 0 &&
                    <Container fluid flex centered>
                        <Text bold size={17} color={theme.palette.background.paper}>Nenhuma leitura encontrada.</Text>
                    </Container>
                }
                {props.clientId &&
                    documentsHooks.entitiesPaginated.items.map(item =>
                        <DocumentsDatalogItemCard clientId={props.clientId} isOnClient={false} document={item} currPage={currPage + 1} />
                    )
                }
            </Container>
            <Pagination page={currPage} pageCount={documentsHooks.entitiesPaginated.pages} setPage={setCurrPage} colorButton={theme.palette.background.paper} colorActiveButton={theme.palette.secondary.main} />

            <DocumentMultiSelectModal
                loading={loading}
                isOpen={documentsMultiSelectModalIsOpen}
                documents={documentsHooks.entitiesPaginated.items}
                actionLabel="Download"
                onAction={(_ids: string[], _callback?: Function) => downloadDocuments(_ids, _callback)}
                onClose={() => setDocumentsMultiSelectModalIsOpen(false)} />
        </Container >
    );
}

export default DocumentsDatalogList;
