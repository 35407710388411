import { FC, useEffect, useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridTreeNodeWithRender,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { SearchOff as NoRowsIcon } from "@mui/icons-material";

import { DefaultTemplate, theme } from "src/components";
import CRUDDataGrid from "src/components/molecules/v2/crud/dataGrid";
import { ICRUDTemplate } from "src/types";

/**
 * This component returns the CRUD Template
 * It uses mui styles
 * @component
 */
const CRUDTemplate: FC<ICRUDTemplate> = (props: ICRUDTemplate) => {
  // State for data grid amount of row
  const [dataGridPageSize, setDataGridPageSize] = useState<number>(
    props.dataGridPageSize ?? 5
  );
  // State for columns of the data grid
  const [dataGridColumns] = useState<GridColDef[]>(props.dataGridColumns ?? []);
  // State for rows of the data grid
  const [dataGridRows, setDataGridRows] = useState<GridRowsProp[]>(
    props.dataGridRows ?? []
  );

  /**
   * Reflects when the rows are updated
   * @effect
   */
  useEffect(() => {
    if (props.dataGridRows) setDataGridRows([...props.dataGridRows]);
  }, [props.dataGridRows]);

  return (
    <DefaultTemplate {...props}>
      <Box display="flex" paddingTop="48px">
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            flexGrow: 1,
            width: "100%",
            alignItems: "center",
            paddingX: "32px",
            paddingY: "24px",
            borderRadius: "12px",
          }}
        >
          <CardContent sx={{ width: "100%", maxWidth: "100%" }}>
            <Box
              display="flex"
              flexDirection="row"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                fontSize="22px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="28px"
                color={theme.palette.primary.main}
              >
                {props.headerTitle}
              </Typography>
              {props.headerChildren}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              flex={2}
              alignItems="center"
              paddingY="24px"
              maxWidth="100%"
              sx={{ overflowX: "auto" }}
            >
              {!props.dataGridLoading &&
              (props.dataGridRows?.length ?? 0) == 0 ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingY="16px"
                  width="100%"
                  height="100px"
                >
                  {props.noRowsIcon ?? (
                    <NoRowsIcon
                      color="primary"
                      sx={{ width: 70, height: 70 }}
                    />
                  )}
                  <Box width="50%" paddingY="8px">
                    <Typography
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="28px"
                      textAlign="center"
                    >
                      {props.noRowsText ?? "Nenhum registro para exibir aqui."}
                    </Typography>
                  </Box>
                  {props.noRowsChildren}
                </Box>
              ) : (
                <CRUDDataGrid
                  loading={props.dataGridLoading}
                  rows={dataGridRows}
                  columns={dataGridColumns}
                  rowCount={props.rowCount}
                  paginationMode={props.paginationMode ?? "client"}
                  paginationModel={
                    props.paginationModel ?? { page: 0, pageSize: 5 }
                  }
                  onPaginationModelChange={props.onPaginationModelChange}
                  pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                  onRowSelectionModelChange={props.onDataGridSelectRows}
                  slotProps={{
                    pagination: {
                      labelRowsPerPage: props.labelRowsPerPage,
                    },
                  }}
                />
              )}
            </Box>

            {props.cardFooterChildren}
          </CardContent>
        </Card>
      </Box>
    </DefaultTemplate>
  );
};

export default CRUDTemplate;
