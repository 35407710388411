import React, { FC, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import {
    Button,
    Container,
    DatePicker,
    Divider,
    Input,
    Spacing,
    Text,
} from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import {
    InputAdornmentEmail,
    InputAdornmentPassword,
    InputAdornmentPrivilege,
    InputAdornmentUser,
    PrivilegesSelect,
    SubscriptionsPlansSelect,
    theme,
} from "src/components";
import { useAuth } from "src/hooks/auth";
import { useClients } from "src/hooks/clients";
import { useInformation } from "src/hooks/information";
import { usePrivileges } from "src/hooks/privileges";
import { useResponsive } from "src/hooks/responsive";
import { useSubscriptions } from "src/hooks/subscriptions";
import { useSubscriptionsPlans } from "src/hooks/subscriptionsPlans";
import { useUsers } from "src/hooks/users";
import {
    UserProps,
    IUsersRegister,
    ISelectValue,
    IPrivilege,
    IUserProfile,
} from "src/types";

const UsersRegister: FC<IUsersRegister> = (params: IUsersRegister) => {
    const edit: boolean = params.id !== undefined;

    const authHook = useAuth();
    const clientHook = useClients();
    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const { hideInformation, showInformation } = useInformation();
    const privilegeHook = usePrivileges();
    const { mdDown } = useResponsive();
    const subscriptionHook = useSubscriptions();
    const subscriptionPlanHook = useSubscriptionsPlans();
    const userHook = useUsers();

    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [userPrivilegeId, setUserPrivilegeId] = useState<string | undefined>(undefined);
    const [privilege, setPrivilege] = useState<any>(undefined);
    const [privilegeName, setPrivilegeName] = useState<string>("");
    const [privilegeError, setPrivilegeError] = useState<string | undefined>(undefined);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (edit) loadEditFields();
    }, [params.user]);

    useEffect(() => {
        if (!edit && privilegeHook.privileges.length > 0) {
            let auxPrivilege: IPrivilege | undefined = privilegeHook.privileges.find(item => item.name == "USER");
            if (auxPrivilege) {
                setPrivilege({
                    id: auxPrivilege.id,
                    label: auxPrivilege.name
                });
                setPrivilegeName(auxPrivilege.name);
            }
        }
    }, [privilegeHook.privileges]);

    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => setText(event.target.value);

    const fetchData = async () => {
        privilegeHook.fetchPrivileges();
        if (edit) loadEditFields();
    };

    const loadEditFields = async () => {
        setLoading(true);
        if (params.user && params.user.id) {
            setName(`${params.user.firstName} ${params.user.lastName}`)
            setValue("name", `${params.user.firstName} ${params.user.lastName}`);

            setEmail(params.user.email);
            setValue("email", params.user.email);

            const privilege: IPrivilege[] = await userHook.fetchUserGroups(params.user.id);
            if (privilege.length > 0) {
                setUserPrivilegeId(privilege[0].id);
                setPrivilege({
                    id: privilege[0].id,
                    label: privilege[0].attributes.description[0]
                });
                setPrivilegeName(privilege[0].attributes.description[0]);
            }
        } else {
            showInformation(
                "Ocorreu um erro ao editar o usuário!",
                undefined,
                undefined,
                () => {
                    setLoading(false);
                    hideInformation();
                    params.onClose();
                }
            );
        }
        setLoading(false);
    };

    const validation = () => {
        let isValid: boolean = true;

        if (privilege === undefined) {
            isValid = false;
            setPrivilegeError("Informe o privilégio");
        }
        return isValid;
    };

    const save = async () => {
        try {
            if (!validation()) return;

            setLoading(true);
            let names: string[] = name.split(" ");

            let payload: UserProps = {
                firstName: names.shift() ?? "",
                lastName: names.join(" "),
                username: email,
                email,
                enabled: true,
                emailVerified: false
            };
            if (password) {
                payload.credentials = [
                    {
                        type: "password",
                        value: password,
                        temporary: false
                    }
                ]
            }

            const privilegeId: string | undefined = privilegeHook.privileges.find(item => item.name == privilegeName)?.id;

            let informationMessage: string = "Usuário cadastrado com sucesso!";
            if (edit && params.id) {
                await userHook.editEntity(params.id, payload);
                if (params.user) {
                    if (userPrivilegeId && userPrivilegeId != privilegeId)
                        await userHook.removeGroup(params.id, userPrivilegeId);
                    if (privilegeId)
                        await userHook.addGroup(params.id, privilegeId);
                }
                informationMessage = "Usuário alterado com sucesso!";
            } else {
                const auxUser: UserProps = await userHook.createNewEntity(payload, false);
                if (auxUser.id) {
                    await clientHook.createClientHasUser(params.clientId, auxUser.id);
                    if (privilegeId)
                        await userHook.addGroup(auxUser.id, privilegeId);
                }
            }
            showInformation(informationMessage, undefined, "Continuar", () => {
                cleanData();
                hideInformation();
                params.onClose();
                setLoading(false);
            });
        } catch (err: any) {
            console.log(err);
            showInformation(err.message as string, undefined, undefined, undefined);
            setLoading(false);
        }
    };

    const cleanData = () => {
        setName("");
        setEmail("");
        setPassword("");
        setShowPassword(false);
        setPrivilege(undefined);
        setPrivilegeName("");
        setPrivilegeError(undefined);
    };

    return (
        <Container fluid spacedBetween veryPadded color={theme.palette.backgroundElements.main}
            width={mdDown ? "90%" : "350px"} height={mdDown ? "auto" : "500px"}>
            <Container fluid flex veryPadded>
                <Text medium size={24}>
                    {edit ? "Editar" : "Criar"} usuário
                </Text>
                <Spacing top={VERY_PADDING * 2} />
                <form onSubmit={handleSubmit(save)} style={{ display: "flex", height: "auto" }}>
                    <Container flex fluid>
                        <Grid container padding={0} rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12}>
                                <Input autoFocus placeholder="*Nome do usuário" size="medium"
                                    backgroundColor={theme.palette.background.default}
                                    value={name}
                                    error={errors.name != undefined} disabled={loading}
                                    startAdornment={<InputAdornmentUser />}
                                    register={{
                                        ...register("name", {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName),
                                            required: "Informe o nome do usuário",
                                        }),
                                    }}
                                    sx={{ width: "100%", height: 40, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                                />
                                {errors.name && (
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>
                                        {(errors.name as any).message}
                                    </Text>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Input placeholder="*E-mail do usuário" size="medium"
                                    backgroundColor={theme.palette.background.default}
                                    value={email}
                                    error={errors.email != undefined} disabled={loading}
                                    startAdornment={<InputAdornmentEmail />}
                                    register={{
                                        ...register("email", {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setEmail),
                                            required: "Informe o e-mail do usuário",
                                            pattern: {
                                                value:
                                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,14}$/i,
                                                message: "Informe um endereço de e-mail válido",
                                            },
                                        }),
                                    }}
                                    sx={{ width: "100%", height: 40, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                                />
                                {errors.email && (
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>
                                        {(errors.email as any).message}
                                    </Text>
                                )}
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Input placeholder={`${edit ? "" : "*"}Senha do usuário`} size="medium"
                                    backgroundColor={theme.palette.background.default}
                                    value={password} type={showPassword ? "text" : "password"}
                                    isPassword showPassword={showPassword} handleClickShowPassword={() => setShowPassword(!showPassword)}
                                    error={errors.password != undefined} disabled={loading}
                                    startAdornment={<InputAdornmentPassword />}
                                    register={{
                                        ...register("password", {
                                            onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setPassword),
                                            required: edit ? undefined : "Informe a senha do usuário",
                                            pattern: {
                                                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:;<>,.?/~`]).{8,}$/i,
                                                message: `Senha fraca. 
                                            Ela deve conter:
                                            uma letra maiúscula,
                                            uma letra minúscula,
                                            um dígito,
                                            um caractere especial
                                            e que tenha pelo menos 8 caracteres
                                            `,
                                            },
                                        }),
                                    }}
                                    sx={{ height: 40, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }}
                                />
                                {errors.password && (
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>
                                        {(errors.password as any).message}
                                    </Text>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <PrivilegesSelect value={privilege} setValue={setPrivilege}
                                    inputValue={privilegeName} setInputValue={setPrivilegeName}
                                    startAdornment={
                                        <Container inline>
                                            <Spacing left={PADDING * 0.8} />
                                            <InputAdornmentPrivilege />
                                        </Container>} placeholder="*Privilégio do usuário"
                                    disabled={loading}
                                    sx={{ width: "100%", height: 40, borderColor: theme.palette.backgroundElements.light, backgroundColor: theme.palette.backgroundElements.light }} />

                                {privilegeError && (
                                    <Text size={13} color={theme.palette.error.main} style={{ marginBottom: 3 }}>
                                        {privilegeError}
                                    </Text>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} display="flex" justifyContent="flex-start">
                                <Button type="submit" loading={loading} disabled={loading} sx={{ minWidth: 140 }}>
                                    <Text color={theme.palette.background.default}>
                                        {edit ? "Salvar" : "Cadastrar usuário"}
                                    </Text>
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} display="flex" justifyContent="flex-end">
                                <Button onClick={() => params.onClose()} color={loading ? undefined : theme.palette.backgroundStrokeActive.dark} sx={{ minWidth: 140 }}
                                    loading={loading} disabled={loading}>
                                    <Text color={theme.palette.background.default}>
                                        {edit ? "Cancelar" : "Cancelar cadastro"}
                                    </Text>
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </form>
            </Container>
        </Container >
    );
};

export default UsersRegister;
