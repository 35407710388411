import { ISelectValue } from "../components/molecules/select";
import { IEntity, IEntityContext } from "./entities";
import { SubscriptionPlanProps } from "./financesApi/subscriptionPlans";
import { SubscriptionProps } from "./financesApi/subscriptions";
import { IProduct } from "./products";
import { ISubscriptionPlanPrice } from "./subscriptionsPlansPrices";

interface ISubscriptionPlan extends IEntity {
  name: string;
  description?: string;
  subscriptionType: number;
  subscriptionTypeDesc?: string;
  monthlyRequests: number;
  price: number;
  isTrial: boolean;
  availableToPublic: boolean;
  prices: ISubscriptionPlanPrice[];
  billingScheme?: string;
  recurringInterval?: string;
  recurringIntervalCount?: number;
  recurringUsageType?: string;
  type?: string;
  unitAmount?: number;
  unitAmountDecimal?: number;
  tiersMode?: string;
  tiers?: any;
  productId?: string;
  product?: IProduct;
  currency?: string;
  useApi?: boolean;
  priority?: number;
  virtualSupport?: boolean;
  customizedSupport?: boolean;
  useApp?: boolean;
  unlimitedUsers?: boolean;
}

interface ISubscriptionPlanContext extends IEntityContext<SubscriptionPlanProps> {
  priceModels: ISelectValue[];
  recurringIntervals: ISelectValue[];
  recurringUsageTypes: ISelectValue[];
  types: ISelectValue[];
  tiersMode: ISelectValue[];
  currencies: ISelectValue[];

  convertBillingSchema: (_billingSchema: string) => string;
  convertRecurringInterval: (_recurringInterval: string) => string;
  convertRecurringUsageType: (_recurringUsageType: string) => string;
  convertTierMode: (_tierMode: string) => string;
  convertCurrency: (_currency: string) => string;
  convertCurrencySimbol: (_currency: string | undefined) => string;
  convertType: (_type: string) => string;
}

export function isSubscriptionPlan(_data: any): _data is SubscriptionPlanProps {
  return (
    "name" in _data &&
    "subscriptionType" in _data &&
    "monthlyRequests" in _data &&
    "price" in _data &&
    "isTrial" in _data &&
    "availableToPublic" in _data
  );
}

export type { ISubscriptionPlan, ISubscriptionPlanContext };
