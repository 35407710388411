import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import "./App.css";
import Theme from "./components/theme";
import { AuthProvider } from "./hooks/auth";
import { FeedbacksProvider } from "./hooks";
import Routes from "./routes";
import { ApiKeysProvider } from "./hooks/apiKeys";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <ApiKeysProvider>
          <FeedbacksProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </FeedbacksProvider>
        </ApiKeysProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
