import React, { FC, useEffect, useState } from "react";
import { Container, CustomTablePagination, Table, Text } from "@pix-force/react-atoms";

import InvoicesDocumentsCountListHeader from "../documentsCountListItem/header";
import InvoicesDocumentsCountListItem from "../documentsCountListItem";
import { useResponsive } from "src/hooks/responsive";
import { InvoiceServices } from "src/services/invoices";
import { IInvoicesDocumentsCountList } from "src/types";
import { IInvoiceDocumentCount, isInvoiceDocumentCount } from "src/types/hooks/invoices";
import theme from "src/components/theme";

const invoiceService = new InvoiceServices();
const InvoicesDocumentsCountList: FC<IInvoicesDocumentsCountList> = (params: IInvoicesDocumentsCountList) => {
    const { mdDown } = useResponsive();

    const [invoicesDocumentsCount, setInvoicesDocumentsCount] = useState<IInvoiceDocumentCount[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        if (params.status == 2 || params.invoiceId == "upcoming") { // status  2 = IN_PROGRESS
            const auxInvoicesDocumentsCount = await invoiceService.getInvoiceDocumentsCountCurrentClient(params.clientId);
            setInvoicesDocumentsCount(auxInvoicesDocumentsCount);
            return;
        }

        const auxInvoicesDocumentsCount = await invoiceService.getInvoiceDocumentsCount(params.invoiceId);
        setInvoicesDocumentsCount(auxInvoicesDocumentsCount);
    }

    return (
        <Container fluid flex flexGrow width="100%">
            {invoicesDocumentsCount.length == 0 &&
                <Container fluid flex centered veryPadded>
                    <Text h1 color={theme.palette.grey[500]}>Não encontramos nenhuma informação de upload.</Text>
                </Container>}
            {invoicesDocumentsCount.map((item: IInvoiceDocumentCount) => {
                return (
                    <Container fluid padded>
                        <InvoicesDocumentsCountListItem item={item} />
                    </Container>
                );
            })}
        </Container>
    );
}

export default InvoicesDocumentsCountList;