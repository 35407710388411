import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import { Button, Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import moment from "moment";

import SubscriptionsItemCardDetails from "./details";
import { theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { useResponsive } from "src/hooks/responsive";
import { SubscriptionProps, ISubscriptionsItemCard } from "src/types";
import { isSubscription } from "src/types/hooks/subscriptions";

const SubscriptionsItemCard: FC<ISubscriptionsItemCard> = (params: ISubscriptionsItemCard) => {
  const cardStyle: any = {
    border: `solid 1px ${theme.palette.backgroundElements.dark}`,
    minHeight: 100
  }

  const authHooks = useAuth();
  const { mdDown } = useResponsive();

  if (!authHooks.client) return null;

  const canChangeSubscription: boolean = authHooks.client.canChangeSubscription ?? authHooks.isSuperAdmin();

  return (
    <Container fluid flex centered>
      <Container relative fluid flex centered color={theme.palette.backgroundElements.main}
        width="100%" height="100px" sx={cardStyle}>
        <Container absolute fluid width="8px" height="101%" color={theme.palette.secondary.main} sx={{ left: 0, top: -1, zIndex: 10 }} />

        <Grid container rowSpacing={2} columnSpacing={2} display="flex" paddingLeft={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={3}>
            {mdDown && <Text subtitle medium>Plano: </Text>}
            <Spacing left={PADDING} />
            <Text subtitle medium>
              {params.name}
            </Text>
          </Grid>
          <Grid item xs={12} md={3}>
            {mdDown && <Text subtitle medium>Data Contratação: </Text>}
            <Text subtitle medium>
              {moment(params.date).format("DD/MM/YYYY")}
            </Text>
          </Grid>
          <Grid item xs={12} md={3}>
            {mdDown && <Text subtitle medium>Valor: </Text>}
            <Text subtitle medium>
              {`R$ ${(params.fixedPrice ?? 0).toLocaleString("pt-BR")}`}
            </Text>
          </Grid>
        </Grid>
      </Container>

      <SubscriptionsItemCardDetails
        subscriptionId={params.subscriptionId}
        subscriptionPlanPublicId={params.subscriptionPlanPublicId}
        name={params.name}
        description={params.description}
        date={params.date}
        fixedPrice={params.fixedPrice}
        pagesIncluded={params.pagesIncluded}
        pricePerPage={params.pricePerPage}
        useApi={params.useApi}
        priority={params.priority}
        virtualSupport={params.virtualSupport}
        customizedSupport={params.customizedSupport}
        useApp={params.useApp}
        unlimitedUsers={params.unlimitedUsers} />
    </Container>
  );
};

export default SubscriptionsItemCard;

export function convertTableRowPropsToISubscription(props: any) {
  const data: any = props.row;
  let subscription: SubscriptionProps | undefined = undefined;
  if (isSubscription(data)) {
    subscription = props.row;
  }
  return subscription;
}
