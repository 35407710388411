import React, { FC, useState } from "react";
import { Container, Text } from "@pix-force/react-atoms";
import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import { useResponsive } from "src/hooks/responsive";
import { IApiKeysList } from "src/types/components/molecules/apiKeys";
import ApiKeyRegisterModal from "../register/modal";
import ApiKeysListItem from "../listItem";
import ApiKeyListHeader from "../listItem/header";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import { useAuth } from "src/hooks/auth";
import Pagination from "../../table/pagination";

const ApiKeysList: FC<IApiKeysList> = (params: IApiKeysList) => {
    const { hasRights } = useAuth();
    const { screenHeight } = useResponsive();

    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState<boolean>(false);
    const [currPage, setCurrPage] = useState<number>(0);

    const pageCount = Math.ceil((screenHeight * 0.6) / 120);
    const apiKeysCount = params.apiKeys.length;

    return (
        <Container fluid flex width="100%" minHeight="80vh">
            <Container fluid width="100%" height="80vh">
                <Container fluid width="100%">
                    <ApiKeyListHeader />
                </Container>
                {params.apiKeys.length == 0 && <Container fluid flex centered width="100%">
                    <Text>Nenhuma Chave de API encontrada.</Text>
                </Container>}
                {params.apiKeys.slice((currPage * pageCount), ((currPage * pageCount) + pageCount)).map(item =>
                    <Container fluid verticalPadded width="100%">
                        <ApiKeysListItem apiKey={item} onRefresh={params.onRefresh} />
                    </Container>)}
            </Container>
            <Pagination page={currPage} pageCount={Math.ceil(apiKeysCount / pageCount)} setPage={setCurrPage} />

            {params.isOnClients && hasRights && hasRights('manage-api_keys') &&
                <Fab color="primary" sx={{ position: "absolute", right: PADDING, bottom: VERY_PADDING }}
                    onClick={() => setIsRegisterModalOpen(true)} >
                    <AddIcon />
                </Fab>
            }

            {params.clientId &&
                <ApiKeyRegisterModal
                    isOpen={isRegisterModalOpen}
                    onRefresh={params.onRefresh}
                    clientId={params.clientId}
                    onClose={() => {
                        params.onRefresh();
                        setIsRegisterModalOpen(false)
                    }} />}
        </Container>
    )
}

export default ApiKeysList;