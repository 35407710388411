import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import {
  CorporateFareOutlined as CorporateIcon,
  EmailOutlined as EmailIcon,
  LocalPhoneOutlined as PhoneIcon,
  LockOutlined as LockIcon,
  PersonOutlined as PersonIcon,
} from "@mui/icons-material";

import PhoneMaskedTextField from "src/components/atoms/v2/phoneMaskedTextField";
import { AccountFormProps } from "src/types";
import { AccountProps } from "src/types/hooks/account";

/**
 * This component submits the update account routine
 * It uses mui styles
 * @component
 */
const AccountForm: FC<AccountFormProps> = (props: AccountFormProps) => {
  // Inits the form hooks
  const { register, handleSubmit } = useForm();

  // State for user fullname
  const [fullname, setFullname] = useState<string>(props.user.fullName);
  // State for client
  const [clientName, setClientName] = useState<string>(props.client.name);
  // State for phone number
  const [phoneNumber, setPhoneNumber] = useState<string>(props.user.phoneNumber || "");

  /**
   * Reflects when component inits
   * @effect
   */
  useEffect(() => {}, []);

  /**
   * Function to fetch all necessary data of the page
   * @function
   */
  const fetchData = () => {};

  /**
   * Function that handles user fullname changes
   * @function
   */
  const handleFullname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullname(event.target.value);
  };

  /**
   * Function that handles client name changes
   * @function
   */
  const handleClientName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientName(event.target.value);
  };

  /**
   * Function that handles phone number changes
   * @function
   */
  const handlePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  /**
   * Function that updates the user information
   * @function
   */
  const onSubmit = async () => {
    // converts the fullname string into two words (first and last name)
    const [firstName, ...lastName] = fullname.split(" ");
    const payload: AccountProps = {
      firstName: firstName,
      lastName: lastName.join(" "),
      phoneNumber,
      email: props.user.email,
    };

    props.onSubmit(payload, clientName);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" alignItems="flex-start" paddingTop={6}>
        <Grid container display="flex" rowSpacing={1}>
          <Grid item xs={12}>
            <TextField
              disabled={props.disabled}
              required
              variant="outlined"
              fullWidth
              size="small"
              label="Nome"
              placeholder="Informe seu nome"
              value={fullname}
              InputProps={{
                startAdornment: <PersonIcon />,
              }}
              error={fullname === ""}
              helperText={fullname === "" ? "*Campo obrigatório" : " "}
              {...register("fullname", {
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleFullname(event),
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.disabled}
              required
              variant="outlined"
              fullWidth
              size="small"
              label="Empresa"
              placeholder="Informe o nome da sua empresa"
              value={clientName}
              error={clientName === ""}
              helperText={clientName === "" ? "*Campo obrigatório" : " "}
              InputProps={{
                startAdornment: <CorporateIcon />,
              }}
              {...register("clientName", {
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleClientName(event),
                required: true,
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={props.disabled}
              variant="outlined"
              fullWidth
              size="small"
              label="Telefone"
              placeholder="Informe o seu telefone"
              value={phoneNumber}
              helperText=" "
              InputProps={{
                startAdornment: <PhoneIcon />,
                inputComponent: PhoneMaskedTextField as any,
              }}
              {...register("phoneNumber", {
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => handlePhoneNumber(event)
              })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              variant="outlined"
              fullWidth
              size="small"
              label="E-mail"
              value={props.user.email}
              helperText=" "
              InputProps={{
                startAdornment: <EmailIcon />,
              }}
            />
          </Grid>
        </Grid>

        <Grid container paddingTop={4}>
          <Grid item xs={6}>
            <Button disabled={props.disabled} startIcon={<LockIcon />}>
              <Typography
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.5px"
                textTransform="initial"
                onClick={props.onResetPasswordClick}
              >
                Alterar minha senha
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end">
            <Button disabled={props.disabled} variant="contained" size="large" type="submit">
              <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                Salvar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default AccountForm;
