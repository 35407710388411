import { FC, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import DocumentsResultDataShowItem from "./item";
import { useDocuments } from "src/hooks/documents";
import { IDocumentsResultDataShow } from "src/types/components/molecules/v2/documents";
import { getValueByPath, setValueByPath } from "src/utils/utils";

/**
 * This component returns the documents data information
 * It uses mui styles
 * @component
 */
const DocumentsResultDataShow: FC<IDocumentsResultDataShow> = (props: IDocumentsResultDataShow) => {
    // Document hooks for upload and types data
    const documents = useDocuments();

    // State for vectorized view data of response
    const [vectorData, setVectorData] = useState<any>([]);

    /**
     * Reflects when state document file has been downloaded
     * @effect
     */
    useEffect(() => {
        fillVectorData(props.data);
    }, [props.data]);

    /** 
     * Function to fill vector of view data
     * @function
     */
    const fillVectorData = (data: any) => {
        const createVector = (auxData: any, parentKey: any) => {
            if (auxData == null) return null;

            const auxVector = Object.keys(auxData).map(key => {
                const auxKey = parentKey !== undefined ? `${parentKey}.${key}` : key;

                let fields: any = [];
                if (typeof auxData[key] === "object") {
                    const tempVector = createVector(auxData[key], auxKey);
                    if (tempVector) fields = tempVector;
                } else {
                    fields.push({
                        key: key,
                        attr: auxKey,
                        value: getValueByPath(auxData, key),
                        onSave: handleValueSave
                    });
                }
                return fields;
            });
            return [].concat(...auxVector);
        }

        const auxVectorData = createVector(data, undefined);
        setVectorData(auxVectorData);
    };


    /** 
     * Function to handle data value save
     * @function
     */
    const handleValueSave = async (key: string, value: string) => {
        let auxViewData: any = { ...props.data };
        setValueByPath(auxViewData, key, value);

        await documents.viewDataEdit(props.id, auxViewData);
        props.refresh();
    };

    return (
        <Box display="flex" flex={1} flexGrow={1}
            width="100%" height="auto">
            <Grid container rowSpacing="24px">
                {vectorData.map((item: any) => {
                    return (
                        <DocumentsResultDataShowItem key={item.key}
                            attr={item.attr}
                            value={item.value}
                            onSave={item.onSave}
                        />
                    );
                })}
            </Grid>
        </Box >
    );
}

export default DocumentsResultDataShow;