import { Axios, AxiosResponse } from "axios";

import api, { API_FINANCES_URL, APP_URL } from "./";
import { EntityServices } from "./entities";
import { IClientCard, IUser } from "../types";
import { parseStringToDate } from "../utils/dates";
import { UserServices } from "./users";

export class ClientCardServices extends EntityServices<IClientCard> {
  constructor() {
    super("Cartões do Cliente", `${API_FINANCES_URL}/client_cards`);
  }

  async getClientCardByClientId(_clientId: string): Promise<IClientCard[]> {
    const response: AxiosResponse = await api.get(`${this.baseUrl}/by_client/${_clientId}`);

    if (response.data && response?.request?.status === 200) {
      const clientsCards: IClientCard[] = [];

      for (let i = 0; i < response.data.length; i++) {
        const data = response.data[i];
        const clientCard: IClientCard = this.convertApiResponseToIEntity(data);

        clientsCards.push(clientCard);
      }
      return clientsCards;
    }
    throw new Error(`Não foi possível buscar o(a) ${this.entityForLog}`);
  }

  async paymentApiCheckoutSession(_clientId: string) {
    const changeSubscription: number = parseInt(new URLSearchParams(window.location.search).get('change_subscription') ?? "0");

    const response: AxiosResponse = await api.post(`${this.baseUrl}/payment_api/checkout/${_clientId}`, {
      successUrl: `${APP_URL}/my-account?${changeSubscription > 0 ? "change_subscription=2" : "tab=payment"}&status=success&clientId=${_clientId}&checkoutSessionId={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${APP_URL}/my-account?tab=payment&status=cancel`,
    });
    if (response.data && response?.request?.status === 200) {
      return response.data;
    }
    throw new Error("Não foi possível efetuar a solicitação de criação do cartão.");
  }

  async paymentApiCheckoutSessionSuccess(_checkoutSessionId: string) {
    const response: AxiosResponse = await api.post(`${this.baseUrl}/payment_api/checkout/success/${_checkoutSessionId}`);
    if (response.data && response?.request?.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    }
    throw new Error("Não foi possível efetuar a solicitação de criação do cartão.");
  }

  async makeItDefault(_id: string) {
    const response: AxiosResponse = await api.post(`${this.baseUrl}/payment_api/make_default/${_id}`);
    if (response.data && response?.request?.status === 200) {
      return this.convertApiResponseToIEntity(response.data);
    }
    throw new Error("Não foi possível efetuar a solicitação tornar o cartão como default.");
  }

  override convertApiResponseToIEntity(_data: any): IClientCard {
    return {
      id: _data.id,
      clientId: _data.clientId,
      paymentApiId: _data.paymentApiId,
      brand: _data.brand,
      country: _data.country,
      expMonth: _data.expMonth,
      expYear: _data.expYear,
      last4: _data.last4,
      email: _data.email,
      name: _data.name,
      phone: _data.phone,
      addressCity: _data.addressCity,
      addressCountry: _data.addressCountry,
      addressLine1: _data.addressLine1,
      addressLine2: _data.addressLine2,
      addressPostalCode: _data.addressPostalCode,
      addressState: _data.addressState,
      isDefault: _data.isDefault,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
