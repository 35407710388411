import { IEntity, IEntityContext, IEntityPaginated } from "./entities";
import { IDocumentCustomModel } from "./documentsCustomsModels";
import { IDocumentStatusVision } from "./documentsStatusVision";
import { IDocumentModel } from "./documentModels";
import { ApiKeyProps } from "./financesApi/apiKeys";
import { DateRange } from "react-day-picker";

interface IDocument extends IEntity {
  userId: string;
  apiKey?: ApiKeyProps;
  type: number;
  modelName?: string;
  data: any;
  viewData?: any;
  processTime: number;
  index: number;
  status: number;
  statusDesc: string;
  subscripted?: boolean;
  file?: string;
  fileType?: string;
  fields?: string[];
  pages?: string;
  analyzedPages?: number;
  inProgressAt?: Date;
  finishedAt?: Date;
  errorAt?: Date;
  source?: number;
  sourceDesc?: string;
  userName?: string;
  statusVision?: IDocumentStatusVision;
  documentCustomModelId: string;
  documentCustomModel?: IDocumentCustomModel;
  model?: IDocumentModel;
  spreadsheetUri?: string;

  selected?: boolean;
}

interface DocumentResponseProps {
  documentId: string;
  data?: any;
  viewData?: any;
  processTime?: number;
  analyzedPages?: number;
  inProgressAt?: Date;
  finishedAt?: Date;
  errorAt?: Date;
  spreadsheetUri?: string;
}

interface IDocumentType {
  value: number;
  name: string;
  description: string;
}

interface IDocumentType {
  value: number;
  name: string;
  description: string;
}

interface IDocumentPayload {
  file: any;
  fileType: string;
  type?: number;
  subtype?: number;
  fields?: string[];
  modelId?: string;
  email?: string;
  phone?: string;
  pages?: string;

  documentCustomModelId?: string;
}

interface IDocumentContext extends IEntityContext<IDocument> {
  fetchEntitiesPaginated: (page: number, perPage: number, filter: string) => void;
  entitiesPaginated: IEntityPaginated<IDocument>;
  setEntitiesPaginated: (entitiesPaginated: IEntityPaginated<IDocument>) => void;

  fetchDocumentByIndex: (_index: number) => Promise<IDocument>;
  fetchDocumentsByClientId: (_clientId: string, page: number, perPage: number) => Promise<IEntityPaginated<IDocument>>;
  uploadDocument: (_payload: IDocumentPayload, _documentType: number) => Promise<IDocument>;
  resend: (id: string) => Promise<IDocument>;
  viewDataEdit: (id: string, viewData: any) => Promise<IDocument>;

  documentsTypes: IDocumentType[];
  availableTypes: IDocumentType[];

  documentsYear: number;
  documentCount: number;
  queryString: string;
  setDocumentCount: (_count: number) => any;
  setDocumentsYear: (_year: number) => any;
  setQueryString: Function;

  dataGridQuery: string;
  setDataGridQuery: (filter: string) => void;
  dataGridPage: number;
  setDataGridPage: (page: number) => void;
  dataGridRowsPerPage: number;
  setDataGridRowsPerPage: (pages: number) => void;
  filterInitialDate: Date | null;
  setFilterInitialDate: (date: Date | null) => void;
  filterFinalDate: Date | null;
  setFilterFinalDate: (date: Date | null) => void;
  search: string;
  setSearch: (search: string) => void;
  range: DateRange | undefined;
  setRange: (range: DateRange | undefined) => void;
  period: string;
  setPeriod: (period: string) => void;
  filterSearch: string;
  setFilterSearch: (filterSearch: string) => void;

  fetchDocumentsTypes: () => Promise<IDocumentType[]>;
  fetchAvailableTypes: () => Promise<IDocumentType[]>;
  convertDocumentType: (_type: number) => string;
  countAllDocumentsTypeByMonth: (_clientId: string, _month: number, _year: number) => Promise<number>;
  countAllDocumentstypesAllMonths: (_clientId: string, _year: number) => Promise<any>;
  countTypeDocumentsByMonth: (_clientId: string, _type: number, _month: number, _year: number) => Promise<number>;
  countTypeDocumentsAllMonths: (_clientId: string, _type: number, _year: number) => Promise<any>;

  currentDocumentFinished: IDocument | undefined;
}

export function isDocument(_data: any): _data is IDocument {
  return "documentCustomModelId" in _data && "userId" in _data && "type" in _data && "index" in _data && "status" in _data;
}

export function isDocumentResponse(_data: any): _data is DocumentResponseProps {
  return (
    ("documentId" in _data &&
      "data" in _data &&
      "viewData" in _data &&
      "processTime" in _data &&
      "analyzedPages" in _data &&
      "inProgressAt" in _data &&
      "finishedAt" in _data &&
      "errorAt" in _data) ||
    ("document_id" in _data &&
      "data" in _data &&
      "view_data" in _data &&
      "process_time" in _data &&
      "analyzed_pages" in _data &&
      "in_progress_at" in _data &&
      "finished_at" in _data &&
      "error_at" in _data)
  );
}

export type { IDocument, DocumentResponseProps, IDocumentType, IDocumentPayload, IDocumentContext };
