export function capitalizeTheFirstLetterOfEachWord(words: string | undefined) {
  if (!words) return "";

  var separateWord = words.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
}

export function normalizeString(word: any) {
  if (word && word.normalize) return word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return word;
}

export function isUUID(str: string) {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(str);
}
