import React, { FC, useEffect, useState } from "react";
import { Container, CustomTablePagination, Table, Text } from "@pix-force/react-atoms";

import InvoicesItemsListHeader from "../invoiceItemListItem/header";
import InvoicesItemsListItem from "../invoiceItemListItem";
import { useResponsive } from "src/hooks/responsive";
import { InvoiceServices } from "src/services/invoices";
import { IInvoicesItemsList, IInvoicesItemsListItem } from "src/types";
import { IInvoiceDocumentCount, IInvoiceItem, isInvoiceDocumentCount } from "src/types/hooks/invoices";
import theme from "src/components/theme";

const invoiceService = new InvoiceServices();
const InvoicesItemsList: FC<IInvoicesItemsList> = (params: IInvoicesItemsList) => {
    const { mdDown } = useResponsive();

    return (
        <Container fluid flex flexGrow width="100%">
            {params.items.length == 0 &&
                <Container fluid flex centered veryPadded>
                    <Text h1 color={theme.palette.grey[500]}>Não encontramos nenhum item da fatura.</Text>
                </Container>}
            {params.items.map((item: IInvoiceItem) => {
                return (
                    <Container fluid padded>
                        <InvoicesItemsListItem item={item} />
                    </Container>
                );
            })}
        </Container>
    );
}

export default InvoicesItemsList;