import React, { FC } from "react";
import Grid from "@mui/material/Grid";
import { Container, Text } from "@pix-force/react-atoms";

import { theme } from "src/components";
import { useDocuments } from "src/hooks/documents";
import { useResponsive } from "src/hooks/responsive";
import { IInvoicesDocumentsCountListItem } from "src/types";

const InvoicesDocumentsCountListItem: FC<IInvoicesDocumentsCountListItem> = (params: IInvoicesDocumentsCountListItem) => {
  const {documentsTypes} = useDocuments();
  const { mdDown } = useResponsive();

  if (params.item == undefined) return null;

  return (
    <Container fluid flex veryPadded color={theme.palette.backgroundStrokeActive.main} sx={{ border: `solid 1px ${theme.palette.backgroundElements.main}` }} >
      <Grid container rowSpacing={2} columnSpacing={2} display="flex">
        <Grid item xs={12} md={9}>
          <Text subtitle medium>Tipo do documento: </Text>
          <Text subtitle medium>
            {documentsTypes.find((_document) => _document.value == params.item?.documentType)?.description}
          </Text>
        </Grid>
        <Grid item xs={12} md={3}>
          <Text subtitle medium>Total de páginas: </Text>
          <Text subtitle medium>
            {params.item.pages}
          </Text>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvoicesDocumentsCountListItem;
