import { FC } from "react";
import Grid from "@mui/material/Grid";
import { Button, Container, Spacing, Text } from "@pix-force/react-atoms";
import { BORDER_RADIUS, PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";
import moment from "moment";

import { theme } from "src/components";
import { useResponsive } from "src/hooks/responsive";
import { ISubscriptionsItemCardDetails } from "src/types";
import { isSubscription } from "src/types/hooks/subscriptions";
import SubscriptionInformationAditional from "../informations/additionalInformation";
import SubscriptionInformationFeature from "../informations/feature";

const SubscriptionsItemCardDetails: FC<ISubscriptionsItemCardDetails> = (params: ISubscriptionsItemCardDetails) => {
    const { mdDown } = useResponsive();

    return (
        <Container relative fluid width="100%">
            <Container absolute fluid width="2px" height="40px" color={theme.palette.secondary.main} sx={{ left: 3, top: -1, zIndex: 10 }} />
            <Container absolute fluid width="20px" height="2px" color={theme.palette.secondary.main} sx={{ left: 3, top: 39, zIndex: 10 }} />

            <Spacing top={VERY_PADDING} />
            <Container fluid sx={{ paddingLeft: VERY_PADDING }}>
                <Container fluid padded maxWidth="200px" color={theme.palette.secondary.main}
                    sx={{
                        paddingLeft: PADDING,
                        borderTopLeftRadius: BORDER_RADIUS,
                        borderTopRightRadius: BORDER_RADIUS,
                    }}>
                    <Text semiBold color={theme.palette.background.paper}>
                        Detalhes do plano atual:
                    </Text>
                </Container>
            </Container>
            <Container fluid flex minHeight="250px" color={theme.palette.primary.dark} border={theme.palette.secondary.main} borderRadius={BORDER_RADIUS}>
                <Grid container flex={1} padding={5}>
                    <Grid item xs={4}>
                        <Container fluid flex flexStart verticalCentered height="100%">
                            <Container fluid centered veryPadded color={theme.palette.secondary.main} sx={{ borderRadius: BORDER_RADIUS }}>
                                <Text size={22} bold color={theme.palette.background.paper}>
                                    {params.name}
                                </Text>
                            </Container>
                            <Spacing top={PADDING} />
                            <Container fluid>
                                <Text size={15} light color={theme.palette.background.paper}>
                                    {params.description}
                                </Text>
                            </Container>
                        </Container>
                    </Grid>
                    <Grid item xs={0.5} padding={1}>
                        <Container fluid width="0.5px" height="100%" color={theme.palette.secondary.main} />
                    </Grid>
                    <Grid item xs={3}>
                        <Container fluid verticalCentered height="100%">
                            {params.fixedPrice == undefined || params.fixedPrice == 0 &&
                                <SubscriptionInformationAditional text="Pague conforme usar." />
                            }
                            <SubscriptionInformationAditional text={`${params.pagesIncluded} - Páginas incluídas`} />
                            <SubscriptionInformationAditional text={`R$ ${(params.pricePerPage ?? 0).toLocaleString("pt-BR")} - Custo por página`} />
                            <SubscriptionInformationAditional text={params.unlimitedUsers ? "Usuário ilimitados" : "Uso individual"} />
                        </Container>
                    </Grid>
                    <Grid item xs={0.5} padding={1}>
                        <Container fluid width="0.5px" height="100%" color={theme.palette.secondary.main} />
                    </Grid>
                    <Grid item xs={4}>
                        <Container fluid verticalCentered height="100%">
                            <SubscriptionInformationFeature hasRights={params.virtualSupport ?? false} text="Suporte Virtual" />
                            <SubscriptionInformationFeature hasRights={params.useApi ?? false} text="API" />
                            <SubscriptionInformationFeature hasRights={params.customizedSupport ?? false} text="Suporte Personalizado 1:1" />
                            <SubscriptionInformationFeature hasRights={params.useApp ?? false} text="Aplicativo Móvel de Captura de Imagens" />
                            <SubscriptionInformationFeature hasRights={(params.priority ?? 0) >= 1} text={`Nível de Prioridade - ${params.priority == 0 ? "Básico" : params.priority == 1 ? "Médio" : params.priority == 2 ? "Alto" : "Máximo"}`} />
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
};

export default SubscriptionsItemCardDetails;
