import React, { FC } from "react";
import PencilIcon from '@mui/icons-material/EditOutlined';
import { Container, Divider, Spacing, } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import { theme } from "src/components";
import { IInputAdornment } from "src/types";

const InputAdornmentPencil: FC<IInputAdornment> = (params: IInputAdornment) => {
    const color: string = params.color ?? theme.palette.primary.main;

    return (
        <Container inline centered>
            <PencilIcon fontSize="small" sx={{ color: color }} />
            <Spacing left={PADDING} />
            <Divider sx={{
                backgroundColor: color,
                width: "1px",
                height: "20px"
            }} />
            <Spacing left={VERY_PADDING} />
        </Container>
    )
}

export default InputAdornmentPencil;
