import React from "react";

import { IEntityContext } from "./entities";
import { UserProps } from "./financesApi/clients";

interface IUserCredential {
  type: string;
  value: string;
  temporary: boolean;
}

interface IUser {
  id?: string;
  createdTimestamp?: number;
  username: string;
  enabled: boolean;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  email: string;
  attributes?: any;
  requiredActions?: string[];
  credentials?: IUserCredential[];
}

interface IUserMinimal {
  email: string;
  name: string;
  avatar?: string;
}

interface IUserContext extends IEntityContext<UserProps> {
  fetchUserGroups: (_id: string) => Promise<any[]>;
  addGroup: (_userId: string, _groupId: string) => Promise<boolean>;
  removeGroup: (_userId: string, _groupId: string) => Promise<boolean>;
}

export function isUser(_data: any): _data is IUser {
  return "username" in _data && "enabled" in _data && "emailVerified" in _data && "firstName" in _data && "lastName" in _data && "email" in _data;
}

export type { IUser, IUserCredential, IUserContext, IUserMinimal };
