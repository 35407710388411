import React, { FC, createContext, useContext, useState } from "react";

import SubscriptionPlanService from "../services/financesApi/subscriptionPlans";
import {
  SubscriptionPlanProps,
  ISubscriptionPlanContext,
  IHookProvider,
  ISelectValue,
} from "../types";

const SubscriptionsPlansContext = createContext<ISubscriptionPlanContext>(
  {} as ISubscriptionPlanContext
);

const constPriceModels: ISelectValue[] = [
  { id: "0", label: "Preço padrão" },
  { id: "1", label: "Preço graduado" },
  // { id: "2", label: "Preço escalonado" },
];

const constRecurringIntervals: ISelectValue[] = [
  { id: "0", label: "Meses" },
  { id: "1", label: "Anos" },
  { id: "2", label: "Semanas" },
  { id: "3", label: "Dias" },
];

const constRecurringUsageTypes: ISelectValue[] = [
  { id: "0", label: "Medida" },
  { id: "1", label: "Licenciada" }
];

const constTypes: ISelectValue[] = [
  { id: "0", label: "Pagamento único" },
  { id: "1", label: "Recorrente" }
];

const constTiersMode: ISelectValue[] = [
  { id: "0", label: "Graduado" },
  { id: "1", label: "Volume" }
];

const constCurrencies: ISelectValue[] = [
  { id: "0", label: "Real brasileiro" },
  { id: "1", label: "Dólar americano" }
];

export const SubscriptionsPlansProvider: FC<IHookProvider> = (
  _params: IHookProvider
) => {
  const subscriptionPlanService = new SubscriptionPlanService();

  const [entities, setSubscriptionsPlans] = useState<SubscriptionPlanProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15");
  const [paginate, setPaginate] = useState<number>(0);

  const priceModels: ISelectValue[] = constPriceModels;
  const recurringIntervals: ISelectValue[] = constRecurringIntervals;
  const recurringUsageTypes: ISelectValue[] = constRecurringUsageTypes;
  const types: ISelectValue[] = constTypes;
  const tiersMode: ISelectValue[] = constTiersMode;
  const currencies: ISelectValue[] = constCurrencies;

  const createNewEntity = async (_subscriptionPlan: SubscriptionPlanProps) => {
    try {
      const subscriptionPlan = await subscriptionPlanService.create(_subscriptionPlan);
      fetchEntities();
      return subscriptionPlan;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (
    _id: string,
    _subscriptionPlan: SubscriptionPlanProps
  ) => {
    try {
      _subscriptionPlan.id = _id;
      const subscriptionPlan = await subscriptionPlanService.update(_subscriptionPlan);
      fetchEntities();
      return subscriptionPlan;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const subscriptionPlan = await subscriptionPlanService.delete(_id);
      fetchEntities();
      return subscriptionPlan;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const subscriptionsPlans: SubscriptionPlanProps[] = await subscriptionPlanService.getAll();
      setSubscriptionsPlans([...subscriptionsPlans]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const subscriptionPlan: SubscriptionPlanProps = await subscriptionPlanService.getById(_id);
    return subscriptionPlan;
  };

  const convertBillingSchema = (_billingSchema: string) => {
    if (_billingSchema == "per_unit")
      return "Por unidade"
    else if (_billingSchema == "tiered")
      return "Escalonado"
    return "Desconhecido";
  }

  const convertRecurringInterval = (_recurringInterval: string) => {
    if (_recurringInterval == "month")
      return "Mensal";
    else if (_recurringInterval == "year")
      return "Anual";
    else if (_recurringInterval == "week")
      return "Semanal";
    else if (_recurringInterval == "day")
      return "Diário";
    return "Desconhecido";
  }

  const convertRecurringUsageType = (_recurringUsageType: string) => {
    if (_recurringUsageType == "metered")
      return "Medida";
    if (_recurringUsageType == "licensed")
      return "Licenciada";
    return "Desconhecido";
  }

  const convertTierMode = (_tierMode: string) => {
    if (_tierMode == "graduated")
      return "Graduado";
    else if (_tierMode == "volume")
      return "Volume";
    return "Desconhecido";
  }

  const convertCurrency = (_currency: string) => {
    if (_currency == "brl")
      return "Real brasileiro";
    else if (_currency == "usd")
      return "Dólar americano";
    return "Desconhecido";
  }

  const convertCurrencySimbol = (_currency: string | undefined) => {
    if (_currency) {
      if (_currency == "brl")
        return "R$";
      else if (_currency == "usd")
        return "$";
    }
    return "R$";
  }

  const convertType = (_type: string) => {
    if (_type == "one_time")
      return "Pagamento único";
    else if (_type == "recurring")
      return "Recorrente";
    return "Desconhecido";
  }

  return (
    <SubscriptionsPlansContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,

        priceModels, recurringIntervals,
        recurringUsageTypes, types,
        tiersMode, currencies,

        convertBillingSchema,
        convertRecurringInterval,
        convertRecurringUsageType,
        convertTierMode,
        convertCurrency,
        convertType,
        convertCurrencySimbol
      }}
    >
      {_params.children}
    </SubscriptionsPlansContext.Provider>
  );
};

export function useSubscriptionsPlans() {
  const context = useContext(SubscriptionsPlansContext);

  if (!context) {
    throw new Error(
      "useSubscriptionsPlans must be used within an SubscriptionsPlansProvider"
    );
  }

  return context;
}
