import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, IconButton, Input, InputLabel, SxProps, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Container, Divider, Page, Spacing, Text, } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import background from "src/assets/background-image.png";
import brand from "src/assets/brand_white.png";
import { ReactComponent as FacebookIcon } from "src/assets/icons/socialMedia/facebook.svg";
import { ReactComponent as InstagramIcon } from "src/assets/icons/socialMedia/instagram.svg";
import { ReactComponent as LinkedInIcon } from "src/assets/icons/socialMedia/linkedin.svg";
import { ReactComponent as YoutubeIcon } from "src/assets/icons/socialMedia/youtube.svg";
import { theme } from "src/components";
import { useAuth } from "src/hooks/auth";
import { useInformation } from "src/hooks/information";
import { useResponsive } from "src/hooks/responsive";

export const createAccountButtonStyle: SxProps = {
  width: "142px",
  height: "45px",
  backgroundColor: "white",
  '&:hover': {
    backgroundColor: "#EBEBEB",
  },
  borderRadius: "2px"
}

export const createAccountButtonTextStyle: React.CSSProperties = {
  lineHeight: "initial",
  textTransform: "initial"
}

const CreateAccount: React.FC = () => {
  const authHooks = useAuth();
  const informationHooks = useInformation();
  const responsiveHooks = useResponsive();

  const [brandWidth, setBrandWidth] = useState<number>(605);
  const [company, setCompany] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (responsiveHooks.lgUp) setBrandWidth(605);
    else if (responsiveHooks.mdUp) setBrandWidth(400);
    else if (responsiveHooks.xsUp) setBrandWidth(350);
  }, [responsiveHooks.lgUp, responsiveHooks.mdUp, responsiveHooks.xsUp]);

  function validateName(name: string) {
    const nameRegex = /^.{3,}$/;
    const test: boolean = nameRegex.test(name);
    if (!test) setNameError(true);
    return test;
  }

  function validateEmail(email: string) {
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const test: boolean = emailRegex.test(email);
    if (!test) setEmailError(true);
    return test;
  }

  const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCompany(inputValue);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setName(inputValue);
    setNameError(!validateName(inputValue) && inputValue.trim() !== '');
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setEmailError(!validateEmail(inputValue) && inputValue.trim() !== '');
  };

  const validate = () => {
    let valid: boolean = true;
    if (!validateName(name)) valid = false;
    if (!validateEmail(email)) valid = false;

    return valid;
  }

  const save = async () => {
    try {
      setLoading(true);
      if (!validate()) return;

      await authHooks.createAccountTrial(name, email, company);
      setSuccess(true);
    } catch (err) {
      if ((err as any).message.indexOf("Client exists on database with e-mail") >= 0)
        informationHooks.showInformation(`Foi encontrado um cliente em nossa base de dados com o e-mail ${email}. Um e-mail foi enviado com mais detalhes.`, "Erro");
      else
        informationHooks.showInformation(`Ocorreu um erro ao criar uma nova conta. Tente novamente mais tarde.`, "Erro");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Page>
      <Container fluid flex centered relative>
        {responsiveHooks.mdUp && <Container absolute fluid flex sx={{ width: "100%", height: "100vh", background: "#001333", opacity: 0.70, zIndex: 5 }} />}
        {responsiveHooks.mdUp && <Container absolute fluid flex sx={{ width: "100%", height: "100vh", background: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }} />}

        <Grid container sx={{ zIndex: 10 }}>
          {responsiveHooks.mdUp &&
            <Grid item xs={0} md={7} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              <img src={brand} style={{ width: brandWidth, height: brandWidth / 3.01 }} />
              <Spacing top={VERY_PADDING} />
              <Container inline centered width="100%">
                <IconButton href="https://www.linkedin.com/company/pixforce/mycompany/" target="_blank">
                  <LinkedInIcon />
                </IconButton>
                <IconButton href="https://www.youtube.com/@pixforce8146" target="_blank">
                  <YoutubeIcon />
                </IconButton>
                <IconButton href="https://www.facebook.com/pixforceoficial" target="_blank">
                  <FacebookIcon />
                </IconButton>
                <IconButton href="https://www.instagram.com/pix_force/" target="_blank">
                  <InstagramIcon />
                </IconButton>
              </Container>
            </Grid>
          }

          {!success &&
            <Grid item xs={12} md={5} display="flex" justifyContent="center">
              <Container fluid centered width={responsiveHooks.mdUp ? "570px" : "100%"} height="100vh" color={"#001333"}>
                <Grid container display="flex" flexDirection="row" justifyContent="center" rowSpacing={2} columnSpacing={3} padding={8}>
                  {responsiveHooks.mdDown &&
                    <Grid item xs={12} display="flex" justifyContent="center" paddingY={5}>
                      <img src={brand} style={{ width: brandWidth, height: brandWidth / 3.01 }} />
                    </Grid>
                  }
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Text medium size={14} color={theme.palette.secondary.main}>CRIAR NOVA CONTA</Text>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Container fluid width="220px">
                      <Text center size={16} color={theme.palette.secondary.main}>Preencha os dados abaixo para criar sua conta.</Text>
                    </Container>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12} display="flex" sx={{ width: "100%" }}>
                    <TextField size="small" variant="outlined" disabled={loading}
                      placeholder="Insira o seu nome"
                      value={name} onChange={handleNameChange}
                      error={nameError} helperText={nameError && "Nome inválido!"}
                      InputProps={{
                        startAdornment: (<Container inline centered>
                          <Text size={14} color={theme.palette.primary.main} style={{ whiteSpace: "nowrap" }}>
                            Nome
                          </Text>
                          <Spacing left={PADDING} />
                          <Container sx={{ width: "1px", height: "16px", backgroundColor: theme.palette.text.primary }} />
                          <Spacing left={PADDING} />
                        </Container>),
                        sx: { backgroundColor: "white" }
                      }}
                      FormHelperTextProps={{
                        sx: { position: 'absolute', left: "-12px", top: '-20px', fontSize: "12px", fontWeight: 400 },
                      }}
                      sx={{ width: "100%" }} />
                  </Grid>
                  <Grid item xs={12} display="flex" sx={{ width: "100%" }}>
                    <TextField id="email" name="email" size="small" variant="outlined" disabled={loading}
                      placeholder="Insira seu e-mail empresarial"
                      value={email} onChange={handleEmailChange}
                      error={emailError} helperText={emailError && "E-mail inválido!"}
                      InputProps={{
                        startAdornment: (<Container inline centered>
                          <Text size={14} color={theme.palette.primary.main} style={{ whiteSpace: "nowrap" }}>
                            E-mail
                          </Text>
                          <Spacing left={PADDING} />
                          <Container sx={{ width: "1px", height: "16px", backgroundColor: theme.palette.text.primary }} />
                          <Spacing left={PADDING} />
                        </Container>),
                        sx: { backgroundColor: "white" }
                      }}
                      FormHelperTextProps={{
                        sx: { position: 'absolute', left: "-12px", top: '-20px', fontSize: "12px", fontWeight: 400 },
                      }}
                      sx={{ width: "100%" }} />
                  </Grid>
                  <Spacing top={PADDING} />
                  <Grid item xs={12} display="flex" flexDirection="column" sx={{ width: "100%" }}>
                    <TextField size="small" variant="outlined" disabled={loading}
                      placeholder="Insira o nome da sua empresa"
                      value={company} onChange={handleCompanyChange}
                      InputProps={{
                        startAdornment: (<Container inline centered>
                          <Text size={14} color={theme.palette.primary.main} style={{ whiteSpace: "nowrap" }}>
                            Nome da empresa
                          </Text>
                          <Spacing left={PADDING} />
                          <Container sx={{ width: "1px", height: "16px", backgroundColor: theme.palette.text.primary }} />
                          <Spacing left={PADDING} />
                        </Container>),
                        sx: { backgroundColor: "white" }
                      }}
                      FormHelperTextProps={{
                        sx: { position: 'absolute', left: "-12px", top: '-20px', fontSize: "12px", fontWeight: 400 },
                      }}
                      sx={{ width: "100%" }} />
                    <Container fluid horizontalPadded>
                      <Text size={12} color={theme.palette.background.paper} style={{ whiteSpace: "nowrap" }}>
                        Opcional
                      </Text>
                    </Container>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <LoadingButton variant="text" size="medium" loading={loading} disabled={loading}
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      sx={{ ...createAccountButtonStyle, opacity: loading ? 0.7 : 1 }}>
                      <Text center bold size={14} color={"#001333"}
                        style={createAccountButtonTextStyle}>
                        Voltar
                      </Text>
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-start">
                    <LoadingButton variant="text" size="medium"
                      disabled={loading ?? (nameError ?? emailError)}
                      loading={loading}
                      onClick={() => save()}
                      sx={{ ...createAccountButtonStyle, opacity: loading ?? nameError ?? emailError ? 0.7 : 1 }}>
                      <Text center bold size={14} color={"#001333"}
                        style={createAccountButtonTextStyle}>
                        Criar conta
                      </Text>
                    </LoadingButton>
                  </Grid>
                </Grid>

              </Container>
            </Grid>
          }

          {
            success &&
            <Grid item xs={12} md={5} display="flex" justifyContent="center">
              <Container fluid centered width={responsiveHooks.mdUp ? "570px" : "100%"} height="100vh" color={"#001333"}>
                <Grid container display="flex" flexDirection="row" justifyContent="center" rowSpacing={2} columnSpacing={3} padding={8}>
                  {responsiveHooks.mdDown &&
                    <Grid item xs={12} display="flex" justifyContent="center" paddingY={5}>
                      <img src={brand} style={{ width: brandWidth, height: brandWidth / 3.01 }} />
                    </Grid>
                  }
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Text medium size={14} color={theme.palette.secondary.main}>CONTA CRIADA COM SUCESSO!</Text>
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <Container fluid width="220px">
                      <Text center size={16} color={theme.palette.secondary.main}>Deseja ir para tela de login?</Text>
                    </Container>
                  </Grid>
                  <Grid item xs={12} />
                  <Spacing top={PADDING} />
                  <Grid item xs={12} />
                  <Grid item xs={12} display="flex" justifyContent="center">
                    <LoadingButton variant="text" size="medium"
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      sx={{ ...createAccountButtonStyle }}>
                      <Text center bold size={14} color={"#001333"}
                        style={createAccountButtonTextStyle}>
                        Login
                      </Text>
                    </LoadingButton>
                  </Grid>
                </Grid>

              </Container>
            </Grid>
          }
        </Grid >
      </Container >
    </Page >
  );
};

export default CreateAccount;
