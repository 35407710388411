import { FC, useState } from "react";
import { Box, Button, Card, CardContent, Grid, IconButton, Modal, SxProps, TextField, Typography } from "@mui/material";
import { LockOutlined as LockIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";

import { AccountResetPasswordProps } from "src/types";
import { useForm } from "react-hook-form";
import { useAccount } from "src/hooks/account";
import { AccountCredentialsProps } from "src/types/hooks/account";
import { useFeedback } from "src/hooks/feedback";

/**
 * This component submits the reset password routine
 * It uses mui styles
 * @component
 */
const AccountResetPassword: FC<AccountResetPasswordProps> = (props: AccountResetPasswordProps) => {
  // Inits the account hooks
  const account = useAccount();
  // Inits the feedback hooks
  const feedback = useFeedback();
  // Inits the form hooks
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // State that controls the form disable value
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  // State for password value
  const [password, setPassword] = useState<string>("");
  // State for showing password value
  const [passwordShow, setPasswordShow] = useState<boolean>(false);
  // State for password confirmation value
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  // State for showing password confirmation value
  const [passwordConfirmShow, setPasswordConfirmShow] = useState<boolean>(false);

  /**
   * Function that handles new password changes
   * @function
   */
  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  /**
   * Function that handles new password confirmation changes
   * @function
   */
  const handlePasswordConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value);
  };

  /**
   * Function that reset user password
   * @function
   */
  const onSubmit = async () => {
    const payload: AccountCredentialsProps = {
      value: password,
      type: "password",
      temporary: false,
    };

    try {
      setFormDisabled(true);
      await account.resetPassword(props.userId, payload);

      feedback.open(
        true,
        "success",
        "",
        undefined,
        "Senha atualizada com sucesso.",
        undefined,
        undefined,
        ["Confirmar"],
        ["contained"],
        [
          () => {
            feedback.close();
            props.onClose();
            window.location.reload();
          },
        ]
      );
    } catch (err) {
      feedback.open(
        true,
        "error",
        "Atenção",
        undefined,
        "Ocorreu um erro ao alterar sua senha.",
        undefined,
        undefined,
        ["Confirmar"],
        ["contained"],
        [
          () => {
            setFormDisabled(false);
            feedback.close();
          },
        ]
      );
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <Modal open={props.open} onClose={() => props.onClose()}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center" height="100vh">
          <Box width="478px" minHeight="268px">
            <Card>
              <CardContent>
                <Typography fontSize="24px" fontWeight="400" lineHeight="36px">
                  Alterar senha
                </Typography>

                <Grid container display="flex" rowSpacing={1} paddingTop={2}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={formDisabled}
                      required
                      type={passwordShow ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="Senha nova"
                      placeholder="Informe sua nova senha"
                      value={password}
                      InputProps={{
                        startAdornment: <LockIcon />,
                        endAdornment: (
                          <IconButton onClick={() => setPasswordShow(!passwordShow)}>
                            {passwordShow ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        ),
                      }}
                      error={errors.password && errors.password.message}
                      helperText={errors.password ? errors.password.message : " "}
                      {...register("password", {
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => handlePassword(event),
                        required: "*Campo obrigatório",
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message: `Sua senha deve conter pelo menos 8 caracteres, 1 letra maíuscula, 1 letra minúscula e 1 caractere especial
                          `,
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={formDisabled}
                      required
                      type={passwordConfirmShow ? "text" : "password"}
                      variant="outlined"
                      fullWidth
                      size="small"
                      label="Repetir senha nova"
                      placeholder="Informe novamente sua nova senha"
                      value={passwordConfirm}
                      InputProps={{
                        startAdornment: <LockIcon />,
                        endAdornment: (
                          <IconButton onClick={() => setPasswordConfirmShow(!passwordConfirmShow)}>
                            {passwordConfirmShow ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        ),
                      }}
                      error={errors.passwordConfirm && errors.passwordConfirm.message}
                      helperText={errors.passwordConfirm ? errors.passwordConfirm.message : " "}
                      {...register("passwordConfirm", {
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => handlePasswordConfirm(event),
                        required: "*Campo obrigatório",
                        validate: (val: string) => {
                          if (watch("password") !== val) return "As duas senhas não se coincidem";
                        },
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button disabled={formDisabled} variant="text" size="large" onClick={() => props.onClose()} sx={{ marginRight: "12px" }}>
                      <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                        Cancelar
                      </Typography>
                    </Button>
                    <Button disabled={formDisabled} variant="contained" size="large" type="submit">
                      <Typography fontSize="16px" fontWeight="400" lineHeight="24px" letterSpacing="0.5px" textTransform="initial">
                        Salvar
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

export default AccountResetPassword;
