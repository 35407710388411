import moment, { Moment } from "moment";

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function removeEmpty(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const downloadCSVFromJson = (filename: any, arrayOfJson: any) => {
  // convert JSON to CSV
  const replacer = (key: any, value: any) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(arrayOfJson[0]);
  let csv = arrayOfJson.map((row: any) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
  csv.unshift(header.join(","));
  csv = csv.join("\r\n");

  // Create link and download
  var link = document.createElement("a");
  link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv));
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function unknownToMoment(valor: unknown): Moment | null {
  if (moment.isMoment(valor)) return valor as Moment;

  if (typeof valor === "string") {
    const momentObj = moment(valor);
    if (momentObj.isValid()) {
      return momentObj;
    }
  }
  return null;
}

export async function downloadAndConvertToBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Erro ao baixar o arquivo. Status: ${response.status}`);
    }

    const blob = await response.blob();
    const base64String = await fileToBase64(blob);

    return base64String;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function fileToBase64(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String.split(",")[1]);
    };

    reader.onerror = () => {
      reject(new Error("Erro ao ler o arquivo."));
    };

    reader.readAsDataURL(blob);
  });
}

export function getBase64Extension(base64String: string): string | undefined {
  const regex = /^data:(.+);base64,/;

  const match = base64String.match(regex);
  if (match && match[1]) {
    const mimeType = match[1];
    const extensions: any = {
      "image/png": "png",
      "image/jpeg": "jpeg",
      "image/gif": "gif",
      pdf: "pdf",
    };

    if (extensions[mimeType]) {
      return extensions[mimeType];
    }
  }

  return undefined;
}
