import { FC, createContext, useCallback, useState, useContext, useEffect } from "react";
import Keycloak, { KeycloakInitOptions } from "keycloak-js";

import api, { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from "src/services";
import { AuthenticationServices } from "src/services/auth";
import {
  ApiKeyProps,
  IAuthContext,
  IAuthLogin,
  IAuthResetPassword,
  IAuthUserLogged,
  IHookProvider,
  SubscriptionProps,
  SubscriptionUsageRecordProps,
  instanceOfIAuthLogin,
} from "src/types";
import { PIX_DOCS_ACCESS_TOKEN, PIX_DOCS_REFRESH_TOKEN, PIX_DOCS_TOKEN_EXPIRES_IN, PIX_DOCS_USER } from "src/utils/consts";
import { parseJwt } from "src/utils/parses";

import ClientService from "src/services/financesApi/clients";
import { ClientProps } from "src/types";
import SubscriptionService from "src/services/financesApi/subscriptions";
import ApiKeyService from "src/services/financesApi/apiKeys";

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const REFRESH_TOKEN_INTERVAL: number = 1000 * 60 * 5; // 1000 miliseconds * 60 seconds * x minutes

export const AuthProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const authenticationServices = new AuthenticationServices("Autenticação", "/api_manager/auth");

  const [keycloak, setKeycloak] = useState<Keycloak | undefined>(undefined);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [refreshToken, setRefreshToken] = useState<string | undefined>(undefined);
  const [expiresIn, setExpiresIn] = useState<number | undefined>(undefined);

  const [groups, setGroups] = useState<string[]>([]);
  const [roles, setRoles] = useState<string[]>([]);

  const [user, setUser] = useState<IAuthUserLogged | undefined>(undefined);
  const [client, setClient] = useState<ClientProps | undefined>(undefined);
  const [subscription, setSubscription] = useState<SubscriptionProps | undefined>(undefined);
  const [apiKeyDefault, setApiKeyDefault] = useState<ApiKeyProps | undefined>(undefined);

  /**
   * Each five minutes the token will be refreshed
   * @effect
   */
  useEffect(() => {
    if (!keycloak) return;

    const interval = setInterval(async () => {
      onRefreshToken();
    }, REFRESH_TOKEN_INTERVAL);

    return () => clearInterval(interval);
  }, [keycloak]);

  /**
   * Function that initializes keycloak, redirecting to login page and returning the access token control
   * @function
   */
  const keycloakInit = async () => {
    const keycloakInitOptions: KeycloakInitOptions = {
      onLoad: "login-required",
    };
    const auxKeycloak = new Keycloak({
      url: KEYCLOAK_URL,
      realm: KEYCLOAK_REALM,
      clientId: KEYCLOAK_CLIENT_ID,
    });

    try {
      await auxKeycloak.init(keycloakInitOptions);
      if (auxKeycloak.authenticated) postLogin(authenticationServices.convertKeycloakToIAuthLogin(auxKeycloak));
    } catch (error: any) {
      console.error("Failed to initialize adapter:", error);
    } finally {
      setKeycloak(auxKeycloak);
    }
  };

  /**
   * Function that refresh user token
   * @function
   */
  const onRefreshToken = async () => {
    if (!keycloak) return;

    const auxKeycloak: Keycloak = keycloak;
    auxKeycloak.updateToken(-1);
    if (auxKeycloak.authenticated) postLogin(authenticationServices.convertKeycloakToIAuthLogin(auxKeycloak));
  };

  /**
   * Function that load user storage informations and stores into app state
   * @function
   */
  const loadStorage = async () => {
    const accessToken: string | null = localStorage.getItem(PIX_DOCS_ACCESS_TOKEN);
    const refreshToken: string | null = localStorage.getItem(PIX_DOCS_REFRESH_TOKEN);
    const expiresInStorage: string | null = localStorage.getItem(PIX_DOCS_TOKEN_EXPIRES_IN);
    if (accessToken && refreshToken && expiresInStorage) {
      const parsedToken: any = parseJwt(accessToken);
      saveGroups(parsedToken.user_groups);
      saveRoles(parsedToken.resource_access);

      const userStorage: any | null = localStorage.getItem(PIX_DOCS_USER);
      const userParsed: any = JSON.parse(userStorage);
      const user: IAuthUserLogged = authenticationServices.convertToIAuthUserLogged(userParsed);
      saveUser(user);

      await fetchUserData();
    }
  };

  /**
   * Function that save all user information after sucessfull login
   * @function
   */
  const postLogin = async (_authLogin: IAuthLogin) => {
    // save the access token, refresh token and expire in data into app state
    saveToken(_authLogin.accessToken, _authLogin.refreshToken, _authLogin.expiresIn);

    // convert access token to obj and save groups, roles and the user into app state
    const parsedToken: any = parseJwt(_authLogin.accessToken);
    if (parsedToken) {
      saveGroups(parsedToken.user_groups);
      saveRoles(parsedToken.resource_access);
      const user: IAuthUserLogged = authenticationServices.convertToIAuthUserLogged(parsedToken);
      saveUser({...user});
    }
  };

  /**
   * Function that save token data into app state and browser storage
   * @function
   */
  const saveToken = (_accessToken: string | undefined, _refreshToken: string | undefined, _expiresIn: number | undefined) => {
    // save token data into app state
    setAccessToken(_accessToken);
    setRefreshToken(_refreshToken);
    setExpiresIn(_expiresIn);

    // save token data into browser storage
    if (_accessToken) localStorage.setItem(PIX_DOCS_ACCESS_TOKEN, _accessToken);
    if (_refreshToken) localStorage.setItem(PIX_DOCS_REFRESH_TOKEN, _refreshToken);
    if (_expiresIn) localStorage.setItem(PIX_DOCS_TOKEN_EXPIRES_IN, _expiresIn.toString());

    // updates api authorization bearer
    api.defaults.headers.common["Authorization"] = `Bearer ${_accessToken}`;
  };

  /**
   * Function that save user data into app state and browser storage
   * @function
   */
  const saveUser = (_user: IAuthUserLogged) => {
    // save user data into app state
    setUser(_user);

    // save user data into browser storage
    localStorage.setItem(PIX_DOCS_USER, JSON.stringify(_user));
  };

  /**
   * Function that save user groups into app state
   * @function
   */
  const saveGroups = (_userGroups: any) => {
    const pixDocsGroups = _userGroups;
    setGroups(pixDocsGroups);
  };

  /**
   * Function that save user groups into app state
   * @function
   */
  const saveRoles = (_resourceAccess: any) => {
    let pixDocsRoles = [];
    if (_resourceAccess.pix_docs) pixDocsRoles = _resourceAccess.pix_docs.roles;
    else if (_resourceAccess.idexa) pixDocsRoles = _resourceAccess.idexa.roles;

    // api finances roles
    let apiFinancesRoles = [];
    if (_resourceAccess["idexa-api-finances"]) apiFinancesRoles = _resourceAccess["idexa-api-finances"].roles;

    // api documents roles
    let apiDocumentsRoles = [];
    if (_resourceAccess["idexa-api-documents"]) apiDocumentsRoles = _resourceAccess["idexa-api-documents"].roles;

    setRoles([...pixDocsRoles, ...apiFinancesRoles, ...apiDocumentsRoles]);
  };

  /**
   * Function thats clear current user session by removing token and user data from app state and browser storage
   * @function
   */
  const clearSession = () => {
    clearToken();
    clearUser();
  };

  /**
   * Function thats removes token from app state and browser storage
   * @function
   */
  const clearToken = () => {
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setExpiresIn(undefined);

    localStorage.removeItem(PIX_DOCS_ACCESS_TOKEN);
    localStorage.removeItem(PIX_DOCS_REFRESH_TOKEN);
    localStorage.removeItem(PIX_DOCS_TOKEN_EXPIRES_IN);
    localStorage.removeItem(PIX_DOCS_USER);

    api.defaults.headers.common["Authorization"] = "";
  };

  /**
   * Function thats removes user from app state and browser storage
   * @function
   */
  const clearUser = () => {
    setUser(undefined);
    setClient(undefined);
    setGroups([]);
    setRoles([]);

    localStorage.removeItem(PIX_DOCS_USER);
  };

  /**
   * Function thats fetch all user data from logged user
   * Client, subscription and api key informations will saved into app state
   * @function
   */
  const fetchUserData = async () => {
    if (!user?.id) return;

    // get client of logged user
    const auxClient: ClientProps = await new ClientService().getByUserId(user.id);
    setClient(auxClient);

    if (auxClient?.id) {
      // get current subscription of logged user
      const auxSubscription: SubscriptionProps = await new SubscriptionService().getCurrentByClientId(auxClient.id);
      setSubscription(auxSubscription);

      // get default api key of logged user
      const auxApiKey: ApiKeyProps = await new ApiKeyService().getDefaultByClientId(auxClient.id);
      setApiKeyDefault(auxApiKey);
    }
  };

  /**
   * Starts logout process by clearing user session
   * @function
   */
  const logout = useCallback(async () => {
    clearSession();
  }, []);

  /**
   * Checks if user has super-admin rights
   * @function
   */
  function isSuperAdmin() {
    return groups && groups.indexOf("/super-admin") >= 0;
  }

  /**
   * Checks if user subscription can use api key
   * @function
   */
  const userCanUseApiKey = () => {
    if (!subscription) return false;

    return subscription.subscriptionPlan?.useApi || false;
  };

  /**
   * Function that creates a new account in app
   * @function
   */
  const createAccountTrial = async (_name: string, _email: string, _company: string | undefined = undefined) => {
    try {
      const subscription: SubscriptionProps = await new SubscriptionService().createFreeTrial(_name, _email, _company ?? _name);
      return subscription;
    } catch (_err) {
      throw _err;
    }
  };

  /**
   * Function that returns the usage record of logged user
   * @function
   */
  const getUsageRecord = async () => {
    if (!subscription || !subscription.id) throw new Error("Assinatura não encontrada.");

    try {
      const subscriptionUsageRecord: SubscriptionUsageRecordProps = await new SubscriptionService().getUsageRecords(subscription.id);
      return subscriptionUsageRecord;
    } catch (err) {
      throw err;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        keycloak,
        keycloakInit,

        accessToken,
        refreshToken,
        expiresIn,
        onRefreshToken,

        user,
        roles,

        client,
        subscription,
        apiKeyDefault,
        getUsageRecord,

        loadStorage,
        postLogin,
        fetchLoggedUser: fetchUserData,

        isSuperAdmin,
        userCanUseApiKey,

        logout,
        createAccountTrial,
      }}
    >
      {_params.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  const hasRights = (path: string): boolean => {
    return !!(context.roles.indexOf(path) >= 0);
  };

  context.hasRights = hasRights;
  return context;
}
