import { FC, useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";

import { CRUDTemplate } from "src/components";
import { useDocumentModels } from "src/hooks/documentModels";

/**
 * This component returns the Documents Custom Models CRUD page
 * It uses mui styles
 * @component
 */
const DocumentModels: FC = () => {
  // Documents models hooks to fetch list
  const documentModels = useDocumentModels();

  // State for current data grid page
  const [dataGridRowsPerPage, setDataGridRowsPerPage] = useState<number>(5);

  // State for current data grid page
  const [dataGridPage, setDataGridPage] = useState<number>(0);

  /**
   * Reflects when component inits
   * @effect
   */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Function to fetch all necessary data of the page
   * @function
   */
  const fetchData = () => {
    documentModels.fetchEntities();
  };

  return (
    <>
      <CRUDTemplate
        dataGridLoading={documentModels.loading}
        title="Modelos padrões"
        headerTitle="Lista de modelos padrões"
        dataGridColumns={documentsDataGridColumns}
        dataGridRows={documentModels.entities}
        dataGridPageSize={dataGridRowsPerPage}
        paginationModel={{
          page: dataGridPage - 1,
          pageSize: dataGridRowsPerPage,
        }}
        onPaginationModelChange={(model, details) => {
          setDataGridPage(model.page + 1);
          setDataGridRowsPerPage(model.pageSize);
        }}
      >
        <div />
      </CRUDTemplate>
    </>
  );
};

export default DocumentModels;

export const documentsDataGridColumns: GridColDef[] = [
  {
    field: "description",
    headerName: "Nome",
    minWidth: 75,
    flex: 3,
  },
  {
    field: "name",
    headerName: "Nome interno",
    flex: 2,
  },
  {
    field: "type",
    headerName: "Código interno",
    minWidth: 75,
    flex: 1,
  },
  {
    field: "service",
    headerName: "Serviço interno",
    minWidth: 75,
    flex: 1,
  },
  {
    field: "gatewayVersion",
    headerName: "Versão Gateway",
    minWidth: 75,
    flex: 1,
  },
];
