import { FC, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Grid, Modal, SxProps, Typography } from "@mui/material";

import DocumentsResultFilePreview from "../results/filePreview";
import { useResponsive } from "src/hooks/responsive";
import { IDocumentsPreview } from "src/types/components/molecules/v2/documents";
import { toBase64 } from "src/utils/converters";
/**
 * This component returns the preview os files
 * @component
 */
const DocumentsPreview: FC<IDocumentsPreview> = (props: IDocumentsPreview) => {
    // Responsive hooks for layout changes
    const responsive = useResponsive();

    // State for selected file
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    // State for selected base64 content
    const [selectedBase64, setSelectedBase64] = useState<any | undefined>(undefined);
    // State for extension file
    const [extension, setExtension] = useState<string | undefined>(undefined);

    /** 
     * Reflects when open status has changed
     * @effect
     */
    useEffect(() => {
        setSelectedIndex(0);
    }, [props.isOpen]);

    /** 
     * Reflects when files has changed
     * @effect
     */
    useEffect(() => {
        if (props.files.length > 0)
            setSelectedFile(0);
    }, [props.files]);

    /** 
     * Reflects when selectedIndex has change
     * @effect
     */
    useEffect(() => {
        setSelectedFile(selectedIndex);
    }, [selectedIndex]);

    const setSelectedFile = async (index: number) => {
        if (!props.files) return;

        const auxBase64: any = await toBase64(props.files[index]);
        const separatorIndex = auxBase64.indexOf(',');
        const base64WithoutHeader = separatorIndex !== -1 ? auxBase64.slice(separatorIndex + 1) : auxBase64;
        setSelectedBase64(base64WithoutHeader);

        let auxExtension: string | undefined = undefined;
        auxExtension = props.files[selectedIndex].name.split(".").pop();
        setExtension(auxExtension);
    }

    return (
        <Modal open={props.isOpen} onClose={() => props.onCancel()}>
            <Box display="flex" flex={1} flexGrow={1} alignItems="center" justifyContent="center" height="100vh">
                <Box display="flex" flexDirection="column"
                    width={responsive.smUp ? "900px" : "450px"} minHeight="500px" maxHeight="650px">
                    <Card sx={cardStyle}>
                        <CardContent sx={{ display: "flex", flexDirection: "column", flex: 1, width: "100%", maxWidth: "100%" }}>
                            <Box display="flex" flex={1}>
                                <Typography fontSize="24px" fontStyle="normal"
                                    fontWeight="400" lineHeight="32px">
                                    Preview de documentos
                                </Typography>
                            </Box>
                            <Grid container columnSpacing="30px" paddingTop="24px" flex={2}>
                                <Grid item xs={2}>
                                    <Grid container rowSpacing="16px" sx={{ maxHeight: "450px", overflowY: "auto" }}>
                                        {props.files.map((item, index) => {
                                            return (
                                                <Grid key={index} item xs={12} position="relative"
                                                    display="flex" flexDirection="column"
                                                    alignItems="center" justifyContent="center">
                                                    <DocumentsResultFilePreview miniature
                                                        file={item}
                                                        extension={item.name.split(".").pop()} />
                                                    <Typography width="125px"
                                                        fontSize="11px" fontStyle="normal" fontWeight="400"
                                                        lineHeight="16px" letterSpacing="0.5px"
                                                        textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden"
                                                        onClick={() => {
                                                            setSelectedIndex(index)
                                                        }}
                                                        sx={{ cursor: "pointer", textDecoration: "underline", color: "blue" }}>
                                                        {item.name}
                                                    </Typography>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    {props.files.length > 0 && extension &&
                                        <DocumentsResultFilePreview dense
                                            width={responsive.mdUp ? 350 : 150}
                                            height={380}
                                            file={props.files[selectedIndex]}
                                            extension={extension} />
                                    }
                                </Grid>
                            </Grid>

                            <Box display="flex" flex={1} alignItems="center" justifyContent="flex-end" width="100%"
                                paddingTop="12px" paddingBottom="12px">
                                <Button variant="contained" onClick={() => props.onCancel()}>
                                    <Typography fontSize="14px" fontStyle="normal" fontWeight="500"
                                        lineHeight="20px" letterSpacing="01px" textTransform="initial">
                                        Fechar
                                    </Typography>
                                </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </Modal>
    )
}

export default DocumentsPreview;

const cardStyle: SxProps = {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    flexGrow: 1,
    width: "100%",
    alignItems: "center",
    padding: "24px",
    borderRadius: "12px"
}