import React, { FC, createContext, useContext, useState } from "react";
import ApiKeyService from "src/services/financesApi/apiKeys";
import { ApiKeyProps, IHookProvider } from "src/types";
import { IApiKeyContext } from "src/types/hooks/apiKeys";


const ApiKeysContext = createContext<IApiKeyContext>({} as IApiKeyContext);

export const ApiKeysProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const apiKeyService = new ApiKeyService();

  const [entities, setApiKeys] = useState<ApiKeyProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("15")
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_apiKey: ApiKeyProps) => {
    try {
      const apiKey = apiKeyService.create(_apiKey);
      fetchEntities();
      return apiKey

    }
    catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _apiKey: ApiKeyProps) => {
    try {
      _apiKey.id = _id;
      const apiKey = await apiKeyService.update(_apiKey);
      fetchEntities();
      return apiKey;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const apiKey = await apiKeyService.delete(_id);
      fetchEntities();
      return apiKey;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async () => {
    setLoading(true);
    try {
      const apiKeys: ApiKeyProps[] = await apiKeyService.getAll();
      setApiKeys([...apiKeys]);

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (_err) {
      console.log(_err);
      setLoading(false);
    }
  };

  const fetchEntity = async (_id: string) => {
    const apiKey: ApiKeyProps = await apiKeyService.getById(_id);
    return apiKey;
  };

  const getByClientId = async (clientId: string) => {
    const apiKeys: ApiKeyProps[] = await apiKeyService.getByClientId(clientId);
    return apiKeys;
  }

  return (
    <ApiKeysContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,

        getByClientId
      }}
    >
      {_params.children}
    </ApiKeysContext.Provider>
  );
};


export function useApiKeys() {
  const context = useContext(ApiKeysContext);
  if (!context) throw new Error("UseApiKeys must be used within an ApiKeysProvider");
  return context;
}