import { EntityServices } from "./entities";
import { IDocumentModel } from "src/types";
import { IDocumentModelField } from "src/types/hooks/documentModels";
import { parseStringToDate } from "src/utils/dates";
import { API_DOCUMENTS_URL } from ".";

export class DocumentModelServices extends EntityServices<IDocumentModel> {
  constructor() {
    super("Modelos", `${API_DOCUMENTS_URL}/document_models`);
  }

  override convertApiResponseToIEntity(_data: any): IDocumentModel {
    const fields: IDocumentModelField[] = [];
    if (_data.fields) {
      for (let i = 0; i < _data.fields.length ?? 0; i++) {
        let field: IDocumentModelField = {
          id: _data.fields[i].id,
          documentModelId: _data.fields[i].documentModelId,
          fieldName: _data.fields[i].fieldName,
          fieldType: _data.fields[i].fieldType,
          description: _data.fields[i].description,
          abbreviation: _data.fields[i].abbreviation,
          example: _data.fields[i].example,
        };
        fields.push(field);
      }
    }

    return {
      id: _data.id,
      name: _data.name,
      description: _data.description,
      type: _data.type,
      service: _data.service,
      serviceVersion: _data.serviceVersion,
      gatewayVersion: _data.gatewayVersion,
      fields,
      abbreviation: _data.abbreviation,
      isDeleted: _data.isDeleted,
      createdAt: parseStringToDate(_data.createdAt, "YYYY-MM-DD HH:mm:ss"),
      updatedAt: parseStringToDate(_data.updatedAt, "YYYY-MM-DD HH:mm:ss"),
    };
  }
}
