import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
// import { Magnifier } from "react-image-magnifiers";

import "./classes.css";
import theme from "src/components/theme";
import { IDocumentsResultFilePreview } from "src/types/components/molecules/v2/documents";
import PdfReader from "src/components/molecules/v2/pdfReader";

/**
 * This component returns the documents file
 * It uses mui styles
 * @component
 */
const DocumentsResultFilePreview: FC<IDocumentsResultFilePreview> = (
  props: IDocumentsResultFilePreview
) => {
  // Const of height of component
  const height: string = props.miniature
    ? "94px"
    : props.dense
    ? "450px"
    : "548px";
  const paddingX: string = props.miniature ? "5px" : "24px";
  const paddingY: string = props.miniature ? "19px" : "24px";

  const [file, setFile] = useState<any>(undefined);

  /**
   * Reflects when base64 has change
   * @effect
   */
  useEffect(() => {
    if (props.base64) {
      const auxFile: any = base64ToFile(
        props.base64,
        "file.pdf",
        "application/pdf"
      );
      setFile(auxFile);
    } else if (props.file) {
      setFile(props.file);
    }
  }, [props.base64, props.file]);

  /**
   * Function to convert base64 to file
   * @function
   */
  function base64ToFile(
    base64String: string,
    fileName: string,
    mimeType: string
  ): File {
    const binaryString = atob(base64String);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([bytes], { type: mimeType });
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      flexGrow={1}
      width="100%"
      height="auto"
    >
      <Box
        position="relative"
        display="flex"
        flex={1}
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        maxWidth="100%"
        maxHeight={props.height ?? height}
        minHeight={props.height ?? height}
        borderRadius="6px"
        paddingY={paddingY}
        paddingX={paddingX}
        sx={{
          backgroundColor: theme.palette.backgroundStrokeActive.light,
          border: props.miniature
            ? "1px solid var(--Schemes-On-Primary-Container, #001A40)"
            : "none",
        }}
      >
        <Box
          display="flex"
          flex={1}
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          maxWidth="100%"
          maxHeight={props.height ?? height}
          minHeight={props.height ?? height}
        >
          {props.extension.toLowerCase() === "pdf" ? (
            <PdfReader
              fileUrl={props.fileUrl ?? (file && URL.createObjectURL(file))}
              page={1}
              height={props.height ?? height}
              miniature={props.miniature}
            ></PdfReader>
          ) : (
            <>
              {props.miniature ? (
                <img
                  src={props.fileUrl ?? (file && URL.createObjectURL(file))}
                  style={{
                    maxHeight: props.height ?? height,
                  }}
                />
              ) : (
                <img
                  src={props.fileUrl ?? (file && URL.createObjectURL(file))}
                  style={{
                    maxHeight: props.height ?? height,
                    maxWidth: "100%",
                  }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentsResultFilePreview;
