import { FC, useEffect } from "react";
import { Checkbox, Grid } from "@mui/material";
import { Container, Text } from "@pix-force/react-atoms";
import { PADDING, VERY_PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import theme from "src/components/theme";
import { IDocumentMultiSelectItem } from "src/types";
import { DATE_FORMAT, parseDateToString, TIME_FORMAT } from "src/utils/dates";
import { useDocuments } from "src/hooks/documents";

const DocumentMultiSelectItem: FC<IDocumentMultiSelectItem> = (props: IDocumentMultiSelectItem) => {
    const { documentsTypes } = useDocuments();

    if (!props.document.id) return null;

    return (
        <Container fluid flex sx={{ paddingInline: VERY_PADDING * 2 }}>
            <Grid container padding={1} alignItems="center" sx={{ borderBottom: `solid 1px ${theme.palette.secondary.main}` }}>
                <Grid item xs={2}>
                    <Checkbox size="small" color="secondary" checked={props.selectedDocumentsId.indexOf(props.document.id) >= 0}
                        onClick={() => props.document.id && props.onSelect(props.document.id)}
                        sx={{ color: "white" }} />
                </Grid>
                <Grid item xs={2}>
                    <Text semiBold size={17} color={theme.palette.background.paper}>
                        {props.document.index}
                    </Text>
                </Grid>
                <Grid item xs={4}>
                    <Text semiBold size={17} color={theme.palette.background.paper}>
                        {documentsTypes.find((_document) => _document.value == props.document.type)?.description}
                    </Text>
                </Grid>
                <Grid item xs={2}>
                    <Text semiBold size={17} color={theme.palette.background.paper}>
                        {props.document.createdAt && parseDateToString(props.document.createdAt, DATE_FORMAT)}
                    </Text>
                </Grid>
                <Grid item xs={2}>
                    <Text semiBold size={17} color={theme.palette.background.paper}>
                        {props.document.createdAt && parseDateToString(props.document.createdAt, TIME_FORMAT)}
                    </Text>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DocumentMultiSelectItem;
