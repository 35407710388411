import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, FormControl, Grid, InputLabel, MenuItem, Switch, TextField, Select, Typography } from "@mui/material";
import 'dayjs/locale/pt-br';

import RegisterTemplate from "src/components/templates/registerTemplate";
import { useAuth } from "src/hooks/auth";
import { useClients } from "src/hooks/clients";
import { useFeedback } from "src/hooks/feedback";
import { useProducts } from "src/hooks/products";
import { ISelectValue } from "src/types";
import { IClientsRegister } from "src/types/components/molecules/v2/clients";
import { IClient } from "src/types/hooks/clients";

import citiesData from "src/utils/municipios.json";
import statesData from "src/utils/estados.json";
import countriesData from "src/utils/paises.json"

const ClientsRegister: FC<IClientsRegister> = (props: IClientsRegister) => {
    const Cities = citiesData.data;
    const States = statesData.data;
    const cnpjMask = '##.###.###/####-##';
    const postalCodeMask = '#####-###';

    // Const about edit mode
    const edit: boolean = props.payload !== undefined;

    // Auth hooks to get client information
    const auth = useAuth();
    // Products hooks to get categories information
    const products = useProducts();
    // Subscriptions plans hooks to create or update data
    const clients = useClients();
    // Information hooks for user feedback
    const feedback = useFeedback();
    const { register, setValue } = useForm();

    const [loading, setLoading] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>("");
    const [emailError, setEmailError] = useState<string>("");
    const [cpfCnpjError, setcpfCnpjError] = useState<string>("");
    const [cityNameError, setCityNameError] = useState<string>("");
    const [streetError, setStreetError] = useState<string>("");
    const [postalCodeError, setPostalCodeError] = useState<string>("");
    const [numberError, setNumberError] = useState<string>("");
    const [districtError, setDistrictError] = useState<string>("");

    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [cpfCnpj, setCpfCnpj] = useState<string>("");
    const [country, setCountry] = useState<string>("BR");
    const [postalCode, setPostalCode] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [additionalInformation, setAdditionalInformation] = useState<string>("");
    const [district, setDistrict] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [cityCode, setCityCode] = useState<string>("");
    const [cityName, setCityName] = useState<string>("");
    const [canChangeSubscription, setCanChangeSubscription] = useState<boolean>(false);

    const [subscriptionType, setSubscriptionType] = useState<ISelectValue | undefined>(undefined);
    const [subscriptionPlan, setSubscriptionPlan] = useState<any>(undefined);
    const [subscriptionPlanName, setSubscriptionPlanName] = useState<string>("");
    const [subscriptionPlanError, setSubscriptionPlanError] = useState<string | undefined>(undefined);
    const [initialSubscriptionDate, setInitialSubscriptionDate] = useState<Date | null>(null);
    const [initialSubscriptionDateError, setInitialSubscriptionDateError] = useState<string | undefined>(undefined);
    const [finalSubscriptionDate, setFinalSubscriptionDate] = useState<Date | null>(null);
    const [finalSubscriptionDateError, setFinalSubscriptionDateError] = useState<string | undefined>(undefined);

    const filteredCitiesByUf = Cities.filter((city) => city.Uf == state);

    const formatPostalCode = (value: string) => {
        const numbersOnly = value.replace(/[^\d]/g, '');
        let formattedValue = '';

        for (let i = 0; i < numbersOnly.length && i < postalCodeMask.length; i++) {
            if (postalCodeMask[i] === '#') {
                formattedValue += numbersOnly[i] ?? '';
            } else {
                formattedValue += postalCodeMask[i];
                if (numbersOnly[i]) {
                    formattedValue += numbersOnly[i];
                }
            }
        }

        return formattedValue;
    };

    const formatCnpj = (value: string) => {
        const numbersOnly = value.replace(/[^\d]/g, '');
        let formattedValue = '';
        let valueIndex = 0;

        // Apply the mask to the CNPJ value
        for (let i = 0; i < cnpjMask.length; i++) {
            if (cnpjMask[i] === '#') {
                formattedValue += numbersOnly[valueIndex] ?? '';
                valueIndex++;
            } else {
                formattedValue += cnpjMask[i];
            }
        }

        return formattedValue;
    };

    /**
     * Reflects component init
     * @effect
     */
    useEffect(() => {
        fetchData();
    }, []);

    /**
     * Handle the change payload in props
     * @effect
     */
    useEffect(() => {
        if (edit) loadEditFields();
    }, [props.payload]);


    /**
     * Function that fetch necessary data
     * @function
     */
    const fetchData = async () => {
        products.fetchEntities();
    };


    /**
     * Function that load edit fields
     * @function
     */
    const loadEditFields = async () => {
        setLoading(true);
        if (props.payload && props.payload.id) {
            const client: IClient = await clients.fetchEntity(props.payload.id);
            setName(client.name);
            if (client.email) setEmail(client.email);
            if (client.cpfCnpj) setCpfCnpj(client.cpfCnpj);
            if (client.cityCode) setCityCode(client.cityCode);
            if (client.cityName) setCityName(client.cityName);
            if (client.country) client.country == "BRA" ? setCountry("BR") : setCountry(client.country);
            if (client.district) setDistrict(client.district);
            if (client.state) setState(client.state)
            if (client.number) setNumber(client.number);
            if (client.postalCode) setPostalCode(client.postalCode);
            if (client.street) setStreet(client.street);
            if (client.additionalInformation) setAdditionalInformation(client.additionalInformation);
            if (client.canChangeSubscription) setCanChangeSubscription(client.canChangeSubscription);
        } else {
            props.onCancel();
            setLoading(false);
            feedback.open(true, "error", "Erro", undefined, "Ocorreu um erro ao editar o cliente.");

        }
        setLoading(false);
    };

    /**
     * Handle the change text of setText passed on args
     * @function
     */
    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>, setText: Function) => {
        setText(event.target.value);
    }
    const handleChangeNumber = (event: React.ChangeEvent<HTMLInputElement>, setNumber: Function) => {
        if (Number.isNaN(event.target.value)) setNumber(undefined);
        else setNumber(parseInt(event.target.value))
    };
    const handleChangeFloat = (event: React.ChangeEvent<HTMLInputElement>, setFloat: Function) => {
        if (Number.isNaN(event.target.value)) setFloat(undefined);
        else setFloat(parseFloat(event.target.value))
    }

    const validation = () => {
        setNameError("");
        let isValid: boolean = true;
        if (name === "") {
            isValid = false;
            setNameError("*Campo incorreto"); // const [nameError, setNameError] = useState<string | undefined>(undefined);
        }
        return isValid;
    };

    const save = async () => {
        try {
            if (!validation()) return;

            setLoading(true);
            let payload: IClient = {
                name,
                email,
                cpfCnpj: cpfCnpj.replace(/[^\d]/g, ''),
                avatarUri: undefined,
                country: country == "BR" ? "BRA" : country,
                postalCode: postalCode.replace(/[^\d]/g, ''),
                street,
                number,
                additionalInformation,
                district,
                state,
                cityCode,
                cityName,
                canChangeSubscription
            };


            let informationMessage: string = "Cliente cadastrado com sucesso!";
            if (edit && props.payload && props.payload.id) {
                await clients.editEntity(props.payload.id, payload);
                informationMessage = "Cliente alterado com sucesso!";
            } else {
                const auxClient: IClient = await clients.createNewEntity(
                    payload,
                    true
                );
            }

            setLoading(false);
            feedback.open(true, "success", "Sucesso", undefined, informationMessage, undefined, false,
                ["Continuar"], ["contained"], [() => {
                    feedback.close();
                    props.onCancel();
                    cleanData();
                    clients.fetchEntities();
                }]);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const cleanData = () => {
        setName("");
        setNameError("");
        setCpfCnpj("");
        setSubscriptionType(undefined);
        setSubscriptionPlan(undefined);
        setSubscriptionPlanName("");
        setSubscriptionPlanError(undefined);
        setInitialSubscriptionDate(null);
        setInitialSubscriptionDateError(undefined);
        setFinalSubscriptionDate(null);
        setFinalSubscriptionDateError(undefined);
        setCountry("")
        setPostalCode("")
        setStreet("")
        setNumber("")
        setAdditionalInformation("")
        setDistrict("")
        setState("")
        setCityCode("")
        setCityName("")
    };

    return (
        <RegisterTemplate
            title={edit ? "Editar cliente" : "Novo cliente"}
            subtitle=""
            cancelClick={() => props.onCancel()}
            okClick={() => save()}>
            <Box display="flex" maxHeight="450px" sx={{ overflowY: "auto" }} paddingTop={1}>
                <Grid container display="flex" rowSpacing="24px" sx={{
                    maxHeight: "60vh",
                    overflowY: "auto"
                }}>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Nome"
                            placeholder="Nome do produto"
                            value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeText(event, setName)}
                            error={nameError !== ""} helperText={nameError} />
                    </Grid>
                    {country == "BR" &&
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth size="small"
                                label="CNPJ"
                                placeholder="CNPJ da empresa"
                                value={cpfCnpj}
                                error={cpfCnpjError !== ""} helperText={cpfCnpjError}
                                {
                                ...register("cpfCnpj", {
                                    onChange: (
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => setCpfCnpj(formatCnpj(event.target.value)),
                                })
                                } />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="E-mail"
                            placeholder="E-mail da empresa"
                            value={email}
                            error={emailError !== ""} helperText={emailError}
                            {...register("email", {
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => setEmail(event.target.value),
                                pattern: {
                                    value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: `Favor informar um e-mail válido`,
                                },
                            })
                            } />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" sx={{ width: "100%" }}>
                            <InputLabel>País</InputLabel>
                            <Select
                                size="small"
                                id='country-select'
                                value={country}
                                label="País"
                                onChange={(event: any) => {
                                    setCountry(event.target.value);
                                }
                                }>
                                {countriesData.map((_country) => {
                                    return <MenuItem value={_country.sigla}>{_country.nome_pais}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="CEP"
                            placeholder=""
                            value={postalCode}
                            error={postalCodeError !== ""} helperText={postalCodeError}
                            {
                            ...register("postalCode", {
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (country == "BR")
                                        setPostalCode(formatPostalCode(event.target.value));
                                    else
                                        setPostalCode(event.target.value);
                                },
                            })
                            }
                        />
                    </Grid>
                    {country == "BR" &&
                        <Grid item xs={12}>
                            <FormControl variant="outlined" sx={{ width: "100%" }}>
                                <InputLabel>Uf</InputLabel>
                                <Select
                                    size="small"
                                    id='uf-select'
                                    value={state}
                                    label="uf"
                                    onChange={(event: any) => setState(event.target.value)}
                                >
                                    {States.map((_state) => {
                                        return <MenuItem value={_state.Uf}>{_state.Nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {country == "BR" ?
                        <Grid item xs={12}>
                            <FormControl variant="outlined" sx={{ width: "100%" }}>
                                <InputLabel>Cidade</InputLabel>
                                <Select
                                    size="small"
                                    id='city-select'
                                    value={cityName}
                                    label="Cidade"
                                    onChange={(event) => {
                                        setCityName(event.target.value);
                                        for (let i = 0; i < filteredCitiesByUf.length; i++) {
                                            const _city = filteredCitiesByUf[i];
                                            if (_city.Nome == event.target.value) {
                                                setCityCode(_city.Codigo.toString());
                                            }
                                        }
                                    }
                                    }
                                >
                                    {filteredCitiesByUf.map((_city) => {
                                        return <MenuItem value={_city.Nome}>{_city.Nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <TextField variant="outlined" fullWidth size="small"
                                label="CEP"
                                placeholder=""
                                value={cityName}
                                error={cityNameError !== ""} helperText={cityNameError}
                                {
                                ...register("cityName", {
                                    onChange: (
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => setCityName(event.target.value)
                                })
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Bairro"
                            placeholder="Bairro, Distrito, etc."
                            value={district}
                            error={districtError !== ""} helperText={districtError}
                            {
                            ...register("district", {
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => setDistrict(event.target.value),
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Logradouro"
                            placeholder=""
                            value={street}
                            error={streetError !== ""} helperText={streetError}
                            {
                            ...register("street", {
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => setStreet(event.target.value),
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Número"
                            placeholder=""
                            value={number}
                            error={numberError !== ""} helperText={numberError}
                            {...register("number", {
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => setNumber(event.target.value),
                            })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" fullWidth size="small"
                            label="Complemento"
                            placeholder=""
                            value={additionalInformation}
                            {...register("additionalInformation", {
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>
                                ) => setAdditionalInformation(event.target.value)
                            })}
                        />
                    </Grid>
                    {auth.isSuperAdmin() &&
                        <Grid item xs={12}>
                            <Typography variant="caption" textTransform="initial">Permissão para alterar assinatura</Typography>
                            <Switch checked={canChangeSubscription} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCanChangeSubscription(event.target.checked)} />
                        </Grid>
                    }
                </Grid>
            </Box>
        </RegisterTemplate>
    )
}

export default ClientsRegister;