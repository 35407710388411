import { FC } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { Container, Spacing, Text } from "@pix-force/react-atoms";
import { PADDING } from "@pix-force/react-atoms/lib/utils/consts";

import theme from "src/components/theme";
import { ISubscriptionInformationFeature } from "src/types";

const SubscriptionInformationFeature: FC<ISubscriptionInformationFeature> = (params: ISubscriptionInformationFeature) => {
    return (
        <Container fluid flexStart>
            <Container inline centered>
                {params.hasRights && <CheckCircleIcon color="secondary" />}
                {!params.hasRights && <DoDisturbOnIcon color="error" />}
                <Spacing left={PADDING / 2} />
                <Text size={params.fontSize ?? 15} light color={theme.palette.background.paper}>
                    {params.text}
                </Text>
            </Container>
        </Container>
    )
}

export default SubscriptionInformationFeature;
